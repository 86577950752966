import { Collapse } from "@nextui-org/react";
import MultipleSelect from "../../MultipleSelect/MultipleSelect";

import { SiAntdesign } from "react-icons/si";
import {
  arquitecto_diseno_edificio,
  construccion_diseno_edificio,
  edificacion_diseno_edificio,
} from "../../../Utils/MultiSelectLists";

const Design = (props: any) => {
  const { formik } = props;
  return (
    <Collapse
      title="Diseño del edificio"
      contentLeft={<SiAntdesign size={30} />}
    >
      <div
        style={{
          padding: "0px 15px 15px 20px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          gap: "1rem",
          alignItems: "start",
        }}
      >
        <MultipleSelect
          multiple
          title={"Edificación"}
          values={formik.values.edificacion_diseno_edificio}
          onChange={(e: any) => {
            formik.setFieldValue("edificacion_diseno_edificio", e);
          }}
          list={edificacion_diseno_edificio}
        ></MultipleSelect>

        <MultipleSelect
          multiple
          title={"Construcción"}
          values={formik.values.construccion_diseno_edificio}
          onChange={(e: any) => {
            formik.setFieldValue("construccion_diseno_edificio", e);
          }}
          list={construccion_diseno_edificio}
        ></MultipleSelect>

        <MultipleSelect
          multiple
          title={"Arquitecto"}
          values={formik.values.arquitecto_diseno_edificio}
          onChange={(e: any) => {
            formik.setFieldValue("arquitecto_diseno_edificio", e);
          }}
          list={arquitecto_diseno_edificio}
        ></MultipleSelect>
      </div>
    </Collapse>
  );
};

export default Design;
