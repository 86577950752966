import { Collapse } from "@nextui-org/react";
import { TbLocation } from "react-icons/tb";
import {
  entorno_promocion,
  orientacion,
} from "../../../Utils/MultiSelectLists";
import MultipleSelect from "../../MultipleSelect/MultipleSelect";

const Location = (props: any) => {
  const { formik } = props;
  return (
    <Collapse
      title="Ubicación de la promoción"
      contentLeft={<TbLocation size={30} />}
    >
      <div
        style={{
          padding: "0px 15px 15px 20px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          gap: "1rem",
          alignItems: "start",
        }}
      >
        <MultipleSelect
          multiple
          title={"Entorno"}
          values={formik.values.entorno_promocion}
          onChange={(e: any) => {
            formik.setFieldValue("entorno_promocion", e);
          }}
          list={entorno_promocion}
        ></MultipleSelect>

        <MultipleSelect
          multiple
          title={"Orientación"}
          values={formik.values.orientacion_preferences}
          onChange={(e: any) => {
            formik.setFieldValue("orientacion_preferences", e);
          }}
          list={orientacion}
        ></MultipleSelect>
      </div>
    </Collapse>
  );
};

export default Location;
