import { Collapse } from "@nextui-org/react";
import Design from "./Collapse/Design";
import Endowments from "./Collapse/Endowments";
import HouseWanted from "./Collapse/HouseWanted";
import Location from "./Collapse/Location";
import QualityAndEquipment from "./Collapse/QualityAndEquipment";

function PreferencesForm(props: any) {
  const { formik, setTabCaracteristicas } = props;

  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <Collapse.Group splitted css={{ borderTop: "" }}>
        <HouseWanted formik={formik} />
        <Endowments formik={formik} />
        <Location formik={formik} />
        <Design formik={formik} />
        <QualityAndEquipment
          formik={formik}
          setTabCaracteristicas={setTabCaracteristicas}
        />
      </Collapse.Group>
    </div>
  );
}

export default PreferencesForm;
