import { List } from "@mui/material";
import uuid from "react-uuid";
import "./LisHeader.css";
import NavItem from "./NavItem";

export default function MainListItems(props: any) {
  const data = props.menu ?? [];
  return (
    <>
      <List component={"nav"}>
        {data
          .filter((item: any) => item.canView === true)
          .map((e: any, i: number) => {
            return (
              <NavItem
                key={uuid()}
                item={e}
                setOpen={props.setOpen}
                open={props.open}
              ></NavItem>
            );
          })}
      </List>
    </>
  );
}
