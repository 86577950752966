import { Card } from "@nextui-org/react";
import BuildingBox from "./BuildingBox";

function Castillete(props: any) {
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "100px",
          background: "var(--bs-body-bg);",
        }}
      >
        <Card
          style={{
            border: "1px solid black",
            width: "fit-content",
            height: "fit-content",
          }}
        >
          <div
            style={{
              padding: "1rem",
              gap: "1rem",
              flexDirection: "column-reverse",
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "1rem",
                height: "200px",
                justifyContent: "center",
              }}
            >
              <BuildingBox
                name={"Piso 1A"}
                size={120}
                bedrooms={4}
                bathrooms={2}
                available
                price={"300.000€"}
              ></BuildingBox>
              <BuildingBox
                name={"Piso 1B"}
                size={120}
                bedrooms={4}
                bathrooms={2}
                price={"300.000€"}
              ></BuildingBox>
              <BuildingBox
                name={"Piso 1C"}
                size={120}
                bedrooms={4}
                bathrooms={2}
                price={"300.000€"}
              ></BuildingBox>
              <BuildingBox
                available
                name={"Piso 1D"}
                size={120}
                bedrooms={4}
                bathrooms={2}
                price={"300.000€"}
              ></BuildingBox>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                width: "100%",
                height: "200px",
                justifyContent: "center",
              }}
            >
              <BuildingBox
                name={"Piso 2A"}
                size={120}
                bedrooms={4}
                bathrooms={2}
                available
                price={"300.000€"}
              ></BuildingBox>
              <BuildingBox
                name={"Piso 2B"}
                size={120}
                bedrooms={3}
                bathrooms={2}
                available
                price={"250.000€"}
              ></BuildingBox>
              <BuildingBox
                name={"Piso 2C"}
                size={60}
                bedrooms={2}
                bathrooms={1}
                price={"200.000€"}
              ></BuildingBox>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                width: "100%",
                height: "200px",
                justifyContent: "center",
              }}
            >
              <BuildingBox
                name={"Piso 3A"}
                size={120}
                bedrooms={4}
                bathrooms={2}
                price={"300.000€"}
              ></BuildingBox>
              <BuildingBox
                name={"Piso 3B"}
                size={90}
                bedrooms={3}
                bathrooms={2}
                price={"250.000€"}
              ></BuildingBox>
              <BuildingBox
                available
                name={"Piso 3C"}
                size={60}
                bedrooms={2}
                bathrooms={1}
                price={"200.000€"}
              ></BuildingBox>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}

export default Castillete;
