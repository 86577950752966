import { Collapse } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import {
  Vivienda_actual_en_prop__pc,
  aficiones,
  con_quien_compra,
  ocupacion__c,
  tipo_de_unidad_familiar__pc,
  vivesDeAlquiler,
} from "../../../Utils/MultiSelectLists";
import { archetypeCanHaveChildren } from "../../../Utils/commonUtils";
import { sayToFormikDataHasChanged } from "../../../Utils/sellerUtils";
import MultipleSelect from "../../MultipleSelect/MultipleSelect";
import CustomInput from "../../Seller/CustomInput/CustomInput";
import SliderCustom from "../../Slider/Slider";
import HowManyChildren from "./HowManyChildren";
import { InputAdornment, TextField } from "@mui/material";
import { formatNumber } from "../../../Utils/formatNumber";

export default function SocioeconomicInformation(props: any) {
  const [children, setChildren] = useState(false);

  const { formik } = props;

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
  ];

  const canHaveChildren = Object.keys(archetypeCanHaveChildren);

  useEffect(() => {
    setChildren(
      canHaveChildren.some(
        (key) => key.toString() === formik.values?.relationalStyle?.toString()
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.relationalStyle]);

  return (
    <Collapse
      title="Inf. Socioeconómica"
      contentLeft={<BsInfoCircle size={30} />}
    >
      <div
        style={{
          padding: "0px 15px 15px 20px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          alignItems: "start",
        }}
      >
        <MultipleSelect
          title={"Tipo de unidad familiar"}
          values={formik.values.Tipo_de_unidad_familiar__pc}
          onChange={(e: any) => {
            formik.setFieldValue("Tipo_de_unidad_familiar__pc", e);
          }}
          list={tipo_de_unidad_familiar__pc}
        ></MultipleSelect>

        <CustomInput
          type="text"
          css={{
            width: "100%",
            fontSize: "17px",
            fontWeight: "bold",
          }}
          clearable
          id="Miembros_en_la_unidad_familiar__pc"
          defaultValue={formik.values.Miembros_en_la_unidad_familiar__pc}
          value={
            formik.values.Miembros_en_la_unidad_familiar__pc
              ? formik.values.Miembros_en_la_unidad_familiar__pc.toString()
              : ""
          }
          initialValue={
            formik.values.Miembros_en_la_unidad_familiar__pc
              ? formik.values.Miembros_en_la_unidad_familiar__pc.toString()
              : ""
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.target.value.replace(/[.,]/g, ""); // Eliminar comas y puntos /^[0-9\b]+$/
            const isValidInput = /^\d*$/.test(inputValue); // Verificar que solo contiene dígitos

            if (isValidInput && parseInt(inputValue) < 100) {
              formik.setFieldValue(
                "Miembros_en_la_unidad_familiar__pc",
                parseInt(inputValue, 10)
              );
            }
          }}
          label="¿Cuántos miembros tiene la familia?"
          placeholder="¿Cuántos miembros tiene la familia?"
          displayedInClientView={true}
        />

        {children && (
          <>
            <SliderCustom
              title="¿Cuántos hijos tienes?"
              value={
                formik.values.Numero_de_hijos__c
                  ? parseInt(formik.values.Numero_de_hijos__c)
                  : 0
              }
              step={1}
              max={6}
              marks={marks}
              id="Numero_de_hijos__c"
              onChange={(e: any) =>
                formik.setFieldValue("Numero_de_hijos__c", e.target.value)
              }
            ></SliderCustom>

            <HowManyChildren
              childNumber={
                formik.values.Numero_de_hijos__c
                  ? parseInt(formik.values.Numero_de_hijos__c)
                  : 0
              }
              formik={formik}
            />
          </>
        )}

        <MultipleSelect
          title={"¿A qué te dedicas?"}
          values={formik.values.Ocupacion__c}
          onChange={(e: any) => {
            formik.setFieldValue("Ocupacion__c", e);
          }}
          list={ocupacion__c}
        ></MultipleSelect>

        <MultipleSelect
          title={"¿Tienes vivienda en propiedad? *"}
          values={formik.values.Vivienda_actual_en_prop__pc}
          onChange={(e: any) => {
            formik.setFieldValue("Vivienda_actual_en_prop__pc", e);
          }}
          list={Vivienda_actual_en_prop__pc}
        ></MultipleSelect>

        <MultipleSelect
          title={"¿Vives de alquiler? *"}
          values={formik.values.Vive_de_alquiler__c}
          onChange={(e: any) => {
            formik.setFieldValue("Vive_de_alquiler__c", e);
            if (e === "No") formik.setFieldValue("Cuantia_mensual_alquiler", 0);
          }}
          list={vivesDeAlquiler}
        ></MultipleSelect>

        {formik.values.Vive_de_alquiler__c === "Si" && (
          <CustomInput
            type="text"
            css={{
              width: "100%",
              fontSize: "17px",
              fontWeight: "bold",
            }}
            clearable
            label="¿Cuál es la cuantía de alquiler mensual"
            value={formik.values.Cuantia_mensual_alquiler}
            defaultValue={formik.values.Cuantia_mensual_alquiler}
            onChange={(event: any) => {
              const value = formatNumber(event.target.value);
              formik.setFieldValue("Cuantia_mensual_alquiler", value);
            }}
            contentRight={<span>€</span>}
          />
        )}

        <MultipleSelect
          values={formik.values.Con_quien_compra__c}
          title={"¿Con quién vas a comprar?"}
          onChange={(e: any) => {
            formik.setFieldValue("Con_quien_compra__c", e);
          }}
          list={con_quien_compra}
        ></MultipleSelect>

        <MultipleSelect
          multiple
          values={formik.values.aficiones}
          title={"¿Cuáles son tus aficiones?"}
          onChange={(e: any) => {
            formik.setFieldValue("aficiones", e);
          }}
          list={aficiones}
        ></MultipleSelect>

        <CustomInput
          type="text"
          css={{ width: "100%", fontSize: "17px", fontWeight: "bold" }}
          clearable
          id="que_busca"
          onChange={(e: any) => {
            formik.setFieldValue("que_busca", e.target.value);
          }}
          label="¿Qué es lo que estás buscando?"
          placeholder="¿Qué es lo que estás buscando?"
          value={formik.values.que_busca}
          initialValue={formik.values.que_busca}
          displayedInClientView={true}
        />
      </div>
    </Collapse>
  );
}
