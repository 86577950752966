export const formatNumber = (value: string, isDecimal: boolean = false) => {
  const valueDecimal = isDecimal ? value.split(",") : value;
  const splitValue = isDecimal
    ? valueDecimal[0].split("").filter((char) => !isNaN(parseInt(char)))
    : value.split("").filter((char) => !isNaN(parseInt(char)));

  const result = [];
  for (let i = splitValue.length - 1; i >= 0; i--) {
    if ((splitValue.length - 1 - i) % 3 === 0 && i !== splitValue.length - 1) {
      result.push(".");
    }
    if (splitValue[i] === "." || !isNaN(parseInt(splitValue[i])))
      result.push(splitValue[i]);
  }
  if (isDecimal) {
    let formattedValue = result.reverse().join("");
    if (valueDecimal.length > 1) {
      formattedValue = formattedValue + "," + valueDecimal[1];
    }
    return formattedValue;
  } else {
    const formattedValue = result.reverse().join("");
    return formattedValue;
  }
};
