import SwitchClient from "./SwitchClient";

export default function ClientViews(props: any) {
  const {
    Index,
    assets,
    plano,
    documentsToShow,
    messageProduct,
    principalProduct,
    tax,
    selectedHouse,
    clientId,
    promotionId,
    likedElements,
    functionToDisplayCalendar,
    functionToDisplayBudget,
  } = props;
  return (
    <SwitchClient
      messageProduct={messageProduct}
      principalProduct={principalProduct}
      tax={tax}
      value={props.value}
      Index={Index}
      assets={assets}
      plano={plano}
      documentsToShow={documentsToShow}
      likedElements={likedElements}
      selectedHouse={selectedHouse}
      clientId={clientId}
      promotionId={promotionId}
      functionToDisplayCalendar={functionToDisplayCalendar}
      functionToDisplayBudget={functionToDisplayBudget}
    ></SwitchClient>
  );
}
