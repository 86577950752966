import { Card, Checkbox } from "@nextui-org/react";
import { useState } from "react";

function TipAndTaskComponent(props: any) {
  const [selected, setSelected] = useState<boolean>(false);

  const handleCheck = () => {
    setSelected(!selected);
  };

  return (
    <Card
      variant="bordered"
      style={{
        minWidth: "199px",
        maxWidth: "349px",
        padding: "9px",
        height: "195px",
        overflow: "inherit",
      }}
    >
      <h1
        style={{
          color: "var(--secondary-color)",
          fontFamily: "lis-title",
          fontSize: "1.5rem",
          fontWeight: "bold",
        }}
      >
        {props.title}
      </h1>
      <p
        style={{
          fontFamily: "lis-body",
          fontSize: "15px",
          lineHeight: "1.4",
        }}
      >
        {props.content}
      </p>
      <div
        onClick={handleCheck}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          alignItems: "end",
          height: "100%",
        }}
      >
        <Checkbox
          isSelected={selected}
          defaultSelected={selected}
          onChange={handleCheck}
          css={{
            fontFamily: "lis-body",
          }}
          size={"sm"}
        >
          <span style={{ fontSize: "14px" }}>Hecho</span>
        </Checkbox>
      </div>
    </Card>
  );
}

export default TipAndTaskComponent;
