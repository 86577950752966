import AllImgVideo from "./AllImgVideo";
import OnlyText from "./OnlyText";

export default function SwitchSellerClient(props: any) {
  const { value, setIndex, sendJson, assets, idClient, idPromotion } = props;

  switch (value) {
    case "all_img_video":
      return (
        <AllImgVideo
          setIndex={setIndex}
          sendJson={sendJson}
          assets={assets?.imagenes_y_videos}
          img={assets?.principal[0]?.value}
          idClient={idClient}
          idPromotion={idPromotion}
        ></AllImgVideo>
      );

    case "map":
      return (
        <OnlyText
          text={"Se esta mostrando el mapa de la zona en la venta del cliente"}
        ></OnlyText>
      );

    case "parcela":
      return (
        <AllImgVideo
          setIndex={setIndex}
          sendJson={sendJson}
          assets={assets?.parcela}
          img={assets?.principal[0]?.value}
          idClient={idClient}
          idPromotion={idPromotion}
        ></AllImgVideo>
      );

    case "infografias_zonas_comunes":
      return (
        <AllImgVideo
          setIndex={setIndex}
          sendJson={sendJson}
          assets={assets?.infografias_zonas_comunes}
          img={assets?.principal[0]?.value}
          idClient={idClient}
          idPromotion={idPromotion}
        ></AllImgVideo>
      );

    case "infografia_interior":
      return (
        <AllImgVideo
          setIndex={setIndex}
          sendJson={sendJson}
          assets={assets?.infografia_interior}
          img={assets?.principal[0]?.value}
          idClient={idClient}
          idPromotion={idPromotion}
        ></AllImgVideo>
      );

    case "infografia_exterior":
      return (
        <AllImgVideo
          setIndex={setIndex}
          sendJson={sendJson}
          assets={assets?.infografias_exteriores}
          img={assets?.principal[0]?.value}
          idClient={idClient}
          idPromotion={idPromotion}
        ></AllImgVideo>
      );

    case "maqueta_fisica":
      return (
        <OnlyText
          text={"Enseña al cliente la maqueta física de la promoción"}
          external
        ></OnlyText>
      );

    case "maqueta_virtual":
      return (
        <OnlyText
          text={"Se esta enseñando el Habiteo en la ventana del cliente"}
        ></OnlyText>
      );

    case "vista_virtual":
      return (
        <OnlyText
          text={"Se está enseñando el Habiteo en la ventana del cliente"}
        ></OnlyText>
      );

    case "visita_virtual_zzcc":
      return (
        <OnlyText
          text={"Se está enseñando el Habiteo en la ventana del cliente"}
        ></OnlyText>
      );

    case "showroom":
      return (
        <OnlyText text={"Enseña al cliente el showroom"} external></OnlyText>
      );

    case "fin_de_la_cita":
      return (
        <AllImgVideo
          setIndex={setIndex}
          sendJson={sendJson}
          assets={assets?.imagenes_y_videos}
          img={assets?.principal[0]?.value}
          idClient={idClient}
          idPromotion={idPromotion}
        ></AllImgVideo>
      );

    default:
      //return <DefaultImage img={assets?.principal[0]?.value}></DefaultImage>;
      return (
        <AllImgVideo
          setIndex={setIndex}
          sendJson={sendJson}
          assets={assets?.imagenes_y_videos}
          img={assets?.principal[0]?.value}
          idClient={idClient}
          idPromotion={idPromotion}
        ></AllImgVideo>
      );
  }
}
