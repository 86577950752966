import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const getFilters = (body: any) => {
  return axios
    .post(
      process.env.REACT_APP_API_BACKEND + `/Filter/filters`,
      body,
      getDefaultConfigHeader()
    )
    .then((res) => res.data)
    .catch((error) => console.error("Error"));
};

export const getFilteredData = (body: any) => {
  return axios
    .post(
      process.env.REACT_APP_API_BACKEND + `/Filter/`,
      body,
      getDefaultConfigHeader()
    )
    .then((res) => res.data)
    .catch((error) => console.error("Error"));
};
