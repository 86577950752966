import { useEffect, useState } from "react";
import { getLikesService } from "../../Service/LikesService/LikesService";

import { getHouseInfoByAssets } from "../../Service/HouseInfoByAssets/HouseInfoByAssets";
import AedasLoading from "../AedasLoading/AedasLoading";

// import { Card } from "@nextui-org/react";
// import { Document, Page } from "react-pdf";
// import { filterPDFName, priceFormatter } from "../../Utils/commonUtils";
import { MdErrorOutline } from "react-icons/md";
import FavoriteHouseList from "./FavoriteHouseList";

const Favorites = (props: any) => {
  const [pointHouse, setPointHouse] = useState<any>({});
  const [infoHouse, setInfoHouse] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [likedElements, setLikedElements] = useState<any>();

  const {
    clientId,
    promotionId,
    functionToDisplayCalendar,
    functionToDisplayBudget,
    selectedHouse,
    updatedLikes,
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      if (clientId && promotionId) {
        let responseLikes = await getLikesService(clientId, promotionId);
        let responseInfoAssets = await getHouseInfoByAssets(
          responseLikes.data?.planos?.map((plano: any) => plano.asset_id)
        );
        setInfoHouse(responseInfoAssets?.data);
        setLikedElements(responseLikes?.data);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [clientId, promotionId, updatedLikes]);

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }

  return (
    <section className="d-flex flex-wrap align-items-center justify-content-center h-100 listado-favoritos">
      <div className="d-flex flex-column">
        <h1 className="pt-3" style={{ fontSize: "30px" }}>
          ¿Será alguna de estas la casa de tus sueños?
        </h1>
        {likedElements?.planos.length > 0 ? (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(230px, 250px))",
              gap: "10px",
              padding: "0.5rem 1rem",
              minWidth: "750px",
            }}
          >
            {likedElements?.planos.map((map: any) => {
              let informationHouse = infoHouse.find(
                (info: any) => info.Asset_Id === map.asset_id
              );

              return (
                <FavoriteHouseList
                  informationHouse={informationHouse}
                  map={map}
                  selectedHouse={
                    selectedHouse === informationHouse?.Id ? true : false
                  }
                  pointHouse={pointHouse}
                  setPointHouse={setPointHouse}
                  functionToDisplayCalendar={functionToDisplayCalendar}
                  functionToDisplayBudget={functionToDisplayBudget}
                />
              );
            })}
          </div>
        ) : (
          <p className="font-family-lis d-flex align-items-center gap-1 mt-4 text-center">
            <MdErrorOutline size={24} color={"#666"} /> Parece que aún no te ha
            gustado alguna vivienda.
          </p>
        )}
      </div>
    </section>
  );
};

export default Favorites;
