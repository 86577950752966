import { Button, Modal } from "@nextui-org/react";
import { FiCheckCircle } from "react-icons/fi";
import { useHistory } from "react-router";
// @ts-ignore
import "react-tabs/style/react-tabs.css";
import uuid from "react-uuid";

import { setNoteByAppointment } from "../../Service/NotesByAppoinmentServices/NotesByAppoinmentServices";

const ModalAppointment = (props: any) => {
  const { id, idClient, idSeller } = props;

  const history = useHistory();

  return (
    <>
      <Modal.Body css={{ padding: "2rem" }}>
        <h1
          style={{
            fontFamily: "lis-body",
            fontSize: "30px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Antes de iniciar la visita recuerda no olvidar estos elementos:
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "1rem",
          }}
        >
          <div>
            <FiCheckCircle color="var(--aedas-green)" size={25}></FiCheckCircle>{" "}
            <span style={{ fontFamily: "lis-body" }}>
              {" "}
              Tener preparado y ordenado el escenario fisico y virtual.
            </span>
          </div>
          <div>
            <FiCheckCircle color="var(--aedas-green)" size={25}></FiCheckCircle>{" "}
            <span style={{ fontFamily: "lis-body" }}>
              Presentarte con tu nombre.
            </span>
          </div>
          <div>
            <FiCheckCircle color="var(--aedas-green)" size={25}></FiCheckCircle>{" "}
            <span style={{ fontFamily: "lis-body" }}>
              Confirmar el nombre de la cita.
            </span>
          </div>
          <div>
            <FiCheckCircle color="var(--aedas-green)" size={25}></FiCheckCircle>{" "}
            <span style={{ fontFamily: "lis-body" }}>
              Llevarle a una zona cómoda.
            </span>
          </div>
          <div>
            <FiCheckCircle color="var(--aedas-green)" size={25}></FiCheckCircle>{" "}
            <span style={{ fontFamily: "lis-body" }}>
              Explicarle en qué va a consistir la visita.
            </span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          css={{ background: "var(--blue-lis)" }}
          onClick={() => {
            // Guardamos las notas
            const nota =
              sessionStorage.getItem("myNotes") !== null
                ? sessionStorage.getItem("myNotes")
                : "";
            setNoteByAppointment({
              idClient,
              idSeller,
              note: nota ? nota : "",
            }).then((response) => response.data);

            //@ts-ignore
            const uuidValue: any = uuid();

            // Guardamos la url del cliente y que se pueda volver a abrir al pulsar el botón correspondiente.
            sessionStorage.setItem(
              "clientUrl",
              JSON.stringify(`/appointment/${id}/client/` + uuidValue)
            );

            const width = window.screen.availWidth;
            const height = window.screen.availHeight;
            const left = window.screen.width; // posición izquierda de la ventana.
            const top = 0; // posición superior de la ventana.

            window.open(
              `/appointment/${id}/client/` + uuidValue,
              "_blank",
              `width=${width}, height=${height}, left=${left}, top=${top}`
            );

            history.push(`/appointment/${id}/seller/` + uuidValue);
          }}
        >
          Empezar
        </Button>
      </Modal.Footer>
    </>
  );
};

export default ModalAppointment;
