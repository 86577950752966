import { Collapse } from "@nextui-org/react";
import GeneralInformation from "./Collapse/GeneralInformation";
import PurchaseReason from "./Collapse/PurchaseReason";
import SocioeconomicInformation from "./Collapse/SocioeconomicInformation";
import "./FormClient.css";
import PaymentForm from "./FormPayment/PaymentForm";

function FormClient(props) {
  const { formik, setTipBudget } = props;

  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <Collapse.Group splitted css={{ borderTop: "" }}>
        <GeneralInformation formik={formik} />
        <SocioeconomicInformation formik={formik} />
        <PurchaseReason formik={formik} />

        <PaymentForm formik={formik} setTipBudget={setTipBudget} />
      </Collapse.Group>
    </div>
  );
}

export default FormClient;
