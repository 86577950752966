import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";
/**
 * Obtiene los planos de una promoción, a saber, dossier comercial y material de calidades.
 * @param idPromo
 * @returns
 */
export const getDocumentsService = (idPromo: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + `/Asset/addDossier/${idPromo}`,
    getDefaultConfigHeader()
  );
};
