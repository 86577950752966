import { ExpandLess, ExpandMore } from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Collapse, List, ListItem } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useEffect, useState } from "react";
import uuid from "react-uuid";
import { ModalExit } from "../ModalExit/ModalExit";
import { useLocation } from "react-router-dom";

export default function NavItem(props: any) {
  const { item, setOpen, open } = props;
  const [expandOpen, setExpandOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const [selectedChild, setSelectedChild] = useState<any>(null);

  const location = useLocation();

  const handleNavItem = () => {
    if (item.children) {
      setExpandOpen(!expandOpen);
      setOpen(true);
    } else {
      item.onClick(item.localPath);
    }
  };

  useEffect(() => {
    if (!open) {
      setExpandOpen(false);
    }
  }, [open]);

  useEffect(() => {
    if (window.location.pathname === item.localPath) {
      setSelected(true);
    } else if (window.location.pathname !== item.localPath) {
      setSelected(false);
    }

    if (
      item.children &&
      item.children.filter((a: any) => a.localPath === window.location.pathname)
    ) {
      setSelectedChild(
        item.children.find((a: any) => a.localPath === window.location.pathname)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    if (
      selectedChild &&
      selectedChild ===
        item.children.find((c: any) => c.localPath === selectedChild.localPath)
    ) {
      setSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChild]);

  const handleNavChildItem = (child: any) => {
    item.onClick(child.localPath);
  };

  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  return (
    <>
      <ModalExit
        open={modalOpen}
        setOpen={setModalOpen}
        action={handleNavItem}
      />
      <ListItemButton
        className={selected && open ? "selectedItem" : ""}
        onClick={() => {
          const path = item.localPath.split("/");
          if (path[path.length - 1] === "configurador") handleNavItem();
          else handleModalOpen();
        }}
      >
        <ListItemIcon
          className={
            selected && !open
              ? "selected selectedBar"
              : selected
              ? "selected"
              : ""
          }
          style={{ fill: selected ? "var(--secondary-color)" : "" }}
        >
          <div>
            <div className='d-flex justify-content-center'>
              {item.icon ?? <DashboardIcon />}
            </div>
            {!open ? (
              <div style={{ width: "37px", paddingTop: "5px" }}>
                <h1
                  style={{
                    fontSize: "10px",
                    color: selected ? "var(--secondary-color)" : "",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  {item.shortName}
                </h1>
              </div>
            ) : (
              <></>
            )}
          </div>
        </ListItemIcon>
        <ListItemText
          style={{ color: selected ? "var(--secondary-color)" : "" }}
          primary={item.name}
        />
        {item.children ? expandOpen ? <ExpandLess /> : <ExpandMore /> : <></>}
      </ListItemButton>
      <Collapse
        in={expandOpen}
        timeout='auto'
        unmountOnExit
        className='navNested'
      >
        {item.children ? (
          <List component='div' disablePadding>
            {item.children
              .filter((childFilter: any) => childFilter.canView === true)
              .map((child: any, j: number) => {
                return (
                  <ListItem
                    key={uuid()}
                    button
                    onClick={() => {
                      handleNavChildItem(child);
                    }}
                  >
                    <ListItemIcon
                      className={
                        selectedChild &&
                        selectedChild.localPath === child.localPath
                          ? "selected"
                          : ""
                      }
                      style={{
                        fill:
                          selectedChild &&
                          selectedChild.localPath === child.localPath
                            ? "var(--secondary-color)"
                            : "",
                      }}
                    >
                      {child.icon ?? <DashboardIcon />}
                    </ListItemIcon>
                    <ListItemText
                      style={{
                        color:
                          selectedChild &&
                          selectedChild.localPath === child.localPath
                            ? "var(--secondary-color)"
                            : "",
                      }}
                      primary={child.name}
                    />
                  </ListItem>
                );
              })}
          </List>
        ) : (
          <></>
        )}
      </Collapse>
    </>
  );
}
