import { useEffect, useState } from "react";
import { MdErrorOutline } from "react-icons/md";
import uuid from "react-uuid";
import { cleanFeatureName, getCleanFeatureName } from "../../Utils/commonUtils";
import { FEATURES_LIST } from "../../Utils/feautresList";
import PillFeature from "./PillFeature";

const PillFeatures = (props: any) => {
  const [diffArray, setDiffArray] = useState([]);
  const [arraySortedFeatures, setArraySortedFeatures] = useState([]);

  const { formik, setContMatches, features, sellerWindow } = props;

  const translateNameFeatures: any = []; // Array donde guardaremos las características con nombre adecuado de Salesforce para ser comparadas.
  let sanitizedNameFeatures: any = []; // Array donde guardaremos las características con el nombre sanitizado.

  useEffect(() => {
    setContMatches(0);

    // Hacemos el mapeo del texto de Salesforce para que sea legible.
    features.forEach((feature: any) => {
      let name = FEATURES_LIST?.find((f: any) => f.Api_name === feature)?.Label;
      translateNameFeatures?.push(name);
      sanitizedNameFeatures?.push(cleanFeatureName(name));
    });

    //@ts-ignore
    let sortedElements = [...translateNameFeatures]?.sort((a, b) =>
      a.localeCompare(b)
    );
    //@ts-ignore
    setArraySortedFeatures(sortedElements);

    if (sellerWindow) {
      let count = 0;

      sortedElements.forEach((feature: any) => {
        if (
          formik?.values.calidades_equipamiento_vivienda?.includes(
            cleanFeatureName(feature)
          )
        ) {
          count += 1;
        }
      });
      setContMatches(count);

      if (formik?.values?.calidades_equipamiento_vivienda.length > 0) {
        // Obtenemos aquellas opciones que el cliente ha marcado pero que no están en la promoción
        const diff = formik?.values?.calidades_equipamiento_vivienda?.filter(
          (valor: any) =>
            !sanitizedNameFeatures?.includes(cleanFeatureName(valor))
        );
        setDiffArray(diff);
      }
    }
  }, [features, formik?.values?.calidades_equipamiento_vivienda]);

  return features ? (
    <>
      <div className="d-flex flex-wrap gap-3 py-1">
        {/* Estas opciones salen en el seller.tsx --> sellerWindow = true y en listado de citas, en el tab de preferencias --> sellerWindow = false */}
        {sellerWindow
          ? arraySortedFeatures?.map((feature: any) => {
              let match =
                formik?.values?.calidades_equipamiento_vivienda?.includes(
                  cleanFeatureName(feature)
                )
                  ? true
                  : false;

              return (
                <PillFeature
                  match={match}
                  key={uuid()}
                  name={feature}
                ></PillFeature>
              );
            })
          : arraySortedFeatures?.map((feature: any) => {
              return (
                <PillFeature
                  match={false}
                  key={uuid()}
                  name={feature}
                ></PillFeature>
              );
            })}
      </div>
      {formik?.values?.calidades_equipamiento_vivienda.length > 0 &&
        diffArray.length > 0 && (
          <div className="d-flex flex-column">
            <p className="mt-4 mb-0">
              <b>Objecciones con esta promoción ({diffArray.length})</b>
            </p>
            <div className="d-flex flex-wrap gap-3 py-1">
              {diffArray
                .sort((a: any, b: any) => a.localeCompare(b))
                .map((feature: any) => {
                  return (
                    <PillFeature
                      notFound={true}
                      key={uuid()}
                      name={getCleanFeatureName(feature)}
                    ></PillFeature>
                  );
                })}
            </div>
          </div>
        )}
    </>
  ) : (
    <p className="font-family-lis d-flex align-items-center gap-1">
      <MdErrorOutline size={24} color={"#666"} /> Sin datos
    </p>
  );
};

export default PillFeatures;
