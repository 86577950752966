import { Button, Modal } from "@nextui-org/react";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { setDurationByAppointmentId } from "../../Service/AppointmentDuration/AppointmentDuration";

const ModalNotIntroduces = (props: any) => {
  const { where, id_appoiment, setModalNotIntroduces } = props;

  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      duration: "00:00:00",
      showedup: false,
      reason: "",
    },
    validationSchema: null,
    onSubmit: (values) => {
      setDurationByAppointmentId(
        id_appoiment,
        formik.values.duration,
        false,
        formik.values.reason,
        false,
        false,
        false
      )
        .then(() => {
          toast.success("La cita ha sido marcada como no realizada");
          history.push("/");
          setModalNotIntroduces(false);
        })
        .catch(() => {
          toast.error("Ha habido un error, intentelo más tarde");
        });
    },
  });

  return (
    <Modal.Body css={{ padding: "2rem" }}>
      <h1
        style={{
          fontFamily: "lis-body",
          fontSize: "20px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        El cliente no se ha presentado
      </h1>
      <p className="text-center">
        Se va a indicar que el cliente no se ha presentado a la cita, ¿esto es
        así?
      </p>
      {/* <form>
        <TextareaAutosize
          id="reason"
          aria-label="non-submitted note"
          name="non-submiteed-note"
          onChange={(e: any) => formik.setFieldValue("reason", e.target.value)}
          value={formik.values.reason}
          style={{
            width: where === "appointment" ? 535 : 300,
            height: 300,
            border: "1px solid #e2e3e3",
            borderRadius: "10px",
          }}
        ></TextareaAutosize>
      </form> */}
      <Modal.Footer className="d-flex justify-content-center">
        <Button onClick={() => setModalNotIntroduces(false)}>Cerrar</Button>
        <Button
          onClick={() => formik.submitForm()}
          css={{
            background: "var(--blue-lis)",
            backgroundColor: "var(--aedas-red)",
          }}
        >
          El cliente no ha acudido
        </Button>
      </Modal.Footer>
    </Modal.Body>
  );
};

export default ModalNotIntroduces;
