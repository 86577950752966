import { Card } from "@nextui-org/react";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
//@ts-ignore
import MultipleSelect from "../../Components/MultipleSelect/MultipleSelect";
import { arquetipo } from "../../Utils/MultiSelectLists";

import SelectRelationalStyle from "../../Components/Seller/SelectRelationalStyle/SelectRelationalStyle";

import "./ClientInfo.css";

const ClientInfo = (props: any) => {
  const { formik, client, setTipRelationalStyle, setTipArchetype } = props;

  //let name = client.displayName.split(" ");
  let customerInitials = client.displayName
    .split(" ")
    .slice(0, 2)
    .map((palabra: any) => palabra.charAt(0).toUpperCase())
    .join("");

  return (
    <Card className="card-client-info">
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "80px",
            }}
          >
            <Card
              style={{
                height: "3.5rem",
                width: "3.5rem",
                background: "var(--lis-ligthblue)",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "20px",
                color: "var(--secondary-color)",
              }}
            >
              {customerInitials}
            </Card>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{}}>
              <h1
                className="customer-name"
                style={{
                  textAlign: "start",
                  fontFamily: "lis-title",
                  fontSize: "17px",
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                {client.displayName}
              </h1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <h1
                  className="customer-email"
                  style={{
                    textAlign: "start",
                    fontFamily: "lis-body",
                    fontSize: "15px",
                    display: "flex",
                    color: "grey",
                    marginBottom: "5px",
                  }}
                >
                  <div style={{ marginRight: "5px" }}>
                    <AiOutlineMail></AiOutlineMail>
                  </div>

                  {client.email}
                </h1>
                <h1
                  className="customer-phone"
                  style={{
                    textAlign: "start",
                    fontFamily: "lis-body",
                    fontSize: "15px",
                    display: "flex",
                    color: "grey",
                    paddingLeft: "0px",
                  }}
                >
                  <div style={{ marginRight: "5px" }}>
                    <AiOutlinePhone size={15}></AiOutlinePhone>
                  </div>

                  {client.phone ?? "-"}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-arquetipo-estilo-relacional"
          style={{ marginTop: "5px" }}
        >
          <div
            className="multipleselect-arquetipo"
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "start",
              marginLeft: "10px",
            }}
          >
            {/* Comentarios informativos para nuestros yos del futuro:
            - Lo que antes era arquetipo y tiene las opciones de: Agua, Aire, Tierra y Fuego ahora se denomina "Estilo relacional"
            - Por supuesto, lo que era antes el estilo relacional (el desplegable) ahora se denomina arquetipo (sigue siendo el desplegable).  
          */}
            <MultipleSelect
              xs
              values={formik.values.Arquetipo__c}
              onChange={(e: any) => {
                formik.setFieldValue("Arquetipo__c", e);
                setTipArchetype(e);
              }}
              list={arquetipo}
            ></MultipleSelect>
          </div>
          <div
            className="relational-style"
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            <SelectRelationalStyle
              formikOptions={formik}
              setTipRelationalStyle={setTipRelationalStyle}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ClientInfo;
