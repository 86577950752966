import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { useEffect, useState } from "react";
import { SellerInfoView } from "../../Utils/SellerInfoView";
import ClientRowInfo from "./ClientRowInfo";

function ClientViewInfo(props: any) {
  const { Values, allQuestionsAnswered } = props;
  const [isHighlightedPresupuesto, setIsHighlightedPresupuesto] =
    useState<boolean>(false);

  useEffect(() => {
    setIsHighlightedPresupuesto(true);
    setTimeout(() => {
      setIsHighlightedPresupuesto(false);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Values?.Presupuesto__c?.[0], Values?.Presupuesto__c?.[1]]);

  const [isHighlightedFinanciar, setIsHighlightedFinanciar] =
    useState<boolean>(false);

  useEffect(() => {
    setIsHighlightedFinanciar(true);
    setTimeout(() => {
      setIsHighlightedFinanciar(false);
    }, 3000);
  }, [
    Values?.PerFinanciacionNecesario__c?.[0],
    Values?.PerFinanciacionNecesario__c?.[1],
  ]);
  return (
    <div className='questions-and-answers'>
      <div className='answered-questions'>
        {SellerInfoView.map((info: any, index: number) => {
          return (
            <ClientRowInfo
              key={index + "answered-questions"}
              Values={Values}
              title={info.title}
              valueName={info.valueName}
            ></ClientRowInfo>
          );
        })}
        {Values?.Presupuesto__c &&
          (Number(Values?.Presupuesto__c[0]) > 0 ||
            (Number(Values?.Presupuesto__c[0]) === 0 &&
              Number(Values?.Presupuesto__c[1]) < 3000000)) && (
            <>
              <p className='mx-2 my-1 lh-1'>
                <span className='question'>
                  <TrendingFlatIcon className='arrow-icon' /> Mi presupuesto
                  aproximado es:{" "}
                </span>
                <span
                  className={
                    isHighlightedPresupuesto ? "response" : "font-bold"
                  }
                >
                  {`Desde ${
                    Values?.Presupuesto__c[0]
                      ? new Intl.NumberFormat().format(Values.Presupuesto__c[0])
                      : "0"
                  }€ hasta ${
                    Values?.Presupuesto__c[1]
                      ? new Intl.NumberFormat().format(Values.Presupuesto__c[1])
                      : "0"
                  }€`}
                </span>
              </p>
              <div className='client-separator'></div>
            </>
          )}
        {Values?.PerFinanciacionNecesario__c &&
          (Number(Values?.PerFinanciacionNecesario__c[0]) > 0 ||
            (Number(Values?.PerFinanciacionNecesario__c[0]) === 0 &&
              Number(Values?.PerFinanciacionNecesario__c[1]) < 100)) && (
            <>
              <p className='mx-2 my-1 lh-1'>
                <span className='question'>
                  <TrendingFlatIcon className='arrow-icon' /> Me gustaría
                  financiar:{" "}
                </span>
                <span
                  className={isHighlightedFinanciar ? "response" : "font-bold"}
                >
                  {`Entre el ${Values?.PerFinanciacionNecesario__c[0]}% y el 
        ${Values?.PerFinanciacionNecesario__c[1]}%`}
                </span>
              </p>
              <div className='client-separator'></div>
            </>
          )}
      </div>

      <div className='unanswered-questions mt-4'>
        {!allQuestionsAnswered && (
          <p className='question'>Nos gustaría conocer</p>
        )}
        {!Values?.Como__c && (
          <>
            <p className='mx-2 my-1 lh-1'>
              <span className='question'>
                <TrendingFlatIcon className='arrow-icon' />
                Cómo has conocido a AEDAS Homes.
              </span>
            </p>
            <div className='client-separator'></div>
          </>
        )}
        {!Values?.DestinoVivienda__c && (
          <>
            <p className='mx-2 my-1 lh-1'>
              <span className='question'>
                {" "}
                <TrendingFlatIcon className='arrow-icon' /> El motivo de la
                compra
              </span>
            </p>
            <div className='client-separator'></div>
          </>
        )}
        {!Values?.Miembros_en_la_unidad_familiar__pc && (
          <>
            <p className='mx-2 my-1 lh-1'>
              <span className='question'>
                <TrendingFlatIcon className='arrow-icon' /> ¿Cuántos sois en la
                familia?
              </span>
            </p>
            <div className='client-separator'></div>
          </>
        )}
        {Number(Values?.Dormitorios__c) < 1 && (
          <>
            <p className='mx-2 my-1 lh-1'>
              <span className='question'>
                <TrendingFlatIcon className='arrow-icon' /> Número de
                habitaciones
              </span>
            </p>
            <div className='client-separator'></div>
          </>
        )}
        {!Values?.Plazo_de_entrega_deseado__c && (
          <>
            <p className='mx-2 my-1 lh-1'>
              <span className='question'>
                <TrendingFlatIcon className='arrow-icon' /> El plazo de entrega
                que necesitas
              </span>
            </p>
            <div className='client-separator'></div>
          </>
        )}
        {!Values?.que_busca && (
          <>
            <p className='mx-2 my-1 lh-1'>
              <span className='question'>
                <TrendingFlatIcon className='arrow-icon' /> ¿Qué estás buscando?
              </span>
            </p>
            <div className='client-separator'></div>
          </>
        )}
        {Values?.Presupuesto__c &&
          Number(Values?.Presupuesto__c[0]) === 0 &&
          Number(Values?.Presupuesto__c[1]) === 3000000 && (
            <>
              <p className='mx-2 my-1 lh-1'>
                <span className='question'>
                  <TrendingFlatIcon className='arrow-icon' /> Tu presupuesto
                  aproximado.
                </span>
              </p>
              <div className='client-separator'></div>
            </>
          )}

        {(Values?.PerFinanciacionNecesario__c?.length === 0 ||
          (Values?.PerFinanciacionNecesario__c &&
            Number(Values?.PerFinanciacionNecesario__c[0]) === 0 &&
            Number(Values?.PerFinanciacionNecesario__c[1]) === 100)) && (
          <>
            <p className='mx-2 my-1 lh-1'>
              <span className='question'>
                <TrendingFlatIcon className='arrow-icon' /> Cuánto te gustaría
                financiar.
              </span>
            </p>
            <div className='client-separator'></div>
          </>
        )}
      </div>
    </div>
  );
}

export default ClientViewInfo;
