import { Autocomplete, IconButton, TextField } from "@mui/material";
import { Card } from "@nextui-org/react";
import {
  AiFillHeart,
  AiOutlineCalendar,
  AiOutlineDesktop,
  AiOutlineDollar,
  AiOutlineEye,
} from "react-icons/ai";
import { setLikesHouseService } from "../../Service/LikesService/LikesService";

import { useEffect, useMemo, useState } from "react";
import { MdErrorOutline } from "react-icons/md";
import {
  getPlainName,
  writeCorrectNameOfPromotion,
} from "../../Utils/commonUtils";
import "./AvailableProduct.css";
import { PdfRender } from "../PdfRender/PdfRender";
import { getTax } from "../../Utils/taxUtils";
import { formatNumber } from "../../Utils/formatNumber";

interface PdfRenderProps {
  pdfs: any[];
  assetId: any;
}

const PreviewPDF = ({ pdfs, assetId }: PdfRenderProps) => {
  // Contiene algun documento
  if (pdfs && pdfs?.length > 0) {
    if (assetId) {
      // Hay un documento seleccionado
      return (
        <img
          src={pdfs.find((a: any) => a.asset_id === assetId)?.url_thumbnail}
          alt="Thumbnail"
          style={{ width: "100%", height: "100%" }}
        />
      );
    } else if (pdfs[0]?.url_thumbnail) {
      // Esta generado el thumbnail
      return (
        <img
          src={pdfs[0]?.url_thumbnail}
          alt="Thumbnail"
          style={{ width: "100%", height: "100%" }}
        />
      );
    } else if (pdfs[0]?.url) {
      // Esta generado la url
      return <PdfRender url={pdfs[0]?.url}></PdfRender>;
    }
  }
  // No hay documentos subidos
  return (
    <div
      className="d-flex justify-content-center align-items-center h-100"
      style={{ backgroundColor: "#fafafa" }}
    >
      <MdErrorOutline size={24} color={"#666"} /> Sin planos
    </div>
  );
};

export const AvaiableProduct = (props: any) => {
  const {
    tax,
    principalProductClient,
    asset,
    // setselectedValue,
    sendJson,
    isClient,
    idClient,
    likes,
    setLikes,
    selectedHouse,
    pointHouse,
    setPointHouse,
    setPrincipalProduct,
    // principalProduct,
    // shadowProduct,
    // setShadowProduct,
    // disableAddToPrincipalProduct,
    canSendCalendarAndBudget,
  } = props;

  const [displayBudget, setDisplayBudget] = useState(false);
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [displayClientWindow, setDisplayClientWindow] = useState(false);
  const [assetId, setAssetId] = useState(null);
  const [plainHouseUrl, setPlainHouseUrl] = useState(null);

  useEffect(() => {
    // Si la casa se desmarca de mostrar en la pantalla, quitamos el presupuesto y el calendario de pagos.
    if (pointHouse !== asset.house_id) {
      setDisplayBudget(false);
      setDisplayCalendar(false);
    }
  }, [pointHouse]);

  // Optenemos todos los assets de la vivienda
  const planos = useMemo(() => asset?.assets, [asset]);

  /**
   * Comprueba si un plano se ha marcado como favorito o no para guardarlo en la BBDD a través del endpoint.
   * @param id Id del plano
   */
  const handleFavorite = (id: any) => {
    // Comprobamos que no exista previamente el asset_id
    if (!likes?.find((e: any) => e === id)) {
      //@ts-ignore
      let results = [...likes, id];
      setLikes(results);

      // Guardado en el endpoint.
      setLikesHouseService(idClient, [...likes, id]);
    } else {
      // Quitamos el id del plano.
      let dataFiltered = likes.filter((e: any) => e !== id);
      setLikes(dataFiltered);

      // Guardado en el endpoint.
      setLikesHouseService(
        idClient,
        likes.filter((e: any) => e !== id)
      );
    }
  };

  /**
   * Envia o quita una vivienda a la pantalla de cliente.
   */
  const handleSendHouseToCustomerView = () => {
    // Filter devuelve un array, si es 0, la vivienda no existe.
    let exist = principalProductClient.filter((element: any) => {
      return element.house_id === asset.house_id;
    });

    // Si no existe, lo añadimos.
    if (exist.length === 0) {
      setDisplayClientWindow(true);
      setPrincipalProduct([...principalProductClient, asset]);
    } else {
      // La vivienda existe, la quitamos.
      setPrincipalProduct(
        principalProductClient.filter((product: any) => {
          return product.house_id !== asset.house_id;
        })
      );

      // Marcamos en azul la casa que coincide con su id.
      setDisplayClientWindow(false);
    }

    const list = { asset: principalProductClient, tax: tax };

    sendJson({
      event: "change_clientview",
      value: "listado_viviendas",
      list: list,
    });
  };

  return (
    <Card
      className="AvailableProduct_tsx available-product"
      variant="bordered"
      style={{
        width: "auto", // 300px
        height: "360px",
        backgroundColor: selectedHouse ? "#c5edf9" : "white",
      }}
      isHoverable
    >
      <div
        style={{
          display: "flex",
          padding: "0.8rem",
          flexDirection: "column",
          gap: "0.7rem",
        }}
      >
        <div className="d-flex flex-column" style={{ width: "100%" }}>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column gap-2">
              <span
                className="portal-adress-and-house"
                style={{
                  textAlign: "left",
                  color: "var(--secondary-color)",
                  fontFamily: "lis-title",
                  fontSize: "25px",
                  lineHeight: "1",
                }}
              >
                P{asset?.portal_address}-
                {asset?.address + asset?.house_n?.at(-1)}
              </span>
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <p
                style={{
                  marginBottom: "0px",
                  fontFamily: "lis-title",
                  fontSize: "22px",
                  lineHeight: "1",
                }}
              >
                {asset.m2_built.toFixed(2)}m<span className="sup">2</span>
              </p>
              {/* {!disableAddToPrincipalProduct ? ( */}
              {!isClient && (
                <IconButton
                  className="btn-icono-listado-productos"
                  title="Enviar/quitar vivienda a la pantalla de cliente"
                  onClick={() => {
                    handleSendHouseToCustomerView();
                  }}
                  style={{
                    background: displayClientWindow ? "#c5edf9" : "#f4f4f4",
                    padding: "4px",
                    fontSize: "11px",
                    color: "#666666",
                  }}
                >
                  <AiOutlineDesktop size={20} />
                </IconButton>
              )}
            </div>
          </div>
          <div
            className="d-flex"
            style={{
              fontFamily: "lis-body",
              fontSize: "12px",
              paddingLeft: "2px",
              lineHeight: "1",
            }}
          >
            {writeCorrectNameOfPromotion(asset.promotion_name)}
          </div>
        </div>
        <div style={{ width: "100%", height: "90px" }}>
          <PreviewPDF pdfs={asset?.assets} assetId={assetId} />
        </div>
        <div
          className="d-flex align-items-center justify-content-center w-100"
          style={{ height: "45px", marginTop: "-5px" }}
        >
          {planos.length > 0 && !isClient && (
            <>
              <div className="w-100">
                <Autocomplete
                  disableClearable
                  id={`combo-box-plain-house-${asset.house_id}`}
                  loading={true}
                  options={planos}
                  sx={{ width: 180 }}
                  size="small"
                  defaultValue={() => {
                    if (planos.length > 1) {
                      return null;
                    } else {
                      const value = planos[0];
                      setAssetId(value.asset_id);
                      setPlainHouseUrl(value.url);
                      return value;
                    }
                  }}
                  getOptionLabel={(plano: any) => {
                    return getPlainName(plano.asset_name);
                  }}
                  renderInput={(params: any) => (
                    <TextField {...params} label="Planos" />
                  )}
                  onChange={(event: any, value: any) => {
                    setAssetId(value.asset_id);
                    setPlainHouseUrl(value.url);
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  zIndex: "5",
                  backgroundColor: "white",
                }}
              >
                {assetId && (
                  <div
                    style={{
                      paddingBottom: "4px",
                      background: "#fff",
                      borderRadius: "10px",
                      fontFamily: "lis-body",
                      fontSize: "12px",
                      display: "flex",
                      gap: "0.5rem",
                      justifyContent: "end",
                      alignItems: "center",
                      marginTop: "2px",
                      marginRight: "0px",
                    }}
                  >
                    <IconButton
                      className="btn-icono-listado-productos"
                      title="Mostrar/ocultar plano a la pantalla del cliente"
                      onClick={() => {
                        if (!isClient) {
                          setPointHouse(null);
                          sendJson({
                            event: "change_clientview",
                            value: "plano",
                            url: plainHouseUrl,
                          });
                        }
                      }}
                      style={{
                        background: "#f4f4f4",
                        padding: "4px",
                        fontSize: "18px",
                      }}
                    >
                      <AiOutlineEye size={20}></AiOutlineEye>
                    </IconButton>
                    <IconButton
                      className="btn-icono-listado-productos"
                      title="Guardar/quitar plano como favorito"
                      onClick={() => {
                        handleFavorite(assetId);
                      }}
                      style={{
                        background: "#f4f4f4",
                        padding: "4px",
                        fontSize: "18px",
                      }}
                    >
                      {likes?.find((e: any) => e === assetId) ? (
                        <AiFillHeart
                          color={"var(--aedas-red)"}
                          size={20}
                        ></AiFillHeart>
                      ) : (
                        <AiFillHeart size={20}></AiFillHeart>
                      )}
                    </IconButton>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        <div
          className="d-flex flex-column justify-content-between"
          style={{ height: "85px", marginTop: "5px" }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              marginTop: "-10px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "0.2rem",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              <span
                style={{
                  padding: "0.2rem",
                  background: "#8080802b",
                  borderRadius: "10px",
                  fontFamily: "lis-body",
                  fontSize: "12px",
                }}
              >
                {asset.bathrooms} baños
              </span>

              <span
                style={{
                  padding: "0.2rem",
                  background: "#8080802b",
                  borderRadius: "10px",
                  fontFamily: "lis-body",
                  fontSize: "12px",
                }}
              >
                {asset.bedrooms} dormitorios
              </span>
              {asset.storage_room ? (
                <span
                  style={{
                    padding: "0.2rem",
                    background: "#8080802b",
                    borderRadius: "10px",
                    fontFamily: "lis-body",
                    fontSize: "12px",
                  }}
                >
                  {asset.storage_room} trastero/s
                </span>
              ) : (
                <></>
              )}
              {asset.parking_slots ? (
                <span
                  style={{
                    padding: "0.2rem",
                    background: "#8080802b",
                    borderRadius: "10px",
                    fontFamily: "lis-body",
                    fontSize: "12px",
                  }}
                >
                  {asset.parking_slots} garaje/s
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div
            className="position-relative"
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                gap: 5,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {/* <div
                style={{
                  fontFamily: "lis-title",
                  fontSize: "14px",
                  // marginRight: "3px",
                }}
              >
                Desde{" "}
              </div> */}
              <div
                style={{
                  fontFamily: "lis-body",
                  fontSize: "27px",
                  lineHeight: "42px",
                  // marginTop: "-14px",
                }}
              >
                {
                  tax.isTax
                    ? new Intl.NumberFormat().format(asset.starting_price) // Formatear el precio con impuestos
                    : new Intl.NumberFormat().format(
                        asset.only_house_price_wo_iva
                      ) // Formatear el precio sin impuestos
                }
                €
              </div>
              <div
                style={{
                  width: 50,
                  fontFamily: "lis-title",
                  fontSize: "10px",
                  marginLeft: "7px",
                  textAlign: "left",
                }}
              >
                {getTax(asset, tax)}
              </div>
            </div>

            <div
              style={{ bottom: "7px", right: "-4px" }}
              className="position-absolute"
            >
              {!isClient && canSendCalendarAndBudget && (
                <div className="d-flex flex-column gap-2">
                  <IconButton
                    className="btn-icono-listado-productos"
                    title="Mostrar/ocultar hoja informativa"
                    onClick={() => {
                      setDisplayBudget(!displayBudget);
                      setDisplayCalendar(false);

                      // Marcamos en azul la casa que coincide con su id.
                      setPointHouse(asset.house_id);

                      sendJson({
                        event: "mostrar_presupuesto",
                        value: !displayBudget,
                        selectedHouse: !displayBudget ? asset.house_id : "",
                      });
                    }}
                    style={{
                      background: displayBudget ? "#c5edf9" : "#f4f4f4",
                      padding: "4px",
                      fontSize: "11px",
                      color: "#666666",
                    }}
                  >
                    <AiOutlineDollar size={20} />
                  </IconButton>
                  <IconButton
                    className="btn-icono-listado-productos"
                    title="Mostrar/ocultar calendario de pagos"
                    onClick={() => {
                      setDisplayCalendar(!displayCalendar);
                      setDisplayBudget(false);

                      // Marcamos en azul la casa que coincide con su id.
                      setPointHouse(asset.house_id);

                      sendJson({
                        event: "mostrar_calendario",
                        value: !displayCalendar,
                        selectedHouse: !displayCalendar ? asset.house_id : "",
                      });
                    }}
                    style={{
                      background: displayCalendar ? "#c5edf9" : "#f4f4f4",
                      padding: "4px",
                      fontSize: "16px",
                    }}
                  >
                    <AiOutlineCalendar size={20} />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Hipoteca */}
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: 5,
          }}
        >
          <div
            style={{
              fontFamily: "lis-title",
              fontSize: "10px",
            }}
          >
            Hipoteca aprox.
          </div>
          <div
            style={{
              fontFamily: "lis-body",
              fontSize: "14px",
              lineHeight: "42px",
            }}
          >
            {formatNumber(asset.mortgage.toFixed(0))}€/mes
          </div>
        </div>
      </div>
    </Card>
  );
};
