import { useEffect } from "react";
import { useFetch } from "../../Hooks/useFetch";
import { getHousePaymentSchedule } from "../../Service/HousePaymentSchedule/HousePaymentSchedule";
import { priceFormatter } from "../../Utils/commonUtils";

import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { Loading } from "@nextui-org/react";
import { BsCalendar2Week } from "react-icons/bs";

const CalendarPayment = (selectedHouse: any) => {
  const [calendar, isFetchingCalendar, updateData] = useFetch(
    getHousePaymentSchedule,
    selectedHouse.selectedHouse
  );

  useEffect(() => {
    updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHouse.selectedHouse]);

  return (
    <>
      {!isFetchingCalendar ? (
        calendar && (
          <section className='d-flex justify-content-center align-items-center h-100'>
            <div>
              <p className='font-family-lis h4 mb-2 d-flex align-items-center'>
                <BsCalendar2Week />
                &nbsp;Calendario de pagos
              </p>
              <div className='client-separator mb-2'></div>
              <p className='lh-1 font-family-lis h5'>
                <TrendingFlatIcon className='arrow-icon' /> Arras:{" "}
                {priceFormatter(calendar?.arras)}
              </p>
              <div className='client-separator'></div>
              <p className='my-2 lh-1 font-family-lis h5'>
                <TrendingFlatIcon className='arrow-icon' /> Contrato privado:{" "}
                {priceFormatter(calendar?.contrato_privado)}
              </p>
              <div className='client-separator'></div>
              {/* <p className="my-2 lh-1 font-family-lis h5">
                <TrendingFlatIcon className="arrow-icon" /> Número de cuotas:{" "}
                {calendar?.num_cuotas === 0 ? "Sin " : calendar?.num_cuotas}
                cuotas
              </p> */}
              {calendar?.num_cuotas === 0 ? (
                <></>
              ) : (
                <>
                  <div className='client-separator'></div>
                  {/* <p className='my-2 lh-1 font-family-lis h5'>
                    <TrendingFlatIcon className='arrow-icon' /> Cuota mensual:{" "}
                    {priceFormatter(
                      (calendar?.cuotas / calendar?.num_cuotas).toFixed(2)
                    )}
                  </p> */}
                  <div className='client-separator'></div>
                  <p className='my-2 lh-1 font-family-lis h5'>
                    <TrendingFlatIcon className='arrow-icon' /> Total cuotas:{" "}
                    {priceFormatter(calendar?.cuotas)}
                  </p>
                </>
              )}

              <div className='client-separator'></div>
              <p className='my-2 lh-1 font-family-lis h5'>
                <TrendingFlatIcon className='arrow-icon' /> Escritura pública:{" "}
                {priceFormatter(calendar?.escritura_publica)}
              </p>
              <div className='client-separator'></div>
              <p className='mt-2 font-family-lis h6'>
                Pago total de la vivienda:{" "}
                <span className='h5'>
                  {priceFormatter(
                    calendar?.arras +
                      calendar?.contrato_privado +
                      calendar?.cuotas +
                      calendar?.escritura_publica
                  )}
                </span>
              </p>
              <p className='mt-3 my-2 lh-1 font-family-lis'>
                <i>Todos los precios incluyen impuestos.</i>
              </p>
            </div>
          </section>
        )
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
            height: "100%",
          }}
        >
          <span style={{ fontSize: "1.5rem", fontFamily: "lis-title" }}>
            Cargando calendario
          </span>{" "}
          <Loading type='points' size='xl'></Loading>
        </div>
      )}
    </>
  );
};

export default CalendarPayment;
