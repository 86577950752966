import { FaPhotoVideo } from "react-icons/fa";
import TabsForm from "./TabsForm";

export default function Location(props: any) {
  const { formik } = props;
  const sections = [
    {
      button: {
        onClick: () => props.setselectedValue("map"),
        icon: <FaPhotoVideo></FaPhotoVideo>,
        name: "Mapa",
      },
      questions: [
        // {
        //   name: "Distribución de la vivienda",
        //   answers: normal_response,
        //   formik: "distribucion_de_la_vivienda",
        // },
        // {
        //   name: "Terrazas",
        //   answers: normal_response,
        //   formik: "terrazas",
        // },
        // {
        //   name: "Separación de zona de día/noche",
        //   answers: normal_response,
        //   formik: "separacion_de_zona_de_dia_y_noche",
        // },
      ],
    },
    {
      button: {
        onClick: () => props.setselectedValue("parcela"),
        icon: <FaPhotoVideo></FaPhotoVideo>,
        name: "Parcela",
      },
      questions: [
        // {
        //   name: "Orientación",
        //   answers: orientacion,
        //   formik: "orientacion",
        // },
        // {
        //   name: "Construcción",
        //   answers: normal_response,
        //   formik: "construccion",
        // },
        // {
        //   name: "Altura del edificio",
        //   answers: normal_response,
        //   formik: "alturaDelEdificio",
        // },
        // {
        //   name: "Arquitectura / Diseño",
        //   answers: normal_response,
        //   formik: "arquitecturaYDiseno",
        // },
      ],
    },
    {
      button: {
        onClick: () => props.setselectedValue("maqueta_virtual"),
        icon: <FaPhotoVideo></FaPhotoVideo>,
        name: "Maqueta virtual",
      },
      questions: [
        // {
        //   name: "Cercania al mar",
        //   answers: normal_response,
        //   formik: "cercaniaAlMar",
        // },
        // {
        //   name: "Orientación",
        //   answers: normal_response,
        //   formik: "orientacion",
        // },
        // {
        //   name: "Construcción",
        //   answers: normal_response,
        //   formik: "construccion",
        // },
      ],
    },
  ];
  return (
    <>
      <TabsForm formik={formik} sections={sections}></TabsForm>
    </>
  );
}
