export const SellerInfoView = [
  {
    title: "He conocido a AEDAS Homes a través de:",
    valueName: "Como__c",
  },
  {
    title: "El motivo de la compra es:",
    valueName: "DestinoVivienda__c",
  },
  {
    title: "Las habitaciones que necesito son:",
    valueName: "Dormitorios__c",
  },
  {
    title: "Mi plazo para la vivienda es:",
    valueName: "Plazo_de_entrega_deseado__c",
  },
  {
    title: "Estoy buscando:",
    valueName: "que_busca",
  },

  {
    title: "¿Cuántos sois en la familia?",
    valueName: "Miembros_en_la_unidad_familiar__pc",
  },
];
