import { Collapse } from "@nextui-org/react";
import {
  alimentacion,
  comprasCuidadoPersonal,
  educacion,
  ocioDeportes,
  repostaje,
  salud,
  transporte,
} from "../../../Utils/MultiSelectLists";
import MultipleSelect from "../../MultipleSelect/MultipleSelect";

import { BsCheckAll } from "react-icons/bs";

const Endowments = (props: any) => {
  const { formik } = props;

  return (
    <Collapse
      title="Dotaciones de la zona"
      contentLeft={<BsCheckAll size={30} />}
    >
      <div
        style={{
          padding: "0px 15px 15px 20px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          gap: "1rem",
          alignItems: "start",
        }}
      >
        <MultipleSelect
          multiple
          title={"Transporte"}
          values={formik.values.transporte}
          onChange={(e: any) => {
            formik.setFieldValue("transporte", e);
          }}
          list={transporte}
        ></MultipleSelect>

        <MultipleSelect
          multiple
          title={"Educación"}
          values={formik.values.educacion}
          onChange={(e: any) => {
            formik.setFieldValue("educacion", e);
          }}
          list={educacion}
        ></MultipleSelect>

        <MultipleSelect
          multiple
          title={"Salud"}
          values={formik.values.salud}
          onChange={(e: any) => {
            formik.setFieldValue("salud", e);
          }}
          list={salud}
        ></MultipleSelect>

        <MultipleSelect
          multiple
          title={"Ocio y deportes"}
          values={formik.values.ocio_y_deportes}
          onChange={(e: any) => {
            formik.setFieldValue("ocio_y_deportes", e);
          }}
          list={ocioDeportes}
        ></MultipleSelect>

        <MultipleSelect
          multiple
          title={"Alimentación"}
          values={formik.values.alimentacion}
          onChange={(e: any) => {
            formik.setFieldValue("alimentacion", e);
          }}
          list={alimentacion}
        ></MultipleSelect>

        <MultipleSelect
          multiple
          title={"Compras cuidado personal"}
          values={formik.values.compras_y_cuidado_personal}
          onChange={(e: any) => {
            formik.setFieldValue("compras_y_cuidado_personal", e);
          }}
          list={comprasCuidadoPersonal}
        ></MultipleSelect>

        <MultipleSelect
          multiple
          title={"Repostaje"}
          values={formik.values.repostaje}
          onChange={(e: any) => {
            formik.setFieldValue("repostaje", e);
          }}
          list={repostaje}
        ></MultipleSelect>
      </div>
    </Collapse>
  );
};

export default Endowments;
