import { ShowFormButtons } from "./ShowFormButtons";

export default function ZonasComunes(props: any) {
  const selectedValue1 = "infografias_zonas_comunes";
  const selectedName1 = "Infografias ZZ.CC";
  const selectedValue2 = "visita_virtual_zzcc";
  const selectedName2 = "Visita virtual ZZ.CC";
  return (
    <ShowFormButtons
      formik={props.formik}
      setselectedValue={props.setselectedValue}
      selectedValue1={selectedValue1}
      selectedName1={selectedName1}
      selectedValue2={selectedValue2}
      selectedName2={selectedName2}
    ></ShowFormButtons>
  );
}
