import Slider from "@mui/material/Slider";
import { styled } from "@mui/system";

const PrettoSlider = styled(Slider)({
  color: "#0088a6",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    background: "#0088a6",
    // lineHeight: 1.2,
    // fontSize: 12,
    // background: "unset",
    // padding: 0,
    // width: 40,
    // height: 40,
    // borderRadius: "50% 50% 50% 0",
    // backgroundColor: "#0088a6",
    // transformOrigin: "bottom left",
    // transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    // "&:before": { display: "none" },
    // "&.MuiSlider-valueLabelOpen": {
    //   transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    // },
    // "& > *": {
    //   transform: "rotate(45deg)",
    // },
  },
});

export default PrettoSlider;
