import axios from "axios";
import { ReverseGeoDecode } from "../../Interfaces/reverseGeodecode";

/**
 *
 * @param latitude
 * @param longitude
 * @returns
 */
export const getReverseGeocode = async (
  latitude: string | number,
  longitude: string | number
): Promise<ReverseGeoDecode> => {
  try {
    const response = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${process.env.REACT_APP_API_KEY_MAPBOX}`
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
