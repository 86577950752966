import Calidades from "./Calidades";
import Exterior from "./Exterior";
import Location from "./Location";
import PisoPiloto from "./PisoPiloto";
import ZonasComunes from "./ZonasComunes";

export default function SwitchSelector(props: any) {
  const { step, formik } = props;
  switch (step) {
    case 1:
      return (
        <Location
          formik={formik}
          setselectedValue={props.setselectedValue}
        ></Location>
      );

    case 2:
      return (
        <Exterior
          formik={formik}
          setselectedValue={props.setselectedValue}
        ></Exterior>
      );

    case 3:
      return (
        <ZonasComunes
          formik={formik}
          setselectedValue={props.setselectedValue}
        ></ZonasComunes>
      );
    case 4:
      return (
        <Calidades
          formik={formik}
          setselectedValue={props.setselectedValue}
        ></Calidades>
      );

    case 5:
      return (
        <PisoPiloto formik={formik} setselectedValue={props.setselectedValue}>
          {" "}
        </PisoPiloto>
      );
    default:
      return <></>;
  }
}
