import { Autocomplete, IconButton, TextField } from "@mui/material";
import { Card, Checkbox } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import {
  priceFormatter,
  writeCorrectNameOfPromotion,
} from "../../Utils/commonUtils";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";

const SummaryHouse = (props: any) => {
  const {
    house,
    map,
    planosAEnviar,
    setPlanosAEnviar,
    sendJson,
    pointHouse,
    setPointHouse,
    generateDia,
    setOpenModalSign,
    setIsDIA,
    setHouseId,
    setAssetId,
  } = props;

  const [displayPlane, setDisplayPlane] = useState(false);

  useEffect(() => {
    if (pointHouse !== house?.Id) {
      setDisplayPlane(false);
    }
  }, [pointHouse]);

  const handlePlanosAEnviar = () => {
    if (
      planosAEnviar.find((pf: any) => {
        return pf.asset_id === map.asset_id;
      })
    ) {
      setPlanosAEnviar(
        planosAEnviar.filter(
          (favorito: any) => favorito.asset_id !== map.asset_id
        )
      );
    } else {
      setPlanosAEnviar([...planosAEnviar, map]);
    }
  };

  return (
    <Card
      className="AvailableProduct_tsx"
      variant="bordered"
      style={{
        width: "auto",
        height: generateDia && house.en_Andalucia ? "300px" : "215px",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "0.7rem",
          flexDirection: "column",
          gap: "0.7rem",
        }}
      >
        <div className="d-flex flex-column" style={{ width: "100%" }}>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column gap-2">
              <span
                className="portal-adress-and-house"
                style={{
                  textAlign: "left",
                  color: "var(--secondary-color)",
                  fontFamily: "lis-title",
                  fontSize: "25px",
                  lineHeight: "1",
                }}
              >
                {/* P{house?.Portal_Direccion__c}-
                {house?.Planta__c + house?.Puerta_Direccion__c} */}
                P{house?.Portal_Direccion__c}-
                {
                  /* {house?.Planta__c + */
                  house?.Piso_Direccion__c + house?.Puerta_Direccion__c
                }
              </span>
            </div>

            <div className="d-flex gap-2 justify-content-end">
              <p
                style={{
                  textAlign: "left",
                  marginBottom: "0px",
                  fontFamily: "lis-title",
                  fontSize: "22px",
                  lineHeight: "1",
                }}
              >
                {house?.M2_Constr__c?.toFixed(2)}m<span className="sup">2</span>
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div
              className="d-flex"
              style={{
                fontFamily: "lis-body",
                fontSize: "12px",
                paddingLeft: "2px",
                lineHeight: "1",
              }}
            >
              {writeCorrectNameOfPromotion(house?.Promocion__r?.Name)}
            </div>
            <div className="d-flex justify-content-end">
              <span
                style={{
                  fontFamily: "lis-body",
                  fontSize: "12px",
                  lineHeight: "1",
                }}
              >
                {map.asset_label}
              </span>
            </div>
          </div>
        </div>
        <div style={{ height: "1px", backgroundColor: "#eaeaea" }}></div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
            marginBottom: "10px",
            height: "45px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0.2rem",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <span
              style={{
                padding: "0.2rem",
                background: "#8080802b",
                borderRadius: "10px",
                fontFamily: "lis-body",
                fontSize: "12px",
              }}
            >
              {house?.N_De_Bagnos__c} baños
            </span>

            <span
              style={{
                padding: "0.2rem",
                background: "#8080802b",
                borderRadius: "10px",
                fontFamily: "lis-body",
                fontSize: "12px",
              }}
            >
              {house?.N_De_Habitaciones__c} dormitorios
            </span>
            {house?.Tiene_Trastero__c ? (
              <span
                style={{
                  padding: "0.2rem",
                  background: "#8080802b",
                  borderRadius: "10px",
                  fontFamily: "lis-body",
                  fontSize: "12px",
                }}
              >
                {house?.Tiene_Trastero__c} trastero/s
              </span>
            ) : (
              <></>
            )}
            {house?.Plazas_De_Parking_Incluidas__c ? (
              <span
                style={{
                  padding: "0.2rem",
                  background: "#8080802b",
                  borderRadius: "10px",
                  fontFamily: "lis-body",
                  fontSize: "12px",
                }}
              >
                {house?.Plazas_De_Parking_Incluidas__c} garaje/s
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="position-relative d-flex w-100">
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "end",
            }}
          >
            <span
              style={{
                fontFamily: "lis-title",
                fontSize: "14px",
                marginRight: "3px",
              }}
            >
              Desde{" "}
            </span>
            <span
              style={{
                fontFamily: "lis-body",
                fontSize: "27px",
                lineHeight: "28px",
                marginTop: "3px",
              }}
            >
              {priceFormatter(house?.PVP__c)}
            </span>
          </div>

          <div
            style={{ bottom: "-3px", right: "-3px" }}
            className="position-absolute"
          >
            <div className="d-flex flex-column gap-2">
              <div>
                <IconButton
                  className="btn-icono-listado-productos"
                  title="Mostrar/ocultar plano a la pantalla del cliente"
                  onClick={() => {
                    setPointHouse(map.id_house);
                    setDisplayPlane(!displayPlane);
                    sendJson({
                      event: "change_clientview",
                      value: !displayPlane
                        ? "plano"
                        : "mostrar_viviendas_favoritas",
                      url: map.url,
                    });
                  }}
                  style={{
                    background: displayPlane ? "#c5edf9" : "#f4f4f4",
                    padding: "4px",
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  <AiOutlineEye size={20}></AiOutlineEye>
                </IconButton>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            height: "1px",
            backgroundColor: "#eaeaea",
            marginTop: "-10px",
            marginBottom: "-10px",
          }}
        ></div>
        {generateDia && house.en_Andalucia && (
          <div style={{ marginTop: "10px" }}>
            <div>Documentos a firmar:</div>
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <div>
                <Button
                  style={{
                    fontFamily: "lis-title",
                    fontSize: "14px",
                    backgroundColor: "#E7E7E7",
                    color: "black",
                    border: "none",
                    borderRadius: "10px",
                  }}
                  disabled={map.dia_signed}
                  onClick={() => {
                    setAssetId(house.Asset_Id);
                    setIsDIA(true);
                    setHouseId(house.Id);
                    setOpenModalSign(true);
                  }}
                >
                  DIA {map.dia_signed ? "✓" : ""}
                </Button>
              </div>
              <div>
                <Button
                  style={{
                    fontFamily: "lis-title",
                    fontSize: "14px",
                    backgroundColor: "#E7E7E7",
                    color: "black",
                    border: "none",
                    borderRadius: "10px",
                  }}
                  disabled={map.dia_proof_signed}
                  onClick={() => {
                    setIsDIA(false);
                    setAssetId(house.Asset_Id);
                    setHouseId(house.Id);
                    setOpenModalSign(true);
                  }}
                >
                  Justificante {map.dia_proof_signed ? "✓" : ""}
                </Button>
              </div>
            </div>
          </div>
        )}
        <div>
          <Checkbox defaultSelected onChange={handlePlanosAEnviar}>
            <span style={{ fontFamily: "lis-title", fontSize: "14px" }}>
              ¿Enviar en la hoja informativa?
            </span>
          </Checkbox>
        </div>
      </div>
    </Card>
  );
};

export default SummaryHouse;
