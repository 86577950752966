import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { useEffect, useState } from "react";
import { capitalizeString } from "../../Utils/commonUtils";

function ClientRowInfo(props: any) {
  const { Values, title, valueName } = props;
  const [isHighlighted, setIsHighlighted] = useState<boolean>(false);

  useEffect(() => {
    setIsHighlighted(true);
    setTimeout(() => {
      setIsHighlighted(false);
    }, 3000);
  }, [Values[valueName]]);
  return (
    <>
      {" "}
      {Values[valueName] ? (
        <>
          <p className="mx-2 my-1 lh-1">
            <span className="question">
              <TrendingFlatIcon className="arrow-icon" /> {title}
            </span>
            <span className={isHighlighted ? "response" : "font-bold"}>
              &nbsp;{capitalizeString(Values[valueName].toString())}
            </span>
          </p>
          <div className="client-separator"></div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default ClientRowInfo;
