import { Collapse } from "@nextui-org/react";
import { AiOutlineHome } from "react-icons/ai";
import {
  caracteristicas_vivienda_buscada,
  Garajes_necesarios__c,
  Vivienda_buscada__c,
} from "../../../Utils/MultiSelectLists";
import MultipleSelect from "../../MultipleSelect/MultipleSelect";
import SliderCustom from "../../Slider/Slider";

const HouseWanted = (props: any) => {
  const { formik } = props;

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
  ];

  return (
    <Collapse
      title="Vivienda buscada"
      contentLeft={<AiOutlineHome size={30} />}
    >
      <div
        style={{
          padding: "0px 15px 15px 20px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          gap: "1rem",
          alignItems: "start",
        }}
      >
        <SliderCustom
          title="¿Cuántos dormitorios estás buscando?"
          displayedInClientView={true}
          value={+formik.values.Dormitorios__c}
          step={1}
          max={6}
          marks={marks}
          id="Dormitorios__c"
          onChange={(e: any) =>
            formik.setFieldValue("Dormitorios__c", e.target.value)
          }
        ></SliderCustom>

        <MultipleSelect
          multiple
          title={"¿Qué tipo de inmueble estás buscando?"}
          values={formik.values.Vivienda_buscada__c}
          onChange={(e: any) => {
            formik.setFieldValue("Vivienda_buscada__c", e);
          }}
          list={Vivienda_buscada__c}
        ></MultipleSelect>

        <MultipleSelect
          title={"¿Cuántos garajes necesitas?"}
          values={formik.values.Garajes_necesarios__c}
          onChange={(e: any) => {
            formik.setFieldValue("Garajes_necesarios__c", e);
          }}
          list={Garajes_necesarios__c}
        ></MultipleSelect>

        <MultipleSelect
          multiple
          title={"Características"}
          values={formik.values.caracteristicas_vivienda_buscada}
          onChange={(e: any) => {
            formik.setFieldValue("caracteristicas_vivienda_buscada", e);
          }}
          list={caracteristicas_vivienda_buscada}
        ></MultipleSelect>
      </div>
    </Collapse>
  );
};

export default HouseWanted;
