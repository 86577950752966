import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { IconButton } from "@mui/material";
import React from "react";
import { AiFillHeart } from "react-icons/ai";
import ImageGallery from "react-image-gallery";
import "./ImagenSlider.css";
import { withRouter } from "react-router-dom";

const PREFIX_URL =
  "https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/";

class ImagenSlider extends React.Component {
  constructor() {
    super();
    this.state = {
      showIndex: false,
      showBullets: true,
      infinite: true,
      showThumbnails: true,
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: true,
      showGalleryPlayButton: true,
      showNav: true,
      isRTL: false,
      slideDuration: 450,
      slideInterval: 6000,
      slideOnThumbnailOver: false,
      thumbnailPosition: "bottom",
      showVideo: {},
      useWindowKeyDown: true,
      idFromUrl: "",
    };

    this.images = [];
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.setState({ idFromUrl: id });
      this.socket = new WebSocket(process.env.REACT_APP_WS + id);
      this.socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === "playSlider") {
          this._imageGallery?.play();
        } else if (message.type === "pauseSlider") {
          this._imageGallery?.pause();
        } else if (message.type === "playVideo") {
          this._playVideo(message.url);
        } else if (message.type === "pauseVideo") {
          this._pauseVideo(message.url);
        } else if (message.type === "resumeVideo") {
          this._resumeVideo(message.url);
        }
      };
    }
  }

  componentWillMount() {
    this.setState({
      images: this.props.images
        ? this.props.images?.map((i) => {
            if (i.video) {
              return {
                thumbnail: i.thumbnail,
                original: i.original,
                idAsset: i.idAsset,
                embedUrl: i.embedUrl,
                renderItem: this._renderVideo.bind(this),
              };
            }
            return {
              idAsset: i.idAsset,
              original: i.original,
              thumbnail: i.thumbnail,
            };
          })
        : [],
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.images !== this.props.images) {
      this.setState({
        images: this.props.images.map((i) => {
          if (i.video) {
            return {
              thumbnail: i.thumbnail,
              original: i.original,
              embedUrl: i.embedUrl,
              idAsset: i.idAsset,
              renderItem: this._renderVideo.bind(this),
            };
          }
          return {
            idAsset: i.idAsset,
            original: i.original,
            thumbnail: i.thumbnail,
          };
        }),
      });
    }
  }

  _onImageClick(event) {
    console.debug(
      "clicked on image",
      event.target,
      "at index",
      this._imageGallery.getCurrentIndex()
    );
  }

  _onSlide(index) {
    this._resetVideo();
    if (this.props.setIndex) {
      this.props.setIndex(index);
    }
  }

  _onPause() {
    console.debug("paused slider");
    this.socket?.send(JSON.stringify({ type: "pauseSlider" })); //Pausar el slider
  }

  _onScreenChange(fullScreenElement) {
    console.debug("isFullScreen?", !!fullScreenElement);
  }

  _onPlay() {
    console.debug("playing slider");
    this.socket?.send(JSON.stringify({ type: "playSlider" })); // Reanudar el slider
  }

  _handleInputChange(state, event) {
    if (event.target.value > 0) {
      this.setState({ [state]: event.target.value });
    }
  }

  _handleCheckboxChange(state, event) {
    this.setState({ [state]: event.target.checked });
  }

  _handleThumbnailPositionChange(event) {
    this.setState({ thumbnailPosition: event.target.value });
  }

  _getStaticImages() {
    let images = [];
    for (let i = 2; i < 12; i++) {
      images.push({
        original: `${PREFIX_URL}${i}.jpg`,
        thumbnail: `${PREFIX_URL}${i}t.jpg`,
      });
    }

    return images;
  }

  _resetVideo() {
    this.setState({ showVideo: {} });

    if (this.state.showPlayButton) {
      this.setState({ showGalleryPlayButton: true });
    }

    if (this.state.showFullscreenButton) {
      this.setState({ showGalleryFullscreenButton: true });
    }
  }

  _toggleShowVideo(url) {
    const showVideo = this.state.showVideo[url];
    this.setState((prevState) => ({
      showVideo: {
        ...prevState.showVideo,
        [url]: !showVideo,
      },
    }));

    if (!showVideo) {
      if (this._imageGallery) {
        this._imageGallery.pause();
      }

      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }

      // Enviar mensaje para reproducir el video en la otra pantalla
      this.socket.send(JSON.stringify({ type: "playVideo", url }));
    } else {
      if (this._imageGallery) {
        this._imageGallery.play();
      }
      // Enviar mensaje para reanudar el slider en la otra pantalla
      this.socket.send(JSON.stringify({ type: "resumeSlider" }));
    }
  }

  _playVideo(url) {
    this.setState((prevState) => ({
      showVideo: {
        ...prevState.showVideo,
        [url]: true,
      },
    }));

    if (this._imageGallery) {
      this._imageGallery.pause();
    }

    if (this.state.showPlayButton) {
      this.setState({ showGalleryPlayButton: false });
    }

    if (this.state.showFullscreenButton) {
      this.setState({ showGalleryFullscreenButton: false });
    }
  }

  _pauseVideo(url) {
    const videoElement = document.querySelector(`video[src="${url}"]`);
    if (videoElement) {
      videoElement.pause();
    }
  }

  _resumeVideo(url) {
    const videoElement = document.querySelector(`video[src="${url}"]`);
    if (videoElement) {
      videoElement.play();
    }
  }

  _handleVideoEnded(url) {
    this.setState((prevState) => ({
      showVideo: {
        ...prevState.showVideo,
        [url]: false,
      },
    }));
    if (this._imageGallery) {
      this._imageGallery.play();
    }
    if (this.state.showPlayButton) {
      this.setState({ showGalleryPlayButton: true });
    }
    if (this.state.showFullscreenButton) {
      this.setState({ showGalleryFullscreenButton: true });
    }
    //Renaudar slider
    this.socket.send(JSON.stringify({ type: "resumeSlider" }));
  }

  _handleVideoPaused(url) {
    //Pausar video
    this.socket.send(JSON.stringify({ type: "pauseVideo", url }));
  }

  _handleVideoResumed(url) {
    //Reanudar video
    this.socket.send(JSON.stringify({ type: "resumeVideo", url }));
  }

  _renderVideo(item) {
    return (
      <div className="video-container position-relative">
        {this.state.showVideo[item.embedUrl] ? (
          <div className="video-wrapper">
            <a
              className="close-video"
              onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
            >
              {" "}
            </a>
            <video
              style={{
                height: this.props.isClient ? "calc(100vh - 100px)" : "",
              }}
              autoPlay={true}
              width="100%"
              src={item.embedUrl}
              frameBorder="0"
              controls={true}
              onEnded={() => this._handleVideoEnded(item.embedUrl)}
              onPause={() => this._handleVideoPaused(item.embedUrl)} // Manejador para pausar video
              onPlay={() => this._handleVideoResumed(item.embedUrl)} // Manejador para reanudar video
            ></video>
          </div>
        ) : (
          <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
            <div
              className="play-button"
              style={{
                position: "absolute",
                zIndex: "2",
                top: "42%",
                right: "47%",
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "50%",
              }}
            >
              <PlayArrowIcon />
            </div>
            <img
              alt="imagen"
              className="image-gallery-image"
              src={item.original}
            />
            {item.description && (
              <span
                className="image-gallery-description"
                style={{ right: "0", left: "initial" }}
              >
                {item.description}
              </span>
            )}
          </a>
        )}
      </div>
    );
  }

  render() {
    return (
      <section className="app" style={{ width: "100%", position: "relative" }}>
        <div>
          {!this.props.isClient ? (
            <div
              style={{
                position: "absolute",
                zIndex: "2",
                top: "0.5rem",
                right: "0.5rem",
              }}
            >
              <IconButton style={{ background: "white" }}>
                {this.props.like.imagenes?.find((e) => {
                  return (
                    e?.asset_id === this.props.images[this.props.index]?.idAsset
                  );
                }) ? (
                  <AiFillHeart
                    color={"var(--aedas-red)"}
                    onClick={() => {
                      const { idAsset } = this.props.images[this.props.index];
                      const assetWithLike = this.props.like?.imagenes?.find(
                        (like) => like?.asset_id === idAsset
                      );
                      this.props.handleDislike(assetWithLike?.id_like);
                    }}
                  ></AiFillHeart>
                ) : (
                  <AiFillHeart
                    onClick={() => {
                      const { idAsset } = this.props.images[this.props.index];
                      this.props.handleLike(idAsset);
                    }}
                  ></AiFillHeart>
                )}
              </IconButton>
            </div>
          ) : (
            <></>
          )}

          <ImageGallery
            autoPlay={!this.props.isClient}
            showFullscreenButton={false}
            startIndex={this.props.index}
            ref={(i) => (this._imageGallery = i)}
            items={this.state.images}
            onClick={this._onImageClick.bind(this)}
            onImageLoad={this._onImageLoad}
            onSlide={this._onSlide.bind(this)}
            onPause={() => {
              this._onPause();
              this.socket?.send(JSON.stringify({ type: "pauseSlider" }));
            }}
            onPlay={() => {
              this._onPlay();
              this.socket?.send(JSON.stringify({ type: "playSlider" }));
            }}
            onScreenChange={this._onScreenChange.bind(this)}
            infinite={this.state.infinite}
            showBullets={false}
            showPlayButton={true}
            showThumbnails={this.state.showThumbnails}
            showIndex={this.state.showIndex}
            isRTL={this.state.isRTL}
            slideDuration={this.state.slideDuration}
            slideInterval={this.state.slideInterval}
            slideOnThumbnailOver={this.state.slideOnThumbnailOver}
            thumbnailPosition={this.state.thumbnailPosition}
            useWindowKeyDown={this.state.useWindowKeyDown}
          />
        </div>
      </section>
    );
  }
}

export default withRouter(ImagenSlider);
