import { Card } from "@nextui-org/react";

export default function OnlyText(props: any) {
  return (
    <div style={{ padding: "10px" }}>
      <Card variant="bordered" style={{ padding: "10px" }}>
        <h1
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            textAlign: "center",
            color: "var(--aedas-red)",
          }}
        >
          ¡Atención!
        </h1>
        {props.external ? (
          <p style={{ textAlign: "justify" }}>
            Es un elemento que debe mostrarse fuera del sistema, al cliente se
            le esta mostrando la imagen por defecto
          </p>
        ) : (
          <p style={{ textAlign: "justify" }}>
            Este elemento no dispone de sincronización debe manejarse desde la
            ventana del cliente
          </p>
        )}

        <h1
          style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}
        >
          {props.text}
        </h1>
      </Card>
    </div>
  );
}
