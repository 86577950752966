import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

/**
 * @description Obtiene el calendario de pagos de una casa.
 * @param { string | int } houseId Id de la casa.
 * @returns
 */
export const getHousePaymentSchedule = (houseId: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND +
      `/Appointment/house_payment_schedule/${houseId}`,
    getDefaultConfigHeader()
  );
};
