import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const getAllSellers = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/Seller/",
    getDefaultConfigHeader()
  );
};

export const getAllClientsBySeller = (id: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/Seller/clients/" + id,
    getDefaultConfigHeader()
  );
};

export const getAllLeadsBySeller = (id: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/Seller/leads/" + id,
    getDefaultConfigHeader()
  );
};

export const getAllOportunitiesBySeller = (id: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/Seller/oportunities/" + id,
    getDefaultConfigHeader()
  );
};

export const getAllKpisBySeller = (id: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/Seller/kpis/" + id,
    getDefaultConfigHeader()
  );
};
