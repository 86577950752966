import { PDFDocument } from "pdf-lib";

export function handlePrint(planos: any, budget: any) {
  mergeAndDownloadPDFs(planos, "Documentacion.pdf", budget);
}

async function mergeAndDownloadPDFs(
  pdfUrls: any,
  outputFilename: any,
  budget: any = undefined
) {
  const mergedPdf = await PDFDocument.create();

  for (const pdfUrl of pdfUrls) {
    const response = await fetch(pdfUrl);
    const pdfBytes = await response.arrayBuffer();

    const pdfDoc = await PDFDocument.load(pdfBytes);

    const pages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
    pages.forEach((page) => mergedPdf.addPage(page));
  }

  if (budget) {
    const budgetBytes = await budget.arrayBuffer();

    const budgetPdfDoc = await PDFDocument.load(budgetBytes);
    const budgetPages = await mergedPdf.copyPages(
      budgetPdfDoc,
      budgetPdfDoc.getPageIndices()
    );
    budgetPages.forEach((page) => mergedPdf.addPage(page));
  }
  const pdfBytes = await mergedPdf.save();

  const blob = new Blob([pdfBytes], { type: "application/pdf" });

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = outputFilename;
  link.click();
}
