import { Checkbox } from "@nextui-org/react";
import { useFormik } from "formik";
import { useEffect } from "react";
import { MdErrorOutline } from "react-icons/md";
import {
  capitalizeString,
  writeCorrectNameToHouseType,
} from "../../Utils/commonUtils";
import { PROMOTION_STATE_MAP } from "../Constants/constants";

export default function InnerFormAvailableProduct(props: any) {
  const { filters, setSelectedFilter } = props;
  const formik = useFormik({
    initialValues: props.selectedFilter ?? {
      portal: [],
      promotion: [],
      floor: [],
      orientation: [],
      availability: ["Disponible"],
      bedrooms: [],
      type: [],
    },
    validationSchema: null,
    onSubmit: (values) => {},
  });

  useEffect(() => {
    if (
      JSON.stringify(props.selectedFilter) !== JSON.stringify(formik.values)
    ) {
      setSelectedFilter(formik.values);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  return (
    <form onSubmit={formik.handleSubmit} style={{ padding: "20px" }}>
      <Checkbox.Group
        onChange={(values: any) => {
          formik.setFieldValue("promotion", values);
        }}
        defaultValue={formik.values.promotion}
        label='Promociones'
        size='lg'
        css={{ marginBottom: "15px" }}
      >
        {filters?.promotion?.length <= 0 ? (
          <p className='font-family-lis d-flex align-items-center gap-1'>
            <MdErrorOutline size={24} color={"#666"} /> Sin promociones
          </p>
        ) : (
          filters?.promotion
            ?.sort((a: any, b: any) => (a.name < b.name ? -1 : 1))
            .map((promo: any, i: any) => {
              return (
                <Checkbox
                  key={`${i}-${promo?.name}`}
                  size='lg'
                  value={promo?.id}
                >
                  {promo?.name} {`(${promo?.amount})`}
                </Checkbox>
              );
            })
        )}
      </Checkbox.Group>

      <Checkbox.Group
        css={{ marginBottom: "15px" }}
        label='Habitaciones'
        size='lg'
        onChange={(values: any) => {
          formik.setFieldValue("bedrooms", values);
        }}
        defaultValue={formik.values.bedrooms}
      >
        {filters?.bedrooms.length <= 0 ? (
          <p className='font-family-lis d-flex align-items-center gap-1'>
            <MdErrorOutline size={24} color={"#666"} /> Sin habitaciones para
            filtrar
          </p>
        ) : (
          filters?.bedrooms
            .sort((a: any, b: any) => (a.name < b.name ? -1 : 1))
            .map((bedroom: any, i: any) => {
              return (
                <Checkbox
                  key={`${i}-${bedroom?.name}`}
                  size='lg'
                  value={bedroom?.name}
                >
                  {bedroom?.name?.toString()} {` (${bedroom?.amount})`}
                </Checkbox>
              );
            })
        )}
      </Checkbox.Group>

      <Checkbox.Group
        onChange={(values: any) => {
          formik.setFieldValue("floor", values);
        }}
        defaultValue={formik.values.floor}
        label='Altura'
        size='lg'
        css={{ marginBottom: "15px" }}
      >
        {filters?.floor?.length <= 0 ? (
          <p className='font-family-lis d-flex align-items-center gap-1'>
            <MdErrorOutline size={24} color={"#666"} /> Sin alturas disponibles
          </p>
        ) : (
          filters?.floor
            ?.sort((a: any, b: any) => (a.name < b.name ? -1 : 1))
            .map((floor: any, i: any) => {
              return (
                floor?.name !== null && (
                  <Checkbox
                    key={`${i}-${floor?.name}`}
                    size='lg'
                    value={floor?.name}
                  >
                    Planta {floor?.name} {`(${floor?.amount})`}
                  </Checkbox>
                )
              );
            })
        )}
      </Checkbox.Group>
      <Checkbox.Group
        onChange={(values: any) => {
          formik.setFieldValue("portal", values);
        }}
        defaultValue={formik.values.portal}
        label='Portal'
        size='lg'
        css={{ marginBottom: "15px" }}
      >
        {filters?.portal?.length <= 0 ? (
          <p className='font-family-lis d-flex align-items-center gap-1'>
            <MdErrorOutline size={24} color={"#666"} /> Sin portales disponibles
          </p>
        ) : (
          filters?.portal
            ?.sort((a: any, b: any) => (a.name < b.name ? -1 : 1))
            .map((floor: any, i: any) => {
              return (
                floor?.name !== null && (
                  <Checkbox
                    key={`${i}-${floor?.name}`}
                    size='lg'
                    value={floor?.name}
                  >
                    Portal {floor?.name} {`(${floor?.amount})`}
                  </Checkbox>
                )
              );
            })
        )}
      </Checkbox.Group>
      <Checkbox.Group
        css={{ marginBottom: "15px" }}
        label='Disponibilidad'
        size='lg'
        onChange={(values: any) => {
          formik.setFieldValue("availability", values);
        }}
        defaultValue={formik.values.availability}
      >
        {filters?.availability?.length <= 0 ? (
          <p className='font-family-lis d-flex align-items-center gap-1'>
            <MdErrorOutline size={24} color={"#666"} /> Sin datos de
            disponibilidad
          </p>
        ) : (
          Object.values(
            filters?.availability?.reduce(
              (accumulator: any, availability: any) => {
                const mappedValue = PROMOTION_STATE_MAP[availability?.name];
                const key = mappedValue ?? availability?.name.toString();
                const existingValue = accumulator[key];

                if (existingValue) {
                  accumulator[key] = {
                    ...existingValue,
                    amount: existingValue.amount + availability.amount,
                  };
                } else {
                  accumulator[key] = {
                    name: availability.name,
                    amount: availability.amount,
                  };
                }

                return accumulator;
              },
              {}
            )
          ).map((availability: any, i: any) => (
            <Checkbox
              key={`${i}-${availability?.name}`}
              size='lg'
              value={availability?.name}
            >
              {capitalizeString(availability?.name)}{" "}
              {` (${availability?.amount})`}
            </Checkbox>
          ))
        )}
      </Checkbox.Group>

      <Checkbox.Group
        onChange={(values: any) => {
          formik.setFieldValue("orientation", values);
        }}
        defaultValue={formik.values.orientation}
        css={{ marginBottom: "15px" }}
        label='Orientación'
        size='lg'
      >
        {filters?.orientation.length <= 0 ? (
          <p className='font-family-lis d-flex align-items-center gap-1'>
            <MdErrorOutline size={24} color={"#666"} /> Sin valores de
            orientación
          </p>
        ) : (
          filters?.orientation
            ?.sort((a: any, b: any) => (a.name < b.name ? -1 : 1))
            .map((orientation: any, i: any) => {
              return (
                orientation?.name !== null && (
                  <Checkbox
                    key={`${i}-${orientation?.name} `}
                    size='lg'
                    value={orientation?.name.toString()}
                  >
                    {capitalizeString(orientation?.name?.toString())}{" "}
                    {` (${orientation?.amount})`}
                  </Checkbox>
                )
              );
            })
        )}
      </Checkbox.Group>

      <Checkbox.Group
        onChange={(values: any) => {
          formik.setFieldValue("type", values);
        }}
        defaultValue={formik.values.type}
        css={{ marginBottom: "15px" }}
        label='Tipo de vivienda'
        size='lg'
      >
        {filters?.type?.length <= 0 ? (
          <p className='font-family-lis d-flex align-items-center gap-1'>
            <MdErrorOutline size={24} color={"#666"} /> Sin valores
          </p>
        ) : (
          filters?.type?.map((type: any, i: any) => {
            return (
              <Checkbox
                key={`${i}-${type?.name} `}
                size='lg'
                value={type?.name}
              >
                {writeCorrectNameToHouseType(type?.name.toString())}{" "}
                {` (${type?.amount})`}
              </Checkbox>
            );
          })
        )}
      </Checkbox.Group>
    </form>
  );
}
