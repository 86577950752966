import { AvaiableProduct } from "../../AvaiableProduct/AvaiableProduct";

export function MainPlanes(props: any) {
  const {
    tax,
    principalProductClient,
    // shadowProduct,
    likes,
    sendJson,
    // setselectedValue,
    idClient,
    setLikes,
    pointHouse,
    setPointHouse,
    setPrincipalProduct,
    principalProduct,
    // setShadowProduct,
    // disableAddToPrincipalProduct,
  } = props;
  return (
    <>
      {principalProduct?.map((p: any) => {
        return (
          <AvaiableProduct
            tax={tax}
            idClient={idClient}
            likes={likes}
            setLikes={setLikes}
            key={"available" + p.house_n}
            asset={p}
            sendJson={sendJson}
            // setselectedValue={setselectedValue}
            pointHouse={pointHouse}
            setPointHouse={setPointHouse}
            principalProductClient={principalProductClient}
            setPrincipalProduct={setPrincipalProduct}
            // shadowProduct={shadowProduct}
            // setShadowProduct={setShadowProduct}
            // disableAddToPrincipalProduct={disableAddToPrincipalProduct}
            canSendCalendarAndBudget={principalProduct?.includes(p)}
          ></AvaiableProduct>
        );
      })}
    </>
  );
}
