import { AedasModal } from "../Modal/Modal";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { TIMEZONES } from "../Constants/constants";
interface ControlZoneDateProps {
  openModalTimeZone: boolean;
  setOpenModalTimeZone: Dispatch<SetStateAction<boolean>>;
}
const ControlZoneDate: FC<ControlZoneDateProps> = ({
  openModalTimeZone,
  setOpenModalTimeZone,
}) => {
  const [selectTimeZone, setSelectTimeZone] = useState(TIMEZONES[0]);

  const handleChange = (e: any) => {
    const newTimeZone = e.target.value;
    setSelectTimeZone(newTimeZone);
  };

  useEffect(() => {
    const storedLocationControlTimezone = localStorage.getItem("timezone");
    if (storedLocationControlTimezone) {
      setSelectTimeZone(JSON.parse(storedLocationControlTimezone));
    }
  }, []);

  return (
    <AedasModal
      width={25}
      body={
        <div>
          <FormControl fullWidth className="my-2">
            <InputLabel id="label_timezone">
              Selecciona la zona horaria
            </InputLabel>
            <Select
              onChange={handleChange}
              labelId="label_timezone"
              value={selectTimeZone}
              label="Selecciona la zona horaria"
            >
              {TIMEZONES.map((data: string) => (
                <MenuItem value={data} key={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            className="mx-2"
            style={{ background: "var(--blue-lis)" }}
            onClick={() => {
              localStorage.setItem("timezone", JSON.stringify(selectTimeZone));
              setOpenModalTimeZone(false);
              window.location.reload();
            }}
          >
            Guardar
          </Button>
          <Button
            onClick={() => setOpenModalTimeZone(false)}
            variant="contained"
            color="inherit"
          >
            Cancelar
          </Button>
        </div>
      }
      open={openModalTimeZone}
      handleClose={() => setOpenModalTimeZone(false)}
      title={"Zona horaria"}
    ></AedasModal>
  );
};

export default ControlZoneDate;
