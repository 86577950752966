import { DataGrid, esES } from "@mui/x-data-grid";
import { Button } from "@nextui-org/react";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import uuid from "react-uuid";

export function TableTestAppoinment(props: any) {
  const uuidValue = uuid();
  const history = useHistory();

  const columns = [
    {
      field: "createdDate",
      headerName: "Fecha",
      editable: false,
      width: 170,
      valueGetter: (params: any) =>
        `${moment(params.row.dateInitial).format("DD-MM-YYYY HH:mm:ss")}`,
    },
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "displayName",
      headerName: "Nombre",
      renderCell: (params: any) => {
        return (
          <div
            style={{ display: "flex", width: "100%", justifyContent: "left" }}
          >
            {params.row.displayName}
          </div>
        );
      },

      flex: 1,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      editable: false,
      renderCell: (params: any) => {
        return (
          <div
            style={{ display: "flex", width: "100%", justifyContent: "left" }}
          >
            {params.row.email}
          </div>
        );
      },
    },
    {
      field: "showedUp",
      headerName: "Presentado",
      editable: false,
      width: 130,
      valueGetter: (params: any) => {
        if (params.row.showedUp === null) {
          return "No hay datos";
        }
        if (params.row.showedUp) {
          return "Si";
        }
        if (!params.row.showedUp) {
          return "No";
        }
      },
    },
    {
      field: "ateneaAppointment",
      headerName: "Realizado en Atenea",
      width: 180,
      editable: false,
      valueGetter: (params: any) => {
        if (params.row.ateneaAppointment) {
          return "Si";
        }
        if (!params.row.ateneaAppointment) {
          return "No";
        }
      },
    },
    {
      field: "phone",
      headerName: "Teléfono",
      width: 130,
      editable: false,
      renderCell: (params: any) => {
        return (
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            {params.row.phone}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Detalle",
      sortable: false,
      width: 100,
      renderCell: (params: any) => {
        const onClick = (e: any) => {
          history.push(
            `/appointment/${params.row.idSalesforce}/seller/${uuidValue}/informacion`
          );
        };

        return (
          <div>
            {params.row.showedUp ? (
              <Button onClick={onClick} style={{ width: "80%" }} size="xs">
                <div
                  style={{
                    display: "flex",
                    // justifyContent: "space-around",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AiOutlineEye
                    size={14}
                    style={{ marginRight: "5px" }}
                    color={"#fffff"}
                  ></AiOutlineEye>{" "}
                  Ver
                </div>
              </Button>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <DataGrid
      style={{
        width: "100%",
      }}
      rows={
        props.row
          ? props.row.map((c: any, i: number) => {
              return {
                id: i,
                displayName: c.name_client,
                dateInitial: c.dateInitial,
                email: c.mail_client,
                phone: c.phone_client,
                idSalesforce: c.id,
                ateneaAppointment: c.ateneaAppointment,
                showedUp: c.showedUp,
              };
            })
          : []
      }
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10]}
      disableSelectionOnClick
      localeText={esES.components.MuiDataGrid.defaultProps.localeText}
    />
  );
}
