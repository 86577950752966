import { Drawer, IconButton } from "@mui/material";
import { Card } from "@nextui-org/react";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

const BuildingBox = (props: any) => {
  const { available, name, size, bathrooms, bedrooms, price } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Drawer anchor={"right"} open={isOpen} onClose={() => setIsOpen(false)}>
        <div style={{ width: "1000px", height: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                fontFamily: "lis-title",
                fontSize: "25px",
                textAlign: "center",
                fontWeight: "bold",
                margin: "0px",
              }}
            >
              {name}
            </h1>
            <IconButton onClick={() => setIsOpen(false)}>
              <AiOutlineClose></AiOutlineClose>
            </IconButton>
          </div>
          <object
            width={"100%"}
            height={"100%"}
            data="http://node01-etl-aedashomes.asplhosting.com:8053/api/v1/Asset/promotion/225/House/38088/Portal%2002-1%C2%BAA-%203D.pdf"
            type="application/pdf"
          >
            <div>No online PDF viewer installed</div>
          </object>
        </div>
      </Drawer>
      <Card
        isHoverable
        variant="bordered"
        style={{
          backgroundColor: !available ? "#b1003b57" : "#00b17687",
          width: "200px",
          height: "200px",
        }}
      >
        <div
          style={{ padding: "10px", cursor: "pointer" }}
          onClick={() => setIsOpen(true)}
        >
          <h1
            style={{
              fontFamily: "lis-title",
              fontSize: "20px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {name}
          </h1>
          <p
            style={{
              fontFamily: "lis-body",
              fontSize: "15px",
              marginBottom: "0.5rem",
            }}
          >
            Tamaño:<span style={{ fontWeight: "bold" }}>{" " + size}m</span>
          </p>
          <p
            style={{
              fontFamily: "lis-body",
              fontSize: "15px",
              marginBottom: "0.5rem",
            }}
          >
            Habitaciones:
            <span style={{ fontWeight: "bold" }}>{" " + bedrooms}</span>
          </p>
          <p
            style={{
              fontFamily: "lis-body",
              fontSize: "15px",
              marginBottom: "0.5rem",
            }}
          >
            Baños:<span style={{ fontWeight: "bold" }}>{" " + bathrooms}</span>
          </p>
          <p
            style={{
              fontFamily: "lis-body",
              fontSize: "15px",
              marginBottom: "0.5rem",
            }}
          >
            Precio:<span style={{ fontWeight: "bold" }}>{" " + price}</span>
          </p>
        </div>
      </Card>
    </>
  );
};

export default BuildingBox;
