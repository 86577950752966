import AllImgVideo from "./AllImgVideo";
import { Documents } from "./Documents";
import Favorites from "./Favorites";
import Habiteo from "./Habiteo";
import ListOfProperties from "./ListOfProperties";
import MapFunction from "./Map";
import { MessageLoadingProduct } from "./MessageLoadingProduct";

const SwitchClient = (props: any) => {
  const {
    value,
    Index,
    assets,
    plano,
    messageProduct,
    principalProduct,
    documentsToShow,
    tax,
    selectedHouse,
    clientId,
    promotionId,
    likedElements,
    functionToDisplayCalendar,
    functionToDisplayBudget,
  } = props;

  switch (value) {
    case "all_img_video":
      return (
        <AllImgVideo
          img={assets?.principal[0]?.value}
          Index={Index}
          assets={assets?.imagenes_y_videos}
        ></AllImgVideo>
      );
    case "map":
      return <MapFunction assets={assets.mapa_google}></MapFunction>;
    case "parcela":
      return (
        <AllImgVideo
          assets={assets?.parcela}
          img={assets?.principal[0]?.value}
        ></AllImgVideo>
      );
    case "infografia_exterior":
      return (
        <AllImgVideo
          assets={assets?.infografias_exteriores}
          img={assets?.principal[0]?.value}
        ></AllImgVideo>
      );
    case "infografia_interior":
      return (
        <AllImgVideo
          assets={assets?.infografia_interior}
          img={assets?.principal[0]?.value}
        ></AllImgVideo>
      );
    case "infografias_zonas_comunes":
      return (
        <AllImgVideo
          assets={assets?.infografias_zonas_comunes}
          img={assets?.principal[0]?.value}
        ></AllImgVideo>
      );
    case "visita_virtual_zzcc":
      return <Habiteo url={assets?.visita_virtual[0]?.value}></Habiteo>;
    case "vista_virtual":
      return <Habiteo url={assets?.visita_virtual[0]?.value}></Habiteo>;
    case "maqueta_virtual":
      return <Habiteo url={assets?.maqueta_virtual[0]?.value}></Habiteo>;
    case "plano":
      return (
        <iframe title="Plano" src={plano} height="100%" width="100%"></iframe>
      );
    case "mostrar_viviendas_favoritas":
      return (
        <Favorites
          clientId={clientId}
          updatedLikes={likedElements}
          promotionId={promotionId}
          functionToDisplayCalendar={functionToDisplayCalendar}
          functionToDisplayBudget={functionToDisplayBudget}
          selectedHouse={selectedHouse}
        />
      );
    case "listado_viviendas":
      return (
        <ListOfProperties
          principalProduct={principalProduct}
          tax={tax}
          selectedHouse={selectedHouse}
        />
      );
    case "cargando_listado_viviendas":
      return <MessageLoadingProduct messageProduct={messageProduct} />;
    case "showDocuments":
      return <Documents documentsToShow={documentsToShow} />;
    default:
      return (
        // <ImageAsDefault img={assets?.principal[0]?.value}></ImageAsDefault>
        <AllImgVideo
          img={assets?.principal[0]?.value}
          Index={Index}
          assets={assets?.imagenes_y_videos}
        ></AllImgVideo>
      );
  }
};

export default SwitchClient;
