import { useState } from "react";
import SwitchModal from "./SwitchModal";

function AppoimentsEndModal(props: any) {
  const {
    mainImage,
    setOpenModal,
    sendJson,
    promotion,
    client,
    formik,
    seconds,
    minutes,
    hours,
    setselectedValue,
    setDisplayEmailPhoneClient,
    setTab,
    setTipArchetype,
    setTipRelationalStyle,
  } = props;
  const [selectedStep, setSelectedStep] = useState(0);

  return (
    <SwitchModal
      mainImage={mainImage}
      setselectedValue={setselectedValue}
      setDisplayEmailPhoneClient={setDisplayEmailPhoneClient}
      selectedStep={selectedStep}
      setOpenModal={setOpenModal}
      sendJson={sendJson}
      setTab={setTab}
      promotion={promotion}
      client={client}
      formik={formik}
      setSelectedStep={setSelectedStep}
      seconds={seconds}
      minutes={minutes}
      hours={hours}
      setTipArchetype={setTipArchetype}
      setTipRelationalStyle={setTipRelationalStyle}
    ></SwitchModal>
  );
}

export default AppoimentsEndModal;
