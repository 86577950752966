function AppoimentTime(props: any) {
  const { visitTime, editable, seconds, minutes, hours, showedup } = props;

  return (
    <div>
      <h1
        style={{
          fontFamily: "lis-title",
          fontSize: "17px",
          fontWeight: "bold",
          color: "var(--secondary-color)",
          marginBottom: "0px",
        }}
      >
        Duración
      </h1>
      {editable ? (
        <h2
          style={{
            fontFamily: "lis-body",
            fontSize: "15px",
            fontWeight: "bold",
            marginBottom: "0px",
          }}
        >
          {showedup ? visitTime : "No presentado"}
        </h2>
      ) : (
        <h2
          style={{
            fontFamily: "lis-body",
            fontSize: "15px",
            fontWeight: "bold",
            marginBottom: "0px",
          }}
        >
          {`${hours < 10 ? "0" + hours : hours}:${
            minutes < 10 ? "0" + minutes : minutes
          }:${seconds < 10 ? "0" + seconds : seconds}`}
        </h2>
      )}
    </div>
  );
}

export default AppoimentTime;
