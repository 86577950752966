import { useEffect, useState } from "react";
import { BsLightbulb } from "react-icons/bs";
import { RxInfoCircled } from "react-icons/rx";
import { getTipsMessages } from "../../Utils/tipsUtils";
//@ts-ignore
import { useInterval } from "usehooks-ts";

import { FcCheckmark } from "react-icons/fc";

import "./TipsAndMessages.css";

export const TipsAndMessages = ({
  tipTab,
  tipStep,
  tipRelationalStyle,
  tipArchetype,
  tipBudget,
  formik,
}: any) => {
  const [flag, setFlag] = useState(0);
  const [displayTips, setDisplayTips] = useState(false);
  const [displayDataRequired, setDisplayDataRequired] = useState(true);

  useInterval(() => {
    setFlag((prevFlag) => (prevFlag + 1) % 3);
  }, 7000);

  useEffect(() => {
    tipTab &&
    tipStep &&
    tipRelationalStyle &&
    tipArchetype &&
    ((Number(tipBudget[0]) !== 0 && Number(tipBudget[1]) === 3000000) ||
      (Number(tipBudget[0]) === 0 && Number(tipBudget[1]) !== 3000000) ||
      (Number(tipBudget[0]) !== 0 && Number(tipBudget[1]) !== 3000000))
      ? setDisplayTips(true)
      : setDisplayTips(false);
  }, [tipTab, tipStep, tipRelationalStyle, tipBudget, tipArchetype]);

  useEffect(() => {
    formik.values.Como__c &&
    formik.values.Codigo_Postal_Actual__pc &&
    formik.values.Numero_de_hijos__c &&
    formik.values.DestinoVivienda__c &&
    formik.values.Miembros_en_la_unidad_familiar__pc &&
    (Number(formik.values.PerFinanciacionNecesario__c[0]) !== 0 ||
      Number(formik.values.PerFinanciacionNecesario__c[1]) !== 100)
      ? setDisplayDataRequired(false)
      : setDisplayDataRequired(true);
  }, [formik.values]);

  if (!displayDataRequired && !displayTips) {
    return mustSelectToViewTips(
      tipTab,
      tipRelationalStyle,
      tipArchetype,
      tipBudget
    );
  }

  if (!displayDataRequired && displayTips) {
    return renderDisplayTips(tipTab, tipStep, tipRelationalStyle, tipArchetype);
  }

  if (displayDataRequired && !displayTips) {
    return flag === 0
      ? rememberToAskClient(formik)
      : flag === 1
      ? rememberToComplete(formik)
      : mustSelectToViewTips(
          tipTab,
          tipRelationalStyle,
          tipArchetype,
          tipBudget
        );
  }

  if (displayDataRequired && displayTips) {
    return flag === 0
      ? rememberToAskClient(formik)
      : flag === 1
      ? rememberToComplete(formik)
      : renderDisplayTips(tipTab, tipStep, tipRelationalStyle, tipArchetype);
  }

  return <>"Sin datos..."</>;
};

/**
 * @description Renderiza los consejos que para el comercial.
 *
 * @param tipTab
 * @param tipStep
 * @param tipRelationalStyle
 * @param tipArchetype
 * @returns
 */
const renderDisplayTips = (
  tipTab: any,
  tipStep: any,
  tipRelationalStyle: any,
  tipArchetype: any
) => {
  return (
    <div className="d-flex justify-content-center align-items-center h-100 p-2">
      {/* Si es de tipo "pago" mostramos directamente los 2 mensajes que nos han indicado */}
      {tipTab === "pago" ? (
        <div className="">
          <p
            style={{
              color: "var(--secondary-color)",
              fontFamily: "lis-title",
              fontSize: "18px",
            }}
            className="d-flex flex-row justify-content-center align-items-center mb-0"
          >
            <BsLightbulb size={24}></BsLightbulb>{" "}
            <strong>Consejo/Pregunta</strong>
          </p>
          <p className="mb-0 lh-sm text-center h5">
            {getTipsMessages(
              tipTab,
              "presupuesto",
              tipRelationalStyle,
              tipArchetype
            )}
          </p>
          <p className="mb-2 lh-sm text-center h5">
            {getTipsMessages(
              tipTab,
              "financiacion",
              tipRelationalStyle,
              tipArchetype
            )}
          </p>
          <p className="mb-2 lh-sm text-center h5">Escuchar la llamada de FV</p>
        </div>
      ) : (
        <div className="">
          <p
            style={{
              color: "var(--secondary-color)",
              fontFamily: "lis-title",
              fontSize: "18px",
            }}
            className="d-flex flex-row justify-content-center align-items-center mb-0"
          >
            <BsLightbulb size={24}></BsLightbulb>{" "}
            <strong>Consejo/Pregunta</strong>
          </p>
          <p className="mb-2 lh-sm text-center h5">
            {getTipsMessages(tipTab, tipStep, tipRelationalStyle, tipArchetype)}
          </p>
          <p className="mb-2 lh-sm text-center h5">
            Escuchar la llamada de Fábrica de visitas
          </p>
        </div>
      )}
    </div>
  );
};

/**
 * @description Renderiza el mensaje que recuerda al comercial los campos que debe preguntar al cliente como datos básicos.
 *
 * @returns
 */
const rememberToAskClient = (formik: any) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100 p-2 cliente-debe-rellenar">
      <p className="mb-0">
        <RxInfoCircled
          className="icon"
          style={{
            color: "var(--secondary-color)",
          }}
          size={24}
        ></RxInfoCircled>
      </p>
      <p
        className="lh-sm mb-2"
        style={{
          fontFamily: "lis-title",
          color: "#333",
        }}
      >
        <strong>Recuerda que el cliente debe rellenar:</strong>
      </p>
      <div
        className="w-100 "
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "2px",
        }}
      >
        {!formik.values.Como__c && (
          <p
            className="requirement-text"
            style={{
              margin: "3px",
              lineHeight: "1",
              fontFamily: "lis-title",
              color: "#666",
            }}
          >
            <FcCheckmark /> ¿Cómo nos ha conocido?
          </p>
        )}
        {!formik.values.Codigo_Postal_Actual__pc && (
          <p
            className="requirement-text"
            style={{
              margin: "3px",
              lineHeight: "1",
              fontFamily: "lis-title",
              color: "#666",
            }}
          >
            <FcCheckmark /> Código postal
          </p>
        )}
        {!formik.values.Miembros_en_la_unidad_familiar__pc && (
          <p
            className="requirement-text"
            style={{
              margin: "3px",
              lineHeight: "1",
              fontFamily: "lis-title",
              color: "#666",
            }}
          >
            <FcCheckmark /> ¿Cuántos son en la unidad familiar?
          </p>
        )}
        {!formik.values.DestinoVivienda__c && (
          <p
            className="requirement-text"
            style={{
              margin: "3px",
              lineHeight: "1",
              fontFamily: "lis-title",
              color: "#666",
            }}
          >
            <FcCheckmark /> ¿Motivo de la compra?
          </p>
        )}
        {formik.values.Numero_de_hijos__c === null ||
        Number(formik.values.Numero_de_hijos__c) < -1 ? (
          <p
            className="requirement-text"
            style={{
              margin: "3px",
              lineHeight: "1",
              fontFamily: "lis-title",
              color: "#666",
            }}
          >
            <FcCheckmark /> Número de hijos
          </p>
        ) : null}

        {/* Esto es un string vacio o un array con el min (posición 0) y max (posición 1) de financación, si los valores son por defecto es porque no ha respondido */}
        {formik.values.PerFinanciacionNecesario__c === "" ||
        (Number(formik.values.PerFinanciacionNecesario__c[0]) === 0 &&
          Number(formik.values.PerFinanciacionNecesario__c[1]) === 100) ? (
          <p
            className="requirement-text"
            style={{
              margin: "3px",
              lineHeight: "1",
              fontFamily: "lis-title",
              color: "#666",
            }}
          >
            <FcCheckmark /> ¿Necesita financiación?
          </p>
        ) : null}
      </div>
    </div>
  );
};

/**
 * @description Renderiza el mensaje que recuerda al comercial los campos que debe preguntar al cliente para que le muestren consejos para cerrar la venta.
 *
 * @param tipTab
 * @param tipRelationalStyle
 * @param tipArchetype
 * @param tipBudget
 * @returns
 */
const mustSelectToViewTips = (
  tipTab: any,
  tipRelationalStyle: any,
  tipArchetype: any,
  tipBudget: any
) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100 p-2">
      <p className="mb-0">
        <BsLightbulb
          size={24}
          style={{
            color: "var(--secondary-color)",
          }}
        ></BsLightbulb>{" "}
      </p>
      <p
        className="lh-sm mb-2"
        style={{
          fontFamily: "lis-title",
          color: "#333",
        }}
      >
        <strong>
          Para ver consejos y/o preguntas que ayuden a cerrar la venta,
          selecciona:
        </strong>
      </p>
      {/* <div
        style={{
          width: "100px",
          height: "2px",
          backgroundColor: "#c5edf9",
          marginTop: "5px",
          marginBottom: "10px",
        }}
      ></div> */}
      <div
        className="w-100"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "5px",
        }}
      >
        {/* Comentarios informativos para nuestros yos del futuro:
          - Lo que antes era arquetipo y tiene las opciones de: Agua, Aire, Tierra y Fuego ahora se denomina "Estilo relacional"
          - Por supuesto, lo que era antes el estilo relacional (el desplegable) ahora se denomina arquetipo (sigue siendo el desplegable).  

          - Siempre recibimos un número como id, si no es un número indicamos que lo debe rellenar
        */}

        {!tipRelationalStyle && (
          <p
            style={{
              margin: "3px",
              lineHeight: "1",
              fontFamily: "lis-title",
              color: "#666",
            }}
          >
            {" "}
            <FcCheckmark /> Arquetipo
          </p>
        )}

        {/* Comentarios informativos para nuestros yos del futuro:
          - Lo que antes era arquetipo y tiene las opciones de: Agua, Aire, Tierra y Fuego ahora se denomina "Estilo relacional".
          - Por supuesto, lo que era antes el estilo relacional (el desplegable) ahora se denomina arquetipo (sigue siendo el desplegable). 

        */}
        {(!tipArchetype || typeof tipArchetype === "undefined") && (
          <p
            style={{
              margin: "3px",
              lineHeight: "1",
              fontFamily: "lis-title",
              color: "#666",
            }}
          >
            {" "}
            <FcCheckmark /> Estilo relacional
          </p>
        )}

        {/* Esto es un array con el min (posición 0) y max (posición 1) de presupuesto, si los valores son por defecto es porque no ha respondido */}
        {Number(tipBudget[0]) === 0 && Number(tipBudget[1]) === 3000000 && (
          <p
            style={{
              margin: "3px",
              lineHeight: "1",
              fontFamily: "lis-title",
              color: "#666",
            }}
          >
            <FcCheckmark /> Hoja informativa
          </p>
        )}

        {!tipTab && (
          <p
            style={{
              margin: "3px",
              lineHeight: "1",
              fontFamily: "lis-title",
              color: "#666",
            }}
          >
            <FcCheckmark /> Pestaña de "show" y/o "pago"
          </p>
        )}
      </div>
    </div>
  );
};

/**
 * @description Renderiza el mensaje que recuerda al comercial los campos que debe rellenar para poder terminar la visita.
 *
 * @returns
 */
const rememberToComplete = (formik: any) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100 p-2 cliente-debe-rellenar">
      <p className="mb-0">
        <RxInfoCircled
          className="icon"
          style={{
            color: "var(--secondary-color)",
          }}
          size={24}
        ></RxInfoCircled>
      </p>
      <p
        className="lh-sm mb-2"
        style={{
          fontFamily: "lis-title",
          color: "#333",
        }}
      >
        <strong>Recuerda que debes completar los campos:</strong>
      </p>
      <div
        className="w-100 "
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "2px",
        }}
      >
        <p
          className="requirement-text"
          style={{
            margin: "3px",
            lineHeight: "1",
            fontFamily: "lis-title",
            color: "#666",
          }}
        >
          <FcCheckmark /> Estilo relacional
        </p>

        <p
          className="requirement-text"
          style={{
            margin: "3px",
            lineHeight: "1",
            fontFamily: "lis-title",
            color: "#666",
          }}
        >
          <FcCheckmark />
          Arquetipo
        </p>

        <p
          className="requirement-text"
          style={{
            margin: "3px",
            lineHeight: "1",
            fontFamily: "lis-title",
            color: "#666",
          }}
        >
          <FcCheckmark /> Código postal actual
        </p>
        <p
          className="requirement-text"
          style={{
            margin: "3px",
            lineHeight: "1",
            fontFamily: "lis-title",
            color: "#666",
          }}
        >
          <FcCheckmark /> País de residencia
        </p>
        <p
          className="requirement-text"
          style={{
            margin: "3px",
            lineHeight: "1",
            fontFamily: "lis-title",
            color: "#666",
          }}
        >
          <FcCheckmark /> Propiedad o alquiler
        </p>
        <p
          className="requirement-text"
          style={{
            margin: "3px",
            lineHeight: "1",
            fontFamily: "lis-title",
            color: "#666",
          }}
        >
          <FcCheckmark /> Motivo de la compra
        </p>
      </div>
    </div>
  );
};
