import { Document, Page } from "react-pdf";

export const PdfRender = ({ url }: { url: string }) => {
  return (
    <Document
      file={url}
      loading={"Cargando pdf..."}
      error={"Error al cargar el pdf"}
      noData={"Ninguna página especificada"}
      onLoadError={console.error}
    >
      <Page
        className={"pdf_custom_assets"}
        pageNumber={1}
        loading={"Cargando pdf..."}
        error={"Error al cargar el pdf"}
        noData={"Ninguna página especificada"}
      />
    </Document>
  );
};
