import { Button } from "@nextui-org/react";

const StartTheAppointment = (props: any) => {
  const { setopenModalStart, setModalNotIntroduces, setIsAppointmentSelected } =
    props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
        width: "50%",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Button
        auto
        css={{
          background: "LightGray",
          backgroundColor: "LightGray",
          width: "180px",
          color: "Black",
          fontWeight: "$semibold",
        }}
        onClick={() => {
          setIsAppointmentSelected(null);
        }}
      >
        Volver
      </Button>
      <Button
        auto
        css={{
          background: "var(--blue-lis)",
          backgroundColor: "var(--aedas-red)",
          width: "180px",
        }}
        onClick={() => {
          setModalNotIntroduces(true);
          setopenModalStart(false);
        }}
      >
        No Presentado
      </Button>
      <Button
        auto
        css={{ background: "var(--blue-lis)", width: "180px" }}
        onPress={() => {
          setopenModalStart(true);
          setModalNotIntroduces(false);
        }}
      >
        Empezar visita
      </Button>
    </div>
  );
};

export default StartTheAppointment;
