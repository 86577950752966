import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

/**
 * @description Obtiene las notas registradas cuando se presenta una promoción a un cliente.
 * @param {string} idClient Id del cliente, se puede obtener con getClientByAppointmentId.
 * @param {string} idSeller Id del vendedor, se puede obtener del localStorage.
 * @returns
 */
export const getNoteByAppointment = (props: any) => {
  const { idClient, idSeller } = props;
  return axios.get(
    process.env.REACT_APP_API_BACKEND +
      "/Note/byAppointment/" +
      idClient +
      "/" +
      idSeller,
    getDefaultConfigHeader()
  );
};

/**
 * @description Guarda las notas registradas cuando se presenta una promoción a un cliente.
 * @param {string} idClient Id del cliente, se puede obtener con getClientByAppointmentId.
 * @param {string} idSeller Id del vendedor, se puede obtener del localStorage.
 * @param {string} note Notas registradas por el vendedor.
 * @returns
 */
export const setNoteByAppointment = (props: any) => {
  const { idClient, idSeller, note } = props;
  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/Note/byAppointment/",
    {
      idClient: idClient,
      idSeller: idSeller,
      description: note,
    },
    getDefaultConfigHeader()
  );
};
