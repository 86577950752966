import {
  BsCloudFog,
  BsCloudRainHeavy,
  BsCloudSnow,
  BsSun,
} from "react-icons/bs";
import {
  TiWeatherCloudy,
  TiWeatherPartlySunny,
  TiWeatherStormy,
} from "react-icons/ti";

export const getWeatherIconByCode = (code: number) => {
  if (code === 0) {
    return <BsSun size={60}></BsSun>;
  }

  if (code === 1 || code === 2) {
    return <TiWeatherPartlySunny size={60}></TiWeatherPartlySunny>;
  }

  if (code === 3) {
    return <TiWeatherCloudy size={60}></TiWeatherCloudy>;
  }

  if (code === 45 || code === 48) {
    return <BsCloudFog size={60}></BsCloudFog>;
  }
  if (
    code === 51 ||
    code === 53 ||
    code === 55 ||
    code === 56 ||
    code === 57 ||
    code === 61 ||
    code === 63 ||
    code === 65 ||
    code === 80 ||
    code === 81
  ) {
    return <BsCloudRainHeavy size={60}></BsCloudRainHeavy>;
  }
  if (
    code === 71 ||
    code === 73 ||
    code === 75 ||
    code === 77 ||
    code === 85 ||
    code === 86
  ) {
    return <BsCloudSnow size={60}></BsCloudSnow>;
  }
  if (code === 95 || code === 96 || code === 99) {
    return <TiWeatherStormy size={60}></TiWeatherStormy>;
  }

  return <></>;
};
