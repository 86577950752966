import { useEffect, useState } from "react";
import AvaiableProductClientView from "../AvaiableProductClientView/AvaiableProductClientView";

const ListOfProperties = (props: any) => {
  const { principalProduct, tax, selectedHouse } = props;

  const [isHighlighted, setIsHighlighted] = useState<boolean>(false);

  useEffect(() => {
    setIsHighlighted(true);
    setTimeout(() => {
      setIsHighlighted(false);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [principalProduct]);

  return (
    <div
      className='d-flex flex-wrap align-items-center justify-content-center h-100 listado-propiedades-cliente'
      style={{
        overflow: "auto",
      }}
    >
      {principalProduct.length > 0 && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h2
              className='pt-3'
              style={{
                fontFamily: "lis-title",
                textAlign: "center",
                fontSize: "30px",
                display: "block",
              }}
            >
              ¡Estas{" "}
              <span className={isHighlighted ? "client-info" : ""}>
                {principalProduct.length}
              </span>{" "}
              viviendas encajan contigo!
            </h2>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(230px, 250px))",
                gap: "10px",
                padding: "0.5rem 1rem",
                minWidth: "750px",
              }}
            >
              {principalProduct.map((p: any, i: number) => {
                return (
                  <AvaiableProductClientView
                    asset={p}
                    key={i}
                    tax={tax}
                    isClient={true}
                    selectedHouse={p.house_id === selectedHouse ? true : false}
                  ></AvaiableProductClientView>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ListOfProperties;
