import axios from "axios";
import qs from "qs";

export const getTokenApim = () => {
  const data = {
    client_id: process.env.REACT_APP_APIM_CLIENT_ID,
    client_secret: process.env.REACT_APP_APIM_CLIENT_SECRET,
    grant_type: "password",
    username: process.env.REACT_APP_APIM_USER,
    password: process.env.REACT_APP_APIM_PASSWORD,
  };
  let config: any = {
    method: "post",
    url: process.env.REACT_APP_APIM_GET_TOKEN,
    headers: {
      "API-Key": process.env.REACT_APP_APIM_GET_TOKEN_KEY,
      Authorization: "Basic " + process.env.REACT_APP_APIM_GET_TOKEN_AUTHO,
    },
    data: qs.stringify(data),
  };

  return axios(config);
};
