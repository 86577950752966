import { Button, Card, Loading, Modal } from "@nextui-org/react";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BiHomeAlt, BiImages, BiTimeFive } from "react-icons/bi";
import { GrMapLocation } from "react-icons/gr";
import { useHistory, useParams } from "react-router-dom";
//@ts-ignore
import { GrNewWindow } from "react-icons/gr";
import { MdErrorOutline, MdFavoriteBorder } from "react-icons/md";
//@ts-ignore
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useStopwatch } from "react-timer-hook";
import AedasLoading from "../../Components/AedasLoading/AedasLoading";
import AppoimentTime from "../../Components/AppoimentTime/AppoimentTime";
import AppoimentsEndModal from "../../Components/AppoimentsEndModal/AppoimentsEndModal";
import { AutoSave } from "../../Components/AutoSave/Autosave";
import FormClient from "../../Components/FormClient/FormClient";
import FormForNotes from "../../Components/FormForNotes/FormForNotes";
import ModalAvailableProduct from "../../Components/ModalAvailableProduct/ModalAvailableProduct";
import ModalFavoritesClient from "../../Components/ModalFavoritesClient/ModalFavoritesClient";
import PromotionInfo from "../../Components/PromotionInfo/PromotionInfo";
import ClientInfo from "../../Components/Seller/ClientInfo";
import { TipsAndMessages } from "../../Components/Seller/TipsAndMessages";
import SellerClient from "../../Components/SellerClient/SellerClient";
import ShowForm from "../../Components/ShowForm/ShowForm";
import { useFetch, useIsLoading } from "../../Hooks/useFetch";
import { getDurationByAppointmentId } from "../../Service/AppointmentDuration/AppointmentDuration";
import {
  getClientByAppointmentId,
  getPromoByAppointmentId,
} from "../../Service/AppointmentService/AppointmentService";
import { getAllAssetsByPromo } from "../../Service/AssetsService/AssetsService";
import {
  getDataFormSeller,
  setDataFormSeller,
} from "../../Service/FormSeller/FormSeller";
import { getNoteByAppointment } from "../../Service/NotesByAppoinmentServices/NotesByAppoinmentServices";
import {
  convertDataFromApiToFormikObjectValues,
  generateObjectToSave,
  getValueForForm,
  objectName,
} from "../../Utils/sellerUtils";
import "./Seller.css";

import PreferencesForm from "../../Components/FormClient/PreferencesForm";
import { PromotionSelector } from "../../Components/Seller/PromotionSelector/PromotionSelector";
import { getPromotionsByAppointment } from "../../Service/PromotionService/PromotionService";
import { getUser } from "../../Utils/commonUtils";
import { ModalMandatoryFields } from "../../Components/ModalMandatoryFields/ModalMandatoryFields";

function Seller() {
  const { id, id_appoiment, edition }: any = useParams();
  const history = useHistory();
  const user = getUser();
  const { seconds, minutes, hours } = useStopwatch({ autoStart: true });
  const [editable, setEditable] = useState(false);
  const [tab, setTab] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openModalPlano, setOpenModalPlano] = useState(false);
  const [openModalFavoritosCliente, setOpenModalFavoritosCliente] =
    useState(false);
  const [openMandatoryModal, setOpenMandatoryModal] = useState(false);
  const [ConnectToServer, setConnectToServer] = useState(false);
  const [ws, setWs] = useState<any>();
  const [principalProduct, setPrincipalProduct] = useState([]); // Producto principal.
  const [shadowProduct, setShadowProduct] = useState([]); // Producto sombra.
  const [otherProduct, setOtherProduct] = useState([]); // Resto de productos.
  const [favouriteProduct, setFavouriteProduct] = useState([]);

  const [idClient, setIdClient] = useState("");
  const [isLoadingAssets, setIsLoading] = useState(true);
  const [notesFromApi, setNotesFromApi] = useState("");
  const [loadingDataFromApi, setLoadingDataFromApi] = useState(true);
  const idSeller = user.id;

  // Estados para controlar los consejos
  const [tipTab, setTipTab] = useState("pago"); // Pestaña de show o pago
  const [tipStep, setTipStep] = useState("proyectoInterior"); //proyectoInterior
  const [tipRelationalStyle, setTipRelationalStyle] = useState(""); // Estilo relacional
  const [tipArchetype, setTipArchetype] = useState(""); // Arquetipo
  const [tipBudget, setTipBudget] = useState([0, 0]); // Presupuesto

  const [displayEmailPhoneClient, setDisplayEmailPhoneClient] = useState(false);
  const [loadingFormik, setLoadingFormik] = useState<boolean>(false);

  useEffect(() => {
    edition ? setEditable(true) : setEditable(false);
    connectWebSocket();
    return () => {
      ws?.close();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_appoiment]);

  const connectWebSocket = useCallback(() => {
    const socket: any = new WebSocket(process.env.REACT_APP_WS + id);

    socket.onclose = () => {
      setConnectToServer(true);
      setTimeout(() => {
        setConnectToServer(false);
        connectWebSocket();
      }, 2000); // Esperar 2 segundos antes de intentar la reconexión
    };

    setWs(socket);
  }, [id]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function sendJson(data: any) {
    if (ws) {
      ws.send(JSON.stringify(data));
    }
  }

  const [client, isFetchingClient] = useFetch(
    getClientByAppointmentId,
    id_appoiment
  );

  const [assets, setAssets] = useState<any>();
  const MAIN_IMAGE = assets?.principal[0]?.value;

  const [promotionsByAppoiment, setPromotionsByAppoiment] = useState([]);

  // REVISAR
  useEffect(() => {
    getPromotionsByAppointment(id_appoiment).then((res: any) =>
      setPromotionsByAppoiment(res.data)
    );
  }, [id_appoiment]);

  const [promotion, setPromotion] = useState<any>();

  // REVISAR
  useEffect(() => {
    getPromoByAppointmentId(id_appoiment).then((res) => setPromotion(res.data));
  }, [id_appoiment]);

  const [duration, isFetchingDuration] = useFetch(
    getDurationByAppointmentId,
    id_appoiment
  );

  const [tabCaracteristicas, setTabCaracteristicas] = useState(0);

  const formik = useFormik({
    initialValues: useMemo(() => {
      return {
        // ** Start: Tab Info Cliente **
        // Info Cliente --> Información General
        Como__c: null,
        Pais_residencia__pc: null,
        Codigo_Postal_Actual__pc: null,
        Ingresos_Anuales_Aprox_Familiares__pc: [0, 3000000],
        Profesion__c: null,
        profesionConyuge2: null,
        Empresa_cliente__pc: null,
        Empresa_De_Conyugue_2__pc: null,

        // Info Cliente --> Información socioeconómica
        Tipo_de_unidad_familiar__pc: null,
        Miembros_en_la_unidad_familiar__pc: null, // ¿Cuántos miembros tiene la familia?
        Numero_de_hijos__c: null,
        Nombre_hijo_hija_1__c: null,
        Nombre_hijo_hija_2__c: null,
        Nombre_hijo_hija_3__c: null,
        Nombre_hijo_hija_4__c: null,
        Nombre_hijo_hija_5__c: null,
        Nombre_hijo_hija_6__c: null,
        Edad_hijo_hija_1__c: 0,
        Edad_hijo_hija_2__c: 0,
        Edad_hijo_hija_3__c: 0,
        Edad_hijo_hija_4__c: 0,
        Edad_hijo_hija_5__c: 0,
        Edad_hijo_hija_6__c: 0,
        Ocupacion__c: null, //A qué te dedicas
        Vivienda_actual_en_prop__pc: null,
        Vive_de_alquiler__c: "No", // Vives de alquier
        Cuantia_mensual_alquiler: 0,
        Con_quien_compra__c: null,
        aficiones: "", //¿Cuáles son tus aficiones?
        que_busca: null, // ¿Qué es lo que estás buscando?

        // Info Cliente --> Vivienda buscada
        // Ahora está en el tab de preferencias

        // Info Cliente --> Motivación de la compra
        DestinoVivienda__c: null, //Motivo de la compra

        // Info Cliente --> Forma de pago
        Presupuesto__c: [0, 3000000], //Presupuesto_orientativo_del_cliente__c
        PerFinanciacionNecesario__c: [0, 100], // Porcentaje_De_Financiacion_Necesario__c
        Plazo_de_entrega_deseado__c: null,

        // ** End: Tab Info Cliente **

        // ** Start: Tab Preferences **

        // Prerencias --> Vivienda buscada
        Dormitorios__c: null,
        Vivienda_buscada__c: null,
        Garajes_necesarios__c: null,
        caracteristicas_vivienda_buscada: null,

        // Preferencias --> Dotaciones de la zona
        transporte: null,
        educacion: null,
        salud: null,
        ocio_y_deportes: null,
        alimentacion: null,
        compras_y_cuidado_personal: null,
        repostaje: null,

        // Preferencias --> Ubicación de la promoción
        entorno_promocion: null,
        orientacion_preferences: null,

        // Preferencias --> Diseño del edificio
        edificacion_diseno_edificio: null,
        construccion_diseno_edificio: null,
        arquitecto_diseno_edificio: null,

        // Preferencias --> Calidades y equipamiento de la vivienda
        calidades_equipamiento_vivienda: null,

        // le_gusta_la_zona: null,
        // cercania_al_centro_urbano: null,
        // orientacion_preferences: null,

        // construccion_preferences: null,
        // altura_del_edificio: null,
        // arquitectura: null,
        // diseno_preferences: null,
        // ** End: Tab Preferences **

        // Varios datos "desperdigados" por la pantalla o modales
        Arquetipo__c: null,
        tiempoVisita: null, // Tiempo de visita
        relationalStyle: null, // Estilo Relacional
        valorationDegree: null, // Valorar cita
        send_email: null, // Finalizar cita
      };
    }, []),
    validationSchema: null,
    onSubmit: async (values, formikHelpers) => {
      try {
        // Guardado de datos
        const dataForm = generateObjectToSave(values);
        await setDataFormSeller(idClient, idSeller, id_appoiment, dataForm);
      } catch (e: any) {
        //@ts-ignore
        formikHelpers.setErrors({ error: e });
      } finally {
        formikHelpers.setSubmitting(false);
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (promotion) {
      setIsLoading(true);
      getAllAssetsByPromo(promotion?.id)
        .then((response) => {
          setAssets(response.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
    sendJson({
      event: "changePromotion",
      value: promotion?.id,
    });
  }, [promotion]);

  useEffect(() => {
    setTipBudget([
      formik.values.Presupuesto__c[0]
        ? Number(formik.values.Presupuesto__c[0])
        : 0,
      formik.values.Presupuesto__c[1]
        ? Number(formik.values.Presupuesto__c[1])
        : 3000000,
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  useEffect(() => {
    if (promotion && client && idSeller && id_appoiment) {
      setLoadingDataFromApi(true);
      getDataFormSeller(promotion.id, client.id, idSeller, id_appoiment)
        .then((response) => {
          transformResponseDataToFormikObject(formik, response.data);
        })
        .finally(() => setLoadingDataFromApi(false));
    }
  }, [promotion, client, idSeller, id_appoiment]);

  const transformResponseDataToFormikObject = (formik: any, data: any) => {
    setLoadingFormik(true);
    convertDataFromApiToFormikObjectValues(formik, data);

    // Seteamos el estilo relacional
    setTipRelationalStyle(
      getValueForForm(data, objectName.sellerForm, "idRelationalStyle")
    );

    // Seteamos el arquetipo
    setTipArchetype(
      getValueForForm(data, objectName.clientForm, "Arquetipo__c")
    );
    setLoadingFormik(false);
  };

  const [notes, isFetchingNotes] = useFetch(getNoteByAppointment, {
    idClient: idClient,
    idSeller: user.id,
  });

  const isLoading = useIsLoading([
    loadingFormik,
    isFetchingClient,
    isLoadingAssets,
    isFetchingNotes,
    isFetchingDuration,
    loadingDataFromApi,
  ]);

  const [selectedValue, setselectedValue] = useState("all_img_video");
  const [Index, setIndex] = useState(1);

  useEffect(() => {
    setIdClient(client?.id);
  }, [client]);

  useEffect(() => {
    setNotesFromApi(notes);
  }, [notes]);

  useEffect(() => {
    if (selectedValue === "all_img_video") {
      sendJson({
        event: "go_index",
        value: Index,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Index, selectedValue]);

  useEffect(() => {
    switch (selectedValue) {
      case "all_img_video":
        setTab(1);
        sendJson({
          event: "change_clientview",
          value: "all_img_video",
        });
        break;
      case "map":
        setTab(1);
        sendJson({
          event: "change_clientview",
          value: "map",
        });
        break;
      case "parcela":
        setTab(1);
        sendJson({
          event: "change_clientview",
          value: "parcela",
        });
        break;
      case "infografia_exterior":
        setTab(1);
        sendJson({
          event: "change_clientview",
          value: "infografia_exterior",
        });
        break;
      case "infografia_interior":
        setTab(1);
        sendJson({
          event: "change_clientview",
          value: "infografia_interior",
        });
        break;
      case "infografias_zonas_comunes":
        setTab(1);
        sendJson({
          event: "change_clientview",
          value: "infografias_zonas_comunes",
        });
        break;
      case "visita_virtual_zzcc":
        setTab(1);
        sendJson({
          event: "change_clientview",
          value: "visita_virtual_zzcc",
        });
        break;
      case "showroom":
        setTab(1);
        sendJson({
          event: "change_clientview",
          value: "showroom",
        });
        break;
      case "vista_virtual":
        setTab(1);
        sendJson({
          event: "change_clientview",
          value: "vista_virtual",
        });
        break;
      case "maqueta_virtual":
        setTab(1);
        sendJson({
          event: "change_clientview",
          value: "maqueta_virtual",
        });
        break;
      case "maqueta_fisica":
        setTab(1);
        sendJson({
          event: "change_clientview",
          value: "maqueta_fisica",
        });
        break;
      case "calidades":
        setTab(1);
        sendJson({
          event: "change_clientview",
          value: "calidades",
        });
        break;
      case "listado_viviendas":
        setTab(1);
        break;
      case "mostrar_presupuesto":
        setTab(1);
        sendJson({
          event: "mostrar_presupuesto",
          value: "mostrar_presupuesto",
        });
        break;
      case "fin_de_la_cita":
        setTab(1);
        sendJson({
          event: "fin_de_la_cita",
          value: displayEmailPhoneClient,
        });
        break;
      case "mostrar_viviendas_favoritas":
        setTab(1);
        sendJson({
          event: "mostrar_viviendas_favoritas",
          value: "pinta las viviendas favoritas",
        });
        break;
      case "gracias_por_tu_visita":
        setTab(1);
        sendJson({
          event: "change_clientview",
          value: "gracias_por_tu_visita",
        });
        break;
      default:
        debugger;
        setTab(1);
        sendJson({
          event: "change_clientview",
          value: "all_img_video",
        });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  useEffect(() => {
    if (ws?.readyState === 1) {
      sendJson({
        event: "change_form",
        value: formik.values,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  useEffect(() => {
    displayEmailPhoneClient === false &&
      // Si cerramos el modal ocultamos los datos de contacto del cliente
      sendJson({
        event: "fin_de_la_cita",
        value: displayEmailPhoneClient,
      });
  }, [displayEmailPhoneClient]);

  const handleSaveDataAndExit = () => {
    // Se redirige al listado de promociones
    formik.submitForm().then(() => history.push("/"));
  };

  const openClientWindow = () => {
    let url = sessionStorage.getItem("clientUrl");
    if (url !== null) {
      url = JSON.parse(url);
      if (typeof url === "string") {
        // Abrimos la ventana en un segundo monitor.
        const width = window.screen.availWidth;
        const height = window.screen.availHeight;
        const left = window.screen.width; // posición izquierda de la ventana
        const top = 0; // posición superior de la ventana

        window.open(
          url,
          "_blank",
          `width=${width}, height=${height}, left=${left}, top=${top}`
        );
      }
      sendJson({
        event: "changePromotion",
        value: promotion?.id,
      });
    }
  };

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }

  return (
    <>
      {ConnectToServer ? (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 101,
            background: "rgb(255 255 255 / 65%)",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <span>Reconectando con el servidor</span>
          <Loading></Loading>
        </div>
      ) : (
        <></>
      )}
      <Modal
        scroll
        width="1200px"
        open={openModal}
        onClose={() => {
          setDisplayEmailPhoneClient(false);
          setOpenModal(false);

          // Si cerramos el modal, volvemos a pintar la galería de imágenes.
          sendJson({
            event: "change_clientview",
            value: "all_img_video",
          });
        }}
        closeButton
        preventClose
      >
        <AppoimentsEndModal
          mainImage={MAIN_IMAGE}
          setselectedValue={setselectedValue}
          setDisplayEmailPhoneClient={setDisplayEmailPhoneClient}
          setOpenModal={setOpenModal}
          sendJson={sendJson}
          setTab={setTab}
          promotion={promotion}
          client={client}
          formik={formik}
          seconds={seconds}
          minutes={minutes}
          hours={hours}
          setTipArchetype={setTipArchetype}
          setTipRelationalStyle={setTipRelationalStyle}
        ></AppoimentsEndModal>
      </Modal>
      <Modal
        id="ModalAvailableProduct_in_seller_tsx"
        preventClose
        scroll
        width="1300px"
        open={openModalPlano}
        onClose={() => {
          setOpenModalPlano(false);

          // Quitamos la casa señalada de la ventana del cliente y ocultamos el presupuesto/calendario.
          sendJson({
            event: "mostrar_presupuesto",
            value: false,
            selectedHouse: "",
          });

          sendJson({
            event: "mostrar_calendario",
            value: false,
            selectedHouse: "",
          });

          // Dejamos puesta la galería de imágenes.
          sendJson({
            event: "change_clientview",
            value: "all_img_video",
          });
        }}
        closeButton
      >
        <ModalAvailableProduct
          promotion={promotion}
          promotions={promotionsByAppoiment}
          formik={formik}
          setselectedValue={setselectedValue}
          sendJson={sendJson}
          setPrincipalProduct={setPrincipalProduct}
          principalProduct={principalProduct}
          shadowProduct={shadowProduct}
          setShadowProduct={setShadowProduct}
          otherProduct={otherProduct}
          setOtherProduct={setOtherProduct}
          favouriteProduct={favouriteProduct}
          setFavouriteProduct={setFavouriteProduct}
          idClient={idClient}
        ></ModalAvailableProduct>
      </Modal>
      <Modal
        preventClose
        scroll
        width="1300px"
        open={openModalFavoritosCliente}
        onClose={() => {
          // Cerramos el modal.
          setOpenModalFavoritosCliente(false);

          // Quitamos la casa señalada de la ventana del cliente y ocultamos el presupuesto/calendario.
          sendJson({
            event: "mostrar_presupuesto",
            value: false,
            selectedHouse: "",
          });

          sendJson({
            event: "mostrar_calendario",
            value: false,
            selectedHouse: "",
          });

          // Mostramos la galería de imágenes.
          sendJson({
            event: "change_clientview",
            value: "all_img_video",
          });
        }}
        closeButton
      >
        <ModalFavoritesClient
          idClient={idClient}
          idPromotion={promotion?.id}
          setOpenModalFavoritosCliente={setOpenModalFavoritosCliente}
          favouriteProduct={favouriteProduct}
          sendJson={sendJson}
          editable={editable}
        ></ModalFavoritesClient>
      </Modal>
      <Modal
        preventClose
        open={openMandatoryModal}
        onClose={() => {}}
        scroll
        width="600px"
      >
        <ModalMandatoryFields
          formik={formik}
          setOpenMandatoryModal={setOpenMandatoryModal}
        ></ModalMandatoryFields>
      </Modal>
      <div
        className={editable ? "background-editable" : ""}
        style={{
          display: "flex",
        }}
      >
        <div className="infoSection">
          <div className="infoSection__container">
            <ClientInfo
              client={client}
              formik={formik}
              setTipArchetype={setTipArchetype}
              setTipRelationalStyle={setTipRelationalStyle}
            ></ClientInfo>
            <PromotionSelector
              promotion={promotion}
              setPromotion={setPromotion}
            ></PromotionSelector>
            <PromotionInfo
              promotion={promotion}
              formik={formik}
              sellerWindow={true}
              tabCaracteristicas={tabCaracteristicas}
              setTabCaracteristicas={setTabCaracteristicas}
            ></PromotionInfo>
          </div>
        </div>
        <div
          style={{
            width: "70%",
            height: "100vh",
            padding: "1rem 1rem 1rem 0rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              height: "80px",
            }}
          >
            <div
              style={{
                width: "62%",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Card
                style={{
                  padding: "0.2rem",
                  height: "80px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    gap: "5px",
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  {!editable && (
                    <>
                      <Card
                        css={{
                          //width: "120px",
                          height: "60px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: `${editable ? "not-allowed" : "pointer"}`,
                        }}
                        variant="bordered"
                        isHoverable={!editable}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                          onClick={() => setselectedValue("all_img_video")}
                        >
                          <BiImages size={20} />
                          <div
                            className="text-center"
                            style={{ fontSize: "11px", fontWeight: "bold" }}
                          >
                            Imágenes y vídeos
                          </div>
                        </div>
                      </Card>
                      <Card
                        css={{
                          //width: "120px",
                          height: "60px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: `${editable ? "not-allowed" : "pointer"}`,
                        }}
                        variant="bordered"
                        isHoverable={!editable}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                          onClick={() => setselectedValue("map")}
                        >
                          <GrMapLocation size={20} />
                          <div style={{ fontSize: "11px", fontWeight: "bold" }}>
                            Mapa
                          </div>
                        </div>
                      </Card>
                      <Card
                        css={{
                          //width: "120px",
                          height: "60px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: `${editable ? "not-allowed" : "pointer"}`,
                        }}
                        variant="bordered"
                        isHoverable={!editable}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setOpenModalPlano(true);
                          }}
                        >
                          <BiHomeAlt size={20} />
                          <div
                            className="text-center"
                            style={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              lineHeight: "1",
                            }}
                          >
                            Listado de viviendas
                          </div>
                        </div>
                      </Card>
                    </>
                  )}
                  <Card
                    css={{
                      //width: "120px",
                      height: "60px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    variant="bordered"
                    isHoverable
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        sendJson({
                          event: "mostrar_viviendas_favoritas",
                          clientId: idClient,
                          promotionId: promotion?.id,
                        });

                        setOpenModalFavoritosCliente(true);
                      }}
                    >
                      <MdFavoriteBorder size={20} />
                      <div
                        className="text-center"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          lineHeight: "1",
                        }}
                      >
                        Favoritos del cliente
                      </div>
                    </div>
                  </Card>
                </div>
              </Card>
            </div>
            <div style={{ width: "20%", height: "80px" }}>
              <Card
                style={{
                  height: "80px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    borderRadius: "10px",
                    width: "2.2rem",
                    height: "2.2rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "var(--lis-ligthblue)",
                    marginLeft: "7px",
                  }}
                >
                  <BiTimeFive
                    size={32}
                    color={"var(--secondary-color)"}
                  ></BiTimeFive>
                </div>
                <AppoimentTime
                  editable={editable}
                  formik={formik}
                  showedup={duration?.showedup}
                  visitTime={duration?.duration}
                  id_appoiment={id_appoiment}
                  seconds={seconds}
                  minutes={minutes}
                  hours={hours}
                ></AppoimentTime>
              </Card>
            </div>
            <div style={{ width: "20%", height: "80px" }}>
              <Card
                css={{
                  height: "80px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {editable ? (
                  <Button
                    size={"md"}
                    css={{ width: "80px" }}
                    onClick={() => {
                      handleSaveDataAndExit();
                    }}
                  >
                    Guardar datos y salir
                  </Button>
                ) : (
                  <div className="btn-ventana-cliente d-flex flex-column align-items-center">
                    <Button
                      size={"sm"}
                      css={{ width: "50px" }}
                      disabled={openModal}
                      onClick={() => {
                        if (
                          ((formik.values.Pais_residencia__pc !== "Spain" ||
                            formik.values.Pais_residencia__pc !== "España") &&
                            formik.values.Arquetipo__c &&
                            formik.values.Pais_residencia__pc &&
                            // formik.values.Vivienda_actual_en_prop__pc &&
                            // formik.values.Vive_de_alquiler__c &&
                            formik.values.DestinoVivienda__c &&
                            formik.values.relationalStyle) ||
                          (formik.values.Codigo_Postal_Actual__pc &&
                            formik.values.Arquetipo__c &&
                            formik.values.Pais_residencia__pc &&
                            formik.values.DestinoVivienda__c &&
                            formik.values.relationalStyle)
                        ) {
                          formik.submitForm();
                          setOpenModal(true);
                        } else {
                          setOpenMandatoryModal(true);
                        }

                        sendJson({
                          event: "mostrar_viviendas_favoritas",
                          clientId: idClient,
                          promotionId: promotion?.id,
                        });
                      }}
                    >
                      Terminar visita
                    </Button>
                    <div
                      className="link-open-cliente-window text-center text-decoration-underline lh-sm"
                      onClick={() => openClientWindow()}
                    >
                      <GrNewWindow /> Reabrir ventana cliente
                    </div>
                  </div>
                )}
              </Card>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              height: "100%",
              maxHeight: " calc(100% - 94px)",
              minHeight: " calc(100% - 94px)",
            }}
          >
            <form
              className="position-relative"
              onSubmit={formik.handleSubmit}
              style={{
                height: "100%",
                width: "59.3%",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  display: "flex",
                  zIndex: "1",
                  bottom: "10px",
                  left: "2px",
                  width: "100%",
                  height: "35px",
                  justifyContent: "end",
                }}
              >
                <div className="d-flex justify-content-end w-100">
                  <AutoSave debounceMs={5000} formik={formik}></AutoSave>
                </div>
              </div>
              <Card
                style={{
                  height: "100%",
                }}
              >
                <div>
                  <ShowForm
                    formik={formik}
                    setselectedValue={setselectedValue}
                    setTipStep={setTipStep}
                  ></ShowForm>
                </div>
                <div
                  style={{
                    height: "1px",
                    borderBottom: "1px solid #eaeaea",
                    padding: "0px 20px 5px 20px",
                  }}
                ></div>
                <Tabs style={{ overflow: "auto" }}>
                  <TabList
                    style={{
                      paddingLeft: "0px",
                      marginTop: "0px",
                      marginLeft: "0px",
                      marginRight: "0px",
                      borderBottom: "1px solid #f4f4f4",
                    }}
                  >
                    <Tab onClick={() => setTipTab("presupuesto")}>
                      Info Cliente
                    </Tab>
                    <Tab onClick={() => setTipTab("financiacion")}>
                      Preferencias de búsqueda
                    </Tab>
                    {/* <Tab onClick={() => setTipTab("show")}>Show</Tab> */}
                    {/* <Tab onClick={() => setTipTab("pago")}>Pago</Tab> */}
                  </TabList>
                  {/* INI: Tab de info cliente */}
                  <TabPanel>
                    <>
                      {/* Si NO ES PRODUCCIÓN mostramos el mensaje */}
                      {process.env.REACT_APP_IS_PRO !== "true" && (
                        <div className="pl-2">
                          <p style={{ marginLeft: "20px" }}>
                            <MdErrorOutline size={24} color={"orange"} /> Esta
                            web es de PRUEBAS,{" "}
                            <b>algunas respuestas no se podrán guardar</b>.
                          </p>
                        </div>
                      )}
                      <div
                        className="seller-form-tabs"
                        style={{
                          paddingLeft: "10px",
                          overflowY: "auto",
                          height: "100%",
                        }}
                      >
                        <FormClient formik={formik}></FormClient>
                      </div>
                    </>
                  </TabPanel>
                  {/* FIN: Tab de info cliente */}
                  {/* INI: Tab de preferencias */}
                  <TabPanel>
                    <>
                      {/* Si NO ES PRODUCCIÓN mostramos el mensaje */}
                      {process.env.REACT_APP_IS_PRO !== "true" && (
                        <div className="pl-2">
                          <p style={{ marginLeft: "20px" }}>
                            <MdErrorOutline size={24} color={"orange"} /> Esta
                            web es de PRUEBAS,{" "}
                            <b>algunas respuestas no se podrán guardar</b>.
                          </p>
                        </div>
                      )}
                      <div
                        className="seller-form-tabs"
                        style={{
                          paddingLeft: "10px",
                          overflowY: "auto",
                          height: "100%",
                        }}
                      >
                        <PreferencesForm
                          formik={formik}
                          setTabCaracteristicas={setTabCaracteristicas}
                        />
                        {/* <FormClient formik={formik}></FormClient> */}
                      </div>
                    </>
                  </TabPanel>
                </Tabs>
              </Card>
            </form>
            <div style={{ width: "40%" }}>
              {!editable && (
                <Card css={{ height: "28%" }} className="tips-and-messages">
                  <TipsAndMessages
                    tipTab={tipTab}
                    tipStep={tipStep}
                    tipRelationalStyle={tipRelationalStyle}
                    tipArchetype={tipArchetype?.toLowerCase()}
                    tipBudget={tipBudget}
                    formik={formik}
                  ></TipsAndMessages>
                </Card>
              )}
              <Card
                className="carousel-images"
                css={{
                  height: editable ? "100%" : "70%",
                  marginTop: "12px",
                }}
              >
                <Tabs
                  selectedIndex={tab}
                  onSelect={(
                    index: number,
                    lastIndex: number,
                    event: Event
                  ) => {
                    setTab(index);
                  }}
                >
                  <TabList>
                    <Tab>Notas</Tab>

                    {!editable && <Tab>Interacción Cliente</Tab>}
                  </TabList>
                  <TabPanel>
                    <div
                      className="notes-editor notas"
                      style={{ padding: "10px", height: "100%" }}
                    >
                      <FormForNotes
                        notesFromApi={notesFromApi}
                        idClient={idClient}
                        idSeller={idSeller}
                        editable={editable}
                      ></FormForNotes>
                    </div>
                  </TabPanel>
                  {!editable && (
                    <TabPanel>
                      <SellerClient
                        assets={assets}
                        value={selectedValue}
                        setIndex={setIndex}
                        sendJson={sendJson}
                        idClient={idClient}
                        idPromotion={promotion?.id}
                      ></SellerClient>
                    </TabPanel>
                  )}
                </Tabs>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Seller;
