import { Loading } from "@nextui-org/react";
import debounce from "just-debounce-it";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";

export const AutoSave = ({ debounceMs, formik }: any) => {
  const [lastSaved, setLastSaved] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    debounce(
      () =>
        //@ts-ignore
        formik.submitForm().then(() => setLastSaved(new Date().toISOString())),
      debounceMs
    ),
    //[formik.submitForm]
    []
  );

  useEffect(() => {
    debouncedSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  let result = null;
  if (!!formik.isSubmitting) {
    result = (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          justifyContent: "end",
          paddingRight: "1rem",
        }}
      >
        <Loading size="xs" />
        <div
          style={{
            fontFamily: "lis-body",
            fontSize: "12px",
          }}
        >
          Guardando
        </div>
      </div>
    );
  } else if (Object.keys(formik.errors).length > 0) {
    //@ts-ignore
    result = (
      <div
        style={{
          fontFamily: "lis-body",
          fontSize: "12px",
          textAlign: "right",
          paddingRight: "1rem",
        }}
      >
        <RiErrorWarningFill color={"#b1003b"} size={20}></RiErrorWarningFill>
        {"     "}Ha habido un error{" "}
      </div>
    );
  } else if (lastSaved !== null) {
    result = (
      <div
        style={{
          fontFamily: "lis-body",
          fontSize: "12px",
          textAlign: "right",
          paddingRight: "1rem",
        }}
      >
        <BsFillCheckCircleFill
          color={"#00b176"}
          size={20}
        ></BsFillCheckCircleFill>
        {"     "} {moment(lastSaved).format("HH:mm:ss")}
      </div>
    );
  }
  return (
    <div className="d-flex justify-content-center align-items-center">
      {result}
    </div>
  );
};
