import { Typography } from "@mui/material";
import { Button, Modal } from "@nextui-org/react";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  action: () => void;
}

export const ModalExit = ({ open, setOpen, action }: Props) => {
  const location = useLocation();

  if (location.pathname === "/") {
    return <></>;
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleExit = () => {
    handleClose();
    action();
  };

  return (
    <Modal open={open} css={{ padding: "2rem" }}>
      <Modal.Body>
        <Typography>¿Estás seguro de que quieres salir?</Typography>
      </Modal.Body>
      <Modal.Footer
        style={{
          justifyContent: "space-between",
          marginRight: "20px",
          marginLeft: "20px",
        }}
      >
        <Button auto flat color='error' onClick={handleClose}>
          Cancelar
        </Button>
        <Button auto onPress={handleExit}>
          Salir
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
