import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";
import { isJSDocLinkCode } from "typescript";
/**
 * Obtiene los planos y las imágenes que le han gustado a un cliente.
 * @param idClient
 * @param idPromo
 * @returns
 */
export const getLikesService = (idClient: any, idPromo: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + `/Asset/likes/${idClient}/${idPromo}`,
    getDefaultConfigHeader()
  );
};

/**
 * Guarda los planos que gustan al cliente de esa promoción.
 * @param {string} idClient
 * @param {array} data
 * @returns
 */
export const setLikesHouseService = (idClient: any, data: any) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND + `/Asset/likesHouse/${idClient}`,
    data,
    getDefaultConfigHeader()
  );
};

/**
 * Guarda las imágenes que gustan al cliente de una promoción.
 * @param {string} idClient
 * @param {array} data
 * @returns
 */
export const setLikesPromotionService = (idClient: any, idAsset: any) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND +
      `/Asset/likePromotion/${idClient}?idAsset=${idAsset}`,
    getDefaultConfigHeader()
  );
};
/**
 * elimina las imágenes que gustan al cliente de una promoción.
 * @param {string} idLike
 * @returns
 */
export const deleteLikePromoAsset = (idLike: any) => {
  return axios.delete(
    process.env.REACT_APP_API_BACKEND + `/Asset/like/${idLike}`,
    getDefaultConfigHeader()
  );
};
