import { BiMapPin } from "react-icons/bi";
import TabsForm from "./TabsForm";

export default function PisoPiloto(props: any) {
  const { formik } = props;
  const sections = [
    {
      button: {
        onClick: () => props.setselectedValue("showroom"),
        icon: <BiMapPin></BiMapPin>,
        name: "Showroom",
      },
      questions: [
        // {
        //   name: "Cocina amueblada",
        //   answers: normal_response,
        //   formik: "cocinaAmueblada",
        // },
        // {
        //   name: "Baño",
        //   answers: normal_response,
        //   formik: "bano",
        // },
        // {
        //   name: "Armarios empotrados",
        //   answers: normal_response,
        //   formik: "armariosEmpotrados",
        // },
      ],
    },
    {
      button: {
        onClick: () => props.setselectedValue("vista_virtual"),
        icon: <BiMapPin></BiMapPin>,
        name: "Visita virtual",
      },
      questions: [
        // {
        //   name: "Cocina abierta",
        //   answers: normal_response,
        //   formik: "cocinaAbierta",
        // },
      ],
    },
    {
      button: {
        onClick: () => props.setselectedValue("infografia_interior"),
        icon: <BiMapPin></BiMapPin>,
        name: "Infografia",
      },
      questions: [
        // {
        //   name: "Aerotermia",
        //   answers: normal_response,
        //   formik: "aerotermia",
        // },
      ],
    },
  ];
  return (
    <>
      <TabsForm formik={formik} sections={sections}></TabsForm>
    </>
  );
}
