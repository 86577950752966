import { useEffect } from "react";
import { useFetch } from "../../Hooks/useFetch";
import { getHouseBudget } from "../../Service/HouseBudget/HouseBudget";
import { priceFormatter } from "../../Utils/commonUtils";

import { Loading } from "@nextui-org/react";
import { AiFillCar } from "react-icons/ai";
import { FaWarehouse } from "react-icons/fa";
import { ImHome } from "react-icons/im";
const Budget = (selectedHouse: any) => {
  const [budget, isFetchingBudget, updateData] = useFetch(
    getHouseBudget,
    selectedHouse.selectedHouse
  );

  useEffect(() => {
    updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHouse.selectedHouse]);

  return (
    <>
      {!isFetchingBudget ? (
        budget && (
          <section className="d-flex justify-content-center align-items-center h-100">
            <div>
              <p className="mb-3 font-family-lis h4">
                Desglose de la hoja informativa
              </p>
              <div className="client-separator"></div>
              <p className="mx-2 my-2 lh-1 font-family-lis h5">
                <ImHome size={24} /> Vivienda:{" "}
                <span className="h5">
                  {priceFormatter(budget?.precio_vivienda)}
                </span>
              </p>
              {budget?.anejos?.map((anejo: any, i: any) => (
                <div key={`${i}-${anejo.key}`}>
                  <div className="client-separator"></div>
                  <p
                    className="mx-2 my-2 lh-1 font-family-lis h5"
                    key={anejo.key}
                  >
                    {anejo.key.toLowerCase() === "trastero" && (
                      <>
                        <FaWarehouse size={24} /> {anejo.key}:{" "}
                        <span className="h5">
                          {priceFormatter(anejo?.value)}
                        </span>
                      </>
                    )}
                    {anejo.key.toLowerCase() === "garaje" && (
                      <>
                        <AiFillCar size={24} /> {anejo.key}:{" "}
                        <span className="h5">
                          {priceFormatter(anejo?.value)}{" "}
                        </span>
                      </>
                    )}
                  </p>
                </div>
              ))}
              <div className="client-separator"></div>
              <p className="mt-2 font-family-lis h6">
                Pago total de la vivienda:{" "}
                <span className="h5">
                  {priceFormatter(budget?.precio_total)}
                </span>
              </p>

              <p className="mt-3 my-2 lh-1 font-family-lis">
                <i>Todos los precios incluyen impuestos.</i>
              </p>
            </div>
          </section>
        )
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
            height: "100%",
          }}
        >
          <span style={{ fontSize: "1.5rem", fontFamily: "lis-title" }}>
            Cargando la hoja informativa
          </span>{" "}
          <Loading type="points" size="xl"></Loading>
        </div>
      )}
    </>
  );
};

export default Budget;
