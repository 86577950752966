import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useEffect, useState } from "react";
import uuid from "react-uuid";
import SwitchSelector from "./SwicthSelector";

import "./ShowForm.css";

const items = [
  {
    name: "Proyecto Interior",
    comment: "",
    value: "proyectoInterior",
  },
  {
    name: "Proyecto exterior",
    comment: "",
    value: "proyectoExterior",
  },
  {
    name: "Zonas comunes",
    comment: "",
    value: "zonasComunes",
  },
  {
    name: "Personalizar calidades",
    comment: "",
    value: "personalizarCalidades",
  },
  {
    name: "Piso piloto",
    comment: "",
    value: "pisoPiloto",
  },
];

function ShowForm(props: any) {
  const { formik, setTipStep } = props;

  const [step, setstep] = useState(1);
  const [steps, setsteps] = useState<any>(
    JSON.parse(sessionStorage.getItem("stepper_aedas") ?? "[]")
  );
  useEffect(() => {
    sessionStorage.setItem("stepper_aedas", JSON.stringify(steps));
  }, [steps]);
  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <div
        className="stepper-atenea pt-2"
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Stepper nonLinear alternativeLabel activeStep={step}>
          {items.map((label, i) => (
            <Step
              onClick={() => setTipStep(label.value)}
              key={uuid()}
              completed={steps.includes(i + 1)}
            >
              <StepLabel
                style={{ cursor: "pointer" }}
                className={steps.includes(i + 1) ? "selected" : ""}
                onClick={() => {
                  setstep(i + 1);
                  setsteps((oldArray: any) => [...oldArray, i + 1]);
                }}
              >
                <h5 style={{ fontFamily: "lis-title", fontSize: "10px" }}>
                  {label.name}
                </h5>
              </StepLabel>
              <p
                style={{
                  fontFamily: "lis-body",
                  fontSize: "14px",
                  textAlign: "center",
                  marginBottom: "5px",
                }}
              >
                {label.comment}
              </p>
            </Step>
          ))}
        </Stepper>
      </div>
      <SwitchSelector
        setselectedValue={props.setselectedValue}
        step={step}
        formik={formik}
      ></SwitchSelector>
    </div>
  );
}

export default ShowForm;
