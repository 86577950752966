export const getTipsMessages = (
  tab: any,
  step: any,
  relationalStyle: any,
  archetype: any
) => {
  // return tab && step && relationalStyle && archetype
  //   ? //@ts-ignore
  //     tipsMessages[tab][step][relationalStyle][archetype]
  //   : "";
  return step && relationalStyle && archetype
    ? //@ts-ignore
      tipsMessagesWithoutShow[step][relationalStyle][archetype]
    : "";
};

/**
 * Listado de mensajes existentes, esto no se usa, pero x si acaso, se deja
 */
// export const tipsMessages = {
//   show: {
//     proyectoInterior: {
//       //Nico: El que abandona el nido
//       "1": {
//         tierra: "Refuerza la practicidad y conexiones de la zona",
//         fuego: "Refuerza la probable revalorización de la zona",
//         agua: "¿Tienes familiares o amigos que vivan por la zona?",
//         aire: "Comenta las múltiples actividades que pueden realizarse en la zona",
//       },
//       //Patricia y Pepe: La pareja primeriza
//       "2": {
//         tierra: "¿Qué os ha llamado la atención de esta zona?",
//         fuego:
//           "Comentar que es una zona en la que están comprando parejas de cierto poder adquisitivo",
//         agua: "Reforzar la tranquilidad y seguridad de la zona",
//         aire: "Comentar que puede convertirse o se trata de un barrio animado y joven",
//       },
//       //Fátima y Cesar: La familia en crecimiento
//       "3": {
//         tierra: "¿Qué es lo que más os ha llamado la atención de esta zona?",
//         fuego:
//           "Comenta el nivel social de otras familias que están comprando en la promoción",
//         agua: "Comenta el nivel social de otras familias que están comprando en la promoción",
//         aire: "Ayúdale a visualizar que tendrán grandes opciones de socializar con vecinos en esta zona",
//       },
//       //Fausto y Carmen: Un futuro para mis hijos
//       "4": {
//         tierra:
//           "Contáis con colegio e instituto a X minutos, y en general todas las comunicaciones para que como padres todo sea más fácil",
//         fuego:
//           "Comenta el nivel social de otras familias que están comprando en la promoción",
//         agua: "Muéstrale por qué sus hijos serán más felices en esta zona que donde viven actualmente",
//         aire: "Ayúdale a visualizar que tendrán grandes opciones de socializar con vecinos en esta zona",
//       },
//       //Nuria y Esteban: Un nuevo episodio
//       "5": {
//         tierra:
//           "¿Qué os gusta especialmente de esta zona respecto a donde vivís actualmente?",
//         fuego:
//           "¿Qué os gusta especialmente de esta zona respecto a donde vivís actualmente?",
//         agua: "¿Tenéis amigos o familia viviendo por la zona?",
//         aire: "¿En qué creéis que vais a mejorar vuestra calidad de vida viviendo en esta zona?",
//       },
//       //Carlos: El caprichoso
//       "6": {
//         tierra:
//           "¿En qué zona vive actualmente? Reforzar ventajas de esta zona Vs la suya",
//         fuego:
//           "¿En qué zona vive actualmente? Ayúdalo a sentir el crecimiento de estatus que tendrá por vivir en esta zona",
//         agua: "¿En qué zona vive actualmente? Muéstrale por qué esta zona es más divertida.",
//         aire: "Aporta datos de revalorización de la zona en los últimos años",
//       },
//       //Iván: Busca oportunidad de inversión
//       "7": {
//         tierra: "Aporta datos de revalorización de la zona en los últimos años",
//         fuego: "Es el momento ideal para invertir en esta ubicación",
//         agua: "¿Qué es lo que más le ha llamado la atención para invertir en esta zona?",
//         aire: "La zona está de moda, está creciendo de una forma increíble",
//       },
//       //Diane & Chris: Un descanso cómodo
//       "8": {
//         tierra: "Imagino que conocen la zona, ¿qué les lleva a comprar aquí?",
//         fuego: "Imagino que conocen la zona, ¿qué les lleva a comprar aquí?",
//         agua: "Refuerza la comodidad y seguridad de la zona",
//         aire: "¿En qué épocas del año tendrían pensado venir aquí? Esta zona es fantástica…",
//       },
//       //Juan: Futuro Jubilado
//       "9": {
//         tierra: "¿Qué es lo que más le gusta de esta zona para jubilarte?",
//         fuego: "¿Qué es lo que más le gusta de esta zona para jubilarte?",
//         agua: "¿Tiene familia cerca de la zona?",
//         aire: "¿Cómo se imagina su jubilación viviendo en esta zona?",
//       },
//       //Nieves y Hugo: Un piso para nuestra hija
//       "10": {
//         tierra:
//           "Esta zona se está revalorizando mucho, por tanto es una inversión excelente a largo plazo para vuestro hijo/a",
//         fuego:
//           "Esta zona se está revalorizando mucho, por tanto es una inversión excelente a largo plazo para vuestro hijo/a",
//         agua: "¿Vosotros vivís cerca de aquí?",
//         aire: "Es una zona a la que están viniendo muchas personas de la edad de vuestra hija…",
//       },
//     },
//     proyectoExterior: {
//       //Nico: El que abandona el nido
//       "1": {
//         tierra:
//           "Muéstrale que es un proyecto donde los m2 están bien aprovechados",
//         fuego:
//           "Hazle sentir que será la envidia de sus amigos con su nueva vivienda",
//         agua: "Refuerza la sostenibilidad y eficiencia energética del proyecto",
//         aire: "Refuerza la modernidad del proyecto",
//       },
//       //Patricia y Pepe: La pareja primeriza
//       "2": {
//         tierra: "Refuerza la modernidad del proyecto",
//         fuego: "Es una zona que se está revalorizando mucho",
//         agua: "Es una zona que se está revalorizando mucho",
//         aire: "Llévalos a la maqueta para explicar los exteriores",
//       },
//       //Fátima y Cesar: La familia en crecimiento
//       "3": {
//         tierra:
//           "Si están presentes los niños, llévalos a ver la maqueta y dales protagonismo",
//         fuego:
//           "Si están presentes los niños, llévalos a ver la maqueta y dales protagonismo",
//         agua: "Si están presentes los niños, llévalos a ver la maqueta y dales protagonismo",
//         aire: "Si están presentes los niños, llévalos a ver la maqueta y dales protagonismo",
//       },
//       //Fausto y Carmen: Un futuro para mis hijos
//       "4": {
//         tierra:
//           "Utiliza la maqueta especialmente para ubicarlos en las opciones del barrio",
//         fuego: "Comenta las calidades de los acabados exteriores",
//         agua: "Hazles sentir que sus hijos se criarán a gusto en esa urbanización, a través de la maqueta",
//         aire: "Hazles sentir que sus hijos se criarán a gusto en esa urbanización, a través de la maqueta",
//       },
//       //Nuria y Esteban: Un nuevo episodio
//       "5": {
//         tierra:
//           "Explica con claridad las diferentes orientaciones posibles para la vivienda",
//         fuego:
//           "Explica con claridad las diferentes orientaciones posibles para la vivienda",
//         agua: "Utiliza la maqueta para que elijan las vistas que les gustaría tener",
//         aire: "Utiliza la maqueta para que elijan las vistas que les gustaría tener",
//       },
//       //Carlos: El caprichoso
//       "6": {
//         tierra: "Contamos con X m2 de zonas verdes…",
//         fuego:
//           "Ayúdalo/a a entender la orientación de la vivienda que más le gusta",
//         agua: "Apóyate especialmente en la maqueta, haciendo partícipe a toda la familia",
//         aire: "Haz hincapié en la modernidad del diseño de la promoción",
//       },
//       //Iván: Busca oportunidad de inversión
//       "7": {
//         tierra: "Apóyate en datos técnicos como m2 disponibles",
//         fuego: "Refuerza la exclusividad Vs otras promociones de la zona",
//         agua: "Apóyate especialmente en la maqueta",
//         aire: "Haz hincapié en la modernidad del diseño de la promoción",
//       },
//       //Diane & Chris: Un descanso cómodo
//       "8": {
//         tierra:
//           "Aporta datos prácticos como orientación, ruidos, cercanía a trasporte público, etc.",
//         fuego:
//           "Aporta datos prácticos como orientación, ruidos, cercanía a trasporte público, etc.",
//         agua: "Utiliza la maqueta para que elijan las vistas que les gustaría tener",
//         aire: "Utiliza la maqueta para que elijan las vistas que les gustaría tener",
//       },
//       //Juan: Futuro Jubilado
//       "9": {
//         tierra: "Contamos con X m2 de zonas verdes…",
//         fuego:
//           "Aporta datos prácticos apoyándote en la maqueta, como orientación, ruidos, etc.",
//         agua: "Muéstrale cuál sería su terraza/ventanal y ayuda a que se visualice en ella.",
//         aire: "¿Qué le parece el diseño de la promoción?",
//       },
//       //Nieves y Hugo: Un piso para nuestra hija
//       "10": {
//         tierra:
//           "Utiliza la maqueta especialmente para ubicarlos en las opciones del barrio",
//         fuego:
//           "Comenta las calidades de los acabados exteriores. Lo mejor para su hija/o",
//         agua: "Ten muy presente durante toda la conversación a su hijo/a y lo que gustaría",
//         aire: "Ten muy presente durante toda la conversación a su hijo/a y lo que gustaría",
//       },
//     },
//     zonasComunes: {
//       //Nico: El que abandona el nido
//       "1": {
//         tierra:
//           "Contar con estas Zonas Comunes aporta mucho valor a tu vivienda si en el futuro quieres vender…",
//         fuego:
//           "Apunta la exclusividad de las Zonas Comunes Vs otras promociones de la zona",
//         agua: "Ayúdalo a visualizarse con una futura familia haciendo uso de las Zonas Comunes",
//         aire: "Ayúdalo a visualizarse con amigos haciendo uso de las Zonas Comunes",
//       },
//       //Patricia y Pepe: La pareja primeriza
//       "2": {
//         tierra:
//           "Es un ambiente muy seguro por si el día de mañana queréis tener hijos…",
//         fuego: "¿A qué zonas comunes dais más importancia?",
//         agua: "Ayúdalos a imaginarse como familia disfrutando de las zonas comunes",
//         aire: "¿A qué zonas comunes dais más importancia?",
//       },
//       //Fátima y Cesar: La familia en crecimiento
//       "3": {
//         tierra:
//           "Es un ambiente muy seguro para que niños de la edad de los vuestros se muevan tranquilos",
//         fuego:
//           "Comenta los puntos de interacción con otras familias similares (piscina, club social, etc)",
//         agua: "Ayúdalos a imaginarse disfrutando de las zonas comunes en familia",
//         aire: "Comenta los puntos de interacción con otras familias similares (piscina, club social, etc)",
//       },
//       //Fausto y Carmen: Un futuro para mis hijos
//       "4": {
//         tierra:
//           "Es un ambiente muy seguro para que niños de la edad de los vuestros se muevan tranquilos",
//         fuego:
//           "Comenta los puntos de interacción con otras familias similares (piscina, club social, etc)",
//         agua: "Ayúdalos a imaginarse disfrutando de las zonas comunes en familia",
//         aire: "Comenta los puntos de interacción con otras familias similares (piscina, club social, etc)",
//       },
//       //Nuria y Esteban: Un nuevo episodio
//       "5": {
//         tierra: "Reforzar calidades arquitectónicas Vs viviendas antiguas",
//         fuego: "Reforzar las zonas comunes Vs otras promociones de la zona",
//         agua: "Ayuda a que se visualicen disfrutando de las zonas comunes",
//         aire: "Reforzar las zonas comunes Vs otras promociones de la zona",
//       },
//       //Carlos: El caprichoso
//       "6": {
//         tierra: "Reforzar calidades arquitectónicas Vs viviendas antiguas",
//         fuego:
//           "¿Cree que mejoraría su disfrute de zonas comunes respecto a la vivienda actual?",
//         agua: "¿En qué cree que mejoraría la vida de su familia con estas zonas comunes?",
//         aire: "¿Qué le parece el diseño de la promoción?",
//       },
//       //Iván: Busca oportunidad de inversión
//       "7": {
//         tierra:
//           "¿Ha podido observar las excelentes calidades arquitectónicas con las que contamos?",
//         fuego:
//           "¿Qué zonas comunes considera imprescindibles para su inversión?",
//         agua: "¿Qué es lo que más le ha gustado de las zonas comunes?",
//         aire: "¿Qué le parece el diseño de la promoción?",
//       },
//       //Diane & Chris: Un descanso cómodo
//       "8": {
//         tierra:
//           "Comenta la orientación de las zonas comunes para que aprecien el sol que disfrutarán",
//         fuego:
//           "Comenta la orientación de las zonas comunes para que aprecien el sol que disfrutarán",
//         agua: "Podrán disfrutar mucho de las zonas comunes por la gran cantidad de horas de sol que hay…",
//         aire: "Podrán disfrutar mucho de las zonas comunes por la gran cantidad de horas de sol que hay…",
//       },
//       //Juan: Futuro Jubilado
//       "9": {
//         tierra:
//           "¿Ha podido observar las excelentes calidades arquitectónicas con las que contamos?",
//         fuego: "¿Qué zonas comunes son las que más cree que utilizaría?",
//         agua: "¿Cómo se imagina disfrutando la piscina cuando se jubiles?",
//         aire: "¿Cómo se imagina disfrutando la piscina cuando se jubiles?",
//       },
//       //Nieves y Hugo: Un piso para nuestra hija
//       "10": {
//         tierra: "Refuerza la practicidad y usabilidad de las zonas comunes",
//         fuego: "¿Qué uso creéis que daría vuestro hijo/a a las zonas comunes?",
//         agua: "Seguro que estas zonas comunes le encantan a vuestro hijo/a…",
//         aire: "Ayuda a que visualicen cómo su hijo/a disfrutaría de las zonas comunes",
//       },
//     },
//     personalizarCalidades: {
//       //Nico: El que abandona el nido
//       "1": {
//         tierra:
//           "No tienes por qué decidirlo ahora, pero tienes varias opciones de acabados disponibles",
//         fuego:
//           "Para nosotros la personalización es fundamental. ¿Qué acabado te gusta más?",
//         agua: "Es muy importante que te sientas a gusto con cada detalle de tu casa. ¿Qué acabado te gusta más?",
//         aire: "Puedes elegir los acabados que más te gusten. ¿Alguna preferencia?",
//       },
//       //Patricia y Pepe: La pareja primeriza
//       "2": {
//         tierra:
//           "Estos acabados son de la máxima calidad y cuentan con gran durabilidad en el tiempo",
//         fuego: "Para los acabados se utilizan las mejores opciones del mercado",
//         agua: "Es muy importante que os sintáis a gusto con cada detalle de vuestra casa. ¿Qué acabado os gusta más?",
//         aire: "¿Queréis ver cómo quedarían los acabados en nuestro piso piloto/infografía?",
//       },
//       //Fátima y Cesar: La familia en crecimiento
//       "3": {
//         tierra:
//           "Estos acabados son de la máxima calidad y cuentan con gran durabilidad en el tiempo",
//         fuego:
//           "Aquí podéis ver los distintos acabados disponibles. ¿Alguno que os guste más?",
//         agua: "Haz partícipe a toda la familia de la decisión de los acabados",
//         aire: "¿Qué acabados os gustan más?",
//       },
//       //Fausto y Carmen: Un futuro para mis hijos
//       "4": {
//         tierra: "Refuerza la practicidad y durabilidad de los acabados",
//         fuego: "Para los acabados se utilizan las mejores opciones del mercado",
//         agua: "¿Queréis ver cómo quedarían los acabados en nuestro piso piloto?",
//         aire: "¿Queréis ver cómo quedarían los acabados en nuestro piso piloto/infografía?",
//       },
//       //Nuria y Esteban: Un nuevo episodio
//       "5": {
//         tierra:
//           "Con estas calidades no tendréis ningún problema en muchísimos años, elijáis la que elijáis",
//         fuego:
//           "Para nosotros la personalización es fundamental. ¿Qué acabado os gusta más?",
//         agua: "¿Queréis ver cómo quedarían los acabados en nuestro piso piloto?",
//         aire: "Podéis elegir los acabados que más os gusten, ¿cuál os llama la atención a primera vista?",
//       },
//       //Carlos: El caprichoso
//       "6": {
//         tierra:
//           "Estos acabados son de la máxima calidad y cuentan con gran durabilidad en el tiempo",
//         fuego: "Para los acabados se utilizan las mejores opciones del mercado",
//         agua: "¿Quiere ver cómo quedarían los acabados en nuestro piso piloto?",
//         aire: "Veamos los acabados en el piso piloto/infografía",
//       },
//       //Iván: Busca oportunidad de inversión
//       "7": {
//         tierra: "¿Qué acabado elegiría?",
//         fuego: "Aquí tiene los distintos acabados, ¿con cuál se queda?",
//         agua: "¿Quiere ver cómo quedarían los acabados en nuestro piso piloto?",
//         aire: "¿Quiere ver cómo quedarían los acabados en nuestro piso piloto/infografía?",
//       },
//       //Diane & Chris: Un descanso cómodo
//       "8": {
//         tierra: "Refuerza la practicidad y durabilidad de los acabados",
//         fuego: "Para los acabados se utilizan las mejores opciones del mercado",
//         agua: "La verdad es que son unos acabados con gran calidez. ¿Cuáles les gustan más?",
//         aire: "¿Quieren ver cómo quedarían los acabados en nuestro piso piloto/infografía?",
//       },
//       //Juan: Futuro Jubilado
//       "9": {
//         tierra: "Refuerza la practicidad y durabilidad de los acabados",
//         fuego: "Para los acabados se utilizan las mejores opciones del mercado",
//         agua: "La verdad es que son unos acabados con gran calidez. ¿Cuáles les gustan más?",
//         aire: "¿Quiere ver cómo quedarían los acabados en nuestro piso piloto/infografía?",
//       },
//       //Nieves y Hugo: Un piso para nuestra hija
//       "10": {
//         tierra:
//           "Ahora os dejo un documento informativo para que vuestro hijo/a pueda elegir sus acabados preferidos",
//         fuego:
//           "Ahora os dejo un documento informativo para que vuestro hijo/a pueda elegir sus acabados preferidos",
//         agua: "¿Qué acabados creéis que gustarían más a vuestro hijo/a?",
//         aire: "¿Qué acabados creéis que gustarían más a vuestro hijo/a?",
//       },
//     },
//     pisoPiloto: {
//       //Nico: El que abandona el nido
//       "1": {
//         tierra:
//           "Esta habitación es muy práctica para tenerla como despacho y dormitorio de visitas",
//         fuego:
//           "¿Qué tienes pensado hacer con las habitaciones que no serán tu dormitorio?",
//         agua: "Esta habitación es ideal para tenerla como despacho y dormitorio de visitas",
//         aire: "Si vienen amigos esta habitación te puede quedar muy cómoda",
//       },
//       //Patricia y Pepe: La pareja primeriza
//       "2": {
//         tierra:
//           "Esta habitación es muy práctica para tenerla como despacho y dormitorio de visitas",
//         fuego:
//           "¿Qué tenéis pensado hacer con las habitaciones que no serán vuestro dormitorio?",
//         agua: "Esta habitación es ideal para tenerla como despacho y dormitorio de visitas",
//         aire: "Este dormitorio es perfecto para que vengan a visitaros",
//       },
//       //Fátima y Cesar: La familia en crecimiento
//       "3": {
//         tierra:
//           '¿Traéis muebles de otro piso o tenéis pensado comprar nuevos? Ayúdales a "encajarlos"',
//         fuego:
//           '¿Traéis muebles de otro piso o tenéis pensado comprar nuevos? Ayúdales a "encajarlos"',
//         agua: "Así quedaría el cuarto de los niños, ¿qué os parece?",
//         aire: "Moveos tranquilamente por el piso, ¿qué os parece?",
//       },
//       //Fausto y Carmen: Un futuro para mis hijos
//       "4": {
//         tierra:
//           '¿Traéis muebles de otro piso o tenéis pensado comprar nuevos? Ayúdales a "encajarlos"',
//         fuego:
//           '¿Traéis muebles de otro piso o tenéis pensado comprar nuevos? Ayúdales a "encajarlos"',
//         agua: "Así quedaría el cuarto de los niños, ¿qué os parece?",
//         aire: "Moveos tranquilamente por el piso, ¿qué os parece?",
//       },
//       //Nuria y Esteban: Un nuevo episodio
//       "5": {
//         tierra:
//           "Como véis, las habitaciones son espaciosas, lo que os da muchas posibilidades. ¿Qué uso tenéis pensado darle? ¿Dormitorios, despacho,…?",
//         fuego:
//           "Como véis, las habitaciones son espaciosas, lo que os da muchas posibilidades. ¿Qué uso tenéis pensado darle? ¿Dormitorios, despacho,…?",
//         agua: "El equivalente a esta ventana/terraza de aquí en la vivienda que estáis valorando, tendría unas vistas muy amplias…",
//         aire: "El equivalente a esta ventana/terraza de aquí en la vivienda que estáis valorando, tendría unas vistas muy amplias…",
//       },
//       //Carlos: El caprichoso
//       "6": {
//         tierra:
//           "¿En qué parte de la vivienda le interesa especialmente que entremos en detalle?",
//         fuego: "¿Cómo se siente en una vivienda totalmente nueva?",
//         agua: "Siéntase como en casa, pruebe todas las estancias de la vivienda",
//         aire: "Siéntase como en casa, pruebe todas las estancias de la vivienda",
//       },
//       //Iván: Busca oportunidad de inversión
//       "7": {
//         tierra:
//           "¿En qué parte de la vivienda le interesa especialmente que entremos en detalle?",
//         fuego:
//           "¿Qué parte de la vivieda considera que dará mayor valor a su inversión? Podemos verla…",
//         agua: "Veamos toda la vivienda. Por favor, indíqueme cuando necesite detalle de algo",
//         aire: 'Si hay piso piloto: "Muévase tranquilamente por la vivienda". Si no lo hay: "¿Quiere coger usted mismo/a el ratón y así puede desplazarse por la vivienda?"',
//       },
//       //Diane & Chris: Un descanso cómodo
//       "8": {
//         tierra: "¿Cómo han pensado utilizar cada una de las habitaciones?",
//         fuego: "¿Les gustaría ver alguna habitación con mayor detalle?",
//         agua: "¿Qué les parece este piso piloto?",
//         aire: "Siéntanse como en casa, prueben todas las estancias de la vivienda",
//       },
//       //Juan: Futuro Jubilado
//       "9": {
//         tierra: "¿Cómo ha pensado utilizar cada una de las habitaciones?",
//         fuego: "¿Qué estancia es más importante para ti?",
//         agua: "Siéntase como en casa, pruebe todas las estancias de la vivienda",
//         aire: "¿Qué tal la decoración? ¿Te gusta el estilo o cómo lo harías tú?",
//       },
//       //Nieves y Hugo: Un piso para nuestra hija
//       "10": {
//         tierra:
//           "Lo ideal es que vuestro hijo/a venga un día por aquí y le enseño el piso piloto",
//         fuego:
//           "Lo ideal es que vuestro hijo/a venga un día por aquí y le enseño el piso piloto",
//         agua: "Si queréis podéis sacar fotos, o incluso hacer videollamada con vuestro hijo/a para que lo vea",
//         aire: "Si queréis podéis sacar fotos, o incluso hacer videollamada con vuestro hijo/a para que lo vea",
//       },
//     },
//   },
//   pago: {
//     presupuesto: {
//       //Nico: El que abandona el nido
//       "1": {
//         tierra: "¿Qué presupuesto tenéis?",
//         fuego: "¿Qué presupuesto tenéis?",
//         agua: "¿Qué presupuesto tenéis?",
//         aire: "¿Qué presupuesto tenéis?",
//       },
//       //Patricia y Pepe: La pareja primeriza
//       "2": {
//         tierra: "¿Qué presupuesto tenéis?",
//         fuego: "¿Qué presupuesto tenéis?",
//         agua: "¿Qué presupuesto tenéis?",
//         aire: "¿Qué presupuesto tenéis?",
//       },
//       //Fátima y Cesar: La familia en crecimiento
//       "3": {
//         tierra:
//           "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
//         fuego:
//           "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
//         agua: "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
//         aire: "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
//       },
//       //Fausto y Carmen: Un futuro para mis hijos
//       "4": {
//         tierra:
//           "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
//         fuego:
//           "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
//         agua: "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
//         aire: "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
//       },
//       //Nuria y Esteban: Un nuevo episodio
//       "5": {
//         tierra:
//           "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
//         fuego:
//           "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
//         agua: "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
//         aire: "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
//       },
//       //Carlos: El caprichoso
//       "6": {
//         tierra:
//           "¿Qué presupuesto tiene? ¿Ha pensado en vender la vivienda actual?",
//         fuego:
//           "¿Qué presupuesto tiene? ¿Ha pensado en vender la vivienda actual?",
//         agua: "¿Qué presupuesto tiene? ¿Ha pensado en vender la vivienda actual?",
//         aire: "¿Qué presupuesto tiene? ¿Ha pensado en vender la vivienda actual?",
//       },
//       //Iván: Busca oportunidad de inversión
//       "7": {
//         tierra: "¿Qué presupuesto tiene previsto para su inversión?",
//         fuego: "¿Qué presupuesto tiene previsto para su inversión?",
//         agua: "¿Qué presupuesto tiene previsto para su inversión?",
//         aire: "¿Qué presupuesto tiene previsto para su inversión?",
//       },
//       //Diane & Chris: Un descanso cómodo
//       "8": {
//         tierra:
//           "¿Qué presupuesto tienen pensado para su vivienda de vacaciones?",
//         fuego:
//           "¿Qué presupuesto tienen pensado para su vivienda de vacaciones?",
//         agua: "¿Qué presupuesto tienen pensado para su vivienda de vacaciones?",
//         aire: "¿Qué presupuesto tienen pensado para su vivienda de vacaciones?",
//       },
//       //Juan: Futuro Jubilado
//       "9": {
//         tierra: "¿Qué presupuesto tiene para su futura vivienda?",
//         fuego: "¿Qué presupuesto tiene para su futura vivienda?",
//         agua: "¿Qué presupuesto tiene para su futura vivienda?",
//         aire: "¿Qué presupuesto tiene para su futura vivienda?",
//       },
//       //Nieves y Hugo: Un piso para nuestra hija
//       "10": {
//         tierra: "¿Qué presupuesto tenéis para la vivienda de vuestra hija?",
//         fuego: "¿Qué presupuesto tenéis para la vivienda de vuestra hija?",
//         agua: "¿Qué presupuesto tenéis para la vivienda de vuestra hija?",
//         aire: "¿Qué presupuesto tenéis para la vivienda de vuestra hija?",
//       },
//     },
//     financiacion: {
//       //Nico: El que abandona el nido
//       "1": {
//         tierra: "¿Cómo tienes previsto financiar la vivienda?",
//         fuego: "¿Cómo tienes previsto financiar la vivienda?",
//         agua: "Imagino que estarás pensando en pedir hipoteca, ¿necesitas ayuda con este tema?",
//         aire: "Imagino que estarás pensando en pedir hipoteca, ¿necesitas ayuda con este tema?",
//       },
//       //Patricia y Pepe: La pareja primeriza
//       "2": {
//         tierra: "¿Cómo tenéis previsto financiar la vivienda?",
//         fuego: "¿Cómo tenéis previsto financiar la vivienda?",
//         agua: "Imagino que estaréis pensando en pedir hipoteca, ¿necesitáis ayuda con este tema?",
//         aire: "Imagino que estaréis pensando en pedir hipoteca, ¿necesitáis ayuda con este tema?",
//       },
//       //Fátima y Cesar: La familia en crecimiento
//       "3": {
//         tierra:
//           "¿Necesitáis alguna ayuda con el tema de la financiación? ¿Qué idea tenéis?",
//         fuego:
//           "¿Necesitáis alguna ayuda con el tema de la financiación? ¿Qué idea tenéis?",
//         agua: "¿Necesitáis alguna ayuda con el tema de la financiación? ¿Qué idea tenéis?",
//         aire: "¿Necesitáis alguna ayuda con el tema de la financiación? ¿Qué idea tenéis?",
//       },
//       //Fausto y Carmen: Un futuro para mis hijos
//       "4": {
//         tierra:
//           "¿Necesitáis alguna ayuda con el tema de la financiación? ¿Qué idea tenéis?",
//         fuego:
//           "¿Necesitáis alguna ayuda con el tema de la financiación? ¿Qué idea tenéis?",
//         agua: "¿Necesitáis alguna ayuda con el tema de la financiación? ¿Qué idea tenéis?",
//         aire: "¿Necesitáis alguna ayuda con el tema de la financiación? ¿Qué idea tenéis?",
//       },
//       //Nuria y Esteban: Un nuevo episodio
//       "5": {
//         tierra:
//           "De cara a la financiación, ¿la idea es pedir hipoteca, ahorros propios o vender vuestra casa actual?",
//         fuego:
//           "De cara a la financiación, ¿la idea es pedir hipoteca, ahorros propios o vender vuestra casa actual?",
//         agua: "De cara a la financiación, ¿la idea es pedir hipoteca, ahorros propios o vender vuestra casa actual?",
//         aire: "De cara a la financiación, ¿la idea es pedir hipoteca, ahorros propios o vender vuestra casa actual?",
//       },
//       //Carlos: El caprichoso
//       "6": {
//         tierra: "¿Cómo tiene pensado financiar la compra de su vivienda?",
//         fuego: "¿Cómo tiene pensado financiar la compra de su vivienda?",
//         agua: "¿Cuenta con ahorros propios o está pensando en solicitar una hipoteca?",
//         aire: "¿Cuenta con ahorros propios o está pensando en solicitar una hipoteca?",
//       },
//       //Iván: Busca oportunidad de inversión
//       "7": {
//         tierra: "¿Cómo tiene pensado financiar su inversión?",
//         fuego: "¿Cómo tiene pensado financiar su inversión?",
//         agua: "¿Cuenta con ahorros propios o está pensando en solicitar una hipoteca?",
//         aire: "¿Cuenta con ahorros propios o está pensando en solicitar una hipoteca?",
//       },
//       //Diane & Chris: Un descanso cómodo
//       "8": {
//         tierra:
//           "¿Contáis con ahorros propios o pensáis pedir hipoteca? Podemos ayudaros con el proceso…",
//         fuego:
//           "¿Contáis con ahorros propios o pensáis pedir hipoteca? Podemos ayudaros con el proceso…",
//         agua: "¿Contáis con ahorros propios o pensáis pedir hipoteca? Podemos ayudaros con el proceso…",
//         aire: "¿Contáis con ahorros propios o pensáis pedir hipoteca? Podemos ayudaros con el proceso…",
//       },
//       //Juan: Futuro Jubilado
//       "9": {
//         tierra:
//           "De cara a la financiación, ¿la idea es pedir hipoteca, ahorros propios o vender su casa actual?",
//         fuego:
//           "De cara a la financiación, ¿la idea es pedir hipoteca, ahorros propios o vender su casa actual?",
//         agua: "De cara a la financiación, ¿la idea es pedir hipoteca, ahorros propios o vender su casa actual?",
//         aire: "De cara a la financiación, ¿la idea es pedir hipoteca, ahorros propios o vender su casa actual?",
//       },
//       //Nieves y Hugo: Un piso para nuestra hija
//       "10": {
//         tierra:
//           "¿Cómo tenéis previsto financiar la vivienda?¿La hipoteca iría a vuestro nombre o al de vuestro hijo/a?",
//         fuego:
//           "¿Cómo tenéis previsto financiar la vivienda?¿La hipoteca iría a vuestro nombre o al de vuestro hijo/a?",
//         agua: "¿Cómo tenéis previsto financiar la vivienda?¿La hipoteca iría a vuestro nombre o al de vuestro hijo/a?",
//         aire: "¿Cómo tenéis previsto financiar la vivienda?¿La hipoteca iría a vuestro nombre o al de vuestro hijo/a?",
//       },
//     },
//   },
// };

/**
 * Listado de mensajes existentes quitando los apartados de show y pago
 */
export const tipsMessagesWithoutShow = {
  // Esto antes estaba en el show, se saca fuera.
  proyectoInterior: {
    //Nico: El que abandona el nido
    "1": {
      tierra: "Refuerza la practicidad y conexiones de la zona",
      fuego: "Refuerza la probable revalorización de la zona",
      agua: "¿Tienes familiares o amigos que vivan por la zona?",
      aire: "Comenta las múltiples actividades que pueden realizarse en la zona",
    },
    //Patricia y Pepe: La pareja primeriza
    "2": {
      tierra: "¿Qué os ha llamado la atención de esta zona?",
      fuego:
        "Comentar que es una zona en la que están comprando parejas de cierto poder adquisitivo",
      agua: "Reforzar la tranquilidad y seguridad de la zona",
      aire: "Comentar que puede convertirse o se trata de un barrio animado y joven",
    },
    //Fátima y Cesar: La familia en crecimiento
    "3": {
      tierra: "¿Qué es lo que más os ha llamado la atención de esta zona?",
      fuego:
        "Comenta el nivel social de otras familias que están comprando en la promoción",
      agua: "Comenta el nivel social de otras familias que están comprando en la promoción",
      aire: "Ayúdale a visualizar que tendrán grandes opciones de socializar con vecinos en esta zona",
    },
    //Fausto y Carmen: Un futuro para mis hijos
    "4": {
      tierra:
        "Contáis con colegio e instituto a X minutos, y en general todas las comunicaciones para que como padres todo sea más fácil",
      fuego:
        "Comenta el nivel social de otras familias que están comprando en la promoción",
      agua: "Muéstrale por qué sus hijos serán más felices en esta zona que donde viven actualmente",
      aire: "Ayúdale a visualizar que tendrán grandes opciones de socializar con vecinos en esta zona",
    },
    //Nuria y Esteban: Un nuevo episodio
    "5": {
      tierra:
        "¿Qué os gusta especialmente de esta zona respecto a donde vivís actualmente?",
      fuego:
        "¿Qué os gusta especialmente de esta zona respecto a donde vivís actualmente?",
      agua: "¿Tenéis amigos o familia viviendo por la zona?",
      aire: "¿En qué creéis que vais a mejorar vuestra calidad de vida viviendo en esta zona?",
    },
    //Carlos: El caprichoso
    "6": {
      tierra:
        "¿En qué zona vive actualmente? Reforzar ventajas de esta zona Vs la suya",
      fuego:
        "¿En qué zona vive actualmente? Ayúdalo a sentir el crecimiento de estatus que tendrá por vivir en esta zona",
      agua: "¿En qué zona vive actualmente? Muéstrale por qué esta zona es más divertida.",
      aire: "Aporta datos de revalorización de la zona en los últimos años",
    },
    //Iván: Busca oportunidad de inversión
    "7": {
      tierra: "Aporta datos de revalorización de la zona en los últimos años",
      fuego: "Es el momento ideal para invertir en esta ubicación",
      agua: "¿Qué es lo que más le ha llamado la atención para invertir en esta zona?",
      aire: "La zona está de moda, está creciendo de una forma increíble",
    },
    //Diane & Chris: Un descanso cómodo
    "8": {
      tierra: "Imagino que conocen la zona, ¿qué les lleva a comprar aquí?",
      fuego: "Imagino que conocen la zona, ¿qué les lleva a comprar aquí?",
      agua: "Refuerza la comodidad y seguridad de la zona",
      aire: "¿En qué épocas del año tendrían pensado venir aquí? Esta zona es fantástica…",
    },
    //Juan: Futuro Jubilado
    "9": {
      tierra: "¿Qué es lo que más le gusta de esta zona para jubilarte?",
      fuego: "¿Qué es lo que más le gusta de esta zona para jubilarte?",
      agua: "¿Tiene familia cerca de la zona?",
      aire: "¿Cómo se imagina su jubilación viviendo en esta zona?",
    },
    //Nieves y Hugo: Un piso para nuestra hija
    "10": {
      tierra:
        "Esta zona se está revalorizando mucho, por tanto es una inversión excelente a largo plazo para vuestro hijo/a",
      fuego:
        "Esta zona se está revalorizando mucho, por tanto es una inversión excelente a largo plazo para vuestro hijo/a",
      agua: "¿Vosotros vivís cerca de aquí?",
      aire: "Es una zona a la que están viniendo muchas personas de la edad de vuestra hija…",
    },
  },
  proyectoExterior: {
    //Nico: El que abandona el nido
    "1": {
      tierra:
        "Muéstrale que es un proyecto donde los m2 están bien aprovechados",
      fuego:
        "Hazle sentir que será la envidia de sus amigos con su nueva vivienda",
      agua: "Refuerza la sostenibilidad y eficiencia energética del proyecto",
      aire: "Refuerza la modernidad del proyecto",
    },
    //Patricia y Pepe: La pareja primeriza
    "2": {
      tierra: "Refuerza la modernidad del proyecto",
      fuego: "Es una zona que se está revalorizando mucho",
      agua: "Es una zona que se está revalorizando mucho",
      aire: "Llévalos a la maqueta para explicar los exteriores",
    },
    //Fátima y Cesar: La familia en crecimiento
    "3": {
      tierra:
        "Si están presentes los niños, llévalos a ver la maqueta y dales protagonismo",
      fuego:
        "Si están presentes los niños, llévalos a ver la maqueta y dales protagonismo",
      agua: "Si están presentes los niños, llévalos a ver la maqueta y dales protagonismo",
      aire: "Si están presentes los niños, llévalos a ver la maqueta y dales protagonismo",
    },
    //Fausto y Carmen: Un futuro para mis hijos
    "4": {
      tierra:
        "Utiliza la maqueta especialmente para ubicarlos en las opciones del barrio",
      fuego: "Comenta las calidades de los acabados exteriores",
      agua: "Hazles sentir que sus hijos se criarán a gusto en esa urbanización, a través de la maqueta",
      aire: "Hazles sentir que sus hijos se criarán a gusto en esa urbanización, a través de la maqueta",
    },
    //Nuria y Esteban: Un nuevo episodio
    "5": {
      tierra:
        "Explica con claridad las diferentes orientaciones posibles para la vivienda",
      fuego:
        "Explica con claridad las diferentes orientaciones posibles para la vivienda",
      agua: "Utiliza la maqueta para que elijan las vistas que les gustaría tener",
      aire: "Utiliza la maqueta para que elijan las vistas que les gustaría tener",
    },
    //Carlos: El caprichoso
    "6": {
      tierra: "Contamos con X m2 de zonas verdes…",
      fuego:
        "Ayúdalo/a a entender la orientación de la vivienda que más le gusta",
      agua: "Apóyate especialmente en la maqueta, haciendo partícipe a toda la familia",
      aire: "Haz hincapié en la modernidad del diseño de la promoción",
    },
    //Iván: Busca oportunidad de inversión
    "7": {
      tierra: "Apóyate en datos técnicos como m2 disponibles",
      fuego: "Refuerza la exclusividad Vs otras promociones de la zona",
      agua: "Apóyate especialmente en la maqueta",
      aire: "Haz hincapié en la modernidad del diseño de la promoción",
    },
    //Diane & Chris: Un descanso cómodo
    "8": {
      tierra:
        "Aporta datos prácticos como orientación, ruidos, cercanía a trasporte público, etc.",
      fuego:
        "Aporta datos prácticos como orientación, ruidos, cercanía a trasporte público, etc.",
      agua: "Utiliza la maqueta para que elijan las vistas que les gustaría tener",
      aire: "Utiliza la maqueta para que elijan las vistas que les gustaría tener",
    },
    //Juan: Futuro Jubilado
    "9": {
      tierra: "Contamos con X m2 de zonas verdes…",
      fuego:
        "Aporta datos prácticos apoyándote en la maqueta, como orientación, ruidos, etc.",
      agua: "Muéstrale cuál sería su terraza/ventanal y ayuda a que se visualice en ella.",
      aire: "¿Qué le parece el diseño de la promoción?",
    },
    //Nieves y Hugo: Un piso para nuestra hija
    "10": {
      tierra:
        "Utiliza la maqueta especialmente para ubicarlos en las opciones del barrio",
      fuego:
        "Comenta las calidades de los acabados exteriores. Lo mejor para su hija/o",
      agua: "Ten muy presente durante toda la conversación a su hijo/a y lo que gustaría",
      aire: "Ten muy presente durante toda la conversación a su hijo/a y lo que gustaría",
    },
  },
  zonasComunes: {
    //Nico: El que abandona el nido
    "1": {
      tierra:
        "Contar con estas Zonas Comunes aporta mucho valor a tu vivienda si en el futuro quieres vender…",
      fuego:
        "Apunta la exclusividad de las Zonas Comunes Vs otras promociones de la zona",
      agua: "Ayúdalo a visualizarse con una futura familia haciendo uso de las Zonas Comunes",
      aire: "Ayúdalo a visualizarse con amigos haciendo uso de las Zonas Comunes",
    },
    //Patricia y Pepe: La pareja primeriza
    "2": {
      tierra:
        "Es un ambiente muy seguro por si el día de mañana queréis tener hijos…",
      fuego: "¿A qué zonas comunes dais más importancia?",
      agua: "Ayúdalos a imaginarse como familia disfrutando de las zonas comunes",
      aire: "¿A qué zonas comunes dais más importancia?",
    },
    //Fátima y Cesar: La familia en crecimiento
    "3": {
      tierra:
        "Es un ambiente muy seguro para que niños de la edad de los vuestros se muevan tranquilos",
      fuego:
        "Comenta los puntos de interacción con otras familias similares (piscina, club social, etc)",
      agua: "Ayúdalos a imaginarse disfrutando de las zonas comunes en familia",
      aire: "Comenta los puntos de interacción con otras familias similares (piscina, club social, etc)",
    },
    //Fausto y Carmen: Un futuro para mis hijos
    "4": {
      tierra:
        "Es un ambiente muy seguro para que niños de la edad de los vuestros se muevan tranquilos",
      fuego:
        "Comenta los puntos de interacción con otras familias similares (piscina, club social, etc)",
      agua: "Ayúdalos a imaginarse disfrutando de las zonas comunes en familia",
      aire: "Comenta los puntos de interacción con otras familias similares (piscina, club social, etc)",
    },
    //Nuria y Esteban: Un nuevo episodio
    "5": {
      tierra: "Reforzar calidades arquitectónicas Vs viviendas antiguas",
      fuego: "Reforzar las zonas comunes Vs otras promociones de la zona",
      agua: "Ayuda a que se visualicen disfrutando de las zonas comunes",
      aire: "Reforzar las zonas comunes Vs otras promociones de la zona",
    },
    //Carlos: El caprichoso
    "6": {
      tierra: "Reforzar calidades arquitectónicas Vs viviendas antiguas",
      fuego:
        "¿Cree que mejoraría su disfrute de zonas comunes respecto a la vivienda actual?",
      agua: "¿En qué cree que mejoraría la vida de su familia con estas zonas comunes?",
      aire: "¿Qué le parece el diseño de la promoción?",
    },
    //Iván: Busca oportunidad de inversión
    "7": {
      tierra:
        "¿Ha podido observar las excelentes calidades arquitectónicas con las que contamos?",
      fuego: "¿Qué zonas comunes considera imprescindibles para su inversión?",
      agua: "¿Qué es lo que más le ha gustado de las zonas comunes?",
      aire: "¿Qué le parece el diseño de la promoción?",
    },
    //Diane & Chris: Un descanso cómodo
    "8": {
      tierra:
        "Comenta la orientación de las zonas comunes para que aprecien el sol que disfrutarán",
      fuego:
        "Comenta la orientación de las zonas comunes para que aprecien el sol que disfrutarán",
      agua: "Podrán disfrutar mucho de las zonas comunes por la gran cantidad de horas de sol que hay…",
      aire: "Podrán disfrutar mucho de las zonas comunes por la gran cantidad de horas de sol que hay…",
    },
    //Juan: Futuro Jubilado
    "9": {
      tierra:
        "¿Ha podido observar las excelentes calidades arquitectónicas con las que contamos?",
      fuego: "¿Qué zonas comunes son las que más cree que utilizaría?",
      agua: "¿Cómo se imagina disfrutando la piscina cuando se jubiles?",
      aire: "¿Cómo se imagina disfrutando la piscina cuando se jubiles?",
    },
    //Nieves y Hugo: Un piso para nuestra hija
    "10": {
      tierra: "Refuerza la practicidad y usabilidad de las zonas comunes",
      fuego: "¿Qué uso creéis que daría vuestro hijo/a a las zonas comunes?",
      agua: "Seguro que estas zonas comunes le encantan a vuestro hijo/a…",
      aire: "Ayuda a que visualicen cómo su hijo/a disfrutaría de las zonas comunes",
    },
  },
  personalizarCalidades: {
    //Nico: El que abandona el nido
    "1": {
      tierra:
        "No tienes por qué decidirlo ahora, pero tienes varias opciones de acabados disponibles",
      fuego:
        "Para nosotros la personalización es fundamental. ¿Qué acabado te gusta más?",
      agua: "Es muy importante que te sientas a gusto con cada detalle de tu casa. ¿Qué acabado te gusta más?",
      aire: "Puedes elegir los acabados que más te gusten. ¿Alguna preferencia?",
    },
    //Patricia y Pepe: La pareja primeriza
    "2": {
      tierra:
        "Estos acabados son de la máxima calidad y cuentan con gran durabilidad en el tiempo",
      fuego: "Para los acabados se utilizan las mejores opciones del mercado",
      agua: "Es muy importante que os sintáis a gusto con cada detalle de vuestra casa. ¿Qué acabado os gusta más?",
      aire: "¿Queréis ver cómo quedarían los acabados en nuestro piso piloto/infografía?",
    },
    //Fátima y Cesar: La familia en crecimiento
    "3": {
      tierra:
        "Estos acabados son de la máxima calidad y cuentan con gran durabilidad en el tiempo",
      fuego:
        "Aquí podéis ver los distintos acabados disponibles. ¿Alguno que os guste más?",
      agua: "Haz partícipe a toda la familia de la decisión de los acabados",
      aire: "¿Qué acabados os gustan más?",
    },
    //Fausto y Carmen: Un futuro para mis hijos
    "4": {
      tierra: "Refuerza la practicidad y durabilidad de los acabados",
      fuego: "Para los acabados se utilizan las mejores opciones del mercado",
      agua: "¿Queréis ver cómo quedarían los acabados en nuestro piso piloto?",
      aire: "¿Queréis ver cómo quedarían los acabados en nuestro piso piloto/infografía?",
    },
    //Nuria y Esteban: Un nuevo episodio
    "5": {
      tierra:
        "Con estas calidades no tendréis ningún problema en muchísimos años, elijáis la que elijáis",
      fuego:
        "Para nosotros la personalización es fundamental. ¿Qué acabado os gusta más?",
      agua: "¿Queréis ver cómo quedarían los acabados en nuestro piso piloto?",
      aire: "Podéis elegir los acabados que más os gusten, ¿cuál os llama la atención a primera vista?",
    },
    //Carlos: El caprichoso
    "6": {
      tierra:
        "Estos acabados son de la máxima calidad y cuentan con gran durabilidad en el tiempo",
      fuego: "Para los acabados se utilizan las mejores opciones del mercado",
      agua: "¿Quiere ver cómo quedarían los acabados en nuestro piso piloto?",
      aire: "Veamos los acabados en el piso piloto/infografía",
    },
    //Iván: Busca oportunidad de inversión
    "7": {
      tierra: "¿Qué acabado elegiría?",
      fuego: "Aquí tiene los distintos acabados, ¿con cuál se queda?",
      agua: "¿Quiere ver cómo quedarían los acabados en nuestro piso piloto?",
      aire: "¿Quiere ver cómo quedarían los acabados en nuestro piso piloto/infografía?",
    },
    //Diane & Chris: Un descanso cómodo
    "8": {
      tierra: "Refuerza la practicidad y durabilidad de los acabados",
      fuego: "Para los acabados se utilizan las mejores opciones del mercado",
      agua: "La verdad es que son unos acabados con gran calidez. ¿Cuáles les gustan más?",
      aire: "¿Quieren ver cómo quedarían los acabados en nuestro piso piloto/infografía?",
    },
    //Juan: Futuro Jubilado
    "9": {
      tierra: "Refuerza la practicidad y durabilidad de los acabados",
      fuego: "Para los acabados se utilizan las mejores opciones del mercado",
      agua: "La verdad es que son unos acabados con gran calidez. ¿Cuáles les gustan más?",
      aire: "¿Quiere ver cómo quedarían los acabados en nuestro piso piloto/infografía?",
    },
    //Nieves y Hugo: Un piso para nuestra hija
    "10": {
      tierra:
        "Ahora os dejo un documento informativo para que vuestro hijo/a pueda elegir sus acabados preferidos",
      fuego:
        "Ahora os dejo un documento informativo para que vuestro hijo/a pueda elegir sus acabados preferidos",
      agua: "¿Qué acabados creéis que gustarían más a vuestro hijo/a?",
      aire: "¿Qué acabados creéis que gustarían más a vuestro hijo/a?",
    },
  },
  pisoPiloto: {
    //Nico: El que abandona el nido
    "1": {
      tierra:
        "Esta habitación es muy práctica para tenerla como despacho y dormitorio de visitas",
      fuego:
        "¿Qué tienes pensado hacer con las habitaciones que no serán tu dormitorio?",
      agua: "Esta habitación es ideal para tenerla como despacho y dormitorio de visitas",
      aire: "Si vienen amigos esta habitación te puede quedar muy cómoda",
    },
    //Patricia y Pepe: La pareja primeriza
    "2": {
      tierra:
        "Esta habitación es muy práctica para tenerla como despacho y dormitorio de visitas",
      fuego:
        "¿Qué tenéis pensado hacer con las habitaciones que no serán vuestro dormitorio?",
      agua: "Esta habitación es ideal para tenerla como despacho y dormitorio de visitas",
      aire: "Este dormitorio es perfecto para que vengan a visitaros",
    },
    //Fátima y Cesar: La familia en crecimiento
    "3": {
      tierra:
        '¿Traéis muebles de otro piso o tenéis pensado comprar nuevos? Ayúdales a "encajarlos"',
      fuego:
        '¿Traéis muebles de otro piso o tenéis pensado comprar nuevos? Ayúdales a "encajarlos"',
      agua: "Así quedaría el cuarto de los niños, ¿qué os parece?",
      aire: "Moveos tranquilamente por el piso, ¿qué os parece?",
    },
    //Fausto y Carmen: Un futuro para mis hijos
    "4": {
      tierra:
        '¿Traéis muebles de otro piso o tenéis pensado comprar nuevos? Ayúdales a "encajarlos"',
      fuego:
        '¿Traéis muebles de otro piso o tenéis pensado comprar nuevos? Ayúdales a "encajarlos"',
      agua: "Así quedaría el cuarto de los niños, ¿qué os parece?",
      aire: "Moveos tranquilamente por el piso, ¿qué os parece?",
    },
    //Nuria y Esteban: Un nuevo episodio
    "5": {
      tierra:
        "Como véis, las habitaciones son espaciosas, lo que os da muchas posibilidades. ¿Qué uso tenéis pensado darle? ¿Dormitorios, despacho,…?",
      fuego:
        "Como véis, las habitaciones son espaciosas, lo que os da muchas posibilidades. ¿Qué uso tenéis pensado darle? ¿Dormitorios, despacho,…?",
      agua: "El equivalente a esta ventana/terraza de aquí en la vivienda que estáis valorando, tendría unas vistas muy amplias…",
      aire: "El equivalente a esta ventana/terraza de aquí en la vivienda que estáis valorando, tendría unas vistas muy amplias…",
    },
    //Carlos: El caprichoso
    "6": {
      tierra:
        "¿En qué parte de la vivienda le interesa especialmente que entremos en detalle?",
      fuego: "¿Cómo se siente en una vivienda totalmente nueva?",
      agua: "Siéntase como en casa, pruebe todas las estancias de la vivienda",
      aire: "Siéntase como en casa, pruebe todas las estancias de la vivienda",
    },
    //Iván: Busca oportunidad de inversión
    "7": {
      tierra:
        "¿En qué parte de la vivienda le interesa especialmente que entremos en detalle?",
      fuego:
        "¿Qué parte de la vivieda considera que dará mayor valor a su inversión? Podemos verla…",
      agua: "Veamos toda la vivienda. Por favor, indíqueme cuando necesite detalle de algo",
      aire: 'Si hay piso piloto: "Muévase tranquilamente por la vivienda". Si no lo hay: "¿Quiere coger usted mismo/a el ratón y así puede desplazarse por la vivienda?"',
    },
    //Diane & Chris: Un descanso cómodo
    "8": {
      tierra: "¿Cómo han pensado utilizar cada una de las habitaciones?",
      fuego: "¿Les gustaría ver alguna habitación con mayor detalle?",
      agua: "¿Qué les parece este piso piloto?",
      aire: "Siéntanse como en casa, prueben todas las estancias de la vivienda",
    },
    //Juan: Futuro Jubilado
    "9": {
      tierra: "¿Cómo ha pensado utilizar cada una de las habitaciones?",
      fuego: "¿Qué estancia es más importante para ti?",
      agua: "Siéntase como en casa, pruebe todas las estancias de la vivienda",
      aire: "¿Qué tal la decoración? ¿Te gusta el estilo o cómo lo harías tú?",
    },
    //Nieves y Hugo: Un piso para nuestra hija
    "10": {
      tierra:
        "Lo ideal es que vuestro hijo/a venga un día por aquí y le enseño el piso piloto",
      fuego:
        "Lo ideal es que vuestro hijo/a venga un día por aquí y le enseño el piso piloto",
      agua: "Si queréis podéis sacar fotos, o incluso hacer videollamada con vuestro hijo/a para que lo vea",
      aire: "Si queréis podéis sacar fotos, o incluso hacer videollamada con vuestro hijo/a para que lo vea",
    },
  },
  // Estos mensajes saldrán cuando se haga clic en el tab de "Info cliente"
  presupuesto: {
    //Nico: El que abandona el nido
    "1": {
      tierra: "¿Qué presupuesto tenéis?",
      fuego: "¿Qué presupuesto tenéis?",
      agua: "¿Qué presupuesto tenéis?",
      aire: "¿Qué presupuesto tenéis?",
    },
    //Patricia y Pepe: La pareja primeriza
    "2": {
      tierra: "¿Qué presupuesto tenéis?",
      fuego: "¿Qué presupuesto tenéis?",
      agua: "¿Qué presupuesto tenéis?",
      aire: "¿Qué presupuesto tenéis?",
    },
    //Fátima y Cesar: La familia en crecimiento
    "3": {
      tierra:
        "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
      fuego:
        "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
      agua: "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
      aire: "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
    },
    //Fausto y Carmen: Un futuro para mis hijos
    "4": {
      tierra:
        "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
      fuego:
        "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
      agua: "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
      aire: "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
    },
    //Nuria y Esteban: Un nuevo episodio
    "5": {
      tierra:
        "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
      fuego:
        "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
      agua: "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
      aire: "¿Qué presupuesto tenéis? ¿Habéis pensado en vender la vivienda actual?",
    },
    //Carlos: El caprichoso
    "6": {
      tierra:
        "¿Qué presupuesto tiene? ¿Ha pensado en vender la vivienda actual?",
      fuego:
        "¿Qué presupuesto tiene? ¿Ha pensado en vender la vivienda actual?",
      agua: "¿Qué presupuesto tiene? ¿Ha pensado en vender la vivienda actual?",
      aire: "¿Qué presupuesto tiene? ¿Ha pensado en vender la vivienda actual?",
    },
    //Iván: Busca oportunidad de inversión
    "7": {
      tierra: "¿Qué presupuesto tiene previsto para su inversión?",
      fuego: "¿Qué presupuesto tiene previsto para su inversión?",
      agua: "¿Qué presupuesto tiene previsto para su inversión?",
      aire: "¿Qué presupuesto tiene previsto para su inversión?",
    },
    //Diane & Chris: Un descanso cómodo
    "8": {
      tierra: "¿Qué presupuesto tienen pensado para su vivienda de vacaciones?",
      fuego: "¿Qué presupuesto tienen pensado para su vivienda de vacaciones?",
      agua: "¿Qué presupuesto tienen pensado para su vivienda de vacaciones?",
      aire: "¿Qué presupuesto tienen pensado para su vivienda de vacaciones?",
    },
    //Juan: Futuro Jubilado
    "9": {
      tierra: "¿Qué presupuesto tiene para su futura vivienda?",
      fuego: "¿Qué presupuesto tiene para su futura vivienda?",
      agua: "¿Qué presupuesto tiene para su futura vivienda?",
      aire: "¿Qué presupuesto tiene para su futura vivienda?",
    },
    //Nieves y Hugo: Un piso para nuestra hija
    "10": {
      tierra: "¿Qué presupuesto tenéis para la vivienda de vuestra hija?",
      fuego: "¿Qué presupuesto tenéis para la vivienda de vuestra hija?",
      agua: "¿Qué presupuesto tenéis para la vivienda de vuestra hija?",
      aire: "¿Qué presupuesto tenéis para la vivienda de vuestra hija?",
    },
  },
  // Estos mensajes saldrán cuando se haga clic en el tab de "Preferencias"
  financiacion: {
    //Nico: El que abandona el nido
    "1": {
      tierra: "¿Cómo tienes previsto financiar la vivienda?",
      fuego: "¿Cómo tienes previsto financiar la vivienda?",
      agua: "Imagino que estarás pensando en pedir hipoteca, ¿necesitas ayuda con este tema?",
      aire: "Imagino que estarás pensando en pedir hipoteca, ¿necesitas ayuda con este tema?",
    },
    //Patricia y Pepe: La pareja primeriza
    "2": {
      tierra: "¿Cómo tenéis previsto financiar la vivienda?",
      fuego: "¿Cómo tenéis previsto financiar la vivienda?",
      agua: "Imagino que estaréis pensando en pedir hipoteca, ¿necesitáis ayuda con este tema?",
      aire: "Imagino que estaréis pensando en pedir hipoteca, ¿necesitáis ayuda con este tema?",
    },
    //Fátima y Cesar: La familia en crecimiento
    "3": {
      tierra:
        "¿Necesitáis alguna ayuda con el tema de la financiación? ¿Qué idea tenéis?",
      fuego:
        "¿Necesitáis alguna ayuda con el tema de la financiación? ¿Qué idea tenéis?",
      agua: "¿Necesitáis alguna ayuda con el tema de la financiación? ¿Qué idea tenéis?",
      aire: "¿Necesitáis alguna ayuda con el tema de la financiación? ¿Qué idea tenéis?",
    },
    //Fausto y Carmen: Un futuro para mis hijos
    "4": {
      tierra:
        "¿Necesitáis alguna ayuda con el tema de la financiación? ¿Qué idea tenéis?",
      fuego:
        "¿Necesitáis alguna ayuda con el tema de la financiación? ¿Qué idea tenéis?",
      agua: "¿Necesitáis alguna ayuda con el tema de la financiación? ¿Qué idea tenéis?",
      aire: "¿Necesitáis alguna ayuda con el tema de la financiación? ¿Qué idea tenéis?",
    },
    //Nuria y Esteban: Un nuevo episodio
    "5": {
      tierra:
        "De cara a la financiación, ¿la idea es pedir hipoteca, ahorros propios o vender vuestra casa actual?",
      fuego:
        "De cara a la financiación, ¿la idea es pedir hipoteca, ahorros propios o vender vuestra casa actual?",
      agua: "De cara a la financiación, ¿la idea es pedir hipoteca, ahorros propios o vender vuestra casa actual?",
      aire: "De cara a la financiación, ¿la idea es pedir hipoteca, ahorros propios o vender vuestra casa actual?",
    },
    //Carlos: El caprichoso
    "6": {
      tierra: "¿Cómo tiene pensado financiar la compra de su vivienda?",
      fuego: "¿Cómo tiene pensado financiar la compra de su vivienda?",
      agua: "¿Cuenta con ahorros propios o está pensando en solicitar una hipoteca?",
      aire: "¿Cuenta con ahorros propios o está pensando en solicitar una hipoteca?",
    },
    //Iván: Busca oportunidad de inversión
    "7": {
      tierra: "¿Cómo tiene pensado financiar su inversión?",
      fuego: "¿Cómo tiene pensado financiar su inversión?",
      agua: "¿Cuenta con ahorros propios o está pensando en solicitar una hipoteca?",
      aire: "¿Cuenta con ahorros propios o está pensando en solicitar una hipoteca?",
    },
    //Diane & Chris: Un descanso cómodo
    "8": {
      tierra:
        "¿Contáis con ahorros propios o pensáis pedir hipoteca? Podemos ayudaros con el proceso…",
      fuego:
        "¿Contáis con ahorros propios o pensáis pedir hipoteca? Podemos ayudaros con el proceso…",
      agua: "¿Contáis con ahorros propios o pensáis pedir hipoteca? Podemos ayudaros con el proceso…",
      aire: "¿Contáis con ahorros propios o pensáis pedir hipoteca? Podemos ayudaros con el proceso…",
    },
    //Juan: Futuro Jubilado
    "9": {
      tierra:
        "De cara a la financiación, ¿la idea es pedir hipoteca, ahorros propios o vender su casa actual?",
      fuego:
        "De cara a la financiación, ¿la idea es pedir hipoteca, ahorros propios o vender su casa actual?",
      agua: "De cara a la financiación, ¿la idea es pedir hipoteca, ahorros propios o vender su casa actual?",
      aire: "De cara a la financiación, ¿la idea es pedir hipoteca, ahorros propios o vender su casa actual?",
    },
    //Nieves y Hugo: Un piso para nuestra hija
    "10": {
      tierra:
        "¿Cómo tenéis previsto financiar la vivienda?¿La hipoteca iría a vuestro nombre o al de vuestro hijo/a?",
      fuego:
        "¿Cómo tenéis previsto financiar la vivienda?¿La hipoteca iría a vuestro nombre o al de vuestro hijo/a?",
      agua: "¿Cómo tenéis previsto financiar la vivienda?¿La hipoteca iría a vuestro nombre o al de vuestro hijo/a?",
      aire: "¿Cómo tenéis previsto financiar la vivienda?¿La hipoteca iría a vuestro nombre o al de vuestro hijo/a?",
    },
  },
};
