import { Card, Input } from "@nextui-org/react";
import { useState } from "react";
import { sayToFormikDataHasChanged } from "../../../Utils/sellerUtils";

const HowManyChildren = (props: any) => {
  const { childNumber, formik } = props;

  const [error, setError] = useState(false);

  let returnItems = [];

  for (let i = 0; i < childNumber; i++) {
    returnItems.push(
      <div key={i} style={{ width: "100%", gap: "10px", display: "flex" }}>
        <Input
          css={{ width: "50%", padding: "10px" }}
          clearable
          id={`Nombre_hijo_hija_${i + 1}__c`}
          onChange={(e: any) => {
            sayToFormikDataHasChanged(formik, e);
          }}
          label={`Nombre hijo/a ${i + 1}`}
          placeholder='Nombre'
          initialValue={formik.values[`Nombre_hijo_hija_${i + 1}__c`]}
        />
        <Input
          type='number'
          css={{ width: "50%", padding: "10px" }}
          clearable
          id={`Edad_hijo_hija_${i + 1}__c`}
          onChange={(e: any) => {
            const inputValue = e.target.value.replace(/[.,]/g, ""); // Eliminar comas y puntos /^[0-9\b]+$/
            const isValidInput = /^\d*$/.test(inputValue); // Verificar que solo contiene dígitos
            if (isValidInput && parseInt(inputValue) < 100) {
              formik.setFieldValue(`Edad_hijo_hija_${i + 1}__c`, inputValue);
            }
          }}
          label={`Edad hijo/a ${i + 1}`}
          placeholder='Edad'
          value={
            formik.values[`Edad_hijo_hija_${i + 1}__c`] === "0" ||
            formik.values[`Edad_hijo_hija_${i + 1}__c`] === 0
              ? null
              : formik.values[`Edad_hijo_hija_${i + 1}__c`]
          }
          initialValue={
            formik.values[`Edad_hijo_hija_${i + 1}__c`] === "0" ||
            formik.values[`Edad_hijo_hija_${i + 1}__c`] === 0
              ? null
              : formik.values[`Edad_hijo_hija_${i + 1}__c`]
          }
        />
        {error && <p>{error}</p>}
      </div>
    );
  }
  if (returnItems.length > 0) {
    return (
      <Card
        variant='bordered'
        css={{
          marginTop: "20px",
          borderTop: "1px solid var(--nextui-colors-border) !important",
        }}
      >
        {returnItems}
      </Card>
    );
  }
  return <></>;
};

export default HowManyChildren;
