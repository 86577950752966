import { Button } from "@nextui-org/react";
import { useState } from "react";
import TabsFormInner from "./TabsFormInner";

export default function TabsForm(props: any) {
  const { sections, formik } = props;
  const [selectedButton, setSelectedButton] = useState<any>(0);

  const handleSelect = (onClick: any, i: number) => {
    setSelectedButton(i);
    onClick();
  };

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "space-evenly", gap: "4px" }}
      >
        {sections.map((section: any, i: any) => (
          <Button
            className="stepper-button-option"
            key={`${i}-${section.button.name}`}
            css={{
              minWidth: "120px",
              backgroundColor:
                i === selectedButton
                  ? "var(--blue-lis)"
                  : "var(--blue-lis-ligth)",
              width: section.button.name.length > 16 ? "140px" : "120px",
            }}
            onClick={() => handleSelect(section.button.onClick, i)}
            // icon={section.button.icon}
            size={"sm"}
          >
            {section.button.name}
          </Button>
        ))}
      </div>
      <div style={{ paddingBottom: "15px" }}>
        {sections[selectedButton].questions.map((question: any, i: any) => {
          return (
            <TabsFormInner
              key={`${i}-${question.name}`}
              question={question}
              formik={formik}
            ></TabsFormInner>
          );
        })}
      </div>
    </>
  );
}
