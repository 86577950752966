import { Loading } from "@nextui-org/react";

interface Props {
  messageProduct: string;
}

export const MessageLoadingProduct = ({ messageProduct }: Props) => {
  return (
    <div
      className='d-flex flex-wrap align-items-center justify-content-center h-100 listado-propiedades-cliente'
      style={{
        overflow: "auto",
      }}
    >
      {messageProduct && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2
            className='pt-3'
            style={{
              fontFamily: "lis-title",
              textAlign: "center",
              fontSize: "30px",
              display: "flex",
              gap: "20px",
            }}
          >
            <span>{messageProduct}</span>
            <Loading />
          </h2>
        </div>
      )}
    </div>
  );
};
