import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Button } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AedasLoading from "../../Components/AedasLoading/AedasLoading";
import { useFetch, useIsLoading } from "../../Hooks/useFetch";
import { getAllSellers } from "../../Service/SellerService/SellerService";

function Demo(props: any) {
  const [sellers, isFetchingSellers] = useFetch(getAllSellers);
  const isLoading = useIsLoading([isFetchingSellers]);
  const [seller, setSeller] = useState();
  const history = useHistory();

  useEffect(() => {
    if (process.env.REACT_APP_IS_PRO === "true") {
      history.push("/");
    }
  }, []);
  const handleConfirmDemo = () => {
    localStorage.setItem("seller_AEDAS_demo", JSON.stringify(seller));
    history.push("/");
  };
  if (isLoading) {
    return <AedasLoading />;
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          background: "white",
          padding: "1rem",
          width: "400px",
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "18px",
          boxShadow: "0px 0px 20px 0px #00000024",
        }}
      >
        <img
          alt="Logo AEDAS"
          style={{ height: "70px" }}
          src="/Assets/img/AF_AEDAS_POSITIVO_RGB.svg"
        ></img>
        <h1
          style={{
            fontFamily: "lis-title",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          Demo Atenea
        </h1>
        <h2
          style={{
            fontFamily: "lis-body",

            fontSize: "15px",
          }}
        >
          Selecionar comercial para comenzar la demo
        </h2>
        <Autocomplete
          id="combo-box-demo"
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.displayName}
              </li>
            );
          }}
          options={sellers}
          onChange={(e: any, newValue: any) => {
            setSeller(newValue);
          }}
          value={seller || null}
          sx={{ width: 300 }}
          getOptionLabel={(option: any) => option.displayName}
          renderInput={(params) => <TextField {...params} label="Comercial" />}
        />
        <Button disabled={!seller} onClick={handleConfirmDemo}>
          Comenzar
        </Button>
      </div>
    </div>
  );
}

export default Demo;
