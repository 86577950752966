import { useEffect, useState } from "react";
import { useIsLoading } from "../../Hooks/useFetch";
import { getLikesService } from "../../Service/LikesService/LikesService";
import {
  getFilteredData,
  getFilters,
} from "../../Service/ModalFilters/modalFilters";
import AedasLoading from "../AedasLoading/AedasLoading";
import InnerFormAvailableProduct from "./InnerFormAvailableProduct";
import { MainPlanes } from "./Planes/MainPlanes";
import { ShadowPlanes } from "./Planes/ShadowPlanes";

import { Button } from "@nextui-org/react";
import { Autocomplete, TextField } from "@mui/material";
import { AvaiableProduct } from "../AvaiableProduct/AvaiableProduct";
import { getPromotionDocuments } from "../../Service/AssetsService/AssetsService";
import { PROMOTION_STATE_MAP } from "../Constants/constants";

//@ts-ignore
function ModalAvailableProduct(props: any) {
  const {
    promotion,
    promotions,
    formik,
    setselectedValue,
    sendJson,
    setPrincipalProduct,
    principalProduct,
    shadowProduct,
    setShadowProduct,
    otherProduct,
    setOtherProduct,
    idClient,
    // favouriteProduct,
    // setFavouriteProduct,
  } = props;

  const [selectedFilter, setSelectedFilter] = useState({
    portal: [],
    promotion: [promotion.id],
    floor: [],
    orientation: [],
    availability: ["Disponible"],
    bedrooms: [],
    type: [],
  });

  const [principalProductClient, setPrincipalProductClient] = useState([]);
  //const promotionId = promotion.id;
  const [filters, setFilters] = useState();
  const [isLoadingSearch, setIsLoadingSearch] = useState(true);
  const isLoading = useIsLoading([isLoadingSearch]);
  const [Assets, setAssets] = useState([]);
  const [likes, setLikes] = useState([]);
  const [promotionsDocuments, setPromotionsDocuments] = useState<any | null>(
    null
  );

  const [selectedPromotion, setSelectedPromotion] = useState(promotion);

  const typeTax = [
    { id: 1, label: "sin impuesto" },
    { id: 2, label: "impuesto" },
  ];
  const [tax, setTax] = useState<any>({ isTax: false, label: "sin impuesto" });

  const [pointHouse, setPointHouse] = useState(""); // Usado para marcar una casa como destacada en la ventana de cliente.

  const [disabledZonasComunes, setDisabledZonasComunes] =
    useState<boolean>(false);
  const [disabledGarajes, setDisabledGarajes] = useState<boolean>(false);
  const [disabledTrasteros, setDisabledTrasteros] = useState<boolean>(false);

  useEffect(() => {
    handleFirstLoad();
  }, []);

  useEffect(() => {
    if (!isLoadingSearch) {
      setPrincipalProduct(
        Assets?.filter((a: any) => {
          if (Number(formik.values.Dormitorios__c) === 0) {
            // Hacemos el valor absoluto para calcular +-3 habitaciones si no ha elegido cuantas quiere.
            return Math.abs(+a.bedrooms - +formik.values.Dormitorios__c) <= 3;
          } else {
            // Filtramos por el habitaciones que ha elegido.principalProduct
            return +a.bedrooms === +formik.values.Dormitorios__c;
          }
        })?.filter((a: any) => {
          return (
            Number(a.starting_price) >=
              Number(formik.values.Presupuesto__c[0]) &&
            Number(a.starting_price) <= Number(formik.values.Presupuesto__c[1])
          );
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingSearch, Assets]);

  useEffect(() => {
    if (!isLoadingSearch && principalProduct) {
      // setPrincipalProductClient(principalProduct);
      // Obtenemos los IDs del producto principal y así luego los podremos quitar del producto sombra.
      let idsProductoPrincipal = principalProduct?.map(
        (item: any) => item.house_id
      );

      setShadowProduct(
        Assets?.filter((a: any) => {
          if (Number(formik.values.Dormitorios__c) === 0) {
            // Hacemos el valor absoluto para calcular +-4 habitaciones si no ha elegido las que quiere.
            return Math.abs(+a.bedrooms - +formik.values.Dormitorios__c) <= 4;
          } else {
            // Hacemos el valor absoluto para calcular +-2 habitaciones respecto a las que necesita.
            return Math.abs(+a.bedrooms - +formik.values.Dormitorios__c) <= 2;
          }
        })
          ?.filter((a: any) => {
            // Filtro de precio con un valor del +20% y del -20%
            return (
              Number(a.starting_price) >=
                Number(formik.values.Presupuesto__c[0]) / 1.2 &&
              Number(a.starting_price) <=
                Number(formik.values.Presupuesto__c[1]) / 0.8
            );
          })
          ?.filter((a: any) => {
            //Quitamos aquellos pisos que ya salen como producto principal
            return !idsProductoPrincipal.includes(a.house_id);
          })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [principalProduct]);

  useEffect(() => {
    if (!isLoadingSearch && principalProduct && shadowProduct) {
      // Obtenemos los IDs del producto principal y así luego los podremos quitar del producto .
      let idsProductoPrincipal = principalProduct?.map(
        (item: any) => item?.house_id
      );

      let idsProductoSombra = shadowProduct?.map((item: any) => item?.house_id);

      setOtherProduct(
        Assets?.filter((a: any) => {
          return !idsProductoPrincipal.includes(a?.house_id);
        })?.filter((a: any) => {
          return !idsProductoSombra.includes(a?.house_id);
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shadowProduct]);

  useEffect(() => {
    if (idClient && promotion.id) {
      getLikesService(idClient, promotion.id)
        .then((response: any) => {
          if (response.data.planos.length > 0) {
            const assetIds = response.data.planos.map(
              (asset: any) => asset.asset_id
            );
            setLikes(assetIds);
          }
        })
        .catch(() => {
          console.log("Hubo un error al obtener los planos favoritos");
        });
    }
  }, [idClient, promotion.id]);

  useEffect(() => {
    if (!isLoadingSearch) {
      if (principalProductClient.length <= 0) {
        handleFirstLoad();
      } else {
        handleGoToList();
      }
    }
  }, [principalProductClient, tax]);

  const handleFirstLoad = () => {
    sendJson({
      event: "change_clientview",
      value: "cargando_listado_viviendas",
      // list: principalProductClient,
      message: "Estamos buscando tu producto ideal",
    });
  };

  const handleGoToList = () => {
    //Se envia el producto principal del cliente
    const list = { asset: principalProductClient, tax: tax };

    sendJson({
      event: "change_clientview",
      value: "listado_viviendas",
      list: list,
    });
  };

  const handleShowDocumentsInClientView = (type: string, label: string) => {
    if (promotionsDocuments) {
      const uuid_promotion = selectedPromotion.id;

      const planosToSend = {
        promotions: promotionsDocuments.promotions
          .filter(
            (documents: any) => documents.uuid_promotion === uuid_promotion
          )
          .map((documents: any) => ({
            planos: documents[type],
            uuid_promotion: documents.uuid_promotion,
          })),
        label: label,
      };

      sendJson({
        event: "change_clientview",
        value: "showDocuments",
        list: planosToSend,
      });
    }
  };

  const disabledButtonsFromPromotrion = (
    promotions: any,
    buttonName: string,
    uuid_promotion: string
  ) => {
    return !promotions.find(
      (documents: any) =>
        documents.uuid_promotion === uuid_promotion &&
        documents[buttonName].length > 0
    );
  };

  useEffect(() => {
    if (isLoadingSearch) {
      getFilters(promotions.map((promo: any) => promo?.id))
        .then((response) => {
          setFilters(response);
          let filters: string[] = [];
          Object.entries(PROMOTION_STATE_MAP).forEach(([key, value]) => {
            if (
              selectedFilter.availability.find((item: string) => item === value)
            ) {
              filters.push(key);
            }
          });
          selectedFilter.availability = filters;
          getFilteredData({
            promos: promotions.map((promo: any) => {
              return promo?.id;
            }),
            filtros: selectedFilter,
          }).then((response: any) => {
            setAssets(response);
            setIsLoadingSearch(false);
          });
        })
        .catch((error) => {});
      getPromotionDocuments(
        selectedFilter.promotion
        //[promotion.id]
      ).then((res: any) => {
        setPromotionsDocuments(res.data);
        setDisabledZonasComunes(
          disabledButtonsFromPromotrion(
            res.data.promotions,
            "Plano_zonas_comunes",
            selectedPromotion.id
          )
        );
        setDisabledGarajes(
          disabledButtonsFromPromotrion(
            res.data.promotions,
            "Planos_garajes",
            selectedPromotion.id
          )
        );
        setDisabledTrasteros(
          disabledButtonsFromPromotrion(
            res.data.promotions,
            "Planos_trasteros",
            selectedPromotion.id
          )
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingSearch, selectedFilter]);

  useEffect(() => {
    setIsLoadingSearch(true);
  }, [selectedFilter]);

  if (isLoading) {
    return (
      <div
        className='d-flex flex-column justify-content-center'
        style={{ minHeight: "0px" }}
      >
        <AedasLoading></AedasLoading>
      </div>
    );
  }

  return (
    <div
      className='modalAvailableProduct_tsx'
      style={{ display: "flex", paddingBottom: "20px" }}
    >
      <div
        style={{
          height: "calc(100vh - 130px)",
          width: "25%",
          borderRight: "1px solid grey",
          overflow: "auto",
        }}
      >
        <InnerFormAvailableProduct
          filters={filters}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          promotions={promotions}
        ></InnerFormAvailableProduct>
      </div>
      <div
        style={{
          height: "calc(100vh - 130px)",
          width: "75%",
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: "1rem",
            paddingRight: "5rem",
            paddingTop: 7,
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Button
              disabled={disabledZonasComunes}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                minWidth: "50px",
                height: "25px",
                fontSize: "11px",
                padding: "0 10px",
              }}
              onClick={() =>
                handleShowDocumentsInClientView(
                  "Plano_zonas_comunes",
                  "Planos Zonas Comunes"
                )
              }
            >
              Ver Planos Zonas Comunes
            </Button>
            <Button
              disabled={disabledGarajes}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                minWidth: "50px",
                height: "25px",
                fontSize: "11px",
                padding: "0 10px",
              }}
              onClick={() =>
                handleShowDocumentsInClientView(
                  "Planos_garajes",
                  "Planos Garajes"
                )
              }
            >
              Ver Planos Garajes
            </Button>
            <Button
              disabled={disabledTrasteros}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                minWidth: "50px",
                height: "25px",
                fontSize: "11px",
                padding: "0 10px",
              }}
              onClick={() =>
                handleShowDocumentsInClientView(
                  "Planos_trasteros",
                  "Planos Trasteros"
                )
              }
            >
              Ver Planos Trasteros
            </Button>
            <Autocomplete
              sx={{ width: 150 }}
              disableClearable
              options={promotions.filter((promotion: any) =>
                selectedFilter.promotion.find((promo) => promotion.id === promo)
              )}
              size='small'
              value={selectedPromotion}
              defaultValue={selectedPromotion}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option: any, value: any) =>
                option.id === value.id
              }
              onChange={(_, value) => {
                setDisabledZonasComunes(
                  disabledButtonsFromPromotrion(
                    promotionsDocuments.promotions,
                    "Plano_zonas_comunes",
                    value.id
                  )
                );
                setDisabledGarajes(
                  disabledButtonsFromPromotrion(
                    promotionsDocuments.promotions,
                    "Planos_garajes",
                    value.id
                  )
                );
                setDisabledTrasteros(
                  disabledButtonsFromPromotrion(
                    promotionsDocuments.promotions,
                    "Planos_trasteros",
                    value.id
                  )
                );
                setSelectedPromotion(value);
              }}
              renderInput={(params: any) => (
                <TextField {...params} label='Promoción' />
              )}
            />
          </div>
          <div style={{ width: 150 }}>
            <Autocomplete
              disableClearable
              options={typeTax}
              size='small'
              value={tax}
              getOptionLabel={(option: any) => option.label}
              isOptionEqualToValue={(option: any, value: any) =>
                option.label === value.label
              }
              onChange={(_, value) => {
                const isTax = value.id === 2;
                const label = value.label as string;

                setTax({ isTax, label });
              }}
              renderInput={(params: any) => (
                <TextField {...params} label='Tipo de Impuesto' />
              )}
            />
          </div>
        </div>

        <div
          style={{
            height: "calc(100vh - 48px)",
            display: "block",
            overflow: "auto",
          }}
        >
          <div style={{ padding: "10px" }}>
            <h1
              style={{
                textAlign: "start",
                fontSize: "20px",
                fontFamily: "lis-title",
              }}
            >
              Producto principal ({principalProduct?.length})
            </h1>
            <div
              className='grid-listado-viviendas grid-producto-principal'
              style={{
                gap: "10px",
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(210px, 230px))",
              }}
            >
              {!isLoadingSearch ? (
                principalProduct?.length > 0 ? (
                  <>
                    <MainPlanes
                      tax={tax}
                      principalProductClient={principalProductClient}
                      likes={likes}
                      sendJson={sendJson}
                      // setselectedValue={setselectedValue}
                      idClient={idClient}
                      formik={formik}
                      setLikes={setLikes}
                      pointHouse={pointHouse}
                      setPointHouse={setPointHouse}
                      setPrincipalProduct={setPrincipalProductClient}
                      principalProduct={principalProduct}
                      // setShadowProduct={setShadowProduct}
                    ></MainPlanes>
                  </>
                ) : (
                  <></>
                )
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <AedasLoading></AedasLoading>
                </div>
              )}
            </div>
          </div>
          <div style={{ padding: "10px", paddingTop: "30px" }}>
            <h1
              style={{
                textAlign: "start",
                fontSize: "20px",
                fontFamily: "lis-title",
              }}
            >
              Producto sombra ({shadowProduct?.length})
            </h1>
            <div
              className='grid-listado-viviendas'
              style={{
                gap: "10px",
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(210px, 230px))",
              }}
            >
              {isLoadingSearch ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <AedasLoading></AedasLoading>
                </div>
              ) : (
                <ShadowPlanes
                  tax={tax}
                  //Propiedas que desactiva el envio a la pantalla del cliente si se esta mostrando
                  // disableAddToPrincipalProduct={principalProduct?.length <= 0}
                  idClient={idClient}
                  shadowProduct={shadowProduct}
                  setShadowProduct={setShadowProduct}
                  formik={formik}
                  likes={likes}
                  setLikes={setLikes}
                  sendJson={sendJson}
                  setselectedValue={setselectedValue}
                  pointHouse={pointHouse}
                  setPointHouse={setPointHouse}
                  principalProductClient={principalProductClient}
                  setPrincipalProduct={setPrincipalProductClient}
                  principalProduct={principalProductClient}
                ></ShadowPlanes>
              )}
            </div>
          </div>
          <div style={{ padding: "10px", paddingTop: "30px" }}>
            <h1
              style={{
                textAlign: "start",
                fontSize: "20px",
                fontFamily: "lis-title",
              }}
            >
              Resto de producto ({otherProduct.length})
            </h1>
            <div
              className='grid-listado-viviendas'
              style={{
                gap: "10px",
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(210px, 230px)",
              }}
            >
              {isLoadingSearch ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <AedasLoading></AedasLoading>
                </div>
              ) : (
                otherProduct.map((other: any) => {
                  return (
                    <AvaiableProduct
                      tax={tax}
                      likes={likes}
                      setLikes={setLikes}
                      key={other.id}
                      asset={other}
                      sendJson={sendJson}
                      idClient={idClient}
                      idPromotion={promotion.id}
                      pointHouse={pointHouse}
                      setPointHouse={setPointHouse}
                      setPrincipalProduct={setPrincipalProductClient}
                      principalProductClient={principalProductClient}
                      canSendCalendarAndBudget={principalProductClient?.includes(
                        //@ts-ignore
                        other
                      )}
                    ></AvaiableProduct>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          padding: "0.5rem",
          width: "inherit",
          background: "white",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button onClick={handleGoToList}>
          Ocultar el plano y mostrar el listado de viviendas
        </Button>
      </div>
    </div>
  );
}

export default ModalAvailableProduct;
