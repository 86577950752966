import { useFetch } from "../../../Hooks/useFetch";
import { getAllRelationalStyle } from "../../../Service/RelationalStyle/RelationalStyle";
import AedasLoading from "../../AedasLoading/AedasLoading";

import { Autocomplete, TextField } from "@mui/material";

const SelectRelationalStyle = (props: any) => {
  const { formikOptions: formik, setTipRelationalStyle } = props;

  const [relationalStylesData, isFetchingRelationalStylesData] = useFetch(
    getAllRelationalStyle
  );

  if (isFetchingRelationalStylesData) {
    return (
      <div>
        <AedasLoading></AedasLoading>
      </div>
    );
  }

  return (
    <div className="mt-2">
      <Autocomplete
        disableClearable
        id="combo-box-relational-style"
        loading={true}
        options={relationalStylesData}
        sx={{ width: 328 }}
        size="small"
        defaultValue={
          relationalStylesData[parseInt(formik.values.relationalStyle) - 1]
        }
        getOptionLabel={(option: any) => {
          return option.name;
        }}
        renderInput={(params: any) => (
          <TextField {...params} label="Arquetipo *" />
        )}
        onChange={(event: any, value: any) => {
          formik.setFieldValue("relationalStyle", value?.id);
          setTipRelationalStyle(value?.id);
        }}
      />
    </div>
  );
};

export default SelectRelationalStyle;
