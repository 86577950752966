// @ts-ignore
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Calls from "../../Components/Calls/Calls";
import { useFetch, useIsLoading } from "../../Hooks/useFetch";
import { getPromoByAppointmentId } from "../../Service/AppointmentService/AppointmentService";
import { getUser } from "../../Utils/commonUtils";
import AedasLoading from "../AedasLoading/AedasLoading";
import PromotionInfoFull from "../PromotionInfo/PromotionInfoFull";
import TipAndTaskComponent from "../TipAndTaskComponent/TipAndTaskComponent";
import FormForNotes from "../FormForNotes/FormForNotes";

const AppointmentClientTabs = (props: any) => {
  const { client, id, idSeller, idClient } = props;

  const [promotion, isFetchingPromotion] = useFetch(
    getPromoByAppointmentId,
    id
  );
  const isLoading = useIsLoading([isFetchingPromotion]);

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }

  return (
    <Tabs style={{ overflow: "hidden", height: "100%" }}>
      <TabList>
        <Tab>Consejos</Tab>
        <Tab>Promoción</Tab>
        <Tab>Notas</Tab>
        <Tab>Llamadas</Tab>
      </TabList>
      <TabPanel style={{ overflowY: "hidden" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            width: "100%",
            height: "100%",
            alignItems: "start",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "5px",
            }}
          >
            <h1
              style={{
                fontFamily: "lis-title",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              Consejos
            </h1>
            <div
              style={{
                display: "flex",
                gap: "1rem",
                overflow: "auto",
              }}
            >
              <TipAndTaskComponent
                title={"Investigar RRSS"}
                content={"Busca al cliente en las redes sociales."}
              ></TipAndTaskComponent>
              <TipAndTaskComponent
                title={"Búsqueda de información"}
                content={
                  "Investiga al cliente y toma notas que ayuden a la hora de realizar la visita."
                }
              ></TipAndTaskComponent>
              <TipAndTaskComponent
                title={"Escuchar la llamada de fábrica de visita"}
                content={
                  "Recuerda escuchar las llamadas del cliente antes de empezar la visita."
                }
              ></TipAndTaskComponent>
            </div>
          </div>
          <div
            style={{
              paddingTop: "1rem",
              gap: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h1
              style={{
                fontFamily: "lis-title",
                fontWeight: "bold",
                fontSize: "25px",
                marginBottom: "0px",
              }}
            >
              Tareas
            </h1>
            <div
              style={{
                display: "flex",
                gap: "1rem",
                overflow: "auto",
              }}
            >
              <TipAndTaskComponent
                title={"Punto de venta"}
                content={
                  "Comprueba que este ordenado el punto de venta para que la cita se desarrolle de la mejor forma posible."
                }
              ></TipAndTaskComponent>
              <TipAndTaskComponent
                title={"Maqueta fisica"}
                content={
                  "Recuerda tener preparada la maqueta fisica para la visita."
                }
              ></TipAndTaskComponent>
              <TipAndTaskComponent
                title={"Showroom"}
                content={"Recuerda tener preparado y recogido el showroom."}
              ></TipAndTaskComponent>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel className="promocion-tab">
        <PromotionInfoFull
          promotion={promotion}
          sellerWindow={false}
        ></PromotionInfoFull>
      </TabPanel>
      <TabPanel>
        <FormForNotes
          idClient={idClient}
          idSeller={idSeller}
          where="appointment"
        ></FormForNotes>
      </TabPanel>
      <TabPanel className="llamadas-tab">
        <Calls idClient={client?.id} idSeller={getUser().id}></Calls>
      </TabPanel>
    </Tabs>
  );
};

export default AppointmentClientTabs;
