import { Button } from "@nextui-org/react";

import { useEffect, useState } from "react";
import { AiOutlinePrinter } from "react-icons/ai";
import { handlePrint } from "../../Utils/printUtils";
import { getBudget } from "../../Service/EmailService/EmailService";
import { useParams } from "react-router-dom";

const PrintDocuments = (props: any) => {
  const { id_appoiment }: any = useParams();
  const { planosFavoritos, documentacion, loading, documentationLanguage } =
    props;

  const [planosImprimir, setPlanosImprimir] = useState<any>([]);
  const [budget, setBudget] = useState<any>(undefined);
  const [loadingBudget, setLoadingBudget] = useState<boolean>(true);

  const obtenerURLs = (arrayObjetos: any) => {
    const urls: any = [];

    arrayObjetos.forEach((objeto: any) => {
      if (objeto.hasOwnProperty("url")) {
        urls.push(objeto.url);
      }
    });

    return urls;
  };

  const fetch = async () => {
    try {
      setLoadingBudget(true);
      const res = await getBudget(id_appoiment, planosFavoritos);
      setBudget(res);
    } catch (err) {
      console.log(err);
      setBudget(undefined);
    } finally {
      setLoadingBudget(false);
    }
    let planos: any = obtenerURLs(planosFavoritos);
    setPlanosImprimir(planos);
  };

  useEffect(() => {
    fetch();
  }, [planosFavoritos]);

  const handleDownload = () => {
    const {
      dossier_calidades_es,
      dossier_calidades_en,
      dossier_comercial_es,
      dossier_comercial_en,
    } = documentacion;

    // Verificar existencia de documentos
    const existeCalidadesEs = Boolean(dossier_calidades_es);
    const existeCalidadesEn = Boolean(dossier_calidades_en);
    const existeComercialEs = Boolean(dossier_comercial_es);
    const existeComercialEn = Boolean(dossier_comercial_en);

    // Añadir documentos de calidades según disponibilidad y lenguaje
    if (existeCalidadesEs && existeCalidadesEn) {
      if (documentationLanguage === "es") {
        planosImprimir.push(dossier_calidades_es);
      } else if (documentationLanguage === "en") {
        planosImprimir.push(dossier_calidades_en);
      }
    } else if (existeCalidadesEs) {
      planosImprimir.push(dossier_calidades_es);
    } else if (existeCalidadesEn) {
      planosImprimir.push(dossier_calidades_en);
    }

    // Añadir documentos comerciales según disponibilidad y lenguaje
    if (existeComercialEs && existeComercialEn) {
      if (documentationLanguage === "es") {
        planosImprimir.push(dossier_comercial_es);
      } else if (documentationLanguage === "en") {
        planosImprimir.push(dossier_comercial_en);
      }
    } else if (existeComercialEs) {
      planosImprimir.push(dossier_comercial_es);
    } else if (existeComercialEn) {
      planosImprimir.push(dossier_comercial_en);
    }

    // Llamar a la función handlePrint con los documentos seleccionados
    handlePrint(planosImprimir, budget);
  };

  return loading || loadingBudget ? (
    <Button disabled>&nbsp;Obteniendo planos y documentación</Button>
  ) : (
    <Button onClick={handleDownload}>
      <AiOutlinePrinter size={18} color={"white"} />
      &nbsp;Descargar planos ({planosFavoritos ? planosFavoritos.length : "0"})
      y documentación para imprimir
    </Button>
  );
};

export default PrintDocuments;
