import { Input } from "@nextui-org/react";

function CustomInput(props: any) {
  const { label, displayedInClientView, ...rest } = props;

  return (
    <Input
      label={
        <>
          <div className='d-flex align-items-center gap-1'>
            {displayedInClientView && (
              <span className='dot-question-in-client-view'></span>
            )}
            {label}
          </div>
        </>
      }
      {...rest}
    />
  );
}

export default CustomInput;
