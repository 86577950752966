import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

/**
 * Obtiene los datos de las casas a través de los assets enviados
 * @param {array} data Listado de id's de planos marcados como favoritos
 * @returns
 */
export const getHouseInfoByAssets = (data: any) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND + `/RelationAssetHouse/info`,
    data,
    getDefaultConfigHeader()
  );
};
