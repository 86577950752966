import { ShowFormButtons } from "./ShowFormButtons";

export default function Exterior(props: any) {
  const selectedValue1 = "maqueta_fisica";
  const selectedName1 = "Maqueta fisica";
  const selectedValue2 = "infografia_exterior";
  const selectedName2 = "Infografia Exterior";
  return (
    <ShowFormButtons
      formik={props.formik}
      setselectedValue={props.setselectedValue}
      selectedValue1={selectedValue1}
      selectedName1={selectedName1}
      selectedValue2={selectedValue2}
      selectedName2={selectedName2}
    ></ShowFormButtons>
  );
}
