export const TIMEZONES = ["Peninsula-Baleares", "Canarias"];

export const PROMOTION_STATE_MAP: Record<string, string> = {
  Vendido: "Vendida",
  "Promoción vendida": "Vendida",
  Disponible: "Disponible",
  "En venta": "En Venta",
  "Contrato CV": "Vendido",
  Escriturado: "Vendido",
  Reservado: "Reservado",
  Bloqueado: "Bloqueado",
  Apalabrado: "Bloqueado",
  Transferencia: "Bloqueado",
  "Reserva PC": "Bloqueado",
  "Pendiente de resolución": "Bloqueado",
  Reserva: "Reserva",
  Próximamente: "Próximamente",
  "Próximamente nuevas unidades": "Próximamente nuevas Uds",
};
