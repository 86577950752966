import "./ThanksForYourVisit.css";

function ThanksForYourVisit() {
  return (
    <div className="d-flex h-100 w-100">
      <div className="bg-thanks h-100 w-100">
        <div className="d-flex" style={{ marginTop: "-20%" }}>
          <div className="row d-flex justify-content-center">
            <img
              alt="Logo AEDAS"
              className="col-12"
              src="/Assets/img/AF_AEDAS_POSITIVO_RGB.svg"
              style={{ width: "358px" }}
            ></img>
            <div className="d-flex justify-content-center mt-4">
              <h1 className="thanks-text">Gracias por tu visita</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThanksForYourVisit;
