import { FaPhotoVideo } from "react-icons/fa";
import TabsForm from "./TabsForm";
import { BiMapPin } from "react-icons/bi";

export const ShowFormButtons = ({
  formik,
  setselectedValue,
  selectedValue1,
  selectedName1,
  selectedValue2,
  selectedName2,
}: any) => {
  const sections = [
    {
      button: {
        onClick: () => setselectedValue(selectedValue1),
        icon: <BiMapPin></BiMapPin>,
        name: selectedName1,
      },
      questions: [],
    },
    {
      button: {
        onClick: () => setselectedValue(selectedValue2),
        icon: <FaPhotoVideo></FaPhotoVideo>,
        name: selectedName2,
      },
      questions: [],
    },
  ];
  return <TabsForm formik={formik} sections={sections}></TabsForm>;
};
