import { Button, Modal } from "@nextui-org/react";

interface Props {
  formik: any;
  setOpenMandatoryModal: any;
}

export const ModalMandatoryFields = ({
  formik,
  setOpenMandatoryModal,
}: Props) => {
  const mandatoryFields = [
    { id: 1, name: "Codigo_Postal_Actual__pc", label: "Código Postal" },
    { id: 2, name: "Arquetipo__c", label: "Arquetipo" },
    { id: 3, name: "Pais_residencia__pc", label: "¿En qué país reside?" },
    // {
    //   id: 4,
    //   name: "Vivienda_actual_en_prop__pc",
    //   label: "Tiene vivienda en propiedad",
    // },
    // { id: 5, name: "Vive_de_alquiler__c", label: "Vive de alquiler" },
    {
      id: 6,
      name: "DestinoVivienda__c",
      label: "¿Cuál es el motivo de la compra?",
    },
    { id: 7, name: "relationalStyle", label: "Estilo relacional" },
  ];
  return (
    <>
      <Modal.Body className="w-full">
        <div className="p-4 flex items-center flex-col w-full">
          <h4 className="text-center">Campos sin rellenar</h4>
          <p className="text-center">
            Para poder terminar la visita debe rellenar los siguientes campos:
          </p>
          <ul className="flex text-start">
            {mandatoryFields.map((field: any) => {
              if (!formik.values[field.name]) {
                return (
                  <li className="text-xs" key={field.id}>
                    {field.label}
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button
            onClick={() => {
              setOpenMandatoryModal(false);
            }}
          >
            Cerrar
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
};
