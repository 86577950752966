import { TextareaAutosize } from "@mui/base";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { AutoSave } from "../../Components/AutoSave/Autosave";
import {
  getNoteByAppointment,
  setNoteByAppointment,
} from "../../Service/NotesByAppoinmentServices/NotesByAppoinmentServices";
import { Textarea } from "@nextui-org/react";

const FormForNotes = (props: any) => {
  console.log(props);

  const [notes, setNotes] = useState<string>("");
  const { idClient, idSeller, editable = false } = props;

  useEffect(() => {
    if (idClient && idSeller) {
      getNoteByAppointment({ idClient: idClient, idSeller: idSeller }).then(
        (response) => {
          console.log(response);
          setNotes(response.data.description);
        }
      );
    }
  }, [idClient, idSeller]);

  const formikNotes = useFormik({
    initialValues: {
      notas: props.notesFromApi ?? "", // Formulario de notas
    },
    validationSchema: null,
    onSubmit: async (values, formikHelpers) => {
      try {
        await setNoteByAppointment({ idClient, idSeller, note: notes });
      } catch (e: any) {
        //@ts-ignore
        formikHelpers.setErrors({ error: e });
      } finally {
        formikHelpers.setSubmitting(false);
      }
    },
  });
  console.log("entro en notaas");

  const handleChange = (event: any) => {
    event.preventDefault();
    sessionStorage.setItem("myNotes", event.target.value);
    setNotes(event.target.value);
    formikNotes.setFieldValue("notas", notes);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        {props.where !== "appointment" && (
          <AutoSave debounceMs={5000} formik={formikNotes}></AutoSave>
        )}
      </div>
      <div className="relative" style={{ height: "90%" }}>
        <textarea
          aria-label="notas"
          name="notas"
          value={notes ?? ""}
          onChange={handleChange}
          style={{
            width: "100%",
            height: "100%",
            //width: props.where === "appointment" ? 1230 : "100%",
            //height: props.where === "appointment" ? 675 : editable ? 677 : 440,
            border: "1px solid #e2e3e3",
            borderRadius: "10px",
          }}
        />
      </div>
    </>
  );
};

export default FormForNotes;
