interface Props {
  documentsToShow: any;
}

export const Documents = ({ documentsToShow }: Props) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* <h1 style={{ width: "100%", textAlign: "center" }}>
        {documentsToShow.label}
      </h1> */}
      {documentsToShow.promotions.map((document: any, index: number) => {
        return (
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              overflow: "auto",
            }}
            key={
              "documentsToShow" +
              documentsToShow.label +
              document.uuid_promotion +
              index
            }
          >
            {document.planos.map((url: string, i: number) => (
              <iframe
                key={
                  "documentsToShow-planos" +
                  documentsToShow.label +
                  document.uuid_promotion +
                  i +
                  index +
                  url
                }
                title='Plano'
                src={url}
                height='100%'
                width='100%'
              ></iframe>
            ))}
          </div>
        );
      })}
    </div>
  );
};
