export const FEATURES_LIST = [
  {
    Api_name: "",
    Label: "",
  },
  {
    Api_name: "Administrador_Finca__c",
    Label: "Administrador finca",
  },
  {
    Api_name: "Aerotermia__c",
    Label: "Aerotermia",
  },
  {
    Api_name: "Ambiente_Default__c",
    Label: "Ambiente Por Defecto",
  },
  {
    Api_name: "Anyo_De_Construccion__c",
    Label: "Año de construcción",
  },
  {
    Api_name: "Anyo_Entrega__c",
    Label: "Año entrega",
  },
  {
    Api_name: "Anyos_De_Credito__c",
    Label: "Años de crédito",
  },
  {
    Api_name: "Arras_Transformables__c",
    Label: "Arras Transformables_old",
  },
  {
    Api_name: "Arras_Transformables_Estado__c",
    Label: "Arras Transformables",
  },
  {
    Api_name: "Arras_Transformables_Primer_Contrato__c",
    Label: "Arras Transformables Primer Contrato",
  },
  {
    Api_name: "Asignar_usuario_especifico__c",
    Label: "Asignar usuario especifico",
  },
  {
    Api_name: "At_Cliente_de_Territorial__c",
    Label: "At.Cliente de Territorial",
  },
  {
    Api_name: "Atributos__c",
    Label: "Atributos",
  },
  {
    Api_name: "BancoAdenda__c",
    Label: "Banco Adenda",
  },
  {
    Api_name: "Calistenia__c",
    Label: "Calistenia",
  },
  {
    Api_name: "Campagna__c",
    Label: "Campaña",
  },
  {
    Api_name: "Cargas__c",
    Label: "Cargas",
  },
  {
    Api_name: "Catalogo_comercial__c",
    Label: "Catálogo comercial",
  },
  {
    Api_name: "CIF_Comercializadora__c",
    Label: "CIF Comercializadora",
  },
  {
    Api_name: "CIF_constructora__c",
    Label: "CIF constructora",
  },
  {
    Api_name: "CIF_D_Obra__c",
    Label: "CIF D Obra",
  },
  {
    Api_name: "CIF_propiedad__c",
    Label: "CIF propiedad",
  },
  {
    Api_name: "CIF_proyectista__c",
    Label: "CIF proyectista",
  },
  {
    Api_name: "CIF_sociedad_promotora__c",
    Label: "CIF sociedad promotora",
  },
  {
    Api_name: "CIF_sociedad_vendedora__c",
    Label: "CIF sociedad vendedora",
  },
  {
    Api_name: "ClausulasArras__c",
    Label: "Cláusulas contrato de arras",
  },
  {
    Api_name: "ClausulasArrasBloqueo__c",
    Label: "Cláusulas contrato de arras Bloqueo",
  },
  {
    Api_name: "ClausulasArrasfase2__c",
    Label: "Cláusulas contrato de arras fase 2",
  },
  {
    Api_name: "ClausulasArrasLPO__c",
    Label: "Cláusulas contrato de arras LPO",
  },
  {
    Api_name: "Clave_Prinex_para_Bloque__c",
    Label: "Clave Prinex para Bloque",
  },
  {
    Api_name: "Clave_Prinex_para_Piso__c",
    Label: "Clave Prinex para Piso",
  },
  {
    Api_name: "Clave_Prinex_para_Portal__c",
    Label: "Clave Prinex para Portal",
  },
  {
    Api_name: "Clave_Prinex_para_Puerta__c",
    Label: "Clave Prinex para Puerta",
  },
  {
    Api_name: "Codigo_Delegacion__c",
    Label: "Código Delegación",
  },
  {
    Api_name: "Codigo_ILC__c",
    Label: "Código ILC",
  },
  {
    Api_name: "Colaborador__c",
    Label: "Colaborador",
  },
  {
    Api_name: "Comercial_1__c",
    Label: "Comercial 1",
  },
  {
    Api_name: "Comercial_2__c",
    Label: "Comercial 2",
  },
  {
    Api_name: "Comercializadora__c",
    Label: "Comercializadora",
  },
  {
    Api_name: "Complejo_Inmobiliario__c",
    Label: "Complejo Inmobiliario",
  },
  {
    Api_name: "Constitucion_sociedad_promotora__c",
    Label: "Constitución sociedad promotora",
  },
  {
    Api_name: "Constitucion_sociedad_vendedora__c",
    Label: "Constitución sociedad vendedora",
  },
  {
    Api_name: "CorreoDelDecorador__c",
    Label: "Correo del decorador",
  },
  {
    Api_name: "Correos_fin_personalizacion__c",
    Label: "Correos fin personalización",
  },
  {
    Api_name: "Correos_personalizacion__c",
    Label: "Correos inicio personalización",
  },
  {
    Api_name: "Correos_resolucion__c",
    Label: "Correos resolución",
  },
  {
    Api_name: "CreatedById",
    Label: "Creado por el Id.",
  },
  {
    Api_name: "CreatedDate",
    Label: "Fecha de creación",
  },
  {
    Api_name: "Cubierta_comunitaria_con_chill_out__c",
    Label: "Cubierta comunitaria con chill - out",
  },
  {
    Api_name: "Cuenta_Tecnico_Territorial__c",
    Label: "Cuenta Cliente Preventa",
  },
  {
    Api_name: "CuentaDePagoAdenda__c",
    Label: "Cuenta De Pago Adenda",
  },
  {
    Api_name: "DatoPersonalVendedora__c",
    Label: "Dato Personal Vendedora",
  },
  {
    Api_name: "Delegacion__c",
    Label: "Dirección Territorial",
  },
  {
    Api_name: "Descripcion__c",
    Label: "Descripción",
  },
  {
    Api_name: "Descripcion_financiacion__c",
    Label: "Descripción financiación",
  },
  {
    Api_name: "Descripcion_forma_de_pago__c",
    Label: "Descripción forma de pago",
  },
  {
    Api_name: "Descripcion_Portales_Castellano__c",
    Label: "Descripción Portales Castellano",
  },
  {
    Api_name: "Descripcion_Portales_Catalan__c",
    Label: "Descripción Portales Catalán",
  },
  {
    Api_name: "Descripcion_Portales_Ingles__c",
    Label: "Descripción Portales Inglés",
  },
  {
    Api_name: "Descripcion_redes_y_proteccion__c",
    Label: "Descripción redes y protección",
  },
  {
    Api_name: "Descripcion_Tipo_De_Interes__c",
    Label: "Descripción Tipo de Interés",
  },
  {
    Api_name: "Descripcion_Tipologias_Castellano__c",
    Label: "Descripción Tipologías Castellano",
  },
  {
    Api_name: "Descripcion_Tipologias_Catalan__c",
    Label: "Descripción Tipologías Catalán",
  },
  {
    Api_name: "Descripcion_Tipologias_Ingles__c",
    Label: "Descripción Tipologías Inglés",
  },
  {
    Api_name: "DescripcionDelServicio__c",
    Label: "Descripción del servicio",
  },
  {
    Api_name: "DescripcionVendedora__c",
    Label: "Descripción Vendedora",
  },
  {
    Api_name: "DetectoresdePresencia__c",
    Label: "Detectores de Presencia",
  },
  {
    Api_name: "Dias_para_firmar_el_contrato__c",
    Label: "Fecha fin de reserva con licencia (días)",
  },
  {
    Api_name: "Direccion__c",
    Label: "Dirección",
  },
  {
    Api_name: "Direccion_Codigo_Comunidad_Autonoma__c",
    Label: "Código Comunidad Autónoma",
  },
  {
    Api_name: "Direccion_Codigo_Distrito__c",
    Label: "Código Distrito",
  },
  {
    Api_name: "Direccion_Codigo_Municipio__c",
    Label: "Código Municipio",
  },
  {
    Api_name: "Direccion_Codigo_Pais__c",
    Label: "Código País",
  },
  {
    Api_name: "Direccion_Codigo_Postal__c",
    Label: "Código Postal",
  },
  {
    Api_name: "Direccion_Codigo_Provincia__c",
    Label: "Código Provincia",
  },
  {
    Api_name: "Direccion_comercializadora__c",
    Label: "Dirección comercializadora",
  },
  {
    Api_name: "Direccion_Comunidad_Autonoma__c",
    Label: "Comunidad autónoma",
  },
  {
    Api_name: "Direccion_constructora__c",
    Label: "Dirección constructora",
  },
  {
    Api_name: "Direccion_D_Obra__c",
    Label: "Direccion D Obra",
  },
  {
    Api_name: "Direccion_Distrito__c",
    Label: "Distrito",
  },
  {
    Api_name: "Direccion_Municipio__c",
    Label: "Municipio",
  },
  {
    Api_name: "Direccion_Nombre_Via__c",
    Label: "Nombre de vía",
  },
  {
    Api_name: "Direccion_Numero__c",
    Label: "Número/s",
  },
  {
    Api_name: "Direccion_Pais__c",
    Label: "País",
  },
  {
    Api_name: "Direccion_propiedad__c",
    Label: "Dirección propiedad",
  },
  {
    Api_name: "Direccion_Provincia__c",
    Label: "Provincia",
  },
  {
    Api_name: "Direccion_proyectista__c",
    Label: "Direccion proyectista",
  },
  {
    Api_name: "Direccion_pv__c",
    Label: "Dirección pv",
  },
  {
    Api_name: "Direccion_sociedad_promotora__c",
    Label: "Dirección sociedad promotora",
  },
  {
    Api_name: "Direccion_sociedad_vendedora__c",
    Label: "Dirección sociedad vendedora",
  },
  {
    Api_name: "Direccion_Tipo_Via__c",
    Label: "Tipo de vía",
  },
  {
    Api_name: "DireccionPV__c",
    Label: "Dirección (pv)",
  },
  {
    Api_name: "Edif_B_Rasante__c",
    Label: "Edif. B/Rasante",
  },
  {
    Api_name: "Edif_S_Rasante__c",
    Label: "Edif. S/Rasante",
  },
  {
    Api_name: "Ejecutado_Preventa__c",
    Label: "% Ejecutado Preventa",
  },
  {
    Api_name: "Email__c",
    Label: "Email",
  },
  {
    Api_name: "Email_Comercial_1__c",
    Label: "Email Comercial 1",
  },
  {
    Api_name: "Email_Comercial_2__c",
    Label: "Email Comercial 2",
  },
  {
    Api_name: "Empresa__c",
    Label: "Empresa",
  },
  {
    Api_name: "Empresa_Constructora__c",
    Label: "Empresa Constructora",
  },
  {
    Api_name: "Entidad_bancaria_propiedad__c",
    Label: "Entidad bancaria propiedad",
  },
  {
    Api_name: "Entidad_Financiera__c",
    Label: "Entidad financiera",
  },
  {
    Api_name: "Entidad_Urbanistica__c",
    Label: "Entidad Urbanística",
  },
  {
    Api_name: "Entrada__c",
    Label: "Entrada",
  },
  {
    Api_name: "Envio_Casos_Int_Arpada__c",
    Label: "Envio Casos Arpada",
  },
  {
    Api_name: "Es_Partner__c",
    Label: "Es Partner",
  },
  {
    Api_name: "Es_VPO__c",
    Label: "VPO",
  },
  {
    Api_name: "Esta_Terminada__c",
    Label: "Obra Terminada",
  },
  {
    Api_name: "Estado_del_punto_de_venta__c",
    Label: "Estado del punto de venta",
  },
  {
    Api_name: "Estado_Obtencion_Licencia__c",
    Label: "Estado Obtención Licencia",
  },
  {
    Api_name: "Estado_Proyecto__c",
    Label: "Estado del Proyecto",
  },
  {
    Api_name: "Estudio_Arquitectura__c",
    Label: "Estudio de Arquitectura",
  },
  {
    Api_name: "Expediente__c",
    Label: "Expediente",
  },
  {
    Api_name: "Fabrica_de_Visitas__c",
    Label: "Fábrica de Visitas",
  },
  {
    Api_name: "Fase_De_Obra__c",
    Label: "Fase de obra",
  },
  {
    Api_name: "Fecha_apertura_punto_de_venta__c",
    Label: "Fecha apertura punto de venta",
  },
  {
    Api_name: "Fecha_DCM__c",
    Label: "Fecha DCM",
  },
  {
    Api_name: "Fecha_de_Alta_FAQS__c",
    Label: "Fecha de Alta FAQS",
  },
  {
    Api_name: "Fecha_de_cierre_de_comercializacion__c",
    Label: "Fecha de cierre de comercialización",
  },
  {
    Api_name: "Fecha_de_entrega_contractual__c",
    Label: "Fecha de entrega contractual",
  },
  {
    Api_name: "Fecha_de_escrituracion__c",
    Label: "Fecha de la última cuota",
  },
  {
    Api_name: "Fecha_de_recepcion_de_acta__c",
    Label: "Fecha de Acta de Recepción",
  },
  {
    Api_name: "Fecha_de_reserva_sin_licencia__c",
    Label: "Fecha fin de reserva",
  },
  {
    Api_name: "Fecha_entrega_actual__c",
    Label: "Fecha entrega actual",
  },
  {
    Api_name: "Fecha_fin_estructura_prevista__c",
    Label: "Fecha prevista/real del fin estructura",
  },
  {
    Api_name: "Fecha_Inicio_Comercializacion__c",
    Label: "Fecha Inicio Comercialización",
  },
  {
    Api_name: "Fecha_Licencia_Obras__c",
    Label: "Fecha Licencia de Obras",
  },
  {
    Api_name: "Fecha_Obtenci_n_Financiaci_n__c",
    Label: "Fecha Obtención Financiación",
  },
  {
    Api_name: "Fecha_prev_inicio_comercializacion__c",
    Label: "Fecha prevista/real inicio comercializ.",
  },
  {
    Api_name: "Fecha_real_de_inicio_comercializacion__c",
    Label: "Fecha real de inicio comercialización",
  },
  {
    Api_name: "Fecha_real_fin_de_estructura__c",
    Label: "Fecha real fin de estructura",
  },
  {
    Api_name: "Fecha_solicitud_licencia__c",
    Label: "Fecha solicitud licencia",
  },
  {
    Api_name: "Fechas_Previstas_Entrega_Llaves__c",
    Label: "Fechas Previstas/Real Entrega de Llaves",
  },
  {
    Api_name: "Fechas_Previstas_Fin_Construccion__c",
    Label: "Fechas Previstas/Real Fin Constr.",
  },
  {
    Api_name: "Fechas_Previstas_Inicio__c",
    Label: "Fechas Previstas/Real: Inicio Proy.",
  },
  {
    Api_name: "Fechas_Previstas_Inicio_Construccion__c",
    Label: "Fechas Previstas/Real: Inicio Constr.",
  },
  {
    Api_name: "Fechas_Reales_Entrega_Llaves__c",
    Label: "Fechas Reales: Entrega de Llaves",
  },
  {
    Api_name: "Fechas_Reales_Fin_Construccion__c",
    Label: "Fechas Reales: Fin Constr.",
  },
  {
    Api_name: "Fechas_Reales_Inicio__c",
    Label: "Fechas Reales: Inicio",
  },
  {
    Api_name: "Fechas_Reales_Inicio_Construccion__c",
    Label: "Fechas Reales: Inicio Constr.",
  },
  {
    Api_name: "Fin_entregas_contractual__c",
    Label: "Fin entregas contractual",
  },
  {
    Api_name: "FormaPagoAdenda1__c",
    Label: "Forma Pago Adenda 1",
  },
  {
    Api_name: "FormaPagoAdenda2__c",
    Label: "Forma Pago Adenda 2",
  },
  {
    Api_name: "FormaPagoBis__c",
    Label: "Forma Pago Bis",
  },
  {
    Api_name: "Garajes_para_externos__c",
    Label: "Garajes para externos",
  },
  {
    Api_name: "Garajes_Totales__c",
    Label: "Garajes Totales",
  },
  {
    Api_name: "Geotermia__c",
    Label: "Geotermia",
  },
  {
    Api_name: "Gerente__c",
    Label: "Aprobador",
  },
  {
    Api_name: "Gerente_CX__c",
    Label: "Gerente Att. Cliente territorial",
  },
  {
    Api_name: "Gerente_texto__c",
    Label: "Gerente",
  },
  {
    Api_name: "Google_Maps__c",
    Label: "Google Maps",
  },
  {
    Api_name: "Honorario_gestion_mobiliario__c",
    Label: "Honorario gestión mobiliario",
  },
  {
    Api_name: "Honorarios_M2__c",
    Label: "Honorarios M2",
  },
  {
    Api_name: "Honorarios_Totales__c",
    Label: "Honorarios Totales",
  },
  {
    Api_name: "Horario_Apertura__c",
    Label: "Horario Apertura PV",
  },
  {
    Api_name: "Horario_Apertura_CC__c",
    Label: "Horario Apertura CC",
  },
  {
    Api_name: "Horario_Apertura_FV__c",
    Label: "Horario Apertura FV",
  },
  {
    Api_name: "Horario_pv__c",
    Label: "Horario (pv)",
  },
  {
    Api_name: "Huerto_urbano__c",
    Label: "Huerto urbano",
  },
  {
    Api_name: "Id",
    Label: "Número de registro",
  },
  {
    Api_name: "Id_Promocion__c",
    Label: "Id Promocion",
  },
  {
    Api_name: "Id_Territorial__c",
    Label: "Id Territorial",
  },
  {
    Api_name: "IDCompleto__c",
    Label: "IDCompleto",
  },
  {
    Api_name: "Identificador__c",
    Label: "Identificador",
  },
  {
    Api_name: "IdentificadorPBC__c",
    Label: "Identificador PBC",
  },
  {
    Api_name: "IdentificadorPrinex__c",
    Label: "Identificador Prinex",
  },
  {
    Api_name: "Importe_Mobiliarios__c",
    Label: "Importe Mobiliarios",
  },
  {
    Api_name: "Importe_Pago_Reserva__c",
    Label: "Importe Pago Reserva",
  },
  {
    Api_name: "Impuesto_adenda__c",
    Label: "% impuesto adenda",
  },
  {
    Api_name: "Inf_a_las_Obras_de_Cons__c",
    Label: "Inf Relativa a las Obras de Construcción",
  },
  {
    Api_name: "Inscripcion_comercializadora__c",
    Label: "Inscripción comercializadora",
  },
  {
    Api_name: "Inscripcion_constructora__c",
    Label: "Inscripción constructora",
  },
  {
    Api_name: "Inscripcion_D_Obra__c",
    Label: "Inscripción D Obra",
  },
  {
    Api_name: "Inscripcion_propietario__c",
    Label: "Inscripción propietario",
  },
  {
    Api_name: "Inscripcion_proyectista__c",
    Label: "Inscripción proyectista",
  },
  {
    Api_name: "Inscripcion_sociedad_promotora__c",
    Label: "Inscripción sociedad promotora",
  },
  {
    Api_name: "Inscripcion_sociedad_vendedora__c",
    Label: "Inscripción sociedad vendedora",
  },
  {
    Api_name: "IsDeleted",
    Label: "Eliminado",
  },
  {
    Api_name: "IsLocked",
    Label: "Está bloqueado",
  },
  {
    Api_name: "isPVSpecified__c",
    Label: "isPVSpecified",
  },
  {
    Api_name: "IVA__c",
    Label: "IVA",
  },
  {
    Api_name: "Jacuzzi__c",
    Label: "Jacuzzi",
  },
  {
    Api_name: "Landing_page__c",
    Label: "Landing page",
  },
  {
    Api_name: "LastActivityDate",
    Label: "Última fecha de actividad",
  },
  {
    Api_name: "LastModifiedById",
    Label: "Última modificación realizada por el Id.",
  },
  {
    Api_name: "LastModifiedDate",
    Label: "Fecha de la última modificación",
  },
  {
    Api_name: "LastReferencedDate",
    Label: "Última fecha de referencia",
  },
  {
    Api_name: "LastViewedDate",
    Label: "Fecha de última visualización",
  },
  {
    Api_name: "Licencia__c",
    Label: "Licencia",
  },
  {
    Api_name: "Locales_Totales__c",
    Label: "Locales Totales",
  },
  {
    Api_name: "Localizacion__c",
    Label: "Localización",
  },
  {
    Api_name: "Localizacion__Latitude__s",
    Label: "Localización (Latitud)",
  },
  {
    Api_name: "Localizacion__Longitude__s",
    Label: "Localización (Longitud)",
  },
  {
    Api_name: "Ludoteca__c",
    Label: "Ludoteca",
  },
  {
    Api_name: "Maqueta__c",
    Label: "Maqueta",
  },
  {
    Api_name: "Max_Anios_Credito__c",
    Label: "Años de crédito (max)",
  },
  {
    Api_name: "Maximo_Habitaciones__c",
    Label: "Máximo habitaciones",
  },
  {
    Api_name: "MayEdit",
    Label: "Se puede modificar",
  },
  {
    Api_name: "Memoria_de_calidades__c",
    Label: "Memoria de calidades",
  },
  {
    Api_name: "Memoria_de_eco_living__c",
    Label: "Memoria de eco-living",
  },
  {
    Api_name: "Meses_desde_Inicio_comercializacion__c",
    Label: "Meses desde Inicio comercialización",
  },
  {
    Api_name: "Metros_Construidos__c",
    Label: "Metros Construidos",
  },
  {
    Api_name: "Minimo_Habitaciones__c",
    Label: "Mínimo habitaciones",
  },
  {
    Api_name: "Movil_Comercial_1__c",
    Label: "Móvil Comercial 1",
  },
  {
    Api_name: "Movil_Comercial_2__c",
    Label: "Móvil Comercial 2",
  },
  {
    Api_name: "Muestra_Vivienda__c",
    Label: "Muestra Vivienda",
  },
  {
    Api_name: "Muestras_en_punto_de_venta__c",
    Label: "Muestras en punto de venta",
  },
  {
    Api_name: "MunicipioCopia__c",
    Label: "Municipio",
  },
  {
    Api_name: "N_cuenta_propiedad__c",
    Label: "N cuenta propiedad",
  },
  {
    Api_name: "N_mero_de_cuenta__c",
    Label: "Número de cuenta",
  },
  {
    Api_name: "Name",
    Label: "Nombre de Promoción",
  },
  {
    Api_name: "Nombre__c",
    Label: "Nombre",
  },
  {
    Api_name: "Nombre_asesor_comercial_1__c",
    Label: "Nombre asesor comercial 1",
  },
  {
    Api_name: "Nombre_asesor_comercial_2__c",
    Label: "Nombre asesor comercial 2",
  },
  {
    Api_name: "Nombre_Clave_1__c",
    Label: "Nombre Clave 1",
  },
  {
    Api_name: "Nombre_Clave_2__c",
    Label: "Nombre Clave 2",
  },
  {
    Api_name: "Nombre_Clave_3__c",
    Label: "Nombre Clave 3",
  },
  {
    Api_name: "Nombre_Clave_4__c",
    Label: "Nombre Clave 4",
  },
  {
    Api_name: "Nombre_Comercial_Cliente__c",
    Label: "Nombre Comercial Cliente",
  },
  {
    Api_name: "Nombre_constructora__c",
    Label: "Nombre constructora",
  },
  {
    Api_name: "Nombre_D_Obra__c",
    Label: "Nombre D Obra",
  },
  {
    Api_name: "Nombre_de_via__c",
    Label: "Nombre de vía (pv)",
  },
  {
    Api_name: "Nombre_del_responsable_comercializadora__c",
    Label: "Nombre del responsable comercializadora",
  },
  {
    Api_name: "Nombre_propiedad__c",
    Label: "Nombre propiedad",
  },
  {
    Api_name: "Nombre_proyectista__c",
    Label: "Nombre proyectista",
  },
  {
    Api_name: "Nombre_sociedad__c",
    Label: "Nombre sociedad vendedora",
  },
  {
    Api_name: "Nombre_sociedad_promotora__c",
    Label: "Nombre sociedad promotora",
  },
  {
    Api_name: "Nombre_Territorial__c",
    Label: "Nombre Director Territorial",
  },
  {
    Api_name: "NombreDelServicio__c",
    Label: "Nombre del servicio",
  },
  {
    Api_name: "Nota_Certificado_Energetico__c",
    Label: "Nota Cert. Energético",
  },
  {
    Api_name: "NotificacionPrereserva__c",
    Label: "Notificacion Prereserva",
  },
  {
    Api_name: "Num_Sociedad__c",
    Label: "Num. Sociedad",
  },
  {
    Api_name: "Numero_Cuotas_de_reserva_a_escrituracion__c",
    Label: "Numero Cuotas",
  },
  {
    Api_name: "Numero_s_pv__c",
    Label: "Numero/s (pv)",
  },
  {
    Api_name: "NumeroSincronizacion__c",
    Label: "Número sincronización",
  },
  {
    Api_name: "NumViviendasDisponibles__c",
    Label: "Num viviendas disponibles",
  },
  {
    Api_name: "Objetivo_2017__c",
    Label: "Objetivo ventas anual",
  },
  {
    Api_name: "Objetivo_ventas_abril__c",
    Label: "Objetivo ventas abril",
  },
  {
    Api_name: "Objetivo_ventas_agosto__c",
    Label: "Objetivo ventas agosto",
  },
  {
    Api_name: "Objetivo_ventas_diciembre__c",
    Label: "Objetivo ventas diciembre",
  },
  {
    Api_name: "Objetivo_ventas_enero__c",
    Label: "Objetivo ventas enero",
  },
  {
    Api_name: "Objetivo_ventas_febrero__c",
    Label: "Objetivo ventas febrero",
  },
  {
    Api_name: "Objetivo_ventas_julio__c",
    Label: "Objetivo ventas julio",
  },
  {
    Api_name: "Objetivo_ventas_junio__c",
    Label: "Objetivo ventas junio",
  },
  {
    Api_name: "Objetivo_ventas_marzo__c",
    Label: "Objetivo ventas marzo",
  },
  {
    Api_name: "Objetivo_ventas_mayo__c",
    Label: "Objetivo ventas mayo",
  },
  {
    Api_name: "Objetivo_ventas_noviembre__c",
    Label: "Objetivo ventas noviembre",
  },
  {
    Api_name: "Objetivo_ventas_octubre__c",
    Label: "Objetivo ventas octubre",
  },
  {
    Api_name: "Objetivo_ventas_septiembre__c",
    Label: "Objetivo ventas septiembre",
  },
  {
    Api_name: "Obra_En_Curso__c",
    Label: "Obra en curso",
  },
  {
    Api_name: "Observaciones_DCM__c",
    Label: "Observaciones (DCM)",
  },
  {
    Api_name: "Ocupacion__c",
    Label: "Ocupación",
  },
  {
    Api_name: "Oficinas_Totales__c",
    Label: "Oficinas Totales",
  },
  {
    Api_name: "OM_URL_Castillete_3D_Formatted__c",
    Label: "URL Castillete 3D Formatted",
  },
  {
    Api_name: "OM_Zona__c",
    Label: "Zona",
  },
  {
    Api_name: "OwnerId",
    Label: "Id. del propietario",
  },
  {
    Api_name: "Permitir_Alta_Area_Privada__c",
    Label: "Permitir alta Área privada",
  },
  {
    Api_name: "Permitir_alta_rea_Qlip__c",
    Label: "Permitir alta Área Qlip",
  },
  {
    Api_name: "PiscinaPrivada__c",
    Label: "Piscina Privada",
  },
  {
    Api_name: "Piso_piloto__c",
    Label: "Piso piloto",
  },
  {
    Api_name: "Piso_tecnico__c",
    Label: "Piso técnico",
  },
  {
    Api_name: "PistaMultideporte__c",
    Label: "Pista polideportiva",
  },
  {
    Api_name: "Placas_Fotovoltaicas__c",
    Label: "Placas Fotovoltaicas",
  },
  {
    Api_name: "Placas_solares_t_rmicas__c",
    Label: "Placas solares térmicas",
  },
  {
    Api_name: "Planos_comerciales__c",
    Label: "Planos comerciales",
  },
  {
    Api_name: "Plantilla_adenda_con_coste__c",
    Label: "Plantilla adenda con coste",
  },
  {
    Api_name: "Plantilla_adenda_sin_coste__c",
    Label: "Plantilla adenda sin coste",
  },
  {
    Api_name: "Plantilla_Arras_Persona_Fisica__c",
    Label: "Plantilla Arras Persona Física",
  },
  {
    Api_name: "Plantilla_Arras_Persona_Juridica__c",
    Label: "Plantilla Arras Persona Jurídica",
  },
  {
    Api_name: "Plantilla_DIA__c",
    Label: "Plantilla DIA",
  },
  {
    Api_name: "Plantilla_FIC_Persona_Fisica__c",
    Label: "Plantilla FIC Persona Física",
  },
  {
    Api_name: "Plantilla_FIC_Persona_Juridica__c",
    Label: "Plantilla FIC Persona Jurídica",
  },
  {
    Api_name: "Plantilla_Ficha_Informativa_Precio__c",
    Label: "Plantilla Ficha Informativa Precio",
  },
  {
    Api_name: "Plantilla_Justificante_DIA__c",
    Label: "Plantilla Justificante DIA",
  },
  {
    Api_name: "Plantilla_Solicitud_Rescision__c",
    Label: "Plantilla Solicitud Rescisión",
  },
  {
    Api_name: "Poblacion_pv__c",
    Label: "Población (pv)",
  },
  {
    Api_name: "Porcent_Pago_de_Reserva_a_Escrituracion__c",
    Label: "Porcentaje en cuotas",
  },
  {
    Api_name: "Porcentaje_De_Hipoteca__c",
    Label: "% de la hipoteca",
  },
  {
    Api_name: "Porcentaje_Entrada__c",
    Label: "% Entrada",
  },
  {
    Api_name: "Porcentaje_letra_final__c",
    Label: "Porcentaje cuota final",
  },
  {
    Api_name: "Porcentaje_Pago_Arras__c",
    Label: "Porcentaje Contrato Compraventa",
  },
  {
    Api_name: "Porcentaje_Pago_Escrituracion__c",
    Label: "Porcentaje Pago Escrituración",
  },
  {
    Api_name: "PorcentajePagoAdenda1__c",
    Label: "Porcentaje Pago Adenda 1",
  },
  {
    Api_name: "PorcentajePagoAdenda2__c",
    Label: "Porcentaje Pago Adenda 2",
  },
  {
    Api_name: "Portales_Publicados__c",
    Label: "Portales Publicados",
  },
  {
    Api_name: "Postventa_Historica__c",
    Label: "Postventa Histórica",
  },
  {
    Api_name: "Precio_Desde__c",
    Label: "Precio Desde",
  },
  {
    Api_name: "Precio_Hasta__c",
    Label: "Precio Hasta",
  },
  {
    Api_name: "ProductosOfrecidos__c",
    Label: "Productos ofrecidos",
  },
  {
    Api_name: "PromedioHabitaciones__c",
    Label: "Promedio (habitaciones)",
  },
  {
    Api_name: "Promocion_formula__c",
    Label: ">",
  },
  {
    Api_name: "Propiedad_Zona_Comun__c",
    Label: "Propiedad Zona Común",
  },
  {
    Api_name: "Provincia_pv_formula__c",
    Label: "Provincia (pv)",
  },
  {
    Api_name: "Prueba1__c",
    Label: "Prueba1",
  },
  {
    Api_name: "Punto_de_Venta__c",
    Label: "Punto de Venta",
  },
  {
    Api_name: "Punto_de_venta_en_obra__c",
    Label: "Punto de venta en obra",
  },
  {
    Api_name: "Punto_de_venta_nuevo__c",
    Label: "Punto de venta",
  },
  {
    Api_name: "Punto_Venta_Codigo_Comunidad__c",
    Label: "Código Comunidad Autónoma (pv)",
  },
  {
    Api_name: "Punto_Venta_Codigo_Distrito__c",
    Label: "Código Distrito (pv)",
  },
  {
    Api_name: "Punto_Venta_Codigo_Municipio__c",
    Label: "Código Municipio (pv)",
  },
  {
    Api_name: "Punto_Venta_Codigo_Pais__c",
    Label: "Código País (pv)",
  },
  {
    Api_name: "Punto_Venta_Codigo_Provincia__c",
    Label: "Código Provincia (pv)",
  },
  {
    Api_name: "Punto_Venta_Coordenadas__c",
    Label: "Coordenadas (pv)",
  },
  {
    Api_name: "Punto_Venta_Coordenadas__Latitude__s",
    Label: "Coordenadas (pv) (Latitud)",
  },
  {
    Api_name: "Punto_Venta_Coordenadas__Longitude__s",
    Label: "Coordenadas (pv) (Longitud)",
  },
  {
    Api_name: "Punto_Venta_Distrito__c",
    Label: "Distrito (pv)",
  },
  {
    Api_name: "Punto_Venta_Google_Maps__c",
    Label: "Google Maps (pv)",
  },
  {
    Api_name: "Punto_Venta_Horario__c",
    Label: "Horario (pv)",
  },
  {
    Api_name: "Punto_Venta_Horario_ENG__c",
    Label: "Horario ENG (pv)",
  },
  {
    Api_name: "Punto_Venta_Municipio__c",
    Label: "Población (pv)",
  },
  {
    Api_name: "Punto_Venta_Nombre_De_Via__c",
    Label: "Nombre de vía (pv)",
  },
  {
    Api_name: "Punto_Venta_Numero__c",
    Label: "Numero/s (pv)",
  },
  {
    Api_name: "Punto_Venta_Pais__c",
    Label: "País (pv)",
  },
  {
    Api_name: "Punto_Venta_Provincia__c",
    Label: "Provincia (pv)",
  },
  {
    Api_name: "Punto_Venta_Sigla_Idioma__c",
    Label: "Sigla Idioma (pv)",
  },
  {
    Api_name: "Punto_Venta_Tipo_De_Via__c",
    Label: "Tipo de vía (pv)",
  },
  {
    Api_name: "PuttinGreen__c",
    Label: "Puttin Green",
  },
  {
    Api_name: "Reasignar_consulta_escriturada__c",
    Label: "Reasignar consulta escriturada",
  },
  {
    Api_name: "Resp_Comercial_DCM__c",
    Label: "Resp. Comercial DCM",
  },
  {
    Api_name: "Resp_Marketing_DCM__c",
    Label: "Resp. Marketing DCM",
  },
  {
    Api_name: "Responsable_Constructora__c",
    Label: "Responsable Constructora",
  },
  {
    Api_name: "Responsable_Reparadora__c",
    Label: "Responsable Reparadora",
  },
  {
    Api_name: "Reuni_n_DCM__c",
    Label: "Reunión DCM",
  },
  {
    Api_name: "Rocodromo__c",
    Label: "Rocódromo",
  },
  {
    Api_name: "Sala_de_cine__c",
    Label: "Sala de cine",
  },
  {
    Api_name: "SaladeFitness__c",
    Label: "Sala de Fitness",
  },
  {
    Api_name: "SalaMultiusosconProyector__c",
    Label: "Sala Multiusos con Proyector",
  },
  {
    Api_name: "Sector__c",
    Label: "Sector",
  },
  {
    Api_name: "Sigla_Idioma__c",
    Label: "Sigla Idioma",
  },
  {
    Api_name: "Situacion__c",
    Label: "Situación",
  },
  {
    Api_name: "Sociedad__c",
    Label: "Sociedad",
  },
  {
    Api_name: "Suelo_Radiante__c",
    Label: "Suelo radiante",
  },
  {
    Api_name: "Suelo_Radiante_Refrescante__c",
    Label: "Suelo radiante y refrescante",
  },
  {
    Api_name: "Sup_Auxiliar_1__c",
    Label: "Sup. Auxiliar 1",
  },
  {
    Api_name: "Sup_Auxiliar_2__c",
    Label: "Sup. Auxiliar 2",
  },
  {
    Api_name: "Sup_Auxiliar_3__c",
    Label: "Sup. Auxiliar 3",
  },
  {
    Api_name: "Sup_Desde__c",
    Label: "Sup. Desde",
  },
  {
    Api_name: "Sup_Hasta__c",
    Label: "Sup. Hasta",
  },
  {
    Api_name: "Superficie_Maxima__c",
    Label: "Superficie máxima",
  },
  {
    Api_name: "Superficie_Minima__c",
    Label: "Superficie mínima",
  },
  {
    Api_name: "SystemModstamp",
    Label: "Modstamp del sistema",
  },
  {
    Api_name: "T_tulo_de_propiedad_del_inmueble__c",
    Label: "Título de propiedad del inmueble",
  },
  {
    Api_name: "Taquillas_inteligentes__c",
    Label: "Taquillas inteligentes",
  },
  {
    Api_name: "Tecnico_Territorial__c",
    Label: "Técnico de Territorial",
  },
  {
    Api_name: "Telefono__c",
    Label: "Teléfono",
  },
  {
    Api_name: "Telefono_1__c",
    Label: "Teléfono 1",
  },
  {
    Api_name: "Telefono_2__c",
    Label: "Teléfono 2",
  },
  {
    Api_name: "Telefono_responsable_comercializadora__c",
    Label: "Teléfono responsable comercializadora",
  },
  {
    Api_name: "Terminos_de_la_compraventa_DIA__c",
    Label: "Términos de la compraventa (DIA)",
  },
  {
    Api_name: "Tiene_Alarma__c",
    Label: "Alarma",
  },
  {
    Api_name: "Tiene_Ascensor__c",
    Label: "Ascensor",
  },
  {
    Api_name: "Tiene_Ascensor_interior__c",
    Label: "Ascensor interior",
  },
  {
    Api_name: "Tiene_Bano_huespedes__c",
    Label: "Baño huéspedes",
  },
  {
    Api_name: "Tiene_Barbacoa__c",
    Label: "Barbacoa",
  },
  {
    Api_name: "Tiene_Bodega__c",
    Label: "Bodega",
  },
  {
    Api_name: "Tiene_Campo_Golf__c",
    Label: "Campo Golf",
  },
  {
    Api_name: "Tiene_Casa_invitados__c",
    Label: "Casa de invitados",
  },
  {
    Api_name: "Tiene_CCTV__c",
    Label: "CCTV 24h",
  },
  {
    Api_name: "Tiene_Club_Social__c",
    Label: "Club Social",
  },
  {
    Api_name: "Tiene_Conserjeria__c",
    Label: "Conserjeria",
  },
  {
    Api_name: "Tiene_Cuarto_plancha__c",
    Label: "Cuarto plancha",
  },
  {
    Api_name: "Tiene_Energia_Solar__c",
    Label: "Energía Solar",
  },
  {
    Api_name: "Tiene_fecha_prevista__c",
    Label: "Tiene fecha prevista",
  },
  {
    Api_name: "Tiene_Garaje_Privado__c",
    Label: "Garaje Privado",
  },
  {
    Api_name: "Tiene_Gastrobar__c",
    Label: "Gastrobar",
  },
  {
    Api_name: "Tiene_Gimnasio__c",
    Label: "Gimnasio",
  },
  {
    Api_name: "Tiene_Instalaciones_Deportivas__c",
    Label: "Otras Instalaciones Deportivas",
  },
  {
    Api_name: "Tiene_Jardin__c",
    Label: "Jardín",
  },
  {
    Api_name: "Tiene_Kiosko_exterior__c",
    Label: "Kiosko exterior con barra y pérgola",
  },
  {
    Api_name: "Tiene_Muebles_diseno__c",
    Label: "Muebles de diseño",
  },
  {
    Api_name: "Tiene_Musica_ambiental__c",
    Label: "Música ambiental",
  },
  {
    Api_name: "Tiene_Otros_Servicios_Comunes__c",
    Label: "Otros servicios comunes",
  },
  {
    Api_name: "Tiene_Padel__c",
    Label: "Pádel",
  },
  {
    Api_name: "Tiene_Parking_Comunitario__c",
    Label: "Parking Comunitario",
  },
  {
    Api_name: "Tiene_Patio__c",
    Label: "Patio",
  },
  {
    Api_name: "Tiene_Piscina__c",
    Label: "Piscina",
  },
  {
    Api_name: "Tiene_Piscina_Cubierta__c",
    Label: "Piscina Cubierta",
  },
  {
    Api_name: "Tiene_Piscina_Infantil__c",
    Label: "Piscina Infantil",
  },
  {
    Api_name: "Tiene_Pista_Running__c",
    Label: "Pista Running",
  },
  {
    Api_name: "Tiene_Pitch_Putt__c",
    Label: "Pitch & Putt",
  },
  {
    Api_name: "Tiene_Plaza_Exterior__c",
    Label: "Plaza exterior",
  },
  {
    Api_name: "Tiene_Primera_linea_playa__c",
    Label: "Primera línea de playa",
  },
  {
    Api_name: "Tiene_Puerta_De_Seguridad__c",
    Label: "Puerta de Seguridad",
  },
  {
    Api_name: "Tiene_Punto_De_Venta_En_Obra__c",
    Label: "Punto de venta en obra",
  },
  {
    Api_name: "Tiene_Sala_Comunitaria__c",
    Label: "Sala Común",
  },
  {
    Api_name: "Tiene_Sala_Gourmet__c",
    Label: "Sala Gourmet",
  },
  {
    Api_name: "Tiene_Sala_Multiusos__c",
    Label: "Sala Multiusos",
  },
  {
    Api_name: "Tiene_Sala_Para_Bicicletas__c",
    Label: "Sala bicicletas",
  },
  {
    Api_name: "Tiene_Sauna__c",
    Label: "Sauna",
  },
  {
    Api_name: "Tiene_Servicio_porteria__c",
    Label: "Servicio Porteria",
  },
  {
    Api_name: "Tiene_Solarium__c",
    Label: "Solárium",
  },
  {
    Api_name: "Tiene_Spa__c",
    Label: "Spa",
  },
  {
    Api_name: "Tiene_Tenis__c",
    Label: "Tenis",
  },
  {
    Api_name: "Tiene_Trastero__c",
    Label: "Trastero",
  },
  {
    Api_name: "Tiene_Vigilancia_privada__c",
    Label: "Vigilancia privada",
  },
  {
    Api_name: "Tiene_Vistas_a_la_montana__c",
    Label: "Vistas a la montaña",
  },
  {
    Api_name: "Tiene_Vistas_al_mar__c",
    Label: "Vistas al mar",
  },
  {
    Api_name: "Tiene_Yoga__c",
    Label: "Yoga",
  },
  {
    Api_name: "Tiene_Zona_Infantil__c",
    Label: "Zona Infantil",
  },
  {
    Api_name: "Tiene_ZZCC_Ajardinadas__c",
    Label: "ZZCC Ajardinadas",
  },
  {
    Api_name: "Tipo_Certificado_Energetico__c",
    Label: "Tipo Cert. Energético",
  },
  {
    Api_name: "Tipo_de_calidad__c",
    Label: "Tipo de calidad",
  },
  {
    Api_name: "Tipo_de_Contrato_de_Arras__c",
    Label: "Tipo de Contrato de Arras",
  },
  {
    Api_name: "Tipo_de_impuesto__c",
    Label: "Tipo de impuesto",
  },
  {
    Api_name: "Tipo_De_Instalaciones_Deportivas__c",
    Label: "Tipo de Instalaciones Deportivas",
  },
  {
    Api_name: "Tipo_De_Interes__c",
    Label: "Tipo de Interés",
  },
  {
    Api_name: "Tipo_de_Maqueta__c",
    Label: "Tipo de Maqueta",
  },
  {
    Api_name: "Tipo_de_punto_de_venta__c",
    Label: "Tipo de punto de venta",
  },
  {
    Api_name: "Tipo_De_Sala_Comunitaria__c",
    Label: "Tipo de Sala Comunitaria",
  },
  {
    Api_name: "Tipo_de_uso__c",
    Label: "Tipo de uso",
  },
  {
    Api_name: "Tipo_de_via_pv__c",
    Label: "Tipo de vía (pv)",
  },
  {
    Api_name: "Tipo_de_vivienda__c",
    Label: "Tipo de vivienda",
  },
  {
    Api_name: "Tipo_Fecha_Entrega__c",
    Label: "Tipo Fecha Entrega",
  },
  {
    Api_name: "Tipo_Fecha_Inicio_Obras__c",
    Label: "Tipo Fecha Inicio Obras",
  },
  {
    Api_name: "Titular_Castellano__c",
    Label: "Titular Castellano",
  },
  {
    Api_name: "Titular_Catalan__c",
    Label: "Titular Catalán",
  },
  {
    Api_name: "Titular_Ingles__c",
    Label: "Titular Inglés",
  },
  {
    Api_name: "Total_Solar__c",
    Label: "Total Solar",
  },
  {
    Api_name: "Trasteros_Totales__c",
    Label: "Trasteros Totales",
  },
  {
    Api_name: "UltimaSincronizacion__c",
    Label: "Última sincronización",
  },
  {
    Api_name: "Unidades__c",
    Label: "Unidades",
  },
  {
    Api_name: "URL_Castillete_3D__c",
    Label: "URL Castillete 3D",
  },
  {
    Api_name: "URL_externa_promocion__c",
    Label: "URL externa promoción",
  },
  {
    Api_name: "URL_Maqueta__c",
    Label: "URL Maqueta",
  },
  {
    Api_name: "URL_muestras_en_punto_de_venta__c",
    Label: "URL muestras en punto de venta",
  },
  {
    Api_name: "URL_Piso_piloto__c",
    Label: "URL Piso piloto",
  },
  {
    Api_name: "URL_Piso_tecnico__c",
    Label: "URL Piso técnico",
  },
  {
    Api_name: "URL_tipo_de_punto_de_venta__c",
    Label: "URL tipo de punto de venta",
  },
  {
    Api_name: "URL_Web_AEDAS__c",
    Label: "URL Web AEDAS",
  },
  {
    Api_name: "Uso_principal__c",
    Label: "Uso principal",
  },
  {
    Api_name: "Valided_presupuesto__c",
    Label: "Validez presupuesto (días)",
  },
  {
    Api_name: "VallaPerimetraldeSeguridad__c",
    Label: "Valla Perimetral de Seguridad",
  },
  {
    Api_name: "Venta_en_PV_multiproducto__c",
    Label: "Venta en PV multiproducto",
  },
  {
    Api_name: "Ventilacion_de_doble_flujo_con_recuper__c",
    Label: "Ventilacion de doble flujo con recuper",
  },
  {
    Api_name: "Video_comercial__c",
    Label: "Vídeo comercial",
  },
  {
    Api_name: "Video_de_avance_de_obras__c",
    Label: "Vídeo de avance de obras",
  },
  {
    Api_name: "Videoportero__c",
    Label: "Videoportero",
  },
  {
    Api_name: "Viviendas_Totales__c",
    Label: "Viviendas Totales",
  },
  {
    Api_name: "Web_internacional__c",
    Label: "Web internacional",
  },
  {
    Api_name: "WEBSITE__c",
    Label: "WEBSITE",
  },
  {
    Api_name: "YaExiste__c",
    Label: "YaExiste",
  },
  {
    Api_name: "z_Asunto_FechaHora__c",
    Label: "z_Asunto_FechaHora",
  },
  {
    Api_name: "z_Direccion__c",
    Label: "z(Oculto)Dirección",
  },
  {
    Api_name: "Zona_Comun_Ludica__c",
    Label: "Zona Común Lúdica",
  },
  {
    Api_name: "Zona_idealista__c",
    Label: "Zona idealista",
  },
];
