import { Card } from "@nextui-org/react";
import { BsArrow90DegRight } from "react-icons/bs";
import { TableTestAppoinment } from "../../Pages/Home/tables/TableTestAppoinment";
import moment from "moment";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export const LastAppointments = (props: any) => {
  const history = useHistory();

  const { appointments, appointmentsLasWeek } = props;

  const notifyNoMoreAppointments = () =>
    toast.info("No se han encontrado citas", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  return (
    <Card
      css={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "1rem",
          width: "100%",
          alignItems: "end",
          justifyContent: "end",
          padding: "10px",
        }}
      >
        <Card
          isHoverable={appointments?.length != 0}
          css={{
            backgroundColor:
              appointments?.length === 0 ? "grey" : "var(--secondary-color)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50px",
            width: "200px",
            cursor: appointments?.length === 0 ? "not-allowed" : "pointer",
          }}
        >
          <div
            onClick={() => {
              if (appointments?.length > 1) {
                let orderedAppointments = appointments?.sort(
                  (a: any, b: any) => {
                    if (moment(a.dateInitial).isAfter(moment(b.dateInitial))) {
                      return 1;
                    } else if (
                      moment(a.dateInitial).isBefore(moment(b.dateInitial))
                    ) {
                      return -1;
                    }
                    return 0;
                  }
                );

                let salesforceId = orderedAppointments.find(
                  (a: any) =>
                    moment(a.dateInitial).format("DD-MM-YYYY HH:mm:ss") >
                    moment()
                      .subtract(30, "seconds")
                      .format("DD-MM-YYYY HH:mm:ss")
                ).id;

                if (salesforceId) {
                  history.push("/appointment/" + salesforceId);
                } else {
                  notifyNoMoreAppointments();
                }
              } else {
                notifyNoMoreAppointments();
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1
              style={{
                color: "white",
                fontSize: "18px",
                paddingTop: "10px",
              }}
            >
              Siguiente Cita
            </h1>
            <BsArrow90DegRight size={30} color={"white"}></BsArrow90DegRight>
          </div>
        </Card>
      </div>
      <hr
        style={{
          marginTop: 0,
        }}
      ></hr>
      <div
        style={{
          padding: "10px",
          height: "calc(100% - 8px)",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <h5>Citas Realizadas</h5>
        <TableTestAppoinment row={appointmentsLasWeek}></TableTestAppoinment>
      </div>
    </Card>
  );
};
