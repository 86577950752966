import { Loading } from "@nextui-org/react";
import "./AedasLoading.css";

export default function AedasLoading() {
  return (
    <div
      style={{ minHeight: "100vh" }}
      className="d-flex justify-content-center w-100 h-100 align-items-center"
    >
      <Loading size="xl"></Loading>
    </div>
  );
}
