import { Card } from "@nextui-org/react";

import { MdErrorOutline } from "react-icons/md";
import { writeCorrectNameOfPromotion } from "../../Utils/commonUtils";
import { PdfRender } from "../PdfRender/PdfRender";
import { getTax } from "../../Utils/taxUtils";
import { formatNumber } from "../../Utils/formatNumber";

function AvaiableProductClientView(props: any) {
  const { asset, tax, selectedHouse } = props;

  return (
    <Card
      className="AvailableProduct_tsx available-product"
      variant="bordered"
      style={{
        width: "auto", // 300px
        height: "300px",
        backgroundColor: selectedHouse ? "#c5edf9" : "white",
      }}
      isHoverable
    >
      <div
        style={{
          display: "flex",
          padding: "0.7rem",
          flexDirection: "column",
          gap: "0.7rem",
        }}
      >
        <div className="d-flex flex-column" style={{ width: "100%" }}>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column gap-2">
              <span
                className="portal-adress-and-house"
                style={{
                  textAlign: "left",
                  color: "var(--secondary-color)",
                  fontFamily: "lis-title",
                  fontSize: "25px",
                  lineHeight: "1",
                }}
              >
                P{asset.portal_address}-{asset.address + asset.house_n.at(-1)}
              </span>
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <p
                style={{
                  marginBottom: "0px",
                  fontFamily: "lis-title",
                  fontSize: "22px",
                  lineHeight: "1",
                }}
              >
                {asset.m2_built.toFixed(2)}m<span className="sup">2</span>
              </p>
            </div>
          </div>
          <div
            className="d-flex"
            style={{
              fontFamily: "lis-body",
              fontSize: "12px",
              paddingLeft: "2px",
              lineHeight: "1",
            }}
          >
            {writeCorrectNameOfPromotion(asset.promotion_name)}
          </div>
        </div>

        {asset?.assets?.find((a: any) => a.asset_name.includes("Plano"))
          ?.url ? (
          <div style={{ width: "100%", height: "90px" }}>
            <PdfRender
              url={
                asset?.assets?.find((a: any) => a.asset_name.includes("Plano"))
                  ?.url ?? ""
              }
            ></PdfRender>
          </div>
        ) : (
          <div style={{ width: "100%", height: "90px" }}>
            <div
              className="d-flex justify-content-center align-items-center h-100"
              style={{ backgroundColor: "#fafafa" }}
            >
              <MdErrorOutline size={24} color={"#666"} /> Sin planos
            </div>
          </div>
        )}
        <div
          className="d-flex flex-column justify-content-between"
          style={{ height: "90px" }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              marginTop: "0px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "0.2rem",
                flexWrap: "wrap",
                width: "100%",
                height: "52px",
              }}
            >
              <span
                style={{
                  padding: "0.2rem",
                  background: "#8080802b",
                  borderRadius: "10px",
                  fontFamily: "lis-body",
                  fontSize: "12px",
                  height: "24px",
                }}
              >
                {asset.bathrooms} baños
              </span>

              <span
                style={{
                  padding: "0.2rem",
                  background: "#8080802b",
                  borderRadius: "10px",
                  fontFamily: "lis-body",
                  fontSize: "12px",
                  height: "24px",
                }}
              >
                {asset.bedrooms} dormitorios
              </span>
              {asset.storage_room ? (
                <span
                  style={{
                    padding: "0.2rem",
                    background: "#8080802b",
                    borderRadius: "10px",
                    fontFamily: "lis-body",
                    fontSize: "12px",
                    height: "24px",
                  }}
                >
                  {asset.storage_room} trastero/s
                </span>
              ) : (
                <></>
              )}
              {asset.parking_slots ? (
                <span
                  style={{
                    padding: "0.2rem",
                    background: "#8080802b",
                    borderRadius: "10px",
                    fontFamily: "lis-body",
                    fontSize: "12px",
                    height: "24px",
                  }}
                >
                  {asset.parking_slots} garaje/s
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              gap: 5,
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {/* <div
              style={{
                fontFamily: "lis-title",
                fontSize: "14px",
              }}
            >
              Desde{" "}
            </div> */}
            <div
              style={{
                fontFamily: "lis-body",
                fontSize: "27px",
                lineHeight: "42px",
              }}
            >
              {
                tax.isTax
                  ? new Intl.NumberFormat().format(asset.starting_price) // Formatear el precio con impuestos
                  : new Intl.NumberFormat().format(
                      asset.only_house_price_wo_iva
                    ) // Formatear el precio sin impuestos
              }
              €
            </div>
            <div
              style={{
                width: 50,
                fontFamily: "lis-title",
                fontSize: "10px",
                marginLeft: "7px",
                textAlign: "left",
              }}
            >
              {getTax(asset, tax)}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: 5,
          }}
        >
          <div
            style={{
              fontFamily: "lis-title",
              fontSize: "10px",
            }}
          >
            Hipoteca aprox.
          </div>
          <div
            style={{
              fontFamily: "lis-body",
              fontSize: "14px",
              lineHeight: "42px",
            }}
          >
            {formatNumber(asset.mortgage.toFixed(0))}€/mes
          </div>
        </div>
      </div>
    </Card>
  );
}

export default AvaiableProductClientView;
