import axios from "axios";
/**
 * Obtiene el documento DIA necesario para las viviendas de las promociones de Andalucía
 */
export const getDIA = (idHousehold: any, type: string) => {
  // const data = {
  //   pInmueble: idHousehold.toString(),
  //   pTipo: type,
  // };
  const data = {
    pInmueble: "57211",
    pTipo: "DIA",
  };
  return axios.post(
    process.env.REACT_APP_API_BACKEND + `/Asset/getDIADocumentation/`,
    data
  );
};

export const sendDIA = (data: any) => {
  return axios
    .post(
      process.env.REACT_APP_API_BACKEND + "/Asset/sendSignedDIADocument/",
      data
    )
    .then((res) => res.data);
};
