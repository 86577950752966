export interface DataBudgetForm {
  value: number;
  label: string;
}

export const dataBudgetForm: DataBudgetForm[] = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 60000,
    label: "60.000",
  },
  {
    value: 90000,
    label: "90.000",
  },
  {
    value: 120000,
    label: "120.000",
  },
  {
    value: 150000,
    label: "150.000",
  },
  {
    value: 180000,
    label: "180.000",
  },
  {
    value: 210000,
    label: "210.000",
  },
  {
    value: 240000,
    label: "240.000",
  },
  {
    value: 270000,
    label: "270.000",
  },
  {
    value: 300000,
    label: "300.000",
  },
  {
    value: 360000,
    label: "360.000",
  },
  {
    value: 420000,
    label: "420.000",
  },
  {
    value: 480000,
    label: "480.000",
  },
  {
    value: 600000,
    label: "600.000",
  },
  {
    value: 1000000,
    label: "1 millón",
  },
  {
    value: 2000000,
    label: "2 millones",
  },
  {
    value: 3000000,
    label: "3 millones",
  },
];

export const dataIncomingMoney: DataBudgetForm[] = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 20000,
    label: "20.000",
  },
  {
    value: 45000,
    label: "45.000",
  },
  {
    value: 60000,
    label: "60.000",
  },
  {
    value: 75000,
    label: "75.000",
  },
  {
    value: 90000,
    label: "90.000",
  },
  {
    value: 120000,
    label: "120.000",
  },
  {
    value: 150000,
    label: "150.000",
  },
  {
    value: 180000,
    label: "180.000",
  },
  {
    value: 210000,
    label: "210.000",
  },
  {
    value: 240000,
    label: "240.000",
  },
  {
    value: 270000,
    label: "270.000",
  },
  {
    value: 300000,
    label: "300.000",
  },
  {
    value: 360000,
    label: "360.000",
  },
  {
    value: 420000,
    label: "420.000",
  },
  {
    value: 480000,
    label: "480.000",
  },
  {
    value: 600000,
    label: "600.000",
  },
  {
    value: 1000000,
    label: "1 millón",
  },
  {
    value: 2000000,
    label: "2 millones",
  },
  {
    value: 3000000,
    label: "3 millones",
  },
];
