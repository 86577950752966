import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const getAllAppointmentsByUserId = (userId: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + `/Appointment/today/${userId}`,
    getDefaultConfigHeader()
  );
};

export const getAllAppointmentsByUserIdAndDate = (props: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND +
      `/Appointment/byDate/${props.userId}/${props.date}`,
    getDefaultConfigHeader()
  );
};

export const getAllAppointmentsByUserIdLastWeek = (userId: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + `/Appointment/lastWeek/${userId}`,
    getDefaultConfigHeader()
  );
};

export const getAllAppointmentsByid = (userId: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + `/Appointment/salesforce/${userId}`,
    getDefaultConfigHeader()
  );
};

export const getClientByAppointmentId = (appointmentId: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + `/Appointment/client/${appointmentId}`,
    getDefaultConfigHeader()
  );
};

export const getPromoByAppointmentId = (appointmentId: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND +
      `/Appointment/promotion/${appointmentId}`,
    getDefaultConfigHeader()
  );
};
