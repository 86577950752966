import { useEffect, useMemo, useState } from "react";
import {
  deleteLikePromoAsset,
  getLikesService,
  setLikesPromotionService,
} from "../../Service/LikesService/LikesService";
import ImagenSlider from "../ImagenSlider/ImagenSlider";

export default function AllImgVideo(props: any) {
  const [index, setindex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [like, setLike] = useState<any>([]);

  const handleLike = (idAsset: any) => {
    setLikesPromotionService(props.idClient, idAsset).then(() => {
      setIsLoading(!isLoading);
    });
  };
  const handleDislike = (idLike: any) => {
    deleteLikePromoAsset(idLike).then(() => {
      setIsLoading(!isLoading);
    });
  };

  useEffect(() => {
    if (props.idClient && props.idPromotion) {
      getLikesService(props.idClient, props.idPromotion)
        .then((response) => {
          setLike(response.data);
        })
        .catch(() => {
          console.log("Hubo un error al obtener las imágenes favoritas");
        });
    }
  }, [props.idClient, props.idPromotion, isLoading]);

  useEffect(() => {
    props.setIndex(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const images = useMemo(() => {
    return props?.assets
      ?.map((a: any) => {
        if (a.name.includes("Video")) {
          return {
            thumbnail: props.img,
            original: props.img,
            embedUrl: a.value,
            idAsset: a.idAsset,
            video: true,
            order: a.order,
          };
        }
        return {
          thumbnail: a.value,
          original: a.value,
          idAsset: a.idAsset,
          video: false,
          order: a.order,
        };
      })
      .sort((a: any, b: any) => {
        if (a.order === null && b.order === null) return 0; // Ambos son null, no hay cambio en el orden
        if (a.order === null) return 1; // a tiene null, lo colocamos al final
        if (b.order === null) return -1; // b tiene null, lo colocamos al final
        return a.order - b.order; // Orden normal si ninguno es null
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.assets]);

  return (
    <div style={{ padding: "10px", alignItems: "center" }}>
      <h1 style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
        Se están mostrando las imágenes
      </h1>
      <div>
        <ImagenSlider
          like={like}
          handleLike={handleLike}
          handleDislike={handleDislike}
          images={images}
          index={index}
          setIndex={setindex}
          sendJson={props.sendJson}
        ></ImagenSlider>
      </div>
    </div>
  );
}
