import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const getAllAssetsByPromo = (promoId: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/Asset/promotion/" + promoId,
    getDefaultConfigHeader()
  );
};

// export const getAllFiltersAssets = (promoId: any, data: any) => {
export const getAllFiltersAssets = (props: any) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND +
      `/Asset/promotion/${props.promoId}/filters`,
    props.data,
    getDefaultConfigHeader()
  );
};

export const getAllProducts = (promoId: any, data: any) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND + `/Asset/promotion/${promoId}`,
    data,
    getDefaultConfigHeader()
  );
};

export const getPromotionDocuments = (data: any) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND + `/Asset/promotion_docs`,
    data,
    getDefaultConfigHeader()
  );
};
