const PillFeature = (props: any) => {
  return (
    <>
      {props.name && (
        <div>
          {props.notFound ? (
            <span
              className="shadow-sm"
              style={{
                backgroundColor: "#f4f4f4",
                color: "#333",
                border: "2px solid #eeb205",
                padding: "2px 6px",
                borderRadius: "10px",
                fontSize: "0.9rem",
              }}
            >
              {props.name}
            </span>
          ) : (
            <span
              className="shadow-sm"
              style={{
                backgroundColor: "#f4f4f4",
                color: props.match ? "var(--secondary-color)" : "#333",
                border: props.match
                  ? "2px solid var(--secondary-color)"
                  : "2px solid #f4f4f4",
                padding: "2px 6px",
                borderRadius: "10px",
                fontSize: "0.9rem",
              }}
            >
              {props.name}
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default PillFeature;
