export default function MapFunction(props: any) {
  return (
    <iframe
      title="Mapa"
      width="100%"
      height="100%"
      id="gmap_canvas"
      src={props?.assets[0]?.value}
    ></iframe>
  );
}
