import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Collapse } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { AiOutlineEuroCircle } from "react-icons/ai";
import { plazoDeEntrega } from "../../../Utils/MultiSelectLists";
import MultipleSelect from "../../MultipleSelect/MultipleSelect";
import { DataBudgetForm, dataBudgetForm } from "./data/dataBudgetForm";
import { DataFinancingForm, dataFinancingForm } from "./data/dataFinancingForm";

function PaymentForm(props: any) {
  const { formik, setTipBudget } = props;

  const [minBudget, setMinBudget] = useState(
    formik.values.Presupuesto__c[0] ? formik.values.Presupuesto__c[0] : 0
  );

  const [maxBudget, setMaxBudget] = useState(
    formik.values.Presupuesto__c[1] ? formik.values.Presupuesto__c[1] : 0
  );

  const [minFinancing, setMinFinancing] = useState(
    formik.values.PerFinanciacionNecesario__c[0]
      ? formik.values.PerFinanciacionNecesario__c[0]
      : 0
  );

  const [maxFinancing, setMaxFinancing] = useState(
    formik.values.PerFinanciacionNecesario__c[1]
      ? formik.values.PerFinanciacionNecesario__c[1]
      : 0
  );

  const handleChangeMinBudget = (event: any) => {
    setMinBudget(event.target.value);
    formik.setFieldValue("Presupuesto__c", [event.target.value, maxBudget]);
    setTipBudget([event.target.value, maxBudget]);
  };

  const handleChangeMaxBudget = (event: any) => {
    setMaxBudget(event.target.value);
    formik.setFieldValue("Presupuesto__c", [minBudget, event.target.value]);
    setTipBudget([minBudget, event.target.value]);
  };

  const handleChangeMinFinancing = (event: any) => {
    setMinFinancing(event.target.value);
    formik.setFieldValue("PerFinanciacionNecesario__c", [
      event.target.value,
      maxFinancing,
    ]);
  };

  const handleChangeMaxFinancing = (event: any) => {
    setMaxFinancing(event.target.value);
    formik.setFieldValue("PerFinanciacionNecesario__c", [
      minFinancing,
      event.target.value,
    ]);
  };

  // Le damos forma de "array".
  useEffect(() => {
    formik.setFieldValue("PerFinanciacionNecesario__c", [
      minFinancing,
      maxFinancing,
    ]);
  }, []);

  return (
    <Collapse
      title='Forma de pago'
      contentLeft={<AiOutlineEuroCircle size={30} />}
    >
      <div
        className='d-flex align-items-center'
        style={{ width: "100%", gap: "25px", paddingTop: "10px" }}
      >
        <div className='mb-2'>
          <span className='dot-question-in-client-view'></span> Presupuesto
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "10px",
          marginRight: "20px",
        }}
      >
        <FormControl fullWidth>
          <InputLabel id='label-min-budget'>Desde</InputLabel>
          <Select
            onChange={handleChangeMinBudget}
            labelId='label-min-budget'
            value={minBudget}
            label='Desde'
          >
            {dataBudgetForm
              .filter((data: DataBudgetForm) => data.value <= maxBudget)
              .map((data: DataBudgetForm) => (
                <MenuItem value={data.value} key={data.value}>
                  {data.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id='label-max-budget'>Hasta</InputLabel>
          <Select
            onChange={handleChangeMaxBudget}
            labelId='label-max-budget'
            value={maxBudget}
            label='Hasta'
          >
            {dataBudgetForm
              .filter((data: DataBudgetForm) => data.value >= minBudget)
              .map((data: DataBudgetForm) => (
                <MenuItem value={data.value}>{data.label}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>

      <div
        className='d-flex align-items-center'
        style={{ width: "100%", gap: "25px", paddingTop: "10px" }}
      >
        <div className='mb-2'>
          <span className='dot-question-in-client-view'></span> Financiación
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "10px",
          marginRight: "20px",
        }}
      >
        <FormControl fullWidth>
          <InputLabel id='label-min-financing'>Desde</InputLabel>
          <Select
            labelId='label-min-financing'
            onChange={handleChangeMinFinancing}
            value={minFinancing}
            label='Desde'
          >
            {dataFinancingForm
              .filter((data: DataFinancingForm) => data.value <= maxFinancing)
              .map((data: DataFinancingForm) => (
                <MenuItem value={data.value}>{data.label}</MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id='label-max-financing'>Hasta</InputLabel>
          <Select
            labelId='label-max-financing'
            onChange={handleChangeMaxFinancing}
            value={maxFinancing}
            label='Hasta'
          >
            {dataFinancingForm
              .filter((data: DataFinancingForm) => data.value >= minFinancing)
              .map((data: DataFinancingForm) => (
                <MenuItem value={data.value}>{data.label}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>

      <div style={{ display: "flex" }}>
        <MultipleSelect
          values={formik.values.Plazo_de_entrega_deseado__c}
          title={"¿Qué plazo de entrega necesitas?"}
          displayedInClientView={true}
          onChange={(e: any) =>
            formik.setFieldValue("Plazo_de_entrega_deseado__c", e)
          }
          list={plazoDeEntrega}
        ></MultipleSelect>
      </div>
    </Collapse>
  );
}

export default PaymentForm;
