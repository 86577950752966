import { useEffect, useState } from "react";
import { getPromotionFeatures } from "../../Service/PromotionService/PromotionService";
import AedasLoading from "../AedasLoading/AedasLoading";

import { Card } from "@nextui-org/react";
import PillFeatures from "./PillFeatures";

const PromotionFeatures = (props: any) => {
  const { promotion, formik, sellerWindow, isOutAppointment } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [features, setFeatures] = useState();

  const [contMatches, setContMatches] = useState(0);

  useEffect(() => {
    if (promotion) {
      getPromotionFeatures(promotion?.id)
        .then((res) => setFeatures(res.data))
        .then(() => setIsLoading(false));
    }
  }, [promotion]);

  if (isLoading) {
    return (
      <div>
        <AedasLoading></AedasLoading>
      </div>
    );
  }

  return (
    <>
      {sellerWindow ? (
        <Card
          css={{ marginRight: "5px", width: isOutAppointment ? "50%" : "100%" }}
        >
          <div style={{ padding: " 0.5rem" }}>
            <span
              className="otras-caracteristicas"
              style={{
                fontFamily: "lis-title",
                fontSize: "17px",
                fontWeight: "bold",
                color: "var(--secondary-color)",
              }}
            >
              Otras características ({contMatches} de{" "}
              {formik.values.calidades_equipamiento_vivienda?.length})
            </span>
            <div>
              <PillFeatures
                features={features}
                formik={formik}
                contMatches={contMatches}
                setContMatches={setContMatches}
                sellerWindow={true}
              ></PillFeatures>
            </div>
          </div>
        </Card>
      ) : (
        <Card css={{ marginRight: "5px", bottom: 0, height: "43%" }}>
          <div style={{ padding: " 0.5rem" }}>
            <span
              className="otras-caracteristicas"
              style={{
                fontFamily: "lis-title",
                fontSize: "17px",
                fontWeight: "bold",
                color: "var(--secondary-color)",
              }}
            >
              Otras características
            </span>
            <div>
              <PillFeatures
                features={features}
                formik={formik}
                contMatches={contMatches}
                setContMatches={setContMatches}
                sellerWindow={false}
              ></PillFeatures>
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default PromotionFeatures;
