import { Autocomplete, TextField } from "@mui/material";
import { Button, Card } from "@nextui-org/react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPromotionsByAppointment } from "../../../Service/PromotionService/PromotionService";

export function PromotionSelector({ promotion, setPromotion }: any) {
  const { id_appoiment }: any = useParams();
  const [disabled, setDisabled] = useState(true);
  const [promotionsByAppoiment, setPromotionsByAppoiment] = useState([]);

  useEffect(() => {
    getPromotionsByAppointment(id_appoiment).then((res: any) =>
      setPromotionsByAppoiment(res.data)
    );
  }, []);

  const formik = useFormik({
    initialValues: {
      pormotionSelected: null,
    },
    onSubmit: (values: any) => {
      setPromotion(values.pormotionSelected);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} style={{ zIndex: "100" }}>
        <Card
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "7px",
            gap: "5px",
            overflow: "hidden",
          }}
        >
          <Autocomplete
            disableClearable
            id="pormotionSelected"
            loading={true}
            options={promotionsByAppoiment}
            sx={{ width: "100%" }}
            size="small"
            defaultValue={promotion}
            getOptionLabel={(option: any) => {
              return option.name;
            }}
            renderInput={(params: any) => (
              <TextField {...params} label="Promociones" />
            )}
            onChange={(e: any, value: any) => {
              setDisabled(false);
              formik.setFieldValue("pormotionSelected", value);
            }}
          />
          <Button
            size={"md"}
            disabled={disabled}
            style={{ minWidth: "0", borderRadius: "7px" }}
            type="submit"
          >
            Ir
          </Button>
        </Card>
      </form>
    </>
  );
}
