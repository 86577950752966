import { useEffect, useMemo, useState } from "react";
import ImagenSlider from "../ImagenSlider/ImagenSlider";

export default function AllImgVideo(props: any) {
  const [index, setindex] = useState(props.Index);

  useEffect(() => {
    setindex(props.Index);
  }, [props.Index]);

  const images = useMemo(() => {
    return props?.assets
      ?.map((a: any) => {
        if (a.name.includes("Video")) {
          return {
            thumbnail: props.img,
            original: props.img,
            embedUrl: a.value,
            idAsset: a.idAsset,
            video: true,
            order: a.order,
          };
        }
        return {
          thumbnail: a.value,
          original: a.value,
          idAsset: a.idAsset,
          video: false,
          order: a.order,
        };
      })
      .sort((a: any, b: any) => {
        if (a.order === null && b.order === null) return 0; // Ambos son null, no hay cambio en el orden
        if (a.order === null) return 1; // a tiene null, lo colocamos al final
        if (b.order === null) return -1; // b tiene null, lo colocamos al final
        return a.order - b.order; // Orden normal si ninguno es null
      });
  }, [props?.assets, props.img]);

  return (
    <div className='client-view'>
      <ImagenSlider index={index} isClient images={images}></ImagenSlider>
    </div>
  );
}
