import SwitchSellerClient from "./SwitchSellserClient";
//@ts-ignore

function SellerClient(props: any) {
  const { setIndex, sendJson, assets, idClient, idPromotion } = props;
  return (
    <SwitchSellerClient
      assets={assets}
      value={props.value}
      setIndex={setIndex}
      sendJson={sendJson}
      idClient={idClient}
      idPromotion={idPromotion}
    ></SwitchSellerClient>
  );
}

export default SellerClient;
