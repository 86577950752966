import * as jose from "jose";
import { useEffect, useState } from "react";
import { useQueryParam } from "./useQueryParam";

function useAuth() {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState<any>();
  const [aedasUser, setAedasUser] = useState<any>();
  const tokenURL = useQueryParam("token");
  const [isLogin, setIsLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleCheck = () => {
    //@ts-ignore
    let aux = JSON.parse(localStorage.getItem("aedas_user"));

    const JWKS = jose.createLocalJWKSet({
      keys: [
        {
          kty: "RSA",
          n: "mmrmDtZhG6U2OAa8jXTaHAMQZDS5rYRmHBUcCGhBZ-hupd4KnPjTstt39mlPQTBlwkPI-j11mnFVWmZaBEYkVmPFEJ601mEJkpOuTL3Mmj45g8Chk9_h97_YQuGbxc51_BtVbjs9nDbcTwD0RYQPYRFOMfIJdUJINeoKAVK3gyEF1N2AxHCSmE2zAyQOcMOahLy9Wp9epaZC_18BEv8HMHe2VbbCPGYz_fg1jr0jwla3AaXukbUu8RaG71pq-lccvmQ58hpiKAvdYi8qGuhb422LufQZ_q-7yqwAEhhgmRqATkT91OtkbonBmCADNLxi_2Nf7GaB8LAgM9WiNuG10Q",
          e: "AQAB",
          alg: "RS256",
          use: "sig",
        },
      ],
    });
    if (aux) {
      jose
        .jwtVerify(aux?.token, JWKS)
        .then((decoded: any) => {
          setUser(decoded.payload);
          setToken(aux?.token);
          setIsLogin(true);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setUser(null);
          setToken(null);
          setIsLogin(false);
          setIsLoading(false);
        });
    } else {
      setUser(null);
      setToken(null);
      setIsLogin(false);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const handleLocalStorageChange = () => {
      handleCheck();
    };

    window.addEventListener("storage", handleLocalStorageChange);

    return () => {
      window.removeEventListener("storage", handleLocalStorageChange);
    };
  }, []);

  useEffect(() => {
    if (tokenURL) {
      localStorage.setItem("aedas_user", tokenURL);
    }
    handleCheck();
  }, []);

  return { isLogin, user, token, isLoading };
}
export default useAuth;
