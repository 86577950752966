import { Card } from "@nextui-org/react";

//@ts-ignore
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { useEffect, useState } from "react";
import PromotionFeatures from "./PromotionFeatures";
import "./PromotionInfo.css";
import PromotionInfoDetail from "./PromotionInfoDetail";

function PromotionInfo(props: any) {
  const {
    promotion,
    formik,
    sellerWindow,
    tabCaracteristicas,
    setTabCaracteristicas,
  } = props;

  const [selectedIndex, setSelectedIndex] = useState(tabCaracteristicas);

  useEffect(() => {
    setSelectedIndex(tabCaracteristicas);
  }, [tabCaracteristicas]);

  return (
    <Card
      className="promotion-info-in-seller"
      css={{
        width: "100%",
        height: "100%",
        padding: "1px",
        alignItems: "center",
      }}
    >
      <Tabs
        style={{ overflow: "auto" }}
        selectedIndex={selectedIndex}
        onSelect={(tabIndex: any) => {
          setSelectedIndex(tabIndex);
          setTabCaracteristicas(0);
        }}
      >
        <TabList
          style={{
            paddingLeft: "0px",
            marginTop: "0px",
            marginLeft: "0px",
            marginRight: "0px",
            borderBottom: "1px solid #f4f4f4",
          }}
        >
          <Tab>Datos de la promoción</Tab>
          <Tab>Características</Tab>
        </TabList>

        <TabPanel>
          <PromotionInfoDetail promotion={promotion} />
        </TabPanel>
        <TabPanel style={{ height: "auto" }}>
          <PromotionFeatures
            promotion={promotion}
            formik={formik}
            sellerWindow={sellerWindow}
          />
        </TabPanel>
      </Tabs>
    </Card>
  );
}

export default PromotionInfo;
