import {
  AiFillCar,
  AiFillFire,
  AiFillShop,
  AiOutlineCloseCircle,
  AiOutlineDislike,
  AiOutlineExclamationCircle,
  AiOutlineLike,
} from "react-icons/ai";
import {
  BiBuilding,
  BiBuildingHouse,
  BiBus,
  BiCoffee,
  BiDislike,
  BiLike,
  BiSwim,
  BiTaxi,
  BiTrain,
  BiWind,
  BiWorld,
} from "react-icons/bi";
import {
  BsArrowUpRight,
  BsBoxSeam,
  BsBuilding,
  BsCalendarCheckFill,
  BsCameraReelsFill,
  BsCameraVideo,
  BsCart3,
  BsClock,
  BsDoorClosed,
  BsDoorOpen,
  BsEmojiFrown,
  BsEmojiNeutral,
  BsEmojiSmile,
  BsFillCalendarCheckFill,
  BsFillCalendarPlusFill,
  BsFillCheckCircleFill,
  BsFillHouseDoorFill,
  BsFillPeopleFill,
  BsFillPersonFill,
  BsFillSunFill,
  BsFillTreeFill,
  BsGoogle,
  BsHouse,
  BsHouseDoor,
  BsMailbox2,
  BsPeopleFill,
  BsPhone,
  BsQuestionLg,
  BsQuestionOctagon,
  BsSmartwatch,
  BsStars,
  BsTablet,
  BsTree,
} from "react-icons/bs";
import { CgGym, CgOptions, CgSmileNone } from "react-icons/cg";
import { CiLocationOn } from "react-icons/ci";
import {
  FaApple,
  FaBath,
  FaBed,
  FaBicycle,
  FaBuilding,
  FaCity,
  FaCompass,
  FaConciergeBell,
  FaHospital,
  FaLeaf,
  FaMapMarkerAlt,
  FaParking,
  FaPlaneDeparture,
  FaRegFutbol,
  FaRegHospital,
  FaSchool,
  FaSubway,
  FaSwimmingPool,
  FaTheaterMasks,
  FaTrain,
  FaUmbrellaBeach,
  FaUniversity,
  FaWarehouse,
  FaWind,
} from "react-icons/fa";
import {
  GiBathtub,
  GiBootPrints,
  GiCookingPot,
  GiElectric,
  GiElevator,
  GiFamilyHouse,
  GiForest,
  GiForkKnifeSpoon,
  GiGardeningShears,
  GiGroundSprout,
  GiHairStrands,
  GiHomeGarage,
  GiHouseKeys,
  GiIonicColumn,
  GiModernCity,
  GiQueenCrown,
  GiSandsOfTime,
  GiSmartphone,
  GiSoccerField,
  GiTennisRacket,
  GiWaterDrop,
} from "react-icons/gi";
import { HiAcademicCap } from "react-icons/hi";
import {
  HiBuildingOffice2,
  HiOutlineBuildingOffice2,
  HiOutlineHomeModern,
} from "react-icons/hi2";
import { ImLocation } from "react-icons/im";
import { IoMdResize } from "react-icons/io";
import {
  MdBeachAccess,
  MdDeck,
  MdDesignServices,
  MdDownhillSkiing,
  MdEast,
  MdElderly,
  MdFamilyRestroom,
  MdHolidayVillage,
  MdLocalMovies,
  MdNorth,
  MdOutlineCottage,
  MdOutlineDryCleaning,
  MdOutlineEmojiNature,
  MdOutlineFamilyRestroom,
  MdOutlineGolfCourse,
  MdOutlineLocalLaundryService,
  MdOutlineLocalPharmacy,
  MdOutlineRoomService,
  MdOutlineSchool,
  MdOutlineSportsBaseball,
  MdOutlineSportsTennis,
  MdOutlineWorkOff,
  MdRoofing,
  MdSchool,
  MdSecurity,
  MdSouth,
  MdSportsTennis,
  MdStoreMallDirectory,
  MdWest,
  MdWork,
} from "react-icons/md";
import {
  RiGasStationFill,
  RiHospitalLine,
  RiLayoutMasonryLine,
  RiNumber1,
  RiNumber2,
  RiNumber3,
} from "react-icons/ri";
import { SiGoogleearth, SiSamsung, SiXiaomi } from "react-icons/si";
import { SlEnergy } from "react-icons/sl";
import {
  TbBellSchool,
  TbBooks,
  TbDiamond,
  TbMoodKid,
  TbMoon2,
  TbMountain,
  TbQuestionMark,
  TbStairs,
  TbSwimming,
  TbToolsKitchen2,
} from "react-icons/tb";

export const zona = [
  {
    id: 1,
    salesforce_value: "Si",
    name: "Si",
    icon: <BiLike size={30}></BiLike>,
  },
  {
    id: 2,
    name: "No",
    salesforce_value: "No",
    icon: <BiDislike size={30}></BiDislike>,
  },
];

export const bloques = [
  {
    id: 1,
    salesforce_value: "Le gusta",
    name: "Le gusta",
    icon: <BiLike size={30}></BiLike>,
  },
  {
    id: 2,
    name: "No le gusta",
    salesforce_value: "No le gusta",
    icon: <BiDislike size={30}></BiDislike>,
  },
  {
    id: 3,
    name: "NSNC",
    salesforce_value: "NSNC",
    icon: <TbQuestionMark size={30}></TbQuestionMark>,
  },
];

export const zonas_verdes = [
  {
    id: 1,
    salesforce_value: "Le gusta",
    name: "Le gusta",
    icon: <BiLike size={30}></BiLike>,
  },
  {
    id: 2,
    name: "No le gusta",
    salesforce_value: "No le gusta",
    icon: <BiDislike size={30}></BiDislike>,
  },
  {
    id: 3,
    name: "NSNC",
    salesforce_value: "NSNC",
    icon: <TbQuestionMark size={30}></TbQuestionMark>,
  },
];

export const normal_response = [
  {
    id: 1,
    name: "Le encanta",
    color: "var(--aedas-green)",
    salesforce_value: "Le gusta",
    icon: <BsEmojiSmile size={30}></BsEmojiSmile>,
  },
  {
    id: 2,
    name: "Indiferente",
    color: "var(--secondary-color)",
    salesforce_value: "Indiferente",
    icon: <BsEmojiNeutral size={30}></BsEmojiNeutral>,
  },
  {
    id: 3,
    name: "No le gusta",
    color: "var(--aedas-orange)",
    salesforce_value: "No le gusta",
    icon: <BsEmojiFrown size={30}></BsEmojiFrown>,
  },

  {
    id: 4,
    name: "Objeción",
    color: "var(--aedas-red)",
    salesforce_value: "Objeción",
    icon: <AiOutlineExclamationCircle size={30}></AiOutlineExclamationCircle>,
  },
];

export const normal_response_without_objection = [
  {
    id: 1,
    name: "Le encanta",
    color: "var(--aedas-green)",
    salesforce_value: "Le gusta",
    icon: <BsEmojiSmile size={30}></BsEmojiSmile>,
  },
  {
    id: 2,
    name: "Indiferente",
    color: "var(--secondary-color)",
    salesforce_value: "Indiferente",
    icon: <BsEmojiNeutral size={30}></BsEmojiNeutral>,
  },
  {
    id: 3,
    name: "No le gusta",
    color: "var(--aedas-orange)",
    salesforce_value: "No le gusta",
    icon: <BsEmojiFrown size={30}></BsEmojiFrown>,
  },
];

export const acceso = [
  {
    id: 1,
    salesforce_value: "Le gusta",
    name: "Le gusta",
    icon: <BiLike size={30}></BiLike>,
  },
  {
    id: 2,
    name: "No le gusta",
    salesforce_value: "No le gusta",
    icon: <BiDislike size={30}></BiDislike>,
  },
  {
    id: 3,
    name: "NSNC",
    salesforce_value: "NSNC",
    icon: <TbQuestionMark size={30}></TbQuestionMark>,
  },
];

export const cercanias = [
  {
    id: 1,
    salesforce_value: "Le gusta",
    name: "Le gusta",
    icon: <BiLike size={30}></BiLike>,
  },
  {
    id: 2,
    name: "No le gusta",
    salesforce_value: "No le gusta",
    icon: <BiDislike size={30}></BiDislike>,
  },
  {
    id: 3,
    name: "NSNC",
    salesforce_value: "NSNC",
    icon: <TbQuestionMark size={30}></TbQuestionMark>,
  },
];

export const con_quien_compra = [
  {
    id: 1,
    salesforce_value: "Solo",
    name: "Solo",
    client: "solo",
    icon: <BsFillPersonFill size={30}></BsFillPersonFill>,
  },
  {
    id: 2,
    name: "Pareja",
    client: "pareja",
    salesforce_value: "Pareja",
    icon: <BsPeopleFill size={30}></BsPeopleFill>,
  },
  {
    id: 3,
    name: "Con un familiar",
    client: "con un familiar",
    salesforce_value: "Con un familiar",
    icon: <MdFamilyRestroom size={30}></MdFamilyRestroom>,
  },
  {
    id: 4,
    name: "Sociedad",
    client: "sociedad",
    salesforce_value: "Sociedad",
    icon: <GiFamilyHouse size={30}></GiFamilyHouse>,
  },
];

export const dis_bloques = [
  {
    id: 1,
    salesforce_value: "Le gusta",
    name: "Le gusta",
    icon: <BiLike size={30}></BiLike>,
  },
  {
    id: 2,
    name: "No le gusta",
    salesforce_value: "No le gusta",
    icon: <BiDislike size={30}></BiDislike>,
  },
  {
    id: 3,
    name: "NSNC",
    salesforce_value: "NSNC",
    icon: <TbQuestionMark size={30}></TbQuestionMark>,
  },
];

export const lista_zonas_acabados = [
  {
    id: 1,
    salesforce_value: "Acabados",
    name: "Acabados",
    icon: <BsStars size={30}></BsStars>,
  },
  {
    id: 2,
    name: "Accesos",
    salesforce_value: "Accesos",
    icon: <TbStairs size={30}></TbStairs>,
  },
  {
    id: 3,
    name: "Baños",
    salesforce_value: "Baños",
    icon: <FaBath size={30}></FaBath>,
  },
  {
    id: 4,
    name: "Claridad",
    salesforce_value: "Claridad",
    icon: <BsFillSunFill size={30}></BsFillSunFill>,
  },
  {
    id: 5,
    name: "Cocina",
    salesforce_value: "Cocina",
    icon: <TbToolsKitchen2 size={30}></TbToolsKitchen2>,
  },
  {
    id: 6,
    name: "Diseño",
    salesforce_value: "Diseño",
    icon: <MdDesignServices size={30}></MdDesignServices>,
  },
  {
    id: 7,
    name: "Distribución",
    salesforce_value: "Distribución",
    icon: <RiLayoutMasonryLine size={30}></RiLayoutMasonryLine>,
  },
  {
    id: 8,
    name: "Distribución",
    salesforce_value: "Distribución",
    icon: <RiLayoutMasonryLine size={30}></RiLayoutMasonryLine>,
  },
  {
    id: 9,
    name: "Eficiencia",
    salesforce_value: "Eficiencia",
    icon: <SlEnergy size={30}></SlEnergy>,
  },
  {
    id: 10,
    name: "Fachada",
    salesforce_value: "Fachada",
    icon: <FaBuilding size={30}></FaBuilding>,
  },
  {
    id: 11,
    name: "Garaje/Parking",
    salesforce_value: "Garaje/Parking",
    icon: <FaParking size={30}></FaParking>,
  },
  {
    id: 12,
    name: "Habitaciones",
    salesforce_value: "Habitaciones",
    icon: <FaBed size={30}></FaBed>,
  },
  {
    id: 13,
    name: "Zonas deportivas",
    salesforce_value: "Zonas deportivas",
    icon: <MdSportsTennis size={30}></MdSportsTennis>,
  },
  {
    id: 14,
    name: "Orientación",
    salesforce_value: "Orientación",
    icon: <FaCompass size={30}></FaCompass>,
  },
  {
    id: 15,
    name: "Otras zonas",
    salesforce_value: "Otras zonas",
    icon: <BsPeopleFill size={30}></BsPeopleFill>,
  },
  {
    id: 16,
    name: "Piscina",
    salesforce_value: "Piscina",
    icon: <FaSwimmingPool size={30}></FaSwimmingPool>,
  },
  {
    id: 17,
    name: "Portal",
    salesforce_value: "Portal",
    icon: <BsMailbox2 size={30}></BsMailbox2>,
  },
  {
    id: 18,
    name: "Seguridad",
    salesforce_value: "Seguridad",
    icon: <MdSecurity size={30}></MdSecurity>,
  },
  {
    id: 19,
    name: "Servicios cercanos",
    salesforce_value: "Servicios cercanos",
    icon: <FaSubway size={30}></FaSubway>,
  },
  {
    id: 20,
    salesforce_value: "Servicios cercanos",
    name: "Servicios cercanos",
    icon: <FaSubway size={30}></FaSubway>,
  },
  {
    id: 21,
    salesforce_value: "Servicios comunes",
    name: "Servicios comunes",
    icon: <MdOutlineRoomService size={30}></MdOutlineRoomService>,
  },
  {
    id: 22,
    salesforce_value: "Suelos",
    name: "Suelos",
    icon: <GiBootPrints size={30}></GiBootPrints>,
  },
  {
    id: 23,
    salesforce_value: "Superficie",
    name: "Superficie",
    icon: <IoMdResize size={30}></IoMdResize>,
  },
  {
    id: 24,
    salesforce_value: "Terraza",
    name: "Terraza",
    icon: <MdDeck size={30}></MdDeck>,
  },
  {
    id: 25,
    name: "Trastero",
    salesforce_value: "Trastero",
    icon: <BsBoxSeam size={30}></BsBoxSeam>,
  },
  {
    id: 26,
    name: "Ubicacion",
    salesforce_value: "Ubicacion",
    icon: <FaMapMarkerAlt size={30}></FaMapMarkerAlt>,
  },
  {
    id: 27,
    name: "Zonas infantiles",
    salesforce_value: "Zonas infantiles",
    icon: <TbMoodKid size={30}></TbMoodKid>,
  },
  {
    id: 28,
    name: "Zonas Verdes",
    salesforce_value: "Zonas Verdes",
    icon: <TbMoodKid size={30}></TbMoodKid>,
  },
];

export const listValue = [
  {
    id: 1,
    name: "Si",
    salesforce_value: "Si",
    icon: <BsFillCheckCircleFill size={30}></BsFillCheckCircleFill>,
  },
  {
    id: 2,
    name: "No",
    salesforce_value: "No",
    icon: <AiOutlineCloseCircle size={30}></AiOutlineCloseCircle>,
  },
];
export const paises = [
  {
    id: 1,
    name: "España",
    salesforce_value: "España",
    icon: <ImLocation size={30}></ImLocation>,
  },
  {
    id: 2,
    name: "Resto de paises",
    salesforce_value: "Resto de paises",
    icon: <BiWorld size={30}></BiWorld>,
  },
];
export const como_c = [
  {
    id: 1,
    name: "Recomendación",
    client: "a través de una recomendación",
    salesforce_value: "Me recomendaron",
    icon: <BsFillPeopleFill size={30}></BsFillPeopleFill>,
  },
  {
    id: 2,
    name: "Internet",
    client: "a través de internet",
    salesforce_value: "Por Internet",
    icon: <BsGoogle size={30}></BsGoogle>,
  },
  {
    id: 3,
    name: "Otra promoción",
    client: "porque visite otra promoción de AEDAS Homes",
    salesforce_value: "Por otras promociones de AEDAS Homes",
    icon: <BsBuilding size={30}></BsBuilding>,
  },
  {
    id: 4,
    name: "Otro",
    client: "por otros medios",
    salesforce_value: "Otros",
    icon: <BsQuestionLg size={30}></BsQuestionLg>,
  },
];

export const arquetipo = [
  {
    id: 1,
    name: "Agua",
    client: "Agua",
    salesforce_value: "Agua",
    icon: <GiWaterDrop size={20}></GiWaterDrop>,
  },
  {
    id: 2,
    name: "Aire",
    client: "Aire",
    salesforce_value: "Aire",
    icon: <BiWind size={20}></BiWind>,
  },
  {
    id: 3,
    name: "Tierra",
    client: "Tierra",
    salesforce_value: "Tierra",
    icon: <GiForest size={20}></GiForest>,
  },
  {
    id: 4,
    name: "Fuego",
    client: "Fuego",
    salesforce_value: "Fuego",
    icon: <AiFillFire size={30}></AiFillFire>,
  },
];

export const conoce_zona = [
  {
    id: 1,
    name: "Si",
    salesforce_value: "Si",
    client: "Conozco la zona",
    icon: <BsFillCheckCircleFill size={30}></BsFillCheckCircleFill>,
  },
  {
    id: 2,
    name: "No",
    color: "var(--aedas-red)",
    client: "No conozco la zona",
    salesforce_value: "No",
    icon: <AiOutlineCloseCircle size={30}></AiOutlineCloseCircle>,
  },
];

export const acabado = [
  {
    id: 1,
    name: "Urban",
    salesforce_value: "Urban",
    client: "Urban",
    icon: <BiBuildingHouse size={30}></BiBuildingHouse>,
  },
  {
    id: 2,
    name: "Natura",
    client: "Natura",
    salesforce_value: "Natura",
    icon: <MdOutlineEmojiNature size={30}></MdOutlineEmojiNature>,
  },
  {
    id: 3,
    name: "Elegance",
    client: "Elegance",
    salesforce_value: "Elegance",
    icon: <GiQueenCrown size={30}></GiQueenCrown>,
  },
];

export const Plazo_de_entrega_deseado__c = [
  {
    id: 1,
    name: "Imediato",
    salesforce_value: "Entrega inmediata",
    icon: <BsFillCalendarCheckFill size={30}></BsFillCalendarCheckFill>,
    client: "de forma imediata",
  },
  {
    id: 2,
    name: "Hasta un año",
    salesforce_value: "Hasta un año",
    icon: <BsClock size={30}></BsClock>,
    client: "en un plazo de un año",
  },
  {
    id: 3,
    name: "Indiferente",
    salesforce_value: "Indiferente",
    icon: <BsClock size={30}></BsClock>,
    client: "un periodo indiferente",
  },
];

export const ocupacion__c = [
  {
    id: 1,
    name: "Autonomo",
    salesforce_value: "Trabajador cuenta propia",
    icon: <BsFillPersonFill size={30}></BsFillPersonFill>,
    client: "autonomo",
  },
  {
    id: 2,
    name: "Trabajador",
    salesforce_value: "Trabajador cuenta ajena",
    icon: <MdWork size={30}></MdWork>,
    client: "trabajador cuenta ajena",
  },
  {
    id: 3,
    name: "Estudiante",
    salesforce_value: "Estudiante",
    icon: <HiAcademicCap size={30}></HiAcademicCap>,
    client: "estudiante",
  },
  {
    id: 4,
    name: "Jubilado",
    salesforce_value: "Jubilado",
    client: "jubilado",
    icon: <MdElderly size={30}></MdElderly>,
  },
  {
    id: 5,
    salesforce_value: "Empleado del hogar",
    client: "empleado del hogar",
    name: "Empleado hogar",
    icon: <MdOutlineDryCleaning size={30}></MdOutlineDryCleaning>,
  },
  {
    id: 6,
    name: "Paro",
    salesforce_value: "En paro",
    client: "paro",
    icon: <MdOutlineWorkOff size={30}></MdOutlineWorkOff>,
  },
  {
    id: 7,
    name: "Pensionista",
    salesforce_value: "Pensionista",
    client: "pensionista",
    icon: <MdOutlineWorkOff size={30}></MdOutlineWorkOff>,
  },
  {
    id: 8,
    name: "Otros",
    client: "otros",
    salesforce_value: "Otros",
    icon: <BsQuestionLg size={30}></BsQuestionLg>,
  },
];

export const alquiler = [
  {
    id: 1,
    name: "Si",
    salesforce_value: "Si",
    client: "de alquiler",
    icon: <BsFillCheckCircleFill size={30}></BsFillCheckCircleFill>,
  },
  {
    id: 2,
    name: "No",
    salesforce_value: "No",
    client: "no vivo de alquiler",
    icon: <AiOutlineCloseCircle size={30}></AiOutlineCloseCircle>,
  },
];

export const aficiones = [
  {
    id: 1,
    name: "Pádel",
    salesforce_value: "Pádel",
    icon: <GiTennisRacket size={30}></GiTennisRacket>,
  },
  {
    id: 2,
    name: "Tenis",
    salesforce_value: "Tenis",
    icon: <GiTennisRacket size={30}></GiTennisRacket>,
  },
  {
    id: 3,
    name: "Fútbol",
    salesforce_value: "Futbol",
    icon: <FaRegFutbol size={30}></FaRegFutbol>,
  },
  {
    id: 4,
    name: "Viajes",
    salesforce_value: "Viajes",
    icon: <FaPlaneDeparture size={30}></FaPlaneDeparture>,
  },
  {
    id: 5,
    name: "Cine",
    salesforce_value: "Cine",
    icon: <MdLocalMovies size={30}></MdLocalMovies>,
  },
  {
    id: 6,
    name: "Lectura",
    salesforce_value: "Lectura",
    icon: <TbBooks size={30}></TbBooks>,
  },
  {
    id: 7,
    name: "Golf",
    salesforce_value: "Golf",
    icon: <MdOutlineGolfCourse size={30}></MdOutlineGolfCourse>,
  },
  {
    id: 8,
    name: "Esquí",
    salesforce_value: "Esquí",
    icon: <MdDownhillSkiing size={30}></MdDownhillSkiing>,
  },
  {
    id: 9,
    name: "Otros",
    salesforce_value: "Otros",
    icon: <TbQuestionMark size={30}></TbQuestionMark>,
  },
];

export const tipo_de_unidad_familiar__pc = [
  {
    id: 1,
    name: "Pareja joven",
    salesforce_value: "Pareja joven",
    icon: <BsFillCheckCircleFill size={30}></BsFillCheckCircleFill>,
  },
  {
    id: 2,
    name: "P. Media edad",
    salesforce_value: "Pareja de mediana edad",
    icon: <AiOutlineCloseCircle size={30}></AiOutlineCloseCircle>,
  },
  {
    id: 3,
    name: "P. Tercera edad",
    salesforce_value: "Pareja de tercera edad",
    icon: <AiOutlineCloseCircle size={30}></AiOutlineCloseCircle>,
  },
  {
    id: 4,
    name: "Soltero",
    salesforce_value: "Single",
    icon: <AiOutlineCloseCircle size={30}></AiOutlineCloseCircle>,
  },
  {
    id: 5,
    name: "Divorciado",
    salesforce_value: "Divorciado",
    icon: <AiOutlineCloseCircle size={30}></AiOutlineCloseCircle>,
  },
  {
    id: 6,
    name: "Viudo",
    salesforce_value: "Viudo/a",
    icon: <AiOutlineCloseCircle size={30}></AiOutlineCloseCircle>,
  },
];

export const Vivienda_buscada__c = [
  {
    id: 1,
    name: "Bajo con jardín",
    salesforce_value: "Bajo con jardín",
    icon: <BsTree size={30}></BsTree>,
    client: "bajo con jardin",
  },
  {
    id: 2,
    name: "Bajo sin jardín",
    salesforce_value: "Bajo sin jardín",
    icon: <BsHouse size={30}></BsHouse>,
    client: "bajo sin jardin",
  },
  {
    id: 3,
    name: "Ático",
    salesforce_value: "Ático",
    icon: <MdRoofing size={30}></MdRoofing>,
    client: "atico",
  },
  {
    id: 4,
    name: "Piso sin terraza",
    salesforce_value: "Piso sin terraza",
    icon: <BsDoorClosed size={30}></BsDoorClosed>,
    client: "piso sin terraza",
  },
  {
    id: 5,
    name: "Piso con terraza",
    salesforce_value: "Piso con terraza",
    icon: <BsDoorOpen size={30}></BsDoorOpen>,
    client: "piso con terraza",
  },
  {
    id: 6,
    name: "Unifamiliar",
    salesforce_value: "Unifamiliar",
    client: "unifamiliar",
    icon: <BsHouseDoor size={30}></BsHouseDoor>,
  },
  {
    id: 7,
    salesforce_value: "Local comercial",
    client: "local comercial",
    name: "Local comercial",
    icon: <AiFillShop size={30}></AiFillShop>,
  },
  {
    id: 8,
    name: "Oficina",
    salesforce_value: "Oficina",
    client: "oficina",
    icon: <HiOutlineBuildingOffice2 size={30}></HiOutlineBuildingOffice2>,
  },
  {
    id: 9,
    name: "Garaje",
    client: "garaje",
    salesforce_value: "Garaje",
    icon: <GiHomeGarage size={30}></GiHomeGarage>,
  },
];

export const Garajes_necesarios__c = [
  {
    id: 1,
    name: "No necesito",
    salesforce_value: "No necesita plaza de parking.",
    icon: <AiOutlineCloseCircle size={30}></AiOutlineCloseCircle>,
    client: "no necesito",
  },
  {
    id: 2,
    name: "Una plaza",
    salesforce_value: "Necesita una plaza de parking.",
    icon: <RiNumber1 size={30}></RiNumber1>,
    client: "una plaza",
  },
  {
    id: 3,
    name: "Dos plazas",
    salesforce_value: "Necesita dos plazas de parking.",
    icon: <RiNumber2 size={30}></RiNumber2>,
    client: "dos plazas",
  },
  {
    id: 4,
    name: "Tres o más plazas",
    salesforce_value: "Necesita tres o más plazas de parking.",
    client: "tres o más plazas",
    icon: <RiNumber3 size={30}></RiNumber3>,
  },
];

export const DestinoVivienda__c = [
  {
    id: 1,
    name: "Primera compra",
    salesforce_value: "Vivienda Habitual - Primera Vivienda",
    icon: <BsFillHouseDoorFill size={30}></BsFillHouseDoorFill>,
    client: "primera vivienda - primera compra",
  },
  {
    id: 2,
    name: "Inversión",
    salesforce_value: "Inversión para un 3º",
    icon: <MdWork size={30}></MdWork>,
    client: "inversión",
  },
  {
    id: 3,
    name: "Reposición",
    salesforce_value: "Vivienda Habitual - Reposición",
    icon: <GiFamilyHouse size={30}></GiFamilyHouse>,
    client: "reposición",
  },
  {
    id: 4,
    name: "Segunda residencia",
    salesforce_value: "Segunda residencia",
    client: "segunda residencia",
    icon: <MdHolidayVillage size={30}></MdHolidayVillage>,
  },
];

export const plazoDeEntrega = [
  {
    id: 1,
    salesforce_value: "Entrega inmediata",
    name: "Inmediato",
    client: "inmediato",
    icon: <BsCalendarCheckFill size={30}></BsCalendarCheckFill>,
  },
  {
    id: 2,
    name: "Hasta la entrega",
    client: "hasta la entrega",
    salesforce_value: "Hasta la entrega",
    icon: <GiHouseKeys size={30}></GiHouseKeys>,
  },
  {
    id: 3,
    name: "Indiferente",
    client: "indiferente",
    salesforce_value: "Indiferente",
    icon: <GiSandsOfTime size={30}></GiSandsOfTime>,
  },
  {
    id: 4,
    name: "Largo plazo",
    client: "largo plazo",
    salesforce_value: "Largo plazo",
    icon: <BsFillCalendarPlusFill size={30}></BsFillCalendarPlusFill>,
  },
];

export const vivesDeAlquiler = [
  {
    id: 1,
    name: "Si",
    salesforce_value: "Si",
    client: "de alquiler",
    icon: <BsFillCheckCircleFill size={30}></BsFillCheckCircleFill>,
  },
  {
    id: 2,
    name: "No",
    salesforce_value: "No",
    client: "no vivo de alquiler",
    icon: <AiOutlineCloseCircle size={30}></AiOutlineCloseCircle>,
  },
];

export const Vivienda_actual_en_prop__pc = [
  {
    id: 1,
    name: "Si",
    salesforce_value: "Sí",
    client: "de alquiler",
    icon: <BsFillCheckCircleFill size={30}></BsFillCheckCircleFill>,
  },
  {
    id: 2,
    name: "No",
    salesforce_value: "No",
    client: "no vivo de alquiler",
    icon: <AiOutlineCloseCircle size={30}></AiOutlineCloseCircle>,
  },
];

export const conQuienCompra = [
  {
    id: 1,
    salesforce_value: "Solo",
    name: "Solo",
    client: "solo",
    icon: <BsFillPersonFill size={30}></BsFillPersonFill>,
  },
  {
    id: 2,
    name: "Pareja",
    client: "pareja",
    salesforce_value: "Pareja",
    icon: <BsPeopleFill size={30}></BsPeopleFill>,
  },
  {
    id: 3,
    name: "Con un familiar",
    client: "con un familiar",
    salesforce_value: "Con un familiar",
    icon: <MdFamilyRestroom size={30}></MdFamilyRestroom>,
  },
  {
    id: 4,
    name: "Sociedad",
    client: "sociedad",
    salesforce_value: "Sociedad",
    icon: <GiFamilyHouse size={30}></GiFamilyHouse>,
  },
];

export const gadgetsQueUsa = [
  {
    id: 1,
    name: "Smartphone",
    salesforce_value: "Smartphone",
    client: "smartphone",
    icon: <GiSmartphone size={30}></GiSmartphone>,
  },
  {
    id: 2,
    name: "Tablet",
    salesforce_value: "Tablet",
    client: "tablet",
    icon: <BsTablet size={30}></BsTablet>,
  },
  {
    id: 3,
    name: "Reloj inteligente",
    salesforce_value: "Reloj inteligente",
    client: "reloj inteligente",
    icon: <BsSmartwatch size={30}></BsSmartwatch>,
  },
];

export const marcasTecnologicas = [
  {
    id: 1,
    name: "Apple",
    salesforce_value: "Apple",
    client: "apple",
    icon: <FaApple size={30}></FaApple>,
  },
  {
    id: 2,
    name: "Samsung",
    salesforce_value: "Samsung",
    client: "samsung",
    icon: <SiSamsung size={30}></SiSamsung>,
  },
  {
    id: 3,
    name: "Xiaomi",
    salesforce_value: "Xiaomi",
    client: "xiaomi",
    icon: <SiXiaomi size={30}></SiXiaomi>,
  },
  {
    id: 4,
    name: "Oppo",
    salesforce_value: "Oppo",
    client: "oppo",
    icon: <BsPhone size={30}></BsPhone>,
  },
  {
    id: 5,
    name: "Otros",
    salesforce_value: "Otros",
    client: "otros",
    icon: <BsQuestionOctagon size={30}></BsQuestionOctagon>,
  },
];

export const orientacion = [
  {
    id: 1,
    name: "Norte",
    salesforce_value: "Norte",
    client: "norte",
    icon: <MdNorth size={30}></MdNorth>,
  },
  {
    id: 2,
    name: "Sur",
    salesforce_value: "Sur",
    client: "sur",
    icon: <MdSouth size={30}></MdSouth>,
  },
  {
    id: 3,
    name: "Este",
    salesforce_value: "Este",
    client: "este",
    icon: <MdEast size={30}></MdEast>,
  },
  {
    id: 4,
    name: "Oeste",
    salesforce_value: "Oeste",
    client: "oeste",
    icon: <MdWest size={30}></MdWest>,
  },
];

export const meGustaNoMeGustaNSNC = [
  {
    id: 1,
    name: "Le gusta",
    salesforce_value: "le_gusta",
    client: "le_gusta",
    icon: <AiOutlineLike size={30}></AiOutlineLike>,
  },
  {
    id: 2,
    name: "No le gusta",
    salesforce_value: "no_le_gusta",
    client: "no_le_gusta",
    icon: <AiOutlineDislike size={30}></AiOutlineDislike>,
  },
  {
    id: 3,
    name: "NS/NC",
    salesforce_value: "nsnc",
    client: "nsnc",
    icon: <CgSmileNone size={30}></CgSmileNone>,
  },
];

export const transporte = [
  {
    id: 1,
    name: "Tren",
    salesforce_value: "Tren",
    client: "tren",
    icon: <BiTrain size={30}></BiTrain>,
  },
  {
    id: 2,
    name: "Metro",
    salesforce_value: "Metro",
    client: "metro",
    icon: <FaTrain size={30}></FaTrain>,
  },
  {
    id: 3,
    name: "Taxi",
    salesforce_value: "Taxi",
    client: "taxi",
    icon: <BiTaxi size={30}></BiTaxi>,
  },
  {
    id: 4,
    name: "Autobús",
    salesforce_value: "Autobús",
    client: "autobús",
    icon: <BiBus size={30}></BiBus>,
  },
  {
    id: 5,
    name: "Coche",
    salesforce_value: "Coche",
    client: "coche",
    icon: <AiFillCar size={30}></AiFillCar>,
  },
];

export const educacion = [
  {
    id: 1,
    name: "Colegio público",
    salesforce_value: "Colegio público",
    client: "colegio público",
    icon: <FaSchool size={30}></FaSchool>,
  },
  {
    id: 2,
    name: "Colegio privado",
    salesforce_value: "Colegio privado",
    client: "colegio privado",
    icon: <MdSchool size={30}></MdSchool>,
  },
  {
    id: 3,
    name: "Guardería",
    salesforce_value: "Guardería",
    client: "guardería",
    icon: <MdOutlineFamilyRestroom size={30}></MdOutlineFamilyRestroom>,
  },
  {
    id: 4,
    name: "Instituto",
    salesforce_value: "Instituto",
    client: "instituto",
    icon: <MdOutlineSchool size={30}></MdOutlineSchool>,
  },
  {
    id: 5,
    name: "Universidad",
    salesforce_value: "Universidad",
    client: "universidad",
    icon: <FaUniversity size={30}></FaUniversity>,
  },
  {
    id: 6,
    name: "Centro de estudiantes",
    salesforce_value: "Centro de estudiantes",
    client: "centro de estudiantes",
    icon: <TbBellSchool size={30}></TbBellSchool>,
  },
];

export const salud = [
  {
    id: 1,
    name: "Centro de salud",
    salesforce_value: "Centro de salud",
    client: "centro de salud",
    icon: <RiHospitalLine size={30}></RiHospitalLine>,
  },
  {
    id: 2,
    name: "Hospital público",
    salesforce_value: "Hospital público",
    client: "hospital público",
    icon: <FaRegHospital size={30}></FaRegHospital>,
  },
  {
    id: 3,
    name: "Hospital privado",
    salesforce_value: "Hospital privado",
    client: "hospital privado",
    icon: <FaHospital size={30}></FaHospital>,
  },
  {
    id: 4,
    name: "Farmacia",
    salesforce_value: "Farmacia",
    client: "farmacia",
    icon: <MdOutlineLocalPharmacy size={30}></MdOutlineLocalPharmacy>,
  },
];

export const ocioDeportes = [
  {
    id: 1,
    name: "Zonas deportivas",
    salesforce_value: "Zonas deportivas",
    client: "zonas deportivas",
    icon: <MdOutlineSportsBaseball size={30}></MdOutlineSportsBaseball>,
  },
  {
    id: 2,
    name: "Gimnasios",
    salesforce_value: "Gimnasios",
    client: "gimnasios",
    icon: <CgGym size={30}></CgGym>,
  },
  {
    id: 3,
    name: "Parques infantiles",
    salesforce_value: "Parques infantiles",
    client: "parques infantiles",
    icon: <BsSmartwatch size={30}></BsSmartwatch>,
  },
  {
    id: 4,
    name: "Zonas verdes",
    salesforce_value: "Zonas verdes",
    client: "zonas verdes",
    icon: <FaLeaf size={30}></FaLeaf>,
  },
  {
    id: 5,
    name: "Playa",
    salesforce_value: "Playa",
    client: "playa",
    icon: <FaUmbrellaBeach size={30}></FaUmbrellaBeach>,
  },
  {
    id: 6,
    name: "Cine",
    salesforce_value: "Cine",
    client: "cine",
    icon: <BsCameraReelsFill size={30}></BsCameraReelsFill>,
  },
  {
    id: 7,
    name: "Teatro",
    salesforce_value: "Teatro",
    client: "teatro",
    icon: <FaTheaterMasks size={30}></FaTheaterMasks>,
  },
];

export const alimentacion = [
  {
    id: 1,
    name: "Restaurantes",
    salesforce_value: "Restaurantes",
    client: "restaurantes",
    icon: <GiForkKnifeSpoon size={30}></GiForkKnifeSpoon>,
  },
  {
    id: 2,
    name: "Cafeterías",
    salesforce_value: "Cafeterías",
    client: "cafeterías",
    icon: <BiCoffee size={30}></BiCoffee>,
  },
  {
    id: 3,
    name: "Supermercados",
    salesforce_value: "Supermercados",
    client: "supermercados",
    icon: <BsCart3 size={30}></BsCart3>,
  },
];

export const comprasCuidadoPersonal = [
  {
    id: 1,
    name: "Peluquería",
    salesforce_value: "Peluquería",
    client: "peluquería",
    icon: <GiHairStrands size={30}></GiHairStrands>,
  },
  {
    id: 2,
    name: "Spa",
    salesforce_value: "Spa",
    client: "spa",
    icon: <GiBathtub size={30}></GiBathtub>,
  },
  {
    id: 3,
    name: "Lavandería",
    salesforce_value: "Lavandería",
    client: "lavandería",
    icon: (
      <MdOutlineLocalLaundryService size={30}></MdOutlineLocalLaundryService>
    ),
  },
  {
    id: 4,
    name: "Centro comercial",
    salesforce_value: "Centro comercial",
    client: "centro comercial",
    icon: <MdStoreMallDirectory size={30}></MdStoreMallDirectory>,
  },
];

export const repostaje = [
  {
    id: 1,
    name: "Gasolinera",
    salesforce_value: "Gasolinera",
    client: "gasolinera",
    icon: <RiGasStationFill size={30}></RiGasStationFill>,
  },
  {
    id: 2,
    name: "Puntos de carga",
    salesforce_value: "Puntos de carga",
    client: "puntos de carga",
    icon: <GiElectric size={30}></GiElectric>,
  },
];

export const entorno_promocion = [
  {
    id: 1,
    name: "Centro urbano",
    salesforce_value: "Centro urbano",
    client: "centro_urbano",
    icon: <GiModernCity size={30}></GiModernCity>,
  },
  {
    id: 2,
    name: "Periferia",
    salesforce_value: "Periferia",
    client: "periferia",
    icon: <FaCity size={30}></FaCity>,
  },
  {
    id: 3,
    name: "Rural",
    salesforce_value: "Rural",
    client: "rural",
    icon: <BsFillTreeFill size={30}></BsFillTreeFill>,
  },
  {
    id: 4,
    name: "Playa",
    salesforce_value: "Playa",
    client: "playa",
    icon: <MdBeachAccess size={30}></MdBeachAccess>,
  },
  {
    id: 5,
    name: "Montaña",
    salesforce_value: "Montaña",
    client: "montana",
    icon: <TbMountain size={30}></TbMountain>,
  },
];

export const caracteristicas_vivienda_buscada = [
  {
    id: 1,
    name: "Cocina abierta",
    salesforce_value: "Cocina abierta",
    client: "cocina_abierta",
    icon: <GiCookingPot size={30}></GiCookingPot>,
  },
  {
    id: 2,
    name: "Cocina cerrada",
    salesforce_value: "Cocina cerrada",
    client: "cocina_cerrada",
    icon: <GiCookingPot size={30}></GiCookingPot>,
  },
  {
    id: 3,
    name: "Personalización",
    salesforce_value: "Personalización",
    client: "personalizacion",
    icon: <CgOptions size={30}></CgOptions>,
  },
  {
    id: 4,
    name: "Separación noche/día",
    salesforce_value: "Separación noche y día",
    client: "separacion_noche_dia",
    icon: <TbMoon2 size={30}></TbMoon2>,
  },
];

/**
 * IMPORTATE: salesforce_value debe estar en:
 * - minúsculas
 * - sin acentos
 * - sin espacios
 * - sin caracteres especiales
 *
 * Ejemplo: Sala Común --> salacomun
 *
 * El valor de aquí se matchea en la función cleanFeatureName, que se usa para remarcar las características de una promoción con lo que se marca en Calidades/Equipamiento de la vivienda
 *
 * Ver también FEATURES_LIST -> en Utils/feautresList --> Ahí se matchea el valor de salesforce a algo "legible"
 */
export const calidades_equipamiento_vivienda = [
  {
    id: 1,
    name: "Aerotermia",
    salesforce_value: "aerotermia",
    client: "aerotermia",
    icon: <FaWind size={30}></FaWind>,
  },
  {
    id: 2,
    name: "Ascensor",
    salesforce_value: "ascensor",
    client: "ascensor",
    icon: <GiElevator size={30}></GiElevator>,
  },
  {
    id: 3,
    name: "Conserjería",
    salesforce_value: "conserjeria",
    client: "conserjeria",
    icon: <FaConciergeBell size={30}></FaConciergeBell>,
  },
  {
    id: 4,
    name: "Geotermia",
    salesforce_value: "geotermia",
    client: "geotermia",
    icon: <SiGoogleearth size={30}></SiGoogleearth>,
  },
  {
    id: 5,
    name: "Gimnasio",
    salesforce_value: "gimnasio",
    client: "gimnasio",
    icon: <CgGym size={30}></CgGym>,
  },
  {
    id: 6,
    name: "ZZCC Ajardinadas",
    salesforce_value: "zzccajardinadas",
    client: "zzccajardinadas",
    icon: <GiGardeningShears size={30}></GiGardeningShears>,
  },
  {
    id: 7,
    name: "Parking comunitario",
    salesforce_value: "parkingcomunitario",
    client: "parking_comunitario",
    icon: <FaParking size={30}></FaParking>,
  },
  {
    id: 8,
    name: "Pista polideportiva",
    salesforce_value: "pistapolideportiva",
    client: "pista_polideportiva",
    icon: <GiSoccerField size={30}></GiSoccerField>,
  },
  {
    id: 9,
    name: "Piscina",
    salesforce_value: "piscina",
    client: "piscina",
    icon: <BiSwim size={30}></BiSwim>,
  },
  {
    id: 10,
    name: "Piscina infantil",
    salesforce_value: "piscinainfantil",
    client: "piscina_infantil",
    icon: <TbSwimming size={30}></TbSwimming>,
  },
  {
    id: 11,
    name: "Sala bicicletas",
    salesforce_value: "salabicicletas",
    client: "sala_bicicletas",
    icon: <FaBicycle size={30}></FaBicycle>,
  },
  {
    id: 12,
    name: "Sala común",
    salesforce_value: "salacomun",
    client: "sala_comun",
    icon: <BsFillPeopleFill size={30}></BsFillPeopleFill>,
  },
  {
    id: 13,
    name: "Suelo radiante",
    salesforce_value: "sueloradiante",
    client: "suelo_radiante",
    icon: <GiGroundSprout size={30}></GiGroundSprout>,
  },
  {
    id: 14,
    name: "Suelo radiante y refrescante",
    salesforce_value: "sueloradianteyrefrescante",
    client: "suelo_radiante_refrescante",
    icon: <GiGroundSprout size={30}></GiGroundSprout>,
  },
  {
    id: 15,
    name: "Tenis",
    salesforce_value: "tenis",
    client: "tenis",
    icon: <MdOutlineSportsTennis size={30}></MdOutlineSportsTennis>,
  },
  {
    id: 16,
    name: "Trastero",
    salesforce_value: "trastero",
    client: "trastero",
    icon: <FaWarehouse size={30}></FaWarehouse>,
  },
  {
    id: 17,
    name: "Videoportero",
    salesforce_value: "videoportero",
    client: "videoportero",
    icon: <BsCameraVideo size={30}></BsCameraVideo>,
  },
  {
    id: 18,
    name: "Zona infantil",
    salesforce_value: "zonainfantil",
    client: "zona_infantil",
    icon: <TbMoodKid size={30}></TbMoodKid>,
  },
  {
    id: 19,
    name: "Pádel",
    salesforce_value: "padel",
    client: "padel",
    icon: <MdOutlineSportsTennis size={30}></MdOutlineSportsTennis>,
  },
];

export const construccion_diseno_edificio = [
  {
    id: 1,
    name: "Moderno",
    salesforce_value: "Moderno",
    client: "moderno",
    icon: <HiOutlineHomeModern size={30}></HiOutlineHomeModern>,
  },
  {
    id: 2,
    name: "Tradicional",
    salesforce_value: "Tradicional",
    client: "tradicional",
    icon: <GiIonicColumn size={30}></GiIonicColumn>,
  },
];

export const edificacion_diseno_edificio = [
  {
    id: 1,
    name: "Torre",
    salesforce_value: "Torre",
    client: "torre",
    icon: <BiBuilding size={30}></BiBuilding>,
  },
  {
    id: 2,
    name: "Bloque",
    salesforce_value: "bloque",
    client: "bloque",
    icon: <HiBuildingOffice2 size={30}></HiBuildingOffice2>,
  },
  {
    id: 3,
    name: "Adosada",
    salesforce_value: "Adosada",
    client: "adosada",
    icon: <MdOutlineCottage size={30}></MdOutlineCottage>,
  },
  {
    id: 4,
    name: "Pareada",
    salesforce_value: "Pareada",
    client: "pareada",
    icon: <MdOutlineCottage size={30}></MdOutlineCottage>,
  },
  {
    id: 5,
    name: "Aislada",
    salesforce_value: "Aislada",
    client: "aislada",
    icon: <MdOutlineCottage size={30}></MdOutlineCottage>,
  },
];

export const arquitecto_diseno_edificio = [
  {
    id: 1,
    name: "Prestigio abierta",
    salesforce_value: "Prestigio",
    client: "prestigio",
    icon: <TbDiamond size={30}></TbDiamond>,
  },
  {
    id: 2,
    name: "Local",
    salesforce_value: "Local",
    client: "local",
    icon: <CiLocationOn size={30}></CiLocationOn>,
  },
  {
    id: 3,
    name: "Emergente",
    salesforce_value: "Emergente",
    client: "emergente",
    icon: <BsArrowUpRight size={30}></BsArrowUpRight>,
  },
];
