import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const getDurationByAppointmentId = (appointmentId: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND +
      `/Appointment/features/${appointmentId}`,
    getDefaultConfigHeader()
  );
};

export const setDurationByAppointmentId = (
  appointmentId: any,
  time: any,
  showedup: any,
  reason: any,
  contactoagendado: boolean,
  emailenviado: boolean,
  informacionsolicitada: boolean
) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND +
      `/Appointment/features/${appointmentId}`,
    {
      duration: time,
      showedup: showedup,
      reason: reason,
      contactoagendado: contactoagendado,
      emailenviado: emailenviado,
      informacionsolicitada: informacionsolicitada,
    },
    getDefaultConfigHeader()
  );
};
