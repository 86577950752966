import { Button, Loading, Modal } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { getDIA, sendDIA } from "../../Service/DIAService/DIADocService";
//@ts-ignore
import SignatureCanvas from "react-signature-canvas";
import { PdfRendering } from "./PdfRender";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import React, { useRef } from "react";
import toast from "react-hot-toast";

export const SignBody = (props: any) => {
  const {
    setOpenModalSign,
    isDIA,
    houseId,
    client,
    assetId,
    setRerenderlikes,
    rerenderlikes,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [document, setDocument] = useState<string | null>(null);
  const [documentURL, setDocumentURL] = useState<string | null>(null);
  const [documentWithSign, setDocumentWithSign] = useState<string | null>();
  const sigCanvasRef = useRef<SignatureCanvas>(null);

  useEffect(() => {
    const type = isDIA ? "DIA" : "justificante";
    getDIA(houseId, type)
      .then((response: any) => {
        setDocument(response?.data?.response?.pDocumento64);
      })
      .catch((error) => {
        console.error("Error al generar el documento:", error);
        setIsLoading(false);
      });
  }, [isDIA, houseId]);

  useEffect(() => {
    if (document) {
      const pdfBlob = base64ToBlob(document, "application/pdf");
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setDocumentURL(pdfUrl);
      setIsLoading(false);
    }
  }, [document]);

  const handleOpenNewWindowPdf = () => {
    window.open(documentURL ?? "", "_blank");
  };

  const handleSaveSignature = () => {
    if (sigCanvasRef.current) {
      const canvas = sigCanvasRef.current.getTrimmedCanvas();
      const signatureImageRaw = canvas.toDataURL("image/png");
      const signatureImage = signatureImageRaw.replace(
        "data:image/png;base64,",
        ""
      );

      const data = {
        house_id: houseId,
        type: isDIA ? "dia" : "justificante",
        document_base64: document,
        signature_base64: signatureImage,
        id_client: client.id,
        id_asset: assetId,
      };
      sendDIA(data)
        .then((response: any) => {
          const pdfBlob = base64ToBlob(
            response.document_base64,
            "application/pdf"
          );
          const pdfUrl = URL.createObjectURL(pdfBlob);

          setDocumentWithSign(pdfUrl);
          toast.success("Firma recogida correctamente");
        })
        .catch((err) =>
          toast.error("Ha habido algún error a la hora de firmar el documento")
        );
    }
  };

  if (isLoading) return <Loading />;
  if (documentWithSign) {
    return (
      <Modal.Body css={{ padding: "5px" }}>
        <div
          style={{
            width: "1180px",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            gap: "40px",
          }}
        >
          <div style={{ width: "100%", height: "370px" }}>
            <PdfRendering url={documentWithSign ?? ""} />
            {/* Holaaaaa */}
          </div>
          <Button
            title="Volver a terminar visita"
            auto
            onClick={() => {
              setRerenderlikes(!rerenderlikes);
              setOpenModalSign(false);
            }}
          >
            Volver
          </Button>
        </div>
      </Modal.Body>
    );
  } else {
    return (
      <Modal.Body css={{ padding: "5px" }}>
        <div
          style={{
            width: "1180px",
            height: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "20px",
            gap: "40px",
          }}
        >
          <div style={{ width: "50%", height: "470px" }}>
            <PdfRendering url={documentURL ?? ""} />
          </div>
          <div style={{ width: "40%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div
                style={{
                  marginTop: "20px",
                  border: "1px solid black",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                <span>
                  {isDIA ? "Firma del comercial" : "Firma del cliente"}:
                </span>
                <SignatureCanvas
                  ref={sigCanvasRef}
                  penColor="blue"
                  canvasProps={{
                    width: 450,
                    height: 150,
                    className: "sigCanvas",
                  }}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
                width: "100%",
                marginTop: "20px",
              }}
            >
              <Button
                title="Volver a terminar visita"
                auto
                onClick={() => {
                  setOpenModalSign(false);
                }}
              >
                Volver
              </Button>
              <Button
                title="Abrir PDF en una ventana nueva"
                auto
                onClick={handleOpenNewWindowPdf}
              >
                Abrir en nueva pestaña
              </Button>

              <Button
                title="Guardar firma y volver a terminar visita"
                auto
                onClick={handleSaveSignature}
              >
                Firmar y volver
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    );
  }
};

// Función para convertir base64 a Blob
function base64ToBlob(base64: string | null, contentType: string) {
  if (!base64) {
    return new Blob(); // Retorna un Blob vacío si base64 es null
  }

  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
}
