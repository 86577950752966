import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";


export const getAllRelationalStyle = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/RelationalStyle/",
    getDefaultConfigHeader()
  );
};
