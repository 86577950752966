import { BiMapPin } from "react-icons/bi";
import TabsForm from "./TabsForm";

export default function Calidades(props: any) {
  const { formik } = props;
  const sections = [
    {
      button: {
        onClick: () => props.setselectedValue("calidades"),
        icon: <BiMapPin></BiMapPin>,
        name: "Muestras fisicas",
      },
      questions: [
        // {
        //   name: "Tipo de acabado",
        //   answers: acabado,
        //   formik: "tipoDeAcabado",
        // },
        // {
        //   name: "Suelo radiante y refrescante",
        //   answers: normal_response,
        //   formik: "sueloRadianteYRefrescante",
        // },
        // {
        //   name: "Aerotermia",
        //   answers: normal_response,
        //   formik: "aerotermia",
        // },
        // {
        //   name: "Aislamientos térmicos y acústicos",
        //   answers: normal_response,
        //   formik: "aislamientosTermicosYAcusticos",
        // },
      ],
    },
  ];
  return (
    <>
      <TabsForm formik={formik} sections={sections}></TabsForm>
    </>
  );
}
