import { Card } from "@nextui-org/react";
import { getUser } from "../../Utils/commonUtils";
import { useState } from "react";
import { TIMEZONES } from "../Constants/constants";
import moment from "moment-timezone";

const Welcome = (props: any) => {
  const [timezone] = useState<string>(
    JSON.parse(localStorage.getItem("timezone") ?? "null") || TIMEZONES[0]
  );

  const [fechaString] = useState(
    timezone === "Canarias"
      ? moment.tz(moment(), "Atlantic/Canary").format("HH:mm")
      : moment.tz(moment(), "Europe/Madrid").format("HH:mm")
  );
  return (
    <Card
      css={{
        padding: "2rem .5rem 2rem .5rem",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          alt="Imagen en blanco"
          src="\Assets\img\blank.svg"
          style={{ height: "5rem", width: "5rem" }}
        ></img>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "1rem",
        }}
      >
        <div style={{}}>
          <h1
            style={{
              textAlign: "start",
              fontFamily: "lis-title",
              fontSize: "20px",
              marginBottom: "0px",
            }}
          >
            Bienvenido,
          </h1>
          <h1
            style={{
              textAlign: "start",
              fontFamily: "lis-title",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            {getUser()?.displayName}
          </h1>
        </div>
        <div
          onClick={() => props.setOpenModalTimeZone(true)}
          style={{ display: "flex", flexDirection: "column", fontSize: "12px" }}
        >
          <span>Hora: {fechaString}</span>
          <strong title={props.locationGeodecodeMapbox}>
            Ubicación detectada: {props.textLocationoWelcome}
          </strong>
          <strong title={props.locationGeodecodeMapbox}>
            Zona horaria: {timezone}
          </strong>
          <span style={{ cursor: "pointer" }}>
            Pulsa aquí para cambiar la zona horaria.
          </span>
        </div>
      </div>
    </Card>
  );
};

export default Welcome;
