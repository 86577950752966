interface PdfRenderProps {
  url: string;
}

export const PdfRendering = ({ url }: PdfRenderProps) => {
  const pdfUrl = `${url}#toolbar=0&navpanes=0`;
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "1px",
        backgroundColor: "white",
        borderRadius: "8px",
        boxShadow: "10px ",
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <iframe
          src={pdfUrl}
          title="Preview"
          style={{
            height: "100%",
            width: "100%",
            boxSizing: "border-box",
            border: "none",
          }}
        />
      </div>
    </div>
  );
};
