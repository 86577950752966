import axios from "axios";
import { getTokenApim } from "../AppimanagerConfig/AppimanagerConfid";

export const checkPostalCode = async (code: any) => {
  try {
    // Obtener el token
    const tokenResponse = await getTokenApim();
    const apimToken = tokenResponse.data.access_token;

    // Configurar la solicitud para obtener los cierres con el token
    let config: any = {
      method: "get",
      url: process.env.REACT_APP_APIM + `/getLocation/${code}`,
      headers: {
        "API-Key": process.env.REACT_APP_APIM_KEY,
        Authorization: "Bearer " + apimToken, // Agregar el token de acceso
      },
    };

    // Realizar la solicitud y devolver el resultado
    return axios(config);
  } catch (error) {
    // Manejar errores
    console.error("Error al obtener el token:", error);
    throw error;
  }
};
