import { Card } from "@nextui-org/react";
import {
  priceFormatter,
  writeCorrectNameOfPromotion,
} from "../../Utils/commonUtils";

import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineCalendar,
  AiOutlineDollar,
  AiOutlineEye,
} from "react-icons/ai";
import { PdfRender } from "../PdfRender/PdfRender";

const FavoriteHouseInModal = (props: any) => {
  const {
    informationHouse,
    map,
    sendJson,
    pointHouse,
    setPointHouse,
    likedElements,
  } = props;

  const [displayBudget, setDisplayBudget] = useState(false);
  const [displayPlane, setDisplayPlane] = useState(false);
  const [displayCalendar, setDisplayCalendar] = useState(false);

  useEffect(() => {
    if (pointHouse !== informationHouse?.Id) {
      setDisplayBudget(false);
      setDisplayCalendar(false);
      setDisplayPlane(false);
    }
  }, [pointHouse]);

  return (
    <Card
      className="AvailableProduct_tsx"
      variant="bordered"
      style={{
        width: "auto", // 300px
        height: "265px",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "0.7rem",
          flexDirection: "column",
          gap: "0.7rem",
        }}
      >
        <div className="d-flex flex-column" style={{ width: "100%" }}>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column gap-2">
              <span
                className="portal-adress-and-house"
                style={{
                  textAlign: "left",
                  color: "var(--secondary-color)",
                  fontFamily: "lis-title",
                  fontSize: "25px",
                  lineHeight: "1",
                }}
              >
                P{informationHouse?.Portal_Direccion__c}-
                {
                  /* {informationHouse?.Planta__c + */
                  informationHouse?.Piso_Direccion__c +
                    informationHouse?.Puerta_Direccion__c
                }
              </span>
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <p
                style={{
                  textAlign: "left",
                  marginBottom: "0px",
                  fontFamily: "lis-title",
                  fontSize: "22px",
                  lineHeight: "1",
                }}
              >
                {informationHouse?.M2_Constr__c?.toFixed(2)}m
                <span className="sup">2</span>
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div
              className="d-flex"
              style={{
                fontFamily: "lis-body",
                fontSize: "12px",
                paddingLeft: "2px",
                lineHeight: "1",
              }}
            >
              {writeCorrectNameOfPromotion(
                informationHouse?.Promocion__r?.Name
              )}
            </div>
            <div className="d-flex justify-content-end">
              <span
                style={{
                  fontFamily: "lis-body",
                  fontSize: "12px",
                  lineHeight: "1",
                }}
              >
                {map.asset_label}
              </span>
            </div>
          </div>
        </div>

        <div style={{ width: "100%", height: "90px" }}>
          <PdfRender url={map?.url}></PdfRender>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
            marginBottom: "10px",
            height: "52px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0.2rem",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <span
              style={{
                padding: "0.2rem",
                background: "#8080802b",
                borderRadius: "10px",
                fontFamily: "lis-body",
                fontSize: "12px",
              }}
            >
              {informationHouse?.N_De_Bagnos__c} baños
            </span>

            <span
              style={{
                padding: "0.2rem",
                background: "#8080802b",
                borderRadius: "10px",
                fontFamily: "lis-body",
                fontSize: "12px",
              }}
            >
              {informationHouse?.N_De_Habitaciones__c} dormitorios
            </span>
            {informationHouse?.Tiene_Trastero__c ? (
              <span
                style={{
                  padding: "0.2rem",
                  background: "#8080802b",
                  borderRadius: "10px",
                  fontFamily: "lis-body",
                  fontSize: "12px",
                }}
              >
                {informationHouse?.Tiene_Trastero__c} trastero/s
              </span>
            ) : (
              <></>
            )}
            {informationHouse?.Plazas_De_Parking_Incluidas__c ? (
              <span
                style={{
                  padding: "0.2rem",
                  background: "#8080802b",
                  borderRadius: "10px",
                  fontFamily: "lis-body",
                  fontSize: "12px",
                }}
              >
                {informationHouse?.Plazas_De_Parking_Incluidas__c} garaje/s
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="position-relative d-flex w-100">
          <div
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <span
              style={{
                fontFamily: "lis-title",
                fontSize: "14px",
                marginRight: "3px",
              }}
            >
              Desde{" "}
            </span>
            <span
              style={{
                fontFamily: "lis-body",
                fontSize: "27px",
                lineHeight: "42px",
                marginTop: "-14px",
              }}
            >
              {priceFormatter(informationHouse?.PVP__c)}
            </span>
          </div>
        </div>
        <div
          style={{ bottom: "7px", right: "6px" }}
          className="position-absolute"
        >
          <div className="d-flex flex-column gap-2">
            <div className="d-flex justify-content-between gap-1">
              <IconButton
                className="btn-icono-listado-productos"
                title="Mostrar/ocultar plano a la pantalla del cliente"
                onClick={() => {
                  setDisplayPlane(!displayPlane);
                  setPointHouse(map.id_house);
                  sendJson({
                    event: "change_clientview",
                    value: !displayPlane
                      ? "plano"
                      : "mostrar_viviendas_favoritas",
                    url: map.url,
                  });
                }}
                style={{
                  background: displayPlane ? "#c5edf9" : "#f4f4f4",
                  padding: "4px",
                  fontSize: "16px",
                }}
              >
                <AiOutlineEye size={22}></AiOutlineEye>
              </IconButton>
              <IconButton
                className="btn-icono-listado-productos"
                title="Guardar/quitar plano como favorito"
                onClick={(e: any) => {
                  //Match entre los likes y la casa seleccionada para recoger el id_like
                  const { Asset_Id } = informationHouse;
                  const assetSelected = likedElements.planos.find(
                    (elm: any) => elm.asset_id === Asset_Id
                  );
                  props.handleDislike(assetSelected.id_like);
                }}
                style={{
                  background: "#f4f4f4",
                  padding: "4px",
                  fontSize: "18px",
                }}
              >
                {likedElements.planos?.find(
                  (e: any) => e.asset_id === map.asset_id
                ) ? (
                  <AiFillHeart
                    color={"var(--aedas-red)"}
                    size={20}
                  ></AiFillHeart>
                ) : (
                  <AiFillHeart size={22}></AiFillHeart>
                )}
              </IconButton>{" "}
            </div>
            <div className="d-flex justify-content-between gap-1">
              <IconButton
                className="btn-icono-listado-productos"
                title="Mostrar/ocultar hoja informativa"
                onClick={() => {
                  setDisplayBudget(!displayBudget);
                  setDisplayCalendar(false);
                  // Marcamos en azul la casa que coincide con su id.
                  setPointHouse(informationHouse?.Id);
                  sendJson({
                    event: "mostrar_presupuesto",
                    value: !displayBudget,
                    selectedHouse: !displayBudget ? informationHouse?.Id : "",
                  });
                }}
                style={{
                  background: displayBudget ? "#c5edf9" : "#f4f4f4",
                  padding: "4px",
                  fontSize: "11px",
                  color: "#666666",
                }}
              >
                <AiOutlineDollar size={22} />
              </IconButton>
              <IconButton
                className="btn-icono-listado-productos"
                title="Mostrar/ocultar calendario de pagos"
                onClick={() => {
                  setDisplayCalendar(!displayCalendar);
                  setDisplayBudget(false);
                  // Marcamos en azul la casa que coincide con su id.
                  setPointHouse(informationHouse?.Id);
                  sendJson({
                    event: "mostrar_calendario",
                    value: !displayCalendar,
                    selectedHouse: !displayCalendar ? informationHouse?.Id : "",
                  });
                }}
                style={{
                  background: displayCalendar ? "#c5edf9" : "#f4f4f4",
                  padding: "4px",
                  fontSize: "16px",
                }}
              >
                <AiOutlineCalendar size={22} />
              </IconButton>{" "}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default FavoriteHouseInModal;
