import { AvaiableProduct } from "../../AvaiableProduct/AvaiableProduct";

export function ShadowPlanes(props: any) {
  const {
    tax,
    principalProductClient,
    shadowProduct,
    likes,
    sendJson,
    // setselectedValue,
    idClient,
    setLikes,
    pointHouse,
    setPointHouse,
    setPrincipalProduct,
    principalProduct,
    // setShadowProduct,
    // disableAddToPrincipalProduct,
  } = props;

  return (
    <>
      {shadowProduct?.map((sp: any) => {
        return (
          <AvaiableProduct
            tax={tax}
            idClient={idClient}
            likes={likes}
            setLikes={setLikes}
            key={"shadowPlanes" + sp.house_n}
            asset={sp}
            sendJson={sendJson}
            // setselectedValue={setselectedValue}
            pointHouse={pointHouse}
            setPointHouse={setPointHouse}
            setPrincipalProduct={setPrincipalProduct}
            principalProductClient={principalProductClient}
            // shadowProduct={shadowProduct}
            // setShadowProduct={setShadowProduct}
            // disableAddToPrincipalProduct={disableAddToPrincipalProduct}
            canSendCalendarAndBudget={principalProduct?.includes(sp)}
          ></AvaiableProduct>
        );
      })}
    </>
  );
}
