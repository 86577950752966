import { Button, Card, Loading, Modal } from "@nextui-org/react";

import { useHistory } from "react-router-dom";
// @ts-ignore
import { useEffect, useRef, useState } from "react";
//@ts-ignore
import { ToastContainer, toast } from "react-toastify";
import TodayAppointments from "../../Components/Home/TodayAppointments";
import Welcome from "../../Components/Home/Welcome";
import { useFetch, useIsLoading } from "../../Hooks/useFetch";
import {
  getAllAppointmentsByUserId,
  getAllAppointmentsByUserIdLastWeek,
} from "../../Service/AppointmentService/AppointmentService";
import {
  getAllClientsBySeller,
  getAllKpisBySeller,
  getAllLeadsBySeller,
  getAllOportunitiesBySeller,
} from "../../Service/SellerService/SellerService";
import { getUser } from "../../Utils/commonUtils";

import QRCode from "react-qr-code";
import ControlZoneDate from "../../Components/ControlZoneDate/ControlZoneDate";
import { getReverseGeocode } from "../../Service/ControlZoneDate/ControlZoneDate";
import { ReverseGeoDecode } from "../../Interfaces/reverseGeodecode";
import { LastAppointments } from "../../Components/Dashboard/LastAppoinments";
import Appointment from "../appointment/Appointment";

function HomeExample() {
  const [isAppointmentSelected, setIsAppointmentSelected] = useState(null);
  const [appointmentPromotionId, setAppointmentPromotionId] = useState(null);
  const [openModalTimeZone, setOpenModalTimeZone] = useState(false);
  const hasNotifiedNotifyMaximizePerformance = useRef(false);

  const [textLocationoWelcome, setTextLocationoWelcome] = useState(
    "Pensinsula / Baleares"
  );
  const [locationGeodecodeMapbox, setLocationGeodecodeMapbox] = useState("...");
  const notifyGoogleChrome = () =>
    toast.error(
      "La aplicación esta diseñada para utilizarse con Google Chrome. Por favor, entre desde Google Chrome.",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      }
    );

  const notifyMaximizePerformance = () => {
    toast.info(
      "¡Mejora tu rendimiento! Cierra las pestañas que no estés utilizando para liberar recursos.",
      {
        position: "top-right",
        autoClose: 5500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    hasNotifiedNotifyMaximizePerformance.current = true;
  };

  const [modalRGPD, setModalRGPD] = useState(false);

  useEffect(() => {
    if (navigator.userAgent.indexOf("Chrome") === -1) {
      notifyGoogleChrome();
    }
    notifyMaximizePerformance();
  }, []);

  const [appointments, isFetchingAppointments] = useFetch(
    getAllAppointmentsByUserId,
    getUser()?.id
  );

  const [clients, isFetchingClients] = useFetch(
    getAllClientsBySeller,
    getUser()?.id
  );

  const [leads, isFetchingLeads, updateLeads, errorLeads] = useFetch(
    getAllLeadsBySeller,
    getUser()?.id
  );

  const [oportunities, isFetchingOportunities] = useFetch(
    getAllOportunitiesBySeller,
    getUser()?.id
  );

  const [kpis, isFetchingKpis, updateKpis, errorKpis] = useFetch(
    getAllKpisBySeller,
    getUser()?.id
  );
  const [appointmentsLasWeek, isFetchingAppointmentsLastWeek] = useFetch(
    getAllAppointmentsByUserIdLastWeek,
    getUser()?.id
  );
  const isLoading = useIsLoading([
    isFetchingOportunities,
    isFetchingLeads,
    isFetchingAppointments,
    isFetchingClients,
    isFetchingOportunities,
    isFetchingKpis,
    isFetchingAppointmentsLastWeek,
  ]);

  const history = useHistory();

  const getLoadStatus = () => {
    if (isFetchingLeads) {
      return <span>Cargando los Intereses </span>;
    }
    if (isFetchingAppointments) {
      return <span>Cargando las citas de hoy </span>;
    }
    if (isFetchingAppointmentsLastWeek) {
      return <span>Cargando las citas anteriores </span>;
    }
    if (isFetchingOportunities) {
      return <span>Cargando las oportunidades </span>;
    }
    if (isFetchingClients) {
      return <span>Cargando las cuentas </span>;
    }
    if (isFetchingKpis) {
      return <span>Cargando KPIs </span>;
    }

    return <span>Cargando</span>;
  };

  useEffect(() => {
    if (!isLoading) {
      const storedLocationControlTimezone = localStorage.getItem(
        "location_control_timezone"
      );
      const locationControlTimezone = storedLocationControlTimezone
        ? JSON.parse(storedLocationControlTimezone)
        : false;

      if (!locationControlTimezone) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            getReverseGeodecode(latitude, longitude);
          },
          (error) => {
            console.error("Error al obtener coordenadas:", error);
          }
        );
      }
    }
  }, [isLoading]);

  /**
   *
   * @param latitude
   * @param longitude
   */
  const getReverseGeodecode = (
    latitude: string | number,
    longitude: string | number
  ) => {
    getReverseGeocode(latitude, longitude)
      .then((data: ReverseGeoDecode) => {
        setLocationGeodecodeMapbox(
          data?.features[0]?.place_name.split(",").slice(-2).join(",").trim() ??
            ""
        );
        const isCanarias =
          data.features[0].place_name.includes("Canarias") ||
          data.features[0].place_name.includes("Las Palmas") ||
          data.features[0].place_name.includes("Santa Cruz de Tenerife");

        if (isCanarias) {
          setTextLocationoWelcome("Islas Canarias");
        }
      })
      .catch((error) => {
        console.error("Error al obtener datos de ubicación:", error);
      });
  };

  if (isLoading) {
    return (
      <div
        className="w-100 h-100 d-flex justify-content-center "
        style={{ flexDirection: "column", alignItems: "center" }}
      >
        <Loading size="xl"></Loading>
        <div style={{ padding: "1rem" }}>
          {getLoadStatus()}
          <ThreeDots></ThreeDots>
        </div>
      </div>
    );
  }

  return (
    <>
      {errorLeads ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Card
            css={{ width: "50%", background: "#b1003b63", padding: "1rem" }}
          >
            <span
              style={{
                textAlign: "center",
                color: "var(--aedas-red)",
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              ¡Ha habido un problema!
            </span>
            <span
              style={{
                textAlign: "center",
                color: "var(--aedas-red)",
              }}
            >
              Parece que el usuario con el que se ha logueado no está dado de
              alta como comercial en SalesForce. Si cree que ha sido un error
              contacte con el administrador del sitio.
            </span>
          </Card>
        </div>
      ) : (
        <>
          <ControlZoneDate
            openModalTimeZone={openModalTimeZone}
            setOpenModalTimeZone={setOpenModalTimeZone}
          />{" "}
          <Modal
            scroll
            preventClose
            width="900px"
            open={modalRGPD}
            onClose={() => setModalRGPD(false)}
            closeButton
          >
            <Modal.Header>
              <span style={{ fontWeight: "bold", fontSize: "2rem" }}>
                El cliente no tiene RGPD aprobado
              </span>
            </Modal.Header>
            <Modal.Body>
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  textAlign: "center",
                }}
              >
                El cliente debe aceptar la politica de RGPD para poder hacer la
                visita en Atenea.
              </span>
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  textAlign: "center",
                }}
              >
                Escaneando el siguiente QR puede acceder a la firma del RGPD:
              </span>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Card
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px",
                    alignItems: "center",
                    width: "fit-content",
                  }}
                >
                  <QRCode value={appointments && appointments[0]?.rgdp_link} />
                </Card>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  setModalRGPD(false);
                  history.push("/");
                }}
              >
                Hecho
              </Button>
            </Modal.Footer>
          </Modal>
          <div style={{ display: "flex" }}>
            {/* Este div es la barra lateral donde esta el wellcome y las citas del día */}
            <div
              style={{
                width: "30%",
                height: "100vh",
                padding: "1rem",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  gap: "1rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Welcome
                  setOpenModalTimeZone={setOpenModalTimeZone}
                  textLocationoWelcome={textLocationoWelcome}
                  locationGeodecodeMapbox={locationGeodecodeMapbox}
                />
                <TodayAppointments
                  appointments={appointments}
                  setModalRGPD={setModalRGPD}
                  setIsAppointmentSelected={setIsAppointmentSelected}
                  setAppointmentPromotionId={setAppointmentPromotionId}
                />
              </div>
            </div>
            {/* Este div es todo el contenedor de la derecha donde se muestran los Tabs */}
            <div
              style={{
                width: "70%",
                height: "100vh",
                padding: "1rem 1rem 1rem 0rem",
              }}
            >
              {isAppointmentSelected ? (
                <Appointment
                  appointmentPromotionId={appointmentPromotionId}
                  setIsAppointmentSelected={setIsAppointmentSelected}
                ></Appointment>
              ) : (
                <LastAppointments
                  appointments={appointments}
                  appointmentsLasWeek={appointmentsLasWeek}
                ></LastAppointments>
              )}
            </div>
          </div>
        </>
      )}

      <ToastContainer />
    </>
  );
}

export default HomeExample;

const ThreeDots = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((count) => (count < 3 ? count + 1 : 0));
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  const baseDots = "...";

  // concatenar la cadena base con los puntos rellenos
  const filledDots = baseDots.slice(0, count);

  return <span>{filledDots}</span>;
};
