import { useEffect, useState } from "react";

export const useFetch = (service: any, params: any = "") => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const [isFetching, setIsFetching] = useState(true);
  const updateData = () => {
    setIsFetching(true);
    setError(null);
    setData(null);
  };
  useEffect(() => {
    if (isFetching) {
      service(params)
        .then((response: any) => setData(response.data))
        .catch((e: any) => {
          setError(e);
        })
        .finally(() => setIsFetching(false));
    }
  }, [isFetching, params, service]);

  return [data, isFetching, updateData, error];
};

export const useIsLoading = (isLoadingArray: any) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let isLoadingValue = false;
    isLoadingArray.forEach((value: boolean) => {
      if (value) {
        isLoadingValue = true;
      }
    });
    if (isLoadingValue) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingArray]);

  return isLoading;
};
