import { Collapse } from "@nextui-org/react";
import { AiOutlineHome } from "react-icons/ai";
import { DestinoVivienda__c } from "../../../Utils/MultiSelectLists";
import MultipleSelect from "../../MultipleSelect/MultipleSelect";

const PurchaseReason = (props: any) => {
  const { formik } = props;

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
  ];

  return (
    <Collapse
      title="Motivación de la compra"
      contentLeft={<AiOutlineHome size={30} />}
    >
      <div
        style={{
          padding: "0px 15px 15px 20px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          gap: "1rem",
          alignItems: "start",
        }}
      >
        <MultipleSelect
          title={"¿Cuál es el motivo de la compra? *"}
          displayedInClientView={true}
          values={formik.values.DestinoVivienda__c}
          onChange={(e: any) => {
            formik.setFieldValue("DestinoVivienda__c", e);
          }}
          list={DestinoVivienda__c}
        ></MultipleSelect>
      </div>
    </Collapse>
  );
};

export default PurchaseReason;
