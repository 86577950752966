import moment from "moment";
import { MdErrorOutline } from "react-icons/md";
import { useFetch } from "../../Hooks/useFetch";
import { getAllEventsByAppoiment } from "../../Service/EventsService/EventsService";
import AedasLoading from "../AedasLoading/AedasLoading";
import HistoryComponent from "../HistoryComponent/HistoryComponnet";

const Calls = (props: any) => {
  const [calls, isFeching] = useFetch(getAllEventsByAppoiment, {
    idClient: props.idClient,
    idSeller: props.idSeller,
  });

  if (isFeching) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <div
      style={{
        gap: "1rem",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "1rem",
        overflow: "auto",
      }}
    >
      {calls ? (
        <>
          {calls.map((c: any) => {
            return (
              <HistoryComponent
                key={`${moment(c.date).format("DD/MM/YYYY")}-${moment(
                  c.date
                ).format("HH:mm")}-${c.title}`}
                hour={moment(c.date).format("HH:mm")}
                date={moment(c.date).format("DD/MM/YYYY")}
                duration={moment
                  .utc(parseInt(c.duration) * 1000)
                  .format("mm:ss")}
                description={c.title}
              ></HistoryComponent>
            );
          })}
        </>
      ) : (
        <p className='font-family-lis d-flex align-items-center gap-1'>
          <MdErrorOutline size={24} color={"#666"} /> No hay llamadas
          registradas
        </p>
      )}
    </div>
  );
};

export default Calls;
