import { Collapse } from "@nextui-org/react";
import { BsHouse } from "react-icons/bs";
import { calidades_equipamiento_vivienda } from "../../../Utils/MultiSelectLists";
import MultipleSelect from "../../MultipleSelect/MultipleSelect";

const QualityAndEquipment = (props: any) => {
  const { formik, setTabCaracteristicas } = props;
  return (
    <Collapse
      title="Calidades/equipamiento de la vivienda"
      contentLeft={<BsHouse size={30} />}
    >
      <div
        style={{
          padding: "0px 15px 15px 20px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          gap: "1rem",
          alignItems: "start",
        }}
      >
        <MultipleSelect
          multiple
          title={"Calidades y equipamientos"}
          values={formik.values.calidades_equipamiento_vivienda}
          onChange={(e: any) => {
            formik.setFieldValue("calidades_equipamiento_vivienda", e);
            setTabCaracteristicas(1);
          }}
          list={calidades_equipamiento_vivienda}
        ></MultipleSelect>
      </div>
    </Collapse>
  );
};

export default QualityAndEquipment;
