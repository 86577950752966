/**
 * @description Constante usada para el autosave del formulario de la página de Seller.
 */
export const fiveSecondsTimeToSave = 5000;

/**
 * @description Informa en X segundos al formik que el input ha variado para que se gestione el autoguardado.
 * @param {object} formik Objeto formik
 * @param {object} e Evento click
 */
export const sayToFormikDataHasChanged = (formik: any, e: any) => {
  setTimeout(() => {
    formik.handleChange(e);
  }, fiveSecondsTimeToSave);
};

/**
 * @description Posibles claves de los objetos devueltos cuando se solicitan los datos de un formulario en la página de Seller.
 */
export const objectName = {
  sellerForm: "sellerForm",
  clientForm: "clientForm",
  promotionForm: "promotionForm",
  preferencesForm: "preferencesForm",
};

/**
 * @description Obtiene los valores de los distintos campos del formulario de venta (página de Seller), que vienen agrupados por objetos de forma un poco peculiar.
 *
 * @param {object} data Datos con la respuesta de la API.
 * @param {string} objectKey Nombre del objeto donde buscar el dato, a saber: sellerForm, clientForm, promotionForm, y preferencesForm
 * @param {string} valueToSearch Clave para buscar su valor: "Como__c", "Arquetipo__c", etc.
 * @returns
 */
export const getValueForForm = (
  data: any,
  objectKey: any,
  valueToSearch: any
) => {
  // El objeto de sellerForm solo tiene dos propiedades: idRelationalStyle y valorationDegree, accedemos a él de forma directa.
  if (objectKey === objectName.sellerForm) {
    return data[objectKey][valueToSearch]
      ? data[objectKey][valueToSearch]
      : null;
  }

  //Las siguientes claves requieren de un array (son multirespuesta), por ello, convertimos el string que viene en un array.
  const keysWithArraysExpected = [
    "aficiones", // info cliente
    // "gadgetsQueUsa", // info cliente
    // "marcasTecnologicas", // info cliente
    "Vivienda_buscada__c", // info cliente
    "Presupuesto__c", // pago
    "PerFinanciacionNecesario__c", // pago

    "Ingresos_Anuales_Aprox_Familiares__pc", // info ingresos aprox familiares

    "caracteristicas_vivienda_buscada", // preferencias -> vivienda buscada

    "transporte", // preferencias -> dotaciones
    "educacion", // preferencias -> dotaciones
    "salud", // preferencias -> dotaciones
    "ocio_y_deportes", // preferencias -> dotaciones
    "alimentacion", // preferencias -> dotaciones
    "compras_y_cuidado_personal", // preferencias -> dotaciones
    "repostaje", // preferencias -> dotaciones

    "edificacion_diseno_edificio", // preferencias -> diseño
    "construccion_diseno_edificio", // preferencias -> diseño
    "arquitecto_diseno_edificio", // preferencias -> diseño

    "entorno_promocion", // preferencias -> dotaciones
    "orientacion_preferences", // preferencias -> ubicación

    "calidades_equipamiento_vivienda", // preferecias -> calidades y equipamiento visita
  ];

  // Miramos si el valueToSearch coincide con la key que debe ser un array.
  // Desde bbdd viene algo como esto --> "Pádel,Tenis,Futbol"
  if (keysWithArraysExpected.includes(valueToSearch)) {
    return data[objectKey].find((item: any) => item.name === valueToSearch)
      ?.value
      ? data[objectKey]
          .find((item: any) => item.name === valueToSearch)
          .value.split(",") //Devolvemos un array con el split.
      : "";
  }

  return data[objectKey]?.find((item: any) => item.name === valueToSearch)
    ?.value
    ? data[objectKey].find((item: any) => item.name === valueToSearch)?.value
    : null;
};

/**
 * @description Genera el objeto que se envía en el POST hacia el endpoint correspondiente.
 * @param {object} formValue Objeto formik con los datos registrados del cliente en la pantalla de Seller.
 *
 * @returns El objeto a enviar a la petición.
 */
export const generateObjectToSave = (formValue: any) => {
  return {
    sellerForm: [
      {
        valorationDegree: formValue.valorationDegree,
        idRelationalStyle: formValue.relationalStyle, // Estilo Relacional
      },
    ],
    preferencesForm: [
      {
        id_key: 1,
        value: formValue.transporte ? formValue.transporte.toString() : "",
      },
      {
        id_key: 2,
        value: formValue.educacion ? formValue.educacion.toString() : "",
      },
      {
        id_key: 3,
        value: formValue.salud ? formValue.salud.toString() : "",
      },
      {
        id_key: 4,
        value: formValue.ocio_y_deportes
          ? formValue.ocio_y_deportes.toString()
          : "",
      },
      {
        id_key: 5,
        value: formValue.alimentacion ? formValue.alimentacion.toString() : "",
      },
      {
        id_key: 6,
        value: formValue.compras_y_cuidado_personal
          ? formValue.compras_y_cuidado_personal.toString()
          : "",
      },
      {
        id_key: 7,
        value: formValue.repostaje ? formValue.repostaje.toString() : "",
      },
      {
        id_key: 11,
        value: formValue.orientacion_preferences
          ? formValue.orientacion_preferences.toString()
          : "",
      },
      {
        id_key: 16,
        value: formValue.caracteristicas_vivienda_buscada
          ? formValue.caracteristicas_vivienda_buscada.toString()
          : "",
      },
      {
        id_key: 17,
        value: formValue.entorno_promocion
          ? formValue.entorno_promocion.toString()
          : "",
      },
      {
        id_key: 18,
        value: formValue.edificacion_diseno_edificio
          ? formValue.edificacion_diseno_edificio.toString()
          : "",
      },
      {
        id_key: 19,
        value: formValue.construccion_diseno_edificio
          ? formValue.construccion_diseno_edificio.toString()
          : "",
      },
      {
        id_key: 20,
        value: formValue.arquitecto_diseno_edificio
          ? formValue.arquitecto_diseno_edificio.toString()
          : "",
      },
      {
        id_key: 21,
        value: formValue.calidades_equipamiento_vivienda
          ? formValue.calidades_equipamiento_vivienda.toString()
          : "",
      },
    ],
    clientForm: [
      {
        idclientform: 1,
        idkeyclient: 1,
        name: "Como__c",
        value: formValue.Como__c,
      },
      {
        idclientform: null,
        idkeyclient: 11,
        name: "PerFinanciacionNecesario__c",
        value: formValue.PerFinanciacionNecesario__c.toString(),
      },
      {
        idclientform: null,
        idkeyclient: 12,
        name: "DestinoVivienda__c",
        value: formValue.DestinoVivienda__c
          ? formValue.DestinoVivienda__c.toString()
          : "",
      },
      {
        idclientform: null,
        idkeyclient: 13,
        name: "Presupuesto__c",
        value: formValue.Presupuesto__c.toString(),
      },
      {
        idclientform: null,
        idkeyclient: 14,
        name: "Arquetipo__c",
        value: formValue.Arquetipo__c,
      },
      {
        idclientform: null,
        idkeyclient: 15,
        name: "Miembros_en_la_unidad_familiar__pc",
        value: formValue.Miembros_en_la_unidad_familiar__pc,
      },
      {
        idclientform: null,
        idkeyclient: 16,
        name: "Tipo_de_unidad_familiar__pc",
        value: formValue.Tipo_de_unidad_familiar__pc,
      },
      {
        idclientform: null,
        idkeyclient: 17,
        name: "Plazo_de_entrega_deseado__c",
        value: formValue.Plazo_de_entrega_deseado__c,
      },
      {
        idclientform: null,
        idkeyclient: 18,
        name: "Codigo_Postal_Actual__pc",
        value: formValue.Codigo_Postal_Actual__pc,
      },
      {
        idclientform: null,
        idkeyclient: 40,
        name: "Pais_residencia__pc",
        value: formValue.Pais_residencia__pc,
      },
      {
        idclientform: null,
        idkeyclient: 41,
        name: "Ingresos_Anuales_Aprox_Familiares__pc",
        value: formValue.Ingresos_Anuales_Aprox_Familiares__pc.toString(),
      },
      {
        idclientform: 2,
        idkeyclient: 2,
        name: "Como__c",
        value: formValue.Como__c,
      },
      {
        idclientform: 3,
        idkeyclient: 3,
        name: "Ocupacion__c",
        value: formValue.Ocupacion__c,
      },
      {
        idclientform: 4,
        idkeyclient: 4,
        name: "Numero_de_hijos__c",
        value: formValue.Numero_de_hijos__c,
      },
      {
        idclientform: 5,
        idkeyclient: 5,
        name: "Edad_hijo_hija_1__c",
        value: formValue.Edad_hijo_hija_1__c
          ? parseInt(formValue.Edad_hijo_hija_1__c)
          : 0,
      },
      {
        idclientform: null,
        idkeyclient: 6,
        name: "Edad_hijo_hija_2__c",
        value: formValue.Edad_hijo_hija_2__c
          ? parseInt(formValue.Edad_hijo_hija_2__c)
          : 0,
      },
      {
        idclientform: null,
        idkeyclient: 7,
        name: "Edad_hijo_hija_3__c",
        value: formValue.Edad_hijo_hija_3__c
          ? parseInt(formValue.Edad_hijo_hija_3__c)
          : 0,
      },
      {
        idclientform: null,
        idkeyclient: 8,
        name: "Edad_hijo_hija_4__c",
        value: formValue.Edad_hijo_hija_4__c
          ? parseInt(formValue.Edad_hijo_hija_4__c)
          : 0,
      },
      {
        idclientform: null,
        idkeyclient: 9,
        name: "Edad_hijo_hija_5__c",
        value: formValue.Edad_hijo_hija_5__c
          ? parseInt(formValue.Edad_hijo_hija_5__c)
          : 0,
      },
      {
        idclientform: null,
        idkeyclient: 10,
        name: "Edad_hijo_hija_6__c",
        value: formValue.Edad_hijo_hija_6__c
          ? parseInt(formValue.Edad_hijo_hija_6__c)
          : 0,
      },
      {
        idclientform: null,
        idkeyclient: 19,
        name: "Profesion__c",
        value: formValue.Profesion__c,
      },
      {
        idclientform: null,
        idkeyclient: 20,
        name: "profesionConyuge2",
        value: formValue.profesionConyuge2,
      },
      {
        idclientform: null,
        idkeyclient: 21,
        name: "Empresa_cliente__pc",
        value: formValue.Empresa_cliente__pc,
      },
      {
        idclientform: null,
        idkeyclient: 22,
        name: "Empresa_De_Conyugue_2__pc",
        value: formValue.Empresa_De_Conyugue_2__pc,
      },
      {
        idclientform: null,
        idkeyclient: 23,
        name: "Vivienda_actual_en_prop__pc",
        value: formValue.Vivienda_actual_en_prop__pc,
      },
      {
        idclientform: null,
        idkeyclient: 24,
        name: "Vive_de_alquiler__c",
        value: formValue.Vive_de_alquiler__c,
      },
      {
        idclientform: null,
        idkeyclient: 25,
        name: "Con_quien_compra__c",
        value: formValue.Con_quien_compra__c,
      },
      {
        idclientform: null,
        idkeyclient: 26,
        name: "aficiones",
        value: formValue.aficiones ? formValue.aficiones.toString() : "",
      },
      {
        idclientform: null,
        idkeyclient: 27,
        name: "Nombre_hijo_hija_1__c",
        value: formValue.Nombre_hijo_hija_1__c,
      },
      {
        idclientform: null,
        idkeyclient: 28,
        name: "Nombre_hijo_hija_2__c",
        value: formValue.Nombre_hijo_hija_2__c,
      },
      {
        idclientform: null,
        idkeyclient: 29,
        name: "Nombre_hijo_hija_3__c",
        value: formValue.Nombre_hijo_hija_3__c,
      },
      {
        idclientform: null,
        idkeyclient: 30,
        name: "Nombre_hijo_hija_4__c",
        value: formValue.Nombre_hijo_hija_4__c,
      },
      {
        idclientform: null,
        idkeyclient: 31,
        name: "Nombre_hijo_hija_5__c",
        value: formValue.Nombre_hijo_hija_5__c,
      },
      {
        idclientform: null,
        idkeyclient: 32,
        name: "Nombre_hijo_hija_6__c",
        value: formValue.Nombre_hijo_hija_6__c,
      },
      {
        idclientform: null,
        idkeyclient: 33,
        name: "que_busca",
        value: formValue.que_busca,
      },
      {
        idclientform: null,
        idkeyclient: 34,
        name: "valorationDegree",
        value: formValue.valorationDegree,
      },
      {
        idclientform: null,
        idkeyclient: 35,
        name: "Dormitorios__c",
        value: formValue.Dormitorios__c,
      },
      {
        idclientform: null,
        idkeyclient: 36,
        name: "Vivienda_buscada__c",
        value: formValue.Vivienda_buscada__c
          ? formValue.Vivienda_buscada__c.toString()
          : "",
      },
      {
        idclientform: null,
        idkeyclient: 37,
        name: "Garajes_necesarios__c",
        value: formValue.Garajes_necesarios__c,
      },
      {
        idclientform: null,
        idkeyclient: 42,
        name: "Cuantia_mensual_alquiler",
        value: formValue.Cuantia_mensual_alquiler,
      },
    ],
    promotionForm: [],
  };
};

/**
 * @description Inserta los valores de la API en la pantalla de Seller.
 * @param {object} formik Formulario Formik.
 * @param {object} dataFromApi Datos devueltos por el endpoint.
 */
export const convertDataFromApiToFormikObjectValues = (
  formik: any,
  dataFromApi: any
) => {
  formik.setFieldValue(
    "Como__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Como__c")
  );

  formik.setFieldValue(
    "Pais_residencia__pc",
    getValueForForm(dataFromApi, objectName.clientForm, "Pais_residencia__pc")
  );

  formik.setFieldValue(
    "Ingresos_Anuales_Aprox_Familiares__pc",
    getValueForForm(
      dataFromApi,
      objectName.clientForm,
      "Ingresos_Anuales_Aprox_Familiares__pc"
    )
  );

  formik.setFieldValue(
    "Codigo_Postal_Actual__pc",
    getValueForForm(
      dataFromApi,
      objectName.clientForm,
      "Codigo_Postal_Actual__pc"
    )
  );

  formik.setFieldValue(
    "Profesion__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Profesion__c")
  );

  formik.setFieldValue(
    "profesionConyuge2",
    getValueForForm(dataFromApi, objectName.clientForm, "profesionConyuge2")
  );

  formik.setFieldValue(
    "Empresa_cliente__pc",
    getValueForForm(dataFromApi, objectName.clientForm, "Empresa_cliente__pc")
  );

  formik.setFieldValue(
    "Empresa_De_Conyugue_2__pc",
    getValueForForm(
      dataFromApi,
      objectName.clientForm,
      "Empresa_De_Conyugue_2__pc"
    )
  );

  formik.setFieldValue(
    "Tipo_de_unidad_familiar__pc",
    getValueForForm(
      dataFromApi,
      objectName.clientForm,
      "Tipo_de_unidad_familiar__pc"
    )
  );

  formik.setFieldValue(
    "Miembros_en_la_unidad_familiar__pc",
    getValueForForm(
      dataFromApi,
      objectName.clientForm,
      "Miembros_en_la_unidad_familiar__pc"
    )
  );

  formik.setFieldValue(
    "Numero_de_hijos__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Numero_de_hijos__c")
  );

  formik.setFieldValue(
    "Nombre_hijo_hija_1__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Nombre_hijo_hija_1__c")
  );

  formik.setFieldValue(
    "Nombre_hijo_hija_2__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Nombre_hijo_hija_2__c")
  );

  formik.setFieldValue(
    "Nombre_hijo_hija_3__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Nombre_hijo_hija_3__c")
  );

  formik.setFieldValue(
    "Nombre_hijo_hija_4__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Nombre_hijo_hija_4__c")
  );

  formik.setFieldValue(
    "Nombre_hijo_hija_5__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Nombre_hijo_hija_5__c")
  );

  formik.setFieldValue(
    "Nombre_hijo_hija_6__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Nombre_hijo_hija_6__c")
  );

  formik.setFieldValue(
    "Edad_hijo_hija_1__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Edad_hijo_hija_1__c")
  );

  formik.setFieldValue(
    "Edad_hijo_hija_2__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Edad_hijo_hija_2__c")
  );

  formik.setFieldValue(
    "Edad_hijo_hija_3__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Edad_hijo_hija_3__c")
  );

  formik.setFieldValue(
    "Edad_hijo_hija_4__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Edad_hijo_hija_4__c")
  );

  formik.setFieldValue(
    "Edad_hijo_hija_5__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Edad_hijo_hija_5__c")
  );

  formik.setFieldValue(
    "Edad_hijo_hija_6__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Edad_hijo_hija_6__c")
  );

  // A qué te dedicas.
  formik.setFieldValue(
    "Ocupacion__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Ocupacion__c")
  );

  formik.setFieldValue(
    "Vivienda_actual_en_prop__pc",
    getValueForForm(
      dataFromApi,
      objectName.clientForm,
      "Vivienda_actual_en_prop__pc"
    )
  );

  formik.setFieldValue(
    "Vive_de_alquiler__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Vive_de_alquiler__c")
  );

  formik.setFieldValue(
    "Cuantia_mensual_alquiler",
    getValueForForm(
      dataFromApi,
      objectName.clientForm,
      "Cuantia_mensual_alquiler"
    )
  );

  formik.setFieldValue(
    "Con_quien_compra__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Con_quien_compra__c")
  );

  //¿Cuáles son tus aficiones?
  formik.setFieldValue(
    "aficiones",
    getValueForForm(dataFromApi, objectName.clientForm, "aficiones")
  );

  // ¿Qué es lo que estás buscando?
  formik.setFieldValue(
    "que_busca",
    getValueForForm(dataFromApi, objectName.clientForm, "que_busca")
  );

  //  Info Cliente --> Vivienda buscada (aunque ahora sale en el tab de preferencias)
  formik.setFieldValue(
    "Dormitorios__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Dormitorios__c")
  );

  formik.setFieldValue(
    "Vivienda_buscada__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Vivienda_buscada__c")
  );

  formik.setFieldValue(
    "Garajes_necesarios__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Garajes_necesarios__c")
  );

  formik.setFieldValue(
    "caracteristicas_vivienda_buscada",
    getValueForForm(
      dataFromApi,
      objectName.preferencesForm,
      "caracteristicas_vivienda_buscada"
    )
  );

  // Motivo de la compra
  formik.setFieldValue(
    "DestinoVivienda__c",
    getValueForForm(dataFromApi, objectName.clientForm, "DestinoVivienda__c")
  );

  // ** End: Tab Info Cliente **

  // ** Start: Tab Preferences **

  // Vivienda buscada (salen en el tab de info cliente)

  // Dotaciones de la zona
  formik.setFieldValue(
    "transporte",
    getValueForForm(dataFromApi, objectName.preferencesForm, "transporte")
  );

  formik.setFieldValue(
    "educacion",
    getValueForForm(dataFromApi, objectName.preferencesForm, "educacion")
  );

  formik.setFieldValue(
    "salud",
    getValueForForm(dataFromApi, objectName.preferencesForm, "salud")
  );

  formik.setFieldValue(
    "ocio_y_deportes",
    getValueForForm(dataFromApi, objectName.preferencesForm, "ocio_y_deportes")
  );

  formik.setFieldValue(
    "alimentacion",
    getValueForForm(dataFromApi, objectName.preferencesForm, "alimentacion")
  );

  formik.setFieldValue(
    "compras_y_cuidado_personal",
    getValueForForm(
      dataFromApi,
      objectName.preferencesForm,
      "compras_y_cuidado_personal"
    )
  );

  formik.setFieldValue(
    "repostaje",
    getValueForForm(dataFromApi, objectName.preferencesForm, "repostaje")
  );

  // Ubicación de la promoción
  formik.setFieldValue(
    "entorno_promocion",
    getValueForForm(
      dataFromApi,
      objectName.preferencesForm,
      "entorno_promocion"
    )
  );

  formik.setFieldValue(
    "orientacion_preferences",
    getValueForForm(
      dataFromApi,
      objectName.preferencesForm,
      "orientacion_preferences"
    )
  );

  // Diseño del edificio
  formik.setFieldValue(
    "edificacion_diseno_edificio",
    getValueForForm(
      dataFromApi,
      objectName.preferencesForm,
      "edificacion_diseno_edificio"
    )
  );

  formik.setFieldValue(
    "construccion_diseno_edificio",
    getValueForForm(
      dataFromApi,
      objectName.preferencesForm,
      "construccion_diseno_edificio"
    )
  );

  formik.setFieldValue(
    "arquitecto_diseno_edificio",
    getValueForForm(
      dataFromApi,
      objectName.preferencesForm,
      "arquitecto_diseno_edificio"
    )
  );

  // Calidades equipamiento vivienda
  formik.setFieldValue(
    "calidades_equipamiento_vivienda",
    getValueForForm(
      dataFromApi,
      objectName.preferencesForm,
      "calidades_equipamiento_vivienda"
    )
  );

  // ** End: Tab Preferences **

  // ** Start: Tab Pago **

  // Presupuesto
  formik.setFieldValue(
    "Presupuesto__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Presupuesto__c") === ""
      ? [0, 3000000]
      : getValueForForm(dataFromApi, objectName.clientForm, "Presupuesto__c")
  );

  // Porcentaje_De_Financiacion_Necesario__c
  formik.setFieldValue(
    "PerFinanciacionNecesario__c",
    getValueForForm(
      dataFromApi,
      objectName.clientForm,
      "PerFinanciacionNecesario__c"
    )
  );

  // Plazo_de_entrega_deseado__c
  formik.setFieldValue(
    "Plazo_de_entrega_deseado__c",
    getValueForForm(
      dataFromApi,
      objectName.clientForm,
      "Plazo_de_entrega_deseado__c"
    )
  );
  // ** End: Tab Pago **

  // Varios datos "desperdigados" por la pantalla

  formik.setFieldValue(
    "relationalStyle",
    getValueForForm(dataFromApi, objectName.sellerForm, "idRelationalStyle")
  );

  formik.setFieldValue(
    "valorationDegree",
    getValueForForm(dataFromApi, objectName.sellerForm, "valorationDegree")
  );

  formik.setFieldValue(
    "Arquetipo__c",
    getValueForForm(dataFromApi, objectName.clientForm, "Arquetipo__c")
  );
};
