import FormLabel from "@mui/joy/FormLabel";
import Radio, { radioClasses } from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Sheet from "@mui/joy/Sheet";
import { useEffect, useRef, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsFillCheckCircleFill } from "react-icons/bs";
import "./MultipleSelect.css";

function MultipleSelect(props: any) {
  const { multiple, onChange, remove, values, xs } = props;
  const [Selected, setSelected] = useState<any>();
  const [SelectedMultiple, setSelectedMultiple] = useState([]);
  const firstRender = useRef(true);

  useEffect(() => {
    if (!firstRender.current) {
      if (multiple && onChange) {
        onChange(SelectedMultiple);
      } else if (onChange) {
        onChange(Selected);
      }
    }
    firstRender.current = false;
  }, [SelectedMultiple, Selected]);

  useEffect(() => {
    if (values) {
      if (multiple) {
        setSelectedMultiple(values.map((e: any) => e));
      } else {
        setSelected(values);
      }
    } else {
      if (multiple) {
        setSelectedMultiple([]);
      } else {
        setSelected(null);
      }
    }
  }, [props.title]);

  return (
    <div
      className="div-multiple-select"
      style={{
        width: props.showCountrys ? "100%" : "fit-content",
        paddingTop: !props.title ? "0px" : "10px",
        paddingRight: "10px",
      }}
    >
      {props.title && !props.objection ? (
        <div className="d-flex align-items-center gap-1">
          {props.displayedInClientView && (
            <span className="dot-question-in-client-view"></span>
          )}
          <label className="nextui-input-block-label">{props.title}</label>
        </div>
      ) : (
        <></>
      )}

      <RadioGroup
        aria-label="platform"
        defaultValue="Website"
        overlay
        name="platform"
        sx={{
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 2,
          [`& .${radioClasses.radio}`]: {
            display: "contents",
            "& > svg": {
              zIndex: 2,
              position: "absolute",
              top: "-8px",
              right: "-8px",
              bgcolor: "background.body",
              borderRadius: "50%",
            },
          },
        }}
      >
        {props.list.map((value: any, i: any) => {
          return (
            <ElementSelect
              setOtherCountries={props.setOtherCountries}
              showCountrys={props.showCountrys}
              otherCountris={props.otherCountris}
              key={`${i}- ${value.toString()}`}
              remove={remove}
              isCheked={
                multiple
                  ? false
                  : values === value?.salesforce_value &&
                    props.values === "España"
              }
              value={value}
              multiple={multiple}
              setSelected={setSelected}
              setSelectedMultiple={setSelectedMultiple}
              Selected={Selected}
              SelectedMultiple={SelectedMultiple}
              values={values}
              xs={xs}
            ></ElementSelect>
          );
        })}
      </RadioGroup>
    </div>
  );
}

export default MultipleSelect;

function ElementSelect(props: any) {
  const {
    value,
    multiple,
    SelectedMultiple,
    Selected,
    setSelected,
    setSelectedMultiple,
    remove,
    xs,
    //values,
  } = props;

  const [isCheked, setisCheked] = useState(props.isCheked);

  useEffect(() => {
    if (value.salesforce_value === Selected) {
      setisCheked(true);
    } else if (
      SelectedMultiple.find((s: any) => s === value.salesforce_value) !==
      undefined
    ) {
      setisCheked(true);
    } else {
      setisCheked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Selected, SelectedMultiple]);

  return (
    <Sheet
      key={value.id}
      variant="outlined"
      onClick={(e) => {
        if (props.showCountrys) {
          props.setOtherCountries(value.id !== 1);
        }
        if (multiple) {
          if (SelectedMultiple.find((s: any) => s === value.salesforce_value)) {
            const newArray = SelectedMultiple.filter(
              (o: any) => o !== value.salesforce_value
            );

            setSelectedMultiple(newArray);
          } else {
            const newArray = [...SelectedMultiple, value.salesforce_value];
            setSelectedMultiple(newArray);
          }
        } else {
          if (Selected === value.salesforce_value) {
            setSelected(null);
          } else {
            setSelected(value.salesforce_value);
          }
        }
      }}
      sx={{
        borderRadius: "md",
        border: "1px solid",
        borderColor: "#0088a6",
        bgcolor: "background.body",
        boxShadow: "sm",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 0,
        p: 1,
        width: xs ? 70 : 120,
        height: 60,
        [`& .${radioClasses.checked}`]: {
          [`& .${radioClasses.action}`]: {
            inset: -1,
            border: "4px solid",
            borderColor: `${
              remove ? "var(--aedas-red)" : value.color ?? "#0088a6"
            }`,
          },
        },
      }}
    >
      <Radio
        id={value.salesforce_value.toLowerCase()}
        value={value.salesforce_value}
        checked={isCheked}
        checkedIcon={
          remove ? (
            <AiFillCloseCircle
              size={20}
              color={"var(--aedas-red)"}
              style={{ background: "white" }}
            ></AiFillCloseCircle>
          ) : (
            <BsFillCheckCircleFill
              size={20}
              color={`${
                remove ? "var(--aedas-red)" : value.color ?? "#0088a6"
              }`}
              style={{ background: "white" }}
            />
          )
        }
      />
      {value.icon}

      <FormLabel
        className="text-center"
        style={{ fontSize: "12px" }}
        htmlFor={value.id}
      >
        {value.name}
      </FormLabel>
    </Sheet>
  );
}
