import { useEffect, useState } from "react";
import { getHouseInfoByAssets } from "../../Service/HouseInfoByAssets/HouseInfoByAssets";
import {
  deleteLikePromoAsset,
  getLikesService,
} from "../../Service/LikesService/LikesService";

import AedasLoading from "../AedasLoading/AedasLoading";

import { Button, Modal } from "@nextui-org/react";

import { MdErrorOutline } from "react-icons/md";
import { getDocumentsService } from "../../Service/DocumentsService/DocumentsService";
import PrintDocuments from "../PrintDocuments/PrintDocuments";
import FavoriteHouseInModal from "./FavoriteHouseInModal";
import "./ModalFavoritesClient.css";

function ModalFavoritesClient(props: any) {
  const {
    idClient,
    idPromotion,
    setOpenModalFavoritosCliente,
    sendJson,
    editable,
  } = props;

  const [likedElements, setLikedElements] = useState<any>({});
  const [infoHouse, setInfoHouse] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<any>(true);
  const [planosFavoritos, setPlanosFavoritos] = useState<any>([]);
  const [documentacion, setDocumentacion] = useState<any>([]);

  const [pointHouse, setPointHouse] = useState(null); // Para marcar una vivienda en la pantalla del cliente.

  useEffect(() => {
    const fetchData = async () => {
      if (idClient && idPromotion && isLoading) {
        let responseLikes = await getLikesService(idClient, idPromotion);

        // Documentos que se pasan al boton de imprimir.
        let documentation = await getDocumentsService(idPromotion);

        // Obtenemos la información de la casa por los IDs de los planos enviados.
        let responseInfoAssets = await getHouseInfoByAssets(
          responseLikes.data?.planos?.map((plano: any) => plano.asset_id)
        );

        // Planos favoritos que se pasan al boton de imprimir
        let dataFavouritesPlaneHouse = responseLikes.data?.planos.map(
          (plano: any) => plano.url
        );

        setDocumentacion(documentation);
        setPlanosFavoritos(dataFavouritesPlaneHouse);
        setInfoHouse(responseInfoAssets.data);
        setLikedElements(responseLikes.data);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [idClient, idPromotion, isLoading]);

  useEffect(() => {
    sendJson({
      event: "mostrar_viviendas_favoritas",
      list: likedElements,
      clientId: idClient,
      promotionId: idPromotion,
    });
  }, [likedElements]);

  const handleDislike = (idLike: any) => {
    deleteLikePromoAsset(idLike).then(() => setIsLoading(true));
  };

  if (isLoading) {
    return (
      <div
        className="d-flex flex-column justify-content-center"
        style={{ minHeight: "0px" }}
      >
        <AedasLoading></AedasLoading>
      </div>
    );
  }
  return (
    <>
      <Modal.Body className="modal-favorites-from-seller-view py-4">
        <div className="">
          <h1
            style={{
              fontFamily: "lis-body",
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Al cliente le ha gustado
          </h1>
          <h3>Imágenes</h3>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "1rem",
              padding: "1rem",
            }}
          >
            {likedElements?.imagenes && likedElements?.imagenes.length > 0 ? (
              likedElements?.imagenes.map((image: any, i: any) => {
                return (
                  <div
                    key={`${i}-${image.url}`}
                    className="d-flex flex-column align-items-center justify-content-center"
                  >
                    <img
                      style={{ maxHeight: "600px" }}
                      src={image.url}
                      alt="Imagen"
                    />
                  </div>
                );
              })
            ) : (
              <p className="font-family-lis d-flex align-items-center gap-1">
                <MdErrorOutline size={24} color={"#666"} /> Sin imágenes
                favoritas
              </p>
            )}
          </div>
          <h3>Planos</h3>
          <div
            className="grid-listado-viviendas mt-2"
            style={{
              display: "grid",
              gap: "1rem",
              padding: "1rem",
            }}
          >
            {likedElements?.planos && likedElements?.planos.length > 0 ? (
              likedElements?.planos.map((map: any, i: any) => {
                let informationHouse = infoHouse?.find(
                  (info: any) => info.Asset_Id === map.asset_id
                );
                return (
                  <FavoriteHouseInModal
                    key={`${i}-`}
                    informationHouse={informationHouse}
                    map={map}
                    likedElements={likedElements}
                    sendJson={sendJson}
                    pointHouse={pointHouse}
                    handleDislike={handleDislike}
                    setPointHouse={setPointHouse}
                  />
                );
              })
            ) : (
              <p className="font-family-lis d-flex align-items-center gap-1">
                <MdErrorOutline size={24} color={"#666"} /> Sin planos favoritos
              </p>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex gap-2">
          {editable && (
            <PrintDocuments
              planosFavoritos={planosFavoritos}
              documentacion={documentacion}
              loading={isLoading}
            />
          )}
          <Button
            css={{ background: "var(--blue-lis)" }}
            onClick={() => {
              setOpenModalFavoritosCliente(false);
            }}
          >
            Cerrar
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
}

export default ModalFavoritesClient;
