import PrettoSlider from "./PrettoSlider";

function SliderCustom(props: any) {
  return (
    <div style={{ paddingTop: "50x", width: "100%" }}>
      {props.interval ? (
        <label
          style={{ paddingBottom: "2rem" }}
          className="nextui-input-block-label"
        >
          {props.title}
        </label>
      ) : (
        <div className="d-flex align-items-center gap-1">
          {props.displayedInClientView && (
            <span className="dot-question-in-client-view"></span>
          )}
          <label className="nextui-input-block-label">{props.title}</label>
        </div>
      )}

      <div style={{ paddingLeft: "15px", width: "100%" }}>
        <PrettoSlider {...props}></PrettoSlider>
      </div>
    </div>
  );
}

export default SliderCustom;
