import { Card } from "@nextui-org/react";

function PromotionCardInfo(props: any) {
  const { icon, title, value, isOutAppointment } = props;

  return (
    <Card
      css={{
        height: "65px",
        padding: "10px",
        display: "flex",
        flexDirection: "row",
        width: isOutAppointment ? "300px" : "100%",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            borderRadius: "10px",
            width: "2.2rem",
            height: "2.2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "var(--lis-ligthblue)",
          }}
        >
          {icon}
        </div>
        <h1
          style={{
            textAlign: "start",
            fontFamily: "lis-title",
            fontSize: "15px",
            fontWeight: "bold",
            margin: "0px",
            color: "var(--secondary-color)",
          }}
        >
          {title}
        </h1>
      </div>
      <div style={{ width: "40%" }}>
        <h2
          style={{
            fontFamily: "lis-body",
            fontSize: "19px",
            margin: "0px",
            color: "black",
            textAlign: "end",
          }}
        >
          {value}
        </h2>
      </div>
    </Card>
  );
}

export default PromotionCardInfo;
