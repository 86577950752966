import { Card } from "@nextui-org/react";

function HistoryComponent(props: any) {
  const duration = (time: string) => {
    const splitTime = time.split(":");
    return splitTime[0] + " min " + splitTime[1] + " s";
  };

  return (
    <Card
      style={{
        display: "flex",
        overflow: "inherit",
        minHeight: "110px",
        maxHeight: "110px",
      }}
    >
      <div
        style={{
          padding: "5px",
          display: "flex",
          height: "125px",
        }}
      >
        <div
          style={{
            width: "30%",
            display: "flex",
            flexDirection: "column",
            borderRight: "1px solid #ddd",
            alignItems: "center",
            justifyContent: "center",
            paddingRight: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1
              style={{
                textAlign: "start",
                fontFamily: "lis-title",
                fontSize: "25px",
                fontWeight: "bold",
              }}
            >
              {props.hour}
            </h1>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1
              style={{
                textAlign: "start",
                fontFamily: "lis-body",
                fontSize: "12px",
              }}
            >
              {props.date}
            </h1>
          </div>
        </div>
        <div
          style={{
            width: "70%",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            marginLeft: "1rem",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "start",
              flexDirection: "column",
            }}
          >
            <h1
              style={{
                textAlign: "start",
                fontFamily: "lis-title",
                fontSize: "12px",
                fontWeight: "bold",
                margin: "0px",
                color: "var(--secondary-color)",
              }}
            >
              Llamada telefónica
            </h1>
            <h2
              style={{
                textAlign: "start",
                fontFamily: "lis-body",
                fontSize: "16px",
                margin: "0px",
              }}
            >
              {props.description ?? "-"}
            </h2>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
                flexDirection: "column",
              }}
            >
              <h1
                style={{
                  textAlign: "start",
                  fontFamily: "lis-title",
                  fontSize: "12px",
                  margin: "0px",
                  fontWeight: "bold",

                  color: "var(--secondary-color)",
                }}
              >
                Duración
              </h1>
              <h2
                style={{
                  textAlign: "start",
                  fontFamily: "lis-body",
                  fontSize: "16px",
                }}
              >
                {duration(props.duration)}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default HistoryComponent;
