import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

/**
 * @description Obtiene los datos del formulario de la página de seller.
 *
 * @param {string} idPromo Id de la promoción, se obtiene del getPromoByAppointmentId.
 * @param {string} idClient Id del cliente, se puede obtener con getClientByAppointmentId.
 * @param {string} idSeller Id del vendedor, se puede obtener del localStorage.
 * @param {string} id_appoiment Id de la cita.
 * @returns
 */
export const getDataFormSeller = (
  idPromo: string,
  idClient: string,
  idSeller: string,
  id_appoiment: string
) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND +
      "/Forms/" +
      idPromo +
      "/" +
      idClient +
      "/" +
      idSeller +
      "/" +
      id_appoiment,
    getDefaultConfigHeader()
  );
};

/**
 * @description Guarda los datos del formulario de la página de seller.
 *
 * @param {string} idClient Id del cliente, se puede obtener con getClientByAppointmentId.
 * @param {string} idSeller Id del vendedor, se puede obtener del localStorage.
 * @param {string} id_appoiment Id de la cita.
 * @param {object} dataForm Notas registradas por el vendedor.
 * @returns
 */
export const setDataFormSeller = (
  idClient: string,
  idSeller: string,
  id_appoiment: string,
  dataForm: object
) => {
  // Cuando estamos en PRODUCCIÓN el id de la cita tiene que ser la correspondiente
  if (process.env.REACT_APP_IS_PRO === "true") {
    return axios.post(
      process.env.REACT_APP_API_BACKEND +
        "/Forms/" +
        idClient +
        "/" +
        idSeller +
        "/" +
        id_appoiment,
      dataForm,
      getDefaultConfigHeader()
    );
  }

  // Cuando NO ESTAMOS en PRODUCCIÓN el id de la cita tiene que el siguiente 00U9L000001OH3eUAG
  return axios.post(
    process.env.REACT_APP_API_BACKEND +
      "/Forms/" +
      idClient +
      "/" +
      idSeller +
      "/00U9L000001OH3eUAG",
    dataForm,
    getDefaultConfigHeader()
  );
};
