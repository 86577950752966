import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const getPromotionFeatures = (promotionID: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/Promotion/features/" + promotionID,
    getDefaultConfigHeader()
  );
};

/**
 * Devuelve las promociones del mismo punto de venta
 * @param appointmentID ID de la cita
 * @returns Promociones del mismo punto de vfenta
 */
export const getPromotionsByAppointment = (appointmentID: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND +
      "/Appointment/promotionPV/" +
      appointmentID,
    getDefaultConfigHeader()
  );
};

/**
 * Devuelve la promocion por ID
 * @param promotionID ID de la promocion
 * @returns
 */
export const getPromotionByID = (promotionID: any) => {
  return axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/Promotion/" + promotionID,
      getDefaultConfigHeader()
    )
    .then((res) => res.data)
    .catch((error) => console.error("Error al traer la promocion"));
};
